import React, {useState} from "react";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
import {indicatorsBreadcumbs} from "./content";
import IndicatorList from "./indicatorList/index.jsx";
import AddIndicatorModal from "./indicatorList/addIndicatorModal.jsx";
import {IndicatorsContextProvider} from "../../../../contexts/indicatorsContext.jsx";
import {permissionsCheck} from "../../../../helpers/utils";

const Indicators = () => {
  const [openAddModal, setOpenAddModal] = useState(false);

  return (
    <IndicatorsContextProvider>
      <HeaderWithBreadcrumbLayout
        breadcrumbArr={indicatorsBreadcumbs}
        btnLabel="إضافة مؤشر"
        handleBtnClick={() => setOpenAddModal(true)}
        hideBtn={permissionsCheck("Pages.Indicators.Create") ? false : true}
      >
        <AddIndicatorModal
          openAddModal={openAddModal}
          setOpenAddModal={setOpenAddModal}
        />
        <IndicatorList/>
      </HeaderWithBreadcrumbLayout>
    </IndicatorsContextProvider>
  );
};

export default Indicators;
