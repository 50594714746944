import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../helpers/isPermission";

export const useGetAuditorExpertPerspectives = ({ currentPage }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAuditorExpertPerspectives } = useQuery({
    queryKey: ["auditorExpertPerspectives", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertPerspectives, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const perspectives = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["auditorExpertPerspectives", currentPage + 1, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertPerspectives, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["auditorExpertPerspectives", currentPage - 1, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertPerspectives, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    perspectives,
    totalCount,
    totalPages,
    data,
    isGettingAuditorExpertPerspectives,
  };
};
