import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../helpers/isPermission";

export const useGetAuditorExpertUnfinishedStandards = ({ currentPage }) => {
  // const { currentStage } = useContext(StageContext);
  const surveyStageId = localStorage.getItem("surveyStageId");

  const queryClient = useQueryClient();

  const {
    data,
    isLoading: isGettingAuditorExpertUnfinishedStandards,
  } = useQuery({
    queryKey: ["AuditorExpertUnfinishedStandards", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const unfinishedStandards = data?.result?.unReviewedAgencies;
  const allStandardsCount = data?.result?.totalAssignedAgenciesCount;
  const unfinishedStandardsCount = data?.result?.totalUnReviewedAgenciesCount;
  const totalPages = Math.ceil(unfinishedStandardsCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertUnfinishedStandards",
        currentPage + 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertUnfinishedStandards",
        currentPage - 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertUnfinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    unfinishedStandards,
    unfinishedStandardsCount,
    allStandardsCount,
    totalPages,
    data,
    isGettingAuditorExpertUnfinishedStandards,
  };
};
