import {useEffect, useState} from "react";
import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import {indicatorDetailsBreadcumbs} from "../content";
import environment from "../../../../../services/environment";
import {axiosGetRequest} from "../../../../../services/Request";
import {useParams} from "react-router";
import Skeleton from "react-skeleton-loader";
import {formateDate} from "../../../../../helpers/dateFormat";
import AddSurveyModal from "./addSurveyModal";
import IndicatorSurveys from "./indicatorSurveys";
import {IndicatorsContextProvider} from "../../../../../contexts/indicatorsContext";
import {permissionsCheck} from "../../../../../helpers/utils";

const IndicatorDetails = () => {
  const {indicatorId} = useParams();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [indicator, setIndicator] = useState([]);

  const getIndicatorDetails = async () => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getIndicatorDetails, {
      Id: indicatorId,
    });
    if (res?.success) {
      setIndicator(res?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIndicatorDetails();
  }, []);

  return (
    <IndicatorsContextProvider>
      <HeaderWithBreadcrumbLayout
        btnLabel="إضافة استبيان"
        breadcrumbArr={indicatorDetailsBreadcumbs}
        handleBtnClick={() => setOpenAddModal(true)}
      >
        <AddSurveyModal
          openAddModal={openAddModal}
          setOpenAddModal={setOpenAddModal}
          indicatorId={indicatorId}
        />

        <div className="">
          {permissionsCheck("Pages.Indicators.Get") ?
            <div className=" p-4 pt-3 mx-2  bg-gray_light rounded-lg ">
              <h3 className="text-blue text-lg"> بيانات المؤشر </h3>
              <div className="p-4 px-6 bg-white rounded pt-3 mt-3">
                <h2 className="text-blue text-lg">
                  {loading ? <Skeleton width="300px"/> : indicator?.title}
                </h2>
                <p className="text-light pt-4 max-w-[750px] pb-6">
                  {loading ? (
                    <Skeleton count={2} width="100%"/>
                  ) : (
                    indicator?.description
                  )}
                </p>
                <div className="flex flex-wrap gap-1 gap-x-16 mb-2">
                  <div className="flex gap-3">
                    {loading ? (
                      <Skeleton width="250px"/>
                    ) : (
                      <>
                        <p className="text-lg text-blue">أضيف بواسطة</p>
                        <p className="text-light text-lg">
                          {indicator?.creatorUserName}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="flex gap-3">
                    {loading ? (
                      <Skeleton width="320px"/>
                    ) : (
                      <>
                        <p className="text-lg text-blue">تاريخ اضافة المؤشر</p>
                        <p className="text-green text-lg">
                          {formateDate(indicator?.creationTime)}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="flex gap-3">
                    {loading ? (
                      <Skeleton width="230px"/>
                    ) : (
                      <>
                        <p className="text-lg text-blue">
                          عدد الدورات داخل المؤشر
                        </p>
                        <p className="text-green text-lg">
                          {indicator?.surveysCount}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            : <div
              className="text-[#bb2124] text-xl text-center flex justify-center items-center">{"ليس لديك صلاحية لهذا"}</div>}
          <IndicatorSurveys/>
        </div>
      </HeaderWithBreadcrumbLayout>
    </IndicatorsContextProvider>
  );
};

export default IndicatorDetails;
