import { Fragment } from "react";
import Breadcrumb from "./breadcrumbItem";
import AppButton from "../../components/buttons/appButton";

const BreadcrumbList = ({
  breadcrumbArr,
  handleBtnClick,
  btnLabel,
  hideBtn,
  hideBtnSeconed,
  handleBtnSeconedClick,
  btnLabelSeconed,
}) => {
  const renderedBreadcrumb = breadcrumbArr?.map((item, indx) => (
    <Fragment key={indx}>
      <Breadcrumb {...item} />
      {breadcrumbArr?.length - 1 !== indx && (
        <span className="lg:mr-5 lg:ml-5 mx-2">/</span>
      )}
    </Fragment>
  ));
  return (
    <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-[30px] p-5 m-5 h-[70px]">
      <div className="flex">{renderedBreadcrumb}</div>
      <div className="flex flex-row items-center">
        {btnLabelSeconed && !hideBtnSeconed && (
          <AppButton onClick={handleBtnSeconedClick} className="mr-3 ml-3">
            {btnLabelSeconed}
          </AppButton>
        )}
        {btnLabel && !hideBtn && (
          <AppButton onClick={handleBtnClick}>{btnLabel}</AppButton>
        )}
      </div>
    </div>
  );
};

export default BreadcrumbList;
