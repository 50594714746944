import { AnserContextProvider } from "./answerContext";
import NewStandardAnswer from "./standardAnswer";

const NewStandardAnswerProgram = () => {
  return (
    <AnserContextProvider>
      <NewStandardAnswer />
    </AnserContextProvider>
  );
};

export default NewStandardAnswerProgram;
