import React, { useContext, useEffect, useState } from "react";
import { StandardProgramContext } from "../../../../../../contexts/StandardProgramContext";
import { DropdownMultiSelect } from "../../../../../../components";
import { t } from "i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import procedures from "../../../../../../assets/icons/procedures.svg";
import lamp from "../../../../../../assets/icons/lamp.svg";
import UploadAdapter from "../../../../../../components/UploadAdapter";
import environment from "../../../../../../services/environment";
import useAxiosGetRequest from "../../../../../../helpers/customHooks/useAxiosGetRequest";
import ExpertAnswerNotes from "./expertAnswerNotes";
import SaveBtn from "../components/buttons/saveBtn";
import { permissionsCheck } from "../../../../../../helpers/utils";

const StandardAnswerNotes = ({
  templates,
  addAnswerReviwer,
  addAnswerExpert,
}) => {
  const [selectedTemplates, setSelectedTemplates] = useState("");
  const standardProgramContext = useContext(StandardProgramContext);

  const [getTemplates, templateList, loading] = useAxiosGetRequest();

  useEffect(() => {
    getTemplates(environment.getAllNoteTemplate);
  }, []);

  // function convertHtmlToString(htmlString) {
  //   var tempElement = document.createElement("div");
  //   tempElement.innerHTML = htmlString;
  //   return tempElement.textContent || tempElement.innerText;
  // }

  function extractTextFromHtml(htmlString) {
    // Remove HTML tags
    var text = htmlString.replace(/<[^>]*>/g, "");
    // Remove backslashes
    text = text.replace(/\\/g, "");
    // Return the extracted text
    return text;
  }

  const templateListOptions =
    templateList?.result?.items &&
    templateList?.result?.items?.map((item) => {
      return {
        // label: item?.id?.toString(),
        label: extractTextFromHtml(item.templateContent) || "",
        value: item.id,
      };
    });

  // const handleChangeTemplates = (value) => {
  //   setSelectedTemplates(value || "");
  // };

  const handleChangeTemplates = (value) => {
    setSelectedTemplates(value || "");
    var myUl = "<ul>";

    for (var i = 0; i < value.length; i++) {
      //   if(value[i].type == "file")
      //     myUl+= "<li>" + value[i].label + "</li>";
      //   else{
      //     myUl+= "<li>" + value[i].label + "</li>";
      //     myUl += createUl(value[i].files);
      //   }
      myUl += "<li>" + value[i].label + "</li>";
    }

    myUl += "</ul>";
    //    value.forEach(element => {
    if (permissionsCheck("Pages.UserType.Reviewer")) {
      standardProgramContext.setNoteReviewer(myUl);
    } else if (
      permissionsCheck("Pages.UserType.Expert") ||
      permissionsCheck("Pages.UserType.ExpertManager")
    ) {
      standardProgramContext.setNoteExpert(myUl);
    }
    //    });
  };

  return (
    <>
      <div className="min-h-[440px]  p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={procedures}
              alt="procedures"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {" "}
              ملاحظات التدقيق
            </h1>
          </div>
          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ""
                    ? "لا يوجد"
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-[100%] mt-7 pr-[88px] flex flex-col items-start overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {permissionsCheck("Pages.UserType.Reviewer") ? (
            <>
              <div className="w-[87%] mb-2">
                <h2 className="text-[#292069] text-lg font-bold mb-1">
                  ملاحظات الفريق الوطني للقياس
                </h2>
                <div
                  className="text=[#7D8BAB] text-[14px] mb-7"
                  dangerouslySetInnerHTML={{
                    __html:
                      standardProgramContext?.noteExpertForReviewer == null
                        ? "<p>لا يوجد ملاحظات للخبير</p>"
                        : standardProgramContext?.noteExpertForReviewer,
                  }}
                ></div>

                <div className="text=[#7D8BAB] text-[14px]">
                  {standardProgramContext?.standard?.programReviewerAnswer ==
                  null
                    ? null
                    : standardProgramContext?.standard
                        ?.expertToReviewerNoteDate}
                </div>
              </div>
              <div className="w-[87%] mb-2">
                <h2 className="text-[#292069] text-lg font-bold mb-3">
                  ملاحظات الفريق الوطني للقياس
                </h2>
                <div className="mb-4">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={selectedTemplates}
                    handleChange={handleChangeTemplates}
                    placeholder={t("اختر من القوالب")}
                    label={t("قوالب الملاحظات")}
                    options={templateListOptions}
                    isMultiple={false}
                  />
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    selectedTemplates?.label ||
                    standardProgramContext?.noteReviewer
                  }
                  onReady={(editor) => {
                    standardProgramContext?.setNoteReviewer(editor.getData());
                    // setContent(editor.getData());
                    editor.plugins.get("FileRepository").createUploadAdapter =
                      function (loader) {
                        return new UploadAdapter(loader);
                      };
                  }}
                  config={{
                    language: "ar",

                    simpleUpload: {
                      uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                      withCredentials: true,

                      headers: {
                        // 'X-CSRF-TOKEN': 'CSRF-Token',
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                    },
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    standardProgramContext?.setNoteReviewer(data);
                    // setContent(data);
                    editor.plugins.get("FileRepository").createUploadAdapter =
                      function (loader) {
                        return new UploadAdapter(loader);
                      };
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                <div className="text=[#7D8BAB] text-[14px]">
                  {standardProgramContext?.standard?.programReviewerAnswer ==
                  null
                    ? null
                    : standardProgramContext?.standard.reviewerNoteDate}
                </div>
              </div>
            </>
          ) : null}
          {permissionsCheck("Pages.UserType.POC") ||
          permissionsCheck("Pages.UserType.CIO") ||
          permissionsCheck("Pages.UserType.Delegate") ? (
            <div className="w-[87%] mb-2">
              <h2 className="text-[#292069] text-[16px] mb-2">
                ملاحظات الفريق الوطني للقياس
              </h2>
              <div
                className="text=[#7D8BAB] text-[14px]"
                dangerouslySetInnerHTML={{
                  __html:
                    standardProgramContext?.noteExpert == null
                      ? "<p>لا يوجد ملاحظات للخبير</p>"
                      : standardProgramContext?.noteExpert,
                }}
              ></div>
              <div className="text=[#7D8BAB] text-[14px]">
                {standardProgramContext?.standard?.programExpertAnswer == null
                  ? null
                  : standardProgramContext?.standard?.agencyNoteDate}
              </div>
            </div>
          ) : null}
          {permissionsCheck("Pages.UserType.Expert") ||
          permissionsCheck("Pages.UserType.ExpertManager") ? (
            <ExpertAnswerNotes templateList={templateList} />
          ) : null}
        </div>
      </div>
      {permissionsCheck("Pages.UserType.Reviewer") && (
        <SaveBtn onClick={addAnswerReviwer} />
      )}
      {(permissionsCheck("Pages.UserType.Expert") ||
        permissionsCheck("Pages.UserType.ExpertManager")) && (
        <SaveBtn onClick={addAnswerExpert} />
      )}
      {/* <SaveBtn onClick={addAnswerExpert} /> */}
    </>
  );
};

export default StandardAnswerNotes;
