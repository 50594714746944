import BreadcrumbList from "../breadcrumb";
import NewHeader from "../header/NewHeader";

const HeaderWithBreadcrumbLayout = ({
  breadcrumbArr,
  handleBtnClick,
  btnLabel,
  children,
  hideBtn = false,
  hideBtnSeconed = false,
  btnLabelSeconed,
  handleBtnSeconedClick
}) => {
  return (
    <div className="bg-white rounded-[30px] min-h-[90%] m-5">
      <NewHeader />
      {breadcrumbArr && (
        <BreadcrumbList
          breadcrumbArr={breadcrumbArr}
          handleBtnClick={handleBtnClick}
          btnLabel={btnLabel}
          hideBtn={hideBtn}
          hideBtnSeconed={hideBtnSeconed}
          btnLabelSeconed={btnLabelSeconed}
          handleBtnSeconedClick={handleBtnSeconedClick}
        />
      )}
      <div className="px-5">{children}</div>
    </div>
  );
};

export default HeaderWithBreadcrumbLayout;
