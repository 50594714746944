import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-skeleton-loader";

function VisitorsStatsCard({
  icon,
  text,
  number,
  bgColor,
  iconBgColor,
  loading,
  skeletonColor,
}) {
  return (
    <div
      className={`p-10 rounded-lg text-white w-full flex justify-center items-center gap-10 ${bgColor}`}
    >
      <div
        className={`rounded-full w-20 h-20 flex justify-center items-center ${iconBgColor}`}
      >
        <FontAwesomeIcon icon={icon} className="text-4xl" />
      </div>

      <div className="flex flex-col gap-2">
        {loading ? (
          <Skeleton width={"50px"} height={"10px"} color={skeletonColor} />
        ) : (
          <h2 className="font-semibold text-3xl">{number}</h2>
        )}

        <p className="text-xl text-gray-100">{text}</p>
      </div>
    </div>
  );
}

export default VisitorsStatsCard;
