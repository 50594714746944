import { atom, useAtom } from 'jotai';
import React, { createContext, useContext, useState } from 'react';

const UploadContext = createContext();
export const uploadingFileAtom = atom(false);

function UploadFilesProvider({ children }) {
  const [uploading, setUploading] = useAtom(uploadingFileAtom);
  const [uploadingProofId, setUploadingProofId] = useState(null);
  const [filesProgress, setFilesProgress] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [uploadedErrorsCount, setUploadedErrorsCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachmentFilesCount, setAttachmentFilesCount] = useState({});

  return (
    <UploadContext.Provider
      value={{
        uploading,
        setUploading,
        uploadingProofId,
        setUploadingProofId,
        filesProgress,
        setFilesProgress,
        itemsCount,
        setItemsCount,
        uploadedCount,
        setUploadedCount,
        uploadedErrorsCount,
        setUploadedErrorsCount,
        files,
        setFiles,
        attachmentFilesCount,
        setAttachmentFilesCount,
        uploadedFiles,
        setUploadedFiles,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}

export const useUploadContext = () => useContext(UploadContext);

export { UploadFilesProvider };
