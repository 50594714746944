import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useCheckBox from "../../helpers/customHooks/useCheckBox";
import useText from "../../helpers/customHooks/useText";
import environment from "../../services/environment";
import { get, post } from "../../services/Request";
import { hayaa } from "./../../assets/imgs/Index";
import { Spinner } from "flowbite-react";
import { StageContext } from "../../contexts/stageContext";

const Login = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [values, handleChange] = useText();
  const stageContext = useContext(StageContext);
  const [loading, setLoading] = useState(false);
  const [valuesCheckbox, handlechangeCheckbox] = useCheckBox();
  const [message, setMessage] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const passwordValidation = (password) => {
    var re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    return (
      re.capital.test(password) &&
      re.length.test(password) &&
      re.specialChar.test(password) &&
      re.digit.test(password)
    );
  };

  const login = () => {
    if (values.name == "") {
      setMessage({ type: "error", message: "الاسم مطلوب" }, setLoading(false));
    } else if (values.password == "") {
      setMessage(
        { type: "error", message: "كلمة المرور مطلوبة" },
        setLoading(false)
      );
    } else {
      setLoading(true);
      let data = {
        userNameOrEmailAddress: values.name,
        password: values.password,
        rememberClient: valuesCheckbox.rememberClient,
      };
      post(environment.login, data, "", (res) => {
        if (res.data.result == null) {
          setMessage(
            { type: "error", message: res.data.error.details },
            setLoading(false)
          );
        } else if (res.status === 500) {
          setMessage(
            { type: "error", message: res.data.error.details },
            setLoading(false)
          );
        } else if (res.data.result != null) {
          var data = jwt_decode(res.data.result.accessToken);
          localStorage.setItem("accessToken", res.data.result.accessToken);
          getUserData(res.data.result.userId);
        }
      });
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getUserData = (userId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getUser + "?Id=" + userId, config, (res) => {
      
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        
        localStorage.setItem("userId", res.data.result?.id);
        localStorage.setItem("userName", res.data.result?.name);
        localStorage.setItem("fullName", res.data.result?.fullName);
        localStorage.setItem("agencyId", res.data.result?.agencyId);
        localStorage.setItem("userType", res.data.result?.staticRole);
        localStorage.setItem("IndicatorId", 1);
        localStorage.setItem("userImage", res.data.result?.profilePicture);
        getUserPermissions(res?.data?.result?.showRole?.id);
        // setLoading(false);
        // window.location.href = "/ChooseCurruntIndicator";
      }
    });
  };

  const getUserPermissions = (roleId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getRoleForEdit + "?Id=" + roleId, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        
        stageContext.setPermissions(res.data.result.permissions);
        stageContext.setRole(res.data.result.role);
        setLoading(false);

        localStorage.setItem(
          "perm",
          JSON.stringify(res.data.result.permissions)
        );
        window.location.href = "/ChooseCurruntIndicator";
      }
    });
  };

  

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const nafazLoading = false;
  const [nafathLoading, setNafathLoading] = useState(false);

  // const handleNafathAuth = () => {

  // }

  return (
    <div
      dir="rtl"
      className="bg-white h-screen overflow-y-scroll relative flex items-center w-full"
    >
      <div className="flex flex-col items-center container mx-auto w-full p-5 h-full">
        <div className="flex justify-center items-center py-20 w-full">
          <div className="flex bg-[#F9F9FF] rounded-[20px] py-16 w-full lg:w-[670px] flex-col items-center justify-center gap-5 ">
            <div className="flex">
              <img src={"/logo.svg"} alt="qiyasLogo" className="w-[240px]" />
            </div>

            <div className="flex flex-col w-[400px]">
              <div className="flex justify-start mb-5">
                <p className="text-lg text-qiyas_violet font-semibold">
                  تسجيل الدخول
                </p>
              </div>

              <div className="flex flex-col gap-3">
                <div>
                  <label
                    for="first_name"
                    class="block mb-3 text-sm font-semibold text-gray-700 dark:text-white"
                  >
                    اسم المستخدم
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="اسم المستخدم"
                    name="name"
                    onChange={handleChange}
                    value={values.name || ""}
                    onKeyDown={(e) => handleKeyDown(e)}
                    required
                  />
                </div>

                <div>
                  <label
                    for="password"
                    class="block mb-3 text-sm font-semibold text-gray-700 dark:text-white"
                  >
                    كلمة المرور
                  </label>
                  <input
                    id="password"
                    name="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    type={showPassword ? "password" : "text"}
                    placeholder={"********"}
                    onChange={handleChange}
                    value={values.password || ""}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>

                <div>
                  <span className="text-sm text-red-600">
                    {message.message}
                  </span>
                </div>

                <div className="flex justify-center flex-col items-center gap-5 mt-3">
                  <button
                    disabled={loading}
                    className="!bg-[#02aaad] hover:!bg-[#379496] transition-all duration-300 rounded-xl w-full px-10 py-2 text-white"
                    onClick={() => login()}
                  >
                    {loading ? (
                      <div className="flex flex-row  justify-center items-center">
                        <Spinner
                          aria-label="Info spinner example"
                          color="info"
                        />
                        <span className="pl-3 pr-3 text-sm text-white">
                          {t("general.loading")}
                        </span>
                      </div>
                    ) : (
                      <span className="mb-2 block">دخول الان</span>
                    )}
                  </button>

                  <div className="flex justify-center">
                    <p>أو الدخول عن طريق</p>
                  </div>

                  <button
                    className="!bg-[#02aaad] hover:!bg-[#379496]  transition-all duration-300 rounded-xl w-full px-10 py-2 text-white"
                    type="button"
                  >
                    {nafazLoading ? (
                      <div className="flex flex-row  justify-center items-center">
                        <Spinner
                          aria-label="Info spinner example"
                          color="info"
                        />
                        <span className="pl-3 pr-3 text-sm text-white">
                          {t("general.loading")}
                        </span>
                      </div>
                    ) : (
                      <a
                        href={`${process.env.REACT_APP_FRONT_URL}/Nafath`}
                        className="mb-2 block"
                      >
                        نفاذ
                      </a>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute right-10 top-10">
        <img src={hayaa} alt="hayaa" width={203.5443037974684} height={60} />
      </div>
    </div>
  );
};

export default Login;
