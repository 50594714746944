import { DropdownMultiSelect } from "../../../../../../../components";
import { useAnswerContext } from "../../answerContext";

const PastProofFilesDropdown = ({ proofId, index, relatedRequirementId, standardProofId }) => {
  const {
    newAgencyAnswer,
    setNewAgencyAnswer,
    selectedPrevfilesArray,
    setSelectedPrevfilesArray,
  } = useAnswerContext();

  console.log({ standardProofId });

  const prevFilesOptions =
    newAgencyAnswer?.attachments?.length > 0 &&
    newAgencyAnswer?.attachments?.map((item) => {
      const attachmentYear = item.creationTime
        ? new Date(item.creationTime).getFullYear()
        : "";

      return {
        label: `[${attachmentYear}]   ` + item.name,
        value: item.id,
        relatedRequirementId,
        standardProofId,
      };
    });

  const handleChangeSelectedPrevfiles = (value) => {
    setSelectedPrevfilesArray(value);

    const standardProofs = newAgencyAnswer?.standardProofs?.map((item) => {
      if (item?.id == proofId) return { ...item, pastSelectedFiles: value };

      return item;
    });

    setNewAgencyAnswer({ ...newAgencyAnswer, standardProofs });
  };

  console.log({ selectedPrevfilesArray });
  console.log({newAgencyAnswer})

  const pastSelectedFiles =
    newAgencyAnswer?.standardProofs?.[index]?.pastSelectedFiles?.filter(
      (item) => item.standardProofId == standardProofId
    ) || [];


  console.log({ pastSelectedFiles });
  console.log({ relatedRequirementId });
  console.log({ "filterByRequirementId(pastSelectedFiles, relatedRequirementId)": filterByRequirementId(pastSelectedFiles, relatedRequirementId)})


  return (
    <div className="pt-1">
      <DropdownMultiSelect
        isClearable={true}
        withoutLabel={false}
        isSearchable={true}
        hasSelectAll={true}
        value={filterByRequirementId(pastSelectedFiles, relatedRequirementId)}
        handleChange={handleChangeSelectedPrevfiles}
        placeholder="اختيار من ملفات سابقة"
        label={"اختيار من ملفات سابقة"}
        options={prevFilesOptions || []}
        isMultiple={true}
      />
    </div>
  );
};

export default PastProofFilesDropdown;

function filterByRequirementId(files, relatedRequirementId) {
  if (!relatedRequirementId) return files;
  return files?.filter(
    (item) => item?.relatedRequirementId == relatedRequirementId
  );
}
