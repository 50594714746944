import React, {useContext, useState} from "react";
import {AppInput, DropdownMultiSelect} from "../../../../../../../components";
import AppButton from "../../../../../../../components/buttons/appButton";
import {AddStandardContext} from "../../addStandardContext";
import {produce} from "immer";
import {errorToast} from "../../../../../../../helpers/toast";

const AddSubRequirement = ({subReq, requirementIndx}) => {
  const {
    requirmentList,
    setSubRequirementCount,
    classifications,
    addFinalValue,
    setAddFinalValue,
  } = useContext(AddStandardContext);
  const [values, setValues] = useState({
    title: "",
    standardRequirementClassificationId: "",
    recommendation: "",
  });
  const [selectedClassifications, setSelectedClassifications] = useState("");

  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
        label: item.title,
        value: item.id,
        id: item.id,
      }))
      : [];

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    setValues({
      ...values,
      standardRequirementClassificationId: value?.id ? value?.id : "",
    });
  };

  const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value});
  };

  const handleSubmit = () => {
    
    // return;
    if (
      !values?.title ||
      !values?.recommendation ||
      !values?.standardRequirementClassificationId
    ) {
      errorToast("يجب ادخال البيانات المطلوبة للمتطلب الفرعى");
      return;
    }
    setSubRequirementCount((prev) => prev + 1);

    setAddFinalValue(
      produce((draft) => {
        const newSubRequirement = {
          ...values,
        };
        if (
          !draft.standardRequirements[requirementIndx].subStandardRequirements
        ) {
          draft.standardRequirements[
            requirementIndx
            ].subStandardRequirements = [];
        }
        draft.standardRequirements[
          requirementIndx
          ].subStandardRequirements.push(newSubRequirement);
      })
    );

    setValues({
      title: "",
      standardRequirementClassificationId: "",
      recommendation: "",
    });
    setSelectedClassifications("");
  };

  return (
    <div className="bg-white p-5 pb-7 rounded-lg mt-7">
      <h2 className="font-semibold mb-4">إضافة متطلب فرعى</h2>

      <div className="row gap-y-3">
        <div className="col-md-6 ">
          <AppInput
            variant="gray"
            label="عنوان"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اكتب هنا"
            options={classificationOptions}
            isMultiple={false}
          />
        </div>
        {/* <div className="col-md-6 col-xl-3">
          <AppInput
            variant="gray"
            label="وزن"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="col-md-6 col-xl-3">
          <AppInput variant="gray" label="محدد" placeholder="اكتب هنا" name="standardRequirementClassificationId"
            value={values.standardRequirementClassificationId}
            onChange={handleChange} />
        </div> */}
      </div>
      <div className="row gap-y-4 mt-8">
        <div className="col-xl-9">
          <AppInput
            variant="gray"
            label="التوصية"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleChange}
          />
        </div>
        <div className="col-xl-3 align-self-end">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة متطلب فرعى</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubRequirement;
