import {useState} from "react";
import {axiosGetRequest} from "../../services/Request";

const useAxiosGetRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async (api, params) => {
    setLoading(true);
    const res = await axiosGetRequest(api, params);
    if (res?.success) {
      setData(res);
    }
    setLoading(false);

  };

  return [getData, data, loading];
};

export default useAxiosGetRequest;

// import { useState, useCallback } from "react";
// import { axiosGetRequest } from "../../services/Request";

// const useAxiosGetRequest = () => {
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState([]);

//   const getData = useCallback(async (endpoint, params) => {
//     setLoading(true);
//     try {
//       const res = await axiosGetRequest(endpoint, params);
//       if (res?.success) {
//         return res.data; // Assuming data is the property you want to extract from the response
//       }
//       throw new Error("Request was not successful");
//     } catch (error) {
//       // Handle errors here if needed
//       throw error; // Rethrow the error to be caught by the component using the hook
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   const fetchData = (endpoint, params) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const data = await getData(endpoint, params);
//         setData(data);
//         resolve(data);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };

//   return [fetchData, data, loading];
// };

// export default useAxiosGetRequest;
