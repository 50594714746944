import ClassificationCard from "./ClassificationCard";
import ClassificationCardSkeleton from "./ClassificationCardSkeleton";

const ClassificationList = ({
                              allClassifications,
                              handleUpdateData,
                              loading,
                            }) => {
  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <>
          <ClassificationCardSkeleton/>
          <ClassificationCardSkeleton/>
          <ClassificationCardSkeleton/>
          <ClassificationCardSkeleton/>
          <ClassificationCardSkeleton/>
        </>
      ) : allClassifications.length == 0 ? (
        <div className="flex justify-center w-full">
          <p>لا يوجد تصنيفات، يمكنك الاٍضافة الان</p>
        </div>
      ) : (
        allClassifications?.map((element, index) => (
          <ClassificationCard
            key={index}
            title={element.title}
            weight={element.weight}
            determinants={element.standardRequirementClassificationDeterminants}
            id={element.id}
            handleUpdateData={handleUpdateData}
            loading={loading}
          />
        ))
      )}
    </div>
  );
};

export default ClassificationList;
