import { logout, notfi } from "../../assets/icons/header";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { avatar, mailBoxIcon } from "../../assets/icons/Index";
import { get, post } from "../../services/Request";
import environment from "../../services/environment";
import StageTimer from "./stageTimer";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatTime,
  logoutUser,
  permissionsCheck,
} from "../../helpers/utils";

import NewTooltip from "../../components/NewTooltip";

const NewHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  // const [unreadCount, setUnreadCount] = useState(0);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsMenuOpen((cur) => !cur);
  };

  const getAllNotifications = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllNotifications + "?skipCount=0&maxResultCount=5",
      config,
      (res) => {
        if (res?.status == 200) {
          setNotifications(res?.data?.result?.items);
          setNotificationsCount(res?.data?.result?.unreadCount);
        }
      }
    );
  };

  const seenNotification = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: id,
    };
    // return true
    post(environment.setNotificationAsRead, data, config, (res) => {
      if (res.status == 200) {
        getAllNotifications();
      }
    });
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // getAllNotifications();
    document.addEventListener("click", handleClickOutside, false);

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const history = useHistory();
  const handleRedirectToTickets = () => {
    history.push("/tickets");
  };
  const { t, i18n } = useTranslation();

  document.body.dir = i18n.dir();

  const getInitials = (fullName) => {
    if (fullName === null) return "- -";
    const initials = fullName
      .split(" ")
      .map((word) => word[0])
      .join(" ")
      .toUpperCase();
    return initials;
  };

  return (
    <div className="flex flex-row justify-between items-center p-5 m-5">
      {/* profile info */}
      <div className="flex flex-row items-center gap-4">
        <div
          className="rounded w-[52px] h-[52px]"
          style={{
            backgroundColor: "#7337AE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="text-white">
            {getInitials(localStorage.getItem("fullName"))}
          </span>
        </div>

        <div
          className="flex flex-col items-start cursor-pointer"
          onClick={() =>
            permissionsCheck("Pages.UserType.POC") ||
            permissionsCheck("Pages.UserType.CIO") ||
            permissionsCheck("Pages.UserType.Delegate")
              ? history.push(`/users/${localStorage.getItem("userId")}/details`)
              : history.push(`/users/${localStorage.getItem("userId")}/details`)
          }
        >
          <span className="text-lg text-black">
            {localStorage.getItem("fullName")}
          </span>
          <span className="text-sm text-[#A1A9C4]">
            {localStorage.getItem("userType")}
          </span>
        </div>
      </div>
      <StageTimer />

      {/* icons */}
      <div className="flex flex-row justify-between gap-4 items-center">
        {permissionsCheck("Pages.MailBox.GetAll") ? (
          <NewTooltip content={"صندوق الرسائل"}>
            <Link to="/mailbox" className="cursor-pointer w-[50px] h-[50px]">
              <img src={mailBoxIcon} alt="Message Icon" />
            </Link>
          </NewTooltip>
        ) : null}

        {/* Notifications icon */}

        <div className="relative inline-block group">
          {notificationsCount > 0 && (
            <span className="-left-1 -top-1 absolute p-[5px] rounded-full bg-emerald-500 flex justify-center items-center text-white">
              <span className="-mt-1">{notificationsCount}</span>
            </span>
          )}
          <NewTooltip content={"الأشعارات"}>
            <div
              onClick={() => history.push("/notifications")}
              className="cursor-pointer w-[50px] h-[50px]"
              // onClick={toggleMenu}
            >
              <img src={notfi} alt="notification Icon" />
            </div>
          </NewTooltip>

          {/*  Notifications Dropdown  */}
          <div
            ref={menuRef}
            className={`notification-menu absolute ${
              isMenuOpen ? "block" : "hidden"
            } left-0 w-[509px] max-h-[560px] mt-2 overflow-y-auto  bg-white border border-gray-200 rounded-[20px] shadow-xl  z-50`}
          >
            {/* <ul className=" flex flex-col divide-y divide-gray-200">
              {notifications.map((notify, index) => (
                <li
                  onClick={() => seenNotification(notify.id)}
                  key={index}
                  className={`hover:bg-slate-200 cursor-pointer ${
                    notify.isRead ? "bg-white" : "bg-slate-100"
                  }`}
                >
                  <div className="flex items-center p-5 ">
                    <div className="flex-shrink-0">
                      <img
                        className="w-10 h-10 rounded-full"
                        src={avatar}
                        alt="profile"
                      />
                    </div>

                    <div className="flex-1 ms-4 flex flex-col gap-1">
                      <div className="flex shrink justify-between">
                        <p className="text-base tracking-wide truncate">
                          {/* {notify.notificationName} */}
            {/* {notify.notificationName?.split(" ")?.length > 4
                            ? notify.notificationName
                                ?.split(" ")
                                .slice(0, 4)
                                .join(" ") + "..."
                            : notify.notificationName}
                        </p>
                        <span className="text-slate-400 shrink-0">
                          {formatDate(new Date(notify.creationTime)) ==
                          formatDate(new Date())
                            ? "Today" +
                              " " +
                              formatTime(new Date(notify.creationTime))
                            : formatDate(new Date(notify.creationTime)) +
                              "  " +
                              formatTime(new Date(notify.creationTime))}
                        </span>
                      </div>
                      <div
                        className="text-slate-400 truncate"
                        style={{
                          fontSize: " 15px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          letterSpacing: "0.36px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            notify.arabicMessage?.split(" ")?.length > 9
                              ? notify.arabicMessage
                                  ?.split(" ")
                                  .slice(0, 9)
                                  .join(" ") + "..."
                              : notify.arabicMessage,
                        }}
                      ></div>
                    </div>
                  </div>
                </li>
              ))}
              <li
                onClick={() => history.push("/notifications")}
                className="hover:bg-slate-100 bg-slate-50 cursor-pointer"
              >
                <div className="flex items-center p-5 ">
                  <div className="flex-1  ms-4 flex flex-col gap-1">
                    <div className="flex  justify-center">
                      <p className=" text-center tracking-wide">المزيد</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul> */}
          </div>
        </div>

        <NewTooltip content={"تسجيل الخروج"}>
          <div
            className="cursor-pointer w-[50px] h-[50px]"
            onClick={() => {
              logoutUser();
              // history.push("/login");
              window.location.reload();
            }}
          >
            <img src={logout} alt="logout Icon" />
          </div>
        </NewTooltip>
      </div>
    </div>
  );
};

export default NewHeader;
