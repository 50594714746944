import { format } from "date-fns";
import moment from "moment";

export const formateDate = (date) => {
  const isValidDate = moment(date).isValid();

  if (!isValidDate) return "---";

  return moment(date).format("DD-MM-YYYY");
};

export function convertToArabicDayName(date) {
  const isValidDate = moment(date).isValid();

  if (!isValidDate) return "---";

  const dayName = moment(date, "YYYY-MM-DD HH:mm:ss").format("dddd");

  // Convert the day name to lowercase for case-insensitive comparison
  const lowerCaseDayName = dayName.toLowerCase();

  // Initialize the Arabic day name variable
  let arabicDayName = "";

  // Switch statement to map English day names to Arabic day names
  switch (lowerCaseDayName) {
    case "sunday":
      arabicDayName = "الأحد";
      break;
    case "monday":
      arabicDayName = "الاثنين";
      break;
    case "tuesday":
      arabicDayName = "الثلاثاء";
      break;
    case "wednesday":
      arabicDayName = "الأربعاء";
      break;
    case "thursday":
      arabicDayName = "الخميس";
      break;
    case "friday":
      arabicDayName = "الجمعة";
      break;
    case "saturday":
      arabicDayName = "السبت";
      break;
    default:
      arabicDayName = "Invalid day name";
  }

  return arabicDayName;
}

export function removeSeconds(timeString) {
  // Split the time string by ":"
  const timeParts = timeString.split(":");

  // Join the first two parts (hours and minutes)
  const modifiedTime = timeParts.slice(0, 2).join(":");

  return modifiedTime;
}

export function parseDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const formattedDate = format(dateTime, "yyyy-MM-dd");
  const formattedTime = format(dateTime, "HH:mm:ss");

  return {
    date: formattedDate,
    time: formattedTime,
  };
}
