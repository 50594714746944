import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";

const ExpertAnswerDegrees = () => {
  const { newExpertAnswer, expertDegree } = useAnswerContext();


  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.reviewerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.pocOfficerFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.pocOfficerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={newExpertAnswer?.checkedExpertDegree?.title || "لا يوجد"}
          circleColor={newExpertAnswer?.checkedExpertDegree?.color || "#fff"}
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            expertDegree?.expertSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            expertDegree?.expertSelfEvaluationCommitmentLevel?.color || "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default ExpertAnswerDegrees;
