import React, { useContext, useEffect, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import StandardSubRequirements from "./standardSubRequirements";
import AppAccordion from "../../../../../../components/appAccordion";
import { AddStandardContext } from "../addStandardContext";
import { produce } from "immer";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";
import { addStandardRequirementValidations } from "../addStandardValidation";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";

const StandardRequirementItem = ({
  index,
  title,
  standardRequirementClassificationId,
  recommendation,
  subStandardRequirements,
  number,
  disabled,
}) => {
  const {
    requirmentList,
    setRequirmentList,
    subRequirementCount,
    setSubRequirementCount,
    classifications,
    addFinalValue,
    setAddFinalValue,
    selectedRequirementAgenciesForEdit,
    selectedAgencies,
  } = useContext(AddStandardContext);
  const [selectedClassifications, setSelectedClassifications] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    recommendation: "",
    standardRequirementClassificationId: "",
    agencyStandardRequirements: "",
  });

  const [selectedRequirementAgencies, setSelectedRequirementAgencies] =
    useState(selectedRequirementAgenciesForEdit);
  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardRequirementValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  // 

  const handleChangeRequirementAgencies = (value) => {
    setSelectedRequirementAgencies(value);
    if (!value || value?.length == 0) {
      setErrors({
        ...errors,
        agencyStandardRequirements: "يجب اختيار جهة حكومية على الأقل للمتطلب",
      });
    } else {
      setErrors({
        ...errors,
        agencyStandardRequirements: "",
      });
    }
  };

  const [requirement, setRequirement] = useState({
    title,
    standardRequirementClassificationId,
    recommendation,
    subStandardRequirements,
  });

  useEffect(() => {
    const itemAgencies = addFinalValue?.standardRequirements?.[
      index
    ]?.agencyStandardRequirements?.map((item) => ({
      value: item?.agencyId,
      label: item?.agencyNameInArabic?.toString() || "",
      id: item?.id,
    }));
    setSelectedRequirementAgencies(itemAgencies);

    const selectedClass = classificationOptions?.filter(
      (item) => item?.id == standardRequirementClassificationId
    );

    setSelectedClassifications(selectedClass?.[0] || "");
  }, [classifications]);

  const body = {
    ...requirement,
    agencyStandardRequirements: selectedRequirementAgencies.map((ele) => ({
      agencyId: ele.value,
    })),
    subStandardRequirements,
  };

  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    if (!value) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: "يجب اختيار التصنيف",
      });
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: "" });
    }
    setRequirement({
      ...requirement,
      standardRequirementClassificationId: value?.id ? value?.id : 0,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRequirement({ ...requirement, [e.target.name]: e.target.value });
    validateInput(name, value);
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      addStandardRequirementValidations,
      {
        title: requirement.title,
        recommendation: requirement.recommendation,
      }
    );

    if (
      !selectedRequirementAgencies ||
      selectedRequirementAgencies?.length == 0
    ) {
      setErrors({
        ...errors,
        agencyStandardRequirements: "يجب اختيار جهة حكومية على الأقل للمتطلب",
      });

      return;
    }
    if (!selectedClassifications) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: "يجب اختيار التصنيف",
      });

      return;
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: "" });
    }
    if (formErrors) {
      
      setErrors({ ...errors, ...formErrors });
      return;
    }
    const editedRequirement = addFinalValue?.standardRequirements?.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ...body,
          };
        }
        return item;
      }
    );

    setIsOpen(false);

    setRequirmentList(editedRequirement);

    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements[index] = body;
        // draft.standardRequirements[
        //   index
        // ].agencyStandardRequirements = selectedRequirementAgencies.map(
        //   (ele) => ({
        //     agencyId: ele.agencyId,
        //   })
        // );
      })
    );
  };

  const handleDeleteClick = () => {
    setSubRequirementCount(
      subRequirementCount -
        requirmentList?.[index]?.subStandardRequirements?.length
    );
    const newReq = requirmentList.filter((item, itemIndx) => itemIndx != index);
    setRequirmentList(newReq);

    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements =
          draft.standardRequirements.filter(
            (item, itemIndx) => itemIndx != index
          ) || [];
      })
    );
  };

  return (
    <div className="mt-3">
      <AppAccordion
        number={number}
        title={title}
        handleDeleteClick={handleDeleteClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <div className=" p-5 pb-7 rounded-lg">
          <h2 className="font-semibold mb-4">تعديل المتطلب</h2>

          <div className="row gap-y-3">
            <div className="col-md-5 ">
              <AppInput
                label="عنوان المتطلب"
                placeholder="اكتب هنا"
                name="title"
                value={requirement.title}
                onChange={handleChange}
                errorMessage={errors?.title}
                disabled={disabled}
              />
            </div>
            <div className="col-md-5">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedClassifications}
                handleChange={handleChangeClassifications}
                label="تصنيف"
                placeholder="اكتب هنا"
                options={classificationOptions}
                isMultiple={false}
                isRequired={true}
                errorMessage={errors?.standardRequirementClassificationId}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="row gap-y-4 mt-8">
            <div className="col-xl-5">
              <AppInput
                label="التوصية"
                placeholder="اكتب هنا"
                name="recommendation"
                value={requirement.recommendation}
                onChange={handleChange}
                errorMessage={errors?.recommendation}
                disabled={disabled}
              />
            </div>
            <div className="col-md-5">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedRequirementAgencies || []}
                handleChange={handleChangeRequirementAgencies}
                label="الجهات الحكومية"
                options={selectedAgencies}
                isMultiple={true}
                isRequired={true}
                disabled={disabled}
                errorMessage={errors?.agencyStandardRequirements}
              />
            </div>

            <div className="col-xl-2 align-self-end">
              <div className="text-end">
                {/* <AppButton onClick={handleSubmit}>تعديل</AppButton> */}
                <AppButton onClick={handleSubmit}>تعديل التصنيف</AppButton>
              </div>
            </div>
          </div>

          <StandardSubRequirements
            subReq={
              addFinalValue.standardRequirements[index]
                .subStandardRequirements || []
            }
            disabled={disabled}
            requirementIndx={index}
          />
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardRequirementItem;
