import React, { useEffect, useState } from "react";
import AppButton from "../../../../../../components/buttons/appButton";
import { DropdownMultiSelect, Title } from "../../../../../../components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../../../../components/UploadAdapter";
import { useParams } from "react-router";
import {
  axiosGetRequest,
  axiosPostRequest,
  axiosPutRequest,
} from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";
import { t } from "i18next";
import SecondaryTapsLayout from "../../../../../../layouts/secondaryTapsLayout";
import { serveySettingTabs } from "../../data";
import { programsIcon } from "../../../../../../assets/icons/Index";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const defaultTemplate = {
  agencyHasAttachment: false,
};

const SurveyEditTemplate = () => {
  const [inputs, setInputs] = useState(defaultTemplate);
  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [correctorDegrees, setCorrectorDegrees] = useState("");
  const [agencyDegrees, setAgencyDegrees] = useState("");

  const { surveyId, id } = useParams();
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [commitmentLevel, setCommitmentLevel] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getCommitmentLevel();
    getTemplate();
  }, []);

  const getTemplate = async () => {
    const res = await axiosGetRequest(environment.getNoteTemplate, {
      Id: id,
    });
    if (res?.success) {
      setTemplate(res?.result);
      setContent(res?.result?.templateContent);
      setAttachment({
        value: res?.result?.agencyHasAttachment,
        label: res?.result?.agencyHasAttachment
          ? "تم ارفاق ملف"
          : "لم يتم ارفاق ملف",
      });
      setCorrectorDegrees({
        value: res?.result?.correctorCommitmentLevels[0]?.commitmentLevel.id,
        label:
          res?.result?.correctorCommitmentLevels[0]?.commitmentLevel?.title,
      });
      setAgencyDegrees({
        value: res?.result?.agencyCommitmentLevels[0]?.commitmentLevel.id,
        label: res?.result?.agencyCommitmentLevels[0]?.commitmentLevel?.title,
      });
    }
  };

  const getCommitmentLevel = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getCommitmentLevelBySurveyId,
      {
        SurveyId: surveyId,
      }
    );
    if (res?.success) {
      setCommitmentLevel(res?.result?.items);
    }
    setLoading(false);
  };

  const commitment = commitmentLevel?.map((item) => ({
    id: item.id,
    label: item.title,
    value: item.id,
  }));

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangeCorrectorDegrees = (value) => {
    setCorrectorDegrees(value || "");
  };

  const handleChangeAgencyDegrees = (value) => {
    setAgencyDegrees(value || "");
  };

  const handleChangeAttachment = (value) => {
    setAttachment(value);
  };

  const handleSubmit = async (e) => {
    const correctorDegreeIds = [
      {
        commitmentLevelId: correctorDegrees.value,
      },
    ];

    const agencyDegreeIds = [{ commitmentLevelId: agencyDegrees.value }];

    const requestValues = {
      id: Number(id),
      surveyId: Number(surveyId),
      correctorDegrees: correctorDegreeIds,
      agencyDegrees: agencyDegreeIds,
      templateContent: content,
      agencyHasAttachment: attachment?.value,
    };

    setAddLoading(true);
    const res = await axiosPutRequest(
      environment.editNoteTemplate,
      requestValues
    );
    setAddLoading(false);
    if (res?.success) {
      toast.success("تم تعديل القالب بنجاح");
      history.push(`/surveys/${surveyId}/settings/templates`);
    }
  };

  const initialValues = {
    content: "",
    correctorDegrees: "",
    agencyDegrees: "",
    attachment: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!content) {
      errors.content = "هذا الحقل مطلوب";
    }
    if (!correctorDegrees) {
      errors.correctorDegrees = "هذا الحقل مطلوب";
    }
    if (!agencyDegrees) {
      errors.agencyDegrees = "هذا الحقل مطلوب";
    }

    return errors;
  };

  return (
    <>
      <SecondaryTapsLayout
        linksArr={serveySettingTabs(`/surveys/1`)}
        breadcrumbs={
          <div className="-mx-5">
            <Title
              iconTitle={programsIcon}
              subTitle={"اعدادات الاستبيان"}
              thired={true}
              subTitleSeconed={"قوالب الملاحظة"}
              title={t("المؤشرات")}
              withoutButton={true}
              toPage={() => history.push(`/indicators`)}
            />
          </div>
        }
        bgWhite
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={() => {
            handleSubmit();
          }}
        >
          {({ handleChange, values }) => (
            <Form className="">
              <div className="px-1 mt-4">
                <h2 className="text-lg text-blue_text mb-3">تعديل قالب</h2>
                <div className="mb-3">
                  <div className="mb-3">
                    <p className="mb-2">
                      المحتوى
                      <span className="mr-1">
                        <FontAwesomeIcon
                          className="w-2 text-red-700"
                          icon={faAsterisk}
                        />
                      </span>
                    </p>
                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      onReady={(editor) => {
                        setContent(editor.getData());
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function(loader) {
                          return new UploadAdapter(loader);
                        };
                      }}
                      config={{
                        language: "ar",

                        simpleUpload: {
                          uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                          withCredentials: true,

                          // Headers sent along with the XMLHttpRequest to the upload server.
                          headers: {
                            // 'X-CSRF-TOKEN': 'CSRF-Token',
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function(loader) {
                          return new UploadAdapter(loader);
                        };
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="content"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={correctorDegrees}
                    handleChange={handleChangeCorrectorDegrees}
                    placeholder={t("اختر درجة المصحح")}
                    label={t(" درجة المصحح (خبير - مدقق - ضابط اتصال)")}
                    options={commitment}
                    name={"correctorDegrees"}
                    isMultiple={false}
                    isRequired={true}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="correctorDegrees"
                    component="div"
                  />
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={agencyDegrees}
                    handleChange={handleChangeAgencyDegrees}
                    placeholder={t("اختر درجة الجهة ")}
                    label={t(" درجة الجهة")}
                    options={commitment}
                    name={"agencyDegrees"}
                    isMultiple={false}
                    isRequired={true}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="agencyDegrees"
                    component="div"
                  />
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={attachment}
                    name={"attachment"}
                    handleChange={handleChangeAttachment}
                    placeholder={t("اختر حالة إرفاق المستند ")}
                    label={t(" حالة إرفاق المستند")}
                    options={[
                      {
                        id: 1,
                        label: "تم ارفاق ملف",
                        value: true,
                      },
                      {
                        id: 2,
                        label: "لم يتم ارفاق ملف",
                        value: false,
                      },
                    ]}
                    isMultiple={false}
                  />
                </div>

                <div className="my-4 flex justify-end gap-3 text-end">
                  <AppButton type="submit" loading={addLoading}>
                    تعديل
                  </AppButton>
                  <Link to={`/surveys/${surveyId}/settings/templates`}>
                    <AppButton variant="danger"> رجوع </AppButton>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </SecondaryTapsLayout>
    </>
  );
};

export default SurveyEditTemplate;
