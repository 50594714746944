const StandardAnswerTopTaps = ({showSection, setShowSection}) => {
  return (
    <div className="bg-transparent flex flex-row justify-start items-center">
      <button
        className={`bg-[${
          showSection == "standard" ? "#1CC081" : "#F9F9FF"
        }] px-10 py-3 rounded-t-2xl ml-7 mr-7 text-center`}
        onClick={() => setShowSection("standard")}
      >
        <span
          className={`${
            showSection == "standard" ? "text-white" : "text-[#7D8BAB]"
          } font-xl`}
        >
          المعيار
        </span>
      </button>
      <button
        className={`px-10 py-3 rounded-t-2xl text-center bg-[${
          showSection == "inquiries" ? "#1CC081" : "#F9F9FF"
        }]`}
        onClick={() => setShowSection("inquiries")}
      >
        <span
          className={`${
            showSection == "inquiries" ? "text-white" : "text-[#7D8BAB]"
          } font-xl`}
        >
          الاستفسارات
        </span>
      </button>
    </div>
  );
};

export default StandardAnswerTopTaps;
