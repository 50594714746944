import React, { useEffect, useState } from "react";
import HeaderWithBreadcrumbLayout from "../../../../../layouts/headerWithBreadcrumbLayout";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import TableSkeleton from "../../../../../components/table/TableSkeleton";
import { previousSurveysBreadcumbs } from "./contents";
import { Badge } from "flowbite-react";
import SummaryAgencyAnswer from "./SummaryAgencyAnswer";
import useAxiosGetRequest from "../../../../../helpers/customHooks/useAxiosGetRequest";
import NewTable from "../../../../../components/NewTable";
import NewTooltip from "../../../../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import AppModal from "../../../../../components/Models/appModal";
import DownloadButton from "../../../../../components/DownloadButton";
import { DropdownMultiSelect } from "../../../../../components";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import useFileDownloader from "../../../../../hooks/usePdfLoader";
import { useHistory } from "react-router-dom";
import { isAgency } from "../../../../../helpers/isPermission";

const PreviousSurveys = () => {
  const history = useHistory();

  const [getOldQiyas, oldQiyas, getOldQiyasLoading] = useAxiosGetRequest();

  const [expertNotes, setExpertNotes] = useState("");
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [filteredData, setFilteredData] = useState(
    oldQiyas?.result?.answerList || []
  );

  useEffect(() => {
    setFilteredData(oldQiyas?.result?.answerList);
  }, [oldQiyas]);

  const { downloadPdf } = useFileDownloader();

  const agencyId = localStorage.getItem("agencyId");

  // New Table //
  const answersBadges = (value) => {
    switch (value) {
      case 1:
        return (
          <Badge
            className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
            size="md"
          >
            التزام كلي
          </Badge>
        );

      case 2:
        return (
          <Badge
            className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
            size="md"
          >
            التزام جزئي
          </Badge>
        );

      case 3:
        return (
          <Badge
            className="text-white font-medium bg-[#575757] pb-3 px-5 rounded-xl"
            size="md"
          >
            لا ينطبق
          </Badge>
        );

      case 4:
        return (
          <Badge
            className="text-white font-medium bg-[#f05868] pb-3 px-5 rounded-xl"
            size="md"
          >
            عدم التزام
          </Badge>
        );

      case 5:
        return (
          <Badge
            className="text-white font-medium bg-[#000] pb-3 px-5 rounded-xl"
            size="md"
          >
            لا يوجد
          </Badge>
        );

      default: {
        return null;
      }
    }
  };
  // ------------------ Agencies --------------
  const [selectedAgency, setSelectedAgency] = useState("");
  const [allAgencies, setAllAgencies] = useState([]);

  // const [getAllAgencies, allAgencies] = useAxiosGetRequest();
  const getStatistics = async (id) => {
    if (id || agencyId != "null")
      getOldQiyas("services/app/OldQiyas/GetAllStatics", {
        id: id ? id : agencyId,
      });
  };

  const getAllAgencies = async () => {
    const resp = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    setAllAgencies(resp);
    setSelectedAgency({
      label: resp?.result?.[0].nameInArabic,
      value: resp?.result?.[0].id,
    });
  };

  useEffect(() => {
    getAllAgencies();
  }, []);

  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);

  const handleChangeAgency = (selectedAgencyObj) => {
    setSelectedAgency(selectedAgencyObj || "");
  };

  const handleGetAllAgencies = async () => {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || "",
      value: item.id,
      name: item.name || "",
    }));

    setAllAgenciesOptions(agenciesOptions);
    setSelectedAgency(agenciesOptions[0]);
  };

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  // ------------------ Agencies --------------

  useEffect(() => {
    if (agencyId == "null") getStatistics(selectedAgency?.value);
    else {
      getStatistics(agencyId);
    }
  }, [selectedAgency?.value]);

  const oldQiyasObj = oldQiyas?.result;

  const oldQiyasAnswerList = oldQiyas?.result?.answerList?.map((item) => {
    return {
      agencyCode: oldQiyas?.result.code,
      agencyName: oldQiyas?.result.name,
      ...item,
    };
  });

  const dataForExcel = oldQiyasAnswerList?.map((ele) => {
    return {
      "رقم المعيار": ele.indicatorNumber,
      "اٍجابة الجهة": ele.answerTypeAgencyName,
      "مستوى الالتزام": ele.answerTypeExpertName,
      "ملاحظة الفريق الوطني": ele.expertNote,
    };
  });

  const columns = [
    {
      name: "رقم المعيار",
      selector: (row) => row.indicatorNumber,
      header: "رقم المعيار",
      accessorKey: "answerListCode",
      sortable: true,
      center: true,
    },
    {
      name: "اٍجابة الجهة",
      selector: (row) => row.answerTypeAgencyName,
      header: "اٍجابة الجهة",
      accessorKey: "answerTypeAgencyName",
      sortable: true,
      center: true,
      cell: (row) => answersBadges(row.answerTypeAgency),
    },
    {
      name: "مستوى الالتزام",
      selector: (row) => row.answerTypeExpertName,
      header: "مستوى الالتزام",
      accessorKey: "answerTypeExpertName",
      sortable: true,
      center: true,
      cell: (row) => answersBadges(row.answerTypeExpert),
    },
    {
      name: "ملاحظة الفريق الوطني",
      selector: (row) => row.expertNote,
      header: "ملاحظة الفريق الوطني",
      accessorKey: "expertNote",
      sortable: true,
      center: true,
      cell: (row) => (
        <div>
          {row.expertNote ? (
            <NewTooltip content={"عرض"}>
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setOpenNotesModal(true);
                  setExpertNotes(row?.expertNote);
                }}
              />
            </NewTooltip>
          ) : (
            "لا يوجد"
          )}
        </div>
      ),
    },
  ];

  // New Table //

  const downloadExcel = useExcelExport();

  const handleDownload = async (agencyId) => {
    const api = `${process.env.REACT_APP_URL}/Reports/DawnloadAgencyQiyas23Report`;
    const params = { agencyId: agencyId };
    const filename = `التقرير النهائي_${oldQiyas?.result?.code}`;
    const errorMessage = "لا يوجد تقرير لهذه الجهة";

    await downloadPdf(api, params, filename, null);
  };

  // search
  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(oldQiyas?.result?.answerList);
    } else {
      const filtered = oldQiyas?.result?.answerList?.filter((ele) => {
        const { indicatorNumber, answerTypeAgency, answerTypeExpert } = ele;
        return (
          indicatorNumber.includes(value) ||
          ("التزام كلي".includes(value) &&
            (answerTypeAgency === 1 || answerTypeExpert === 1)) ||
          ("التزام جزئي".includes(value) &&
            (answerTypeAgency === 2 || answerTypeExpert === 2)) ||
          ("لا ينطبق".includes(value) &&
            (answerTypeAgency === 3 || answerTypeExpert === 3)) ||
          ("عدم التزام".includes(value) &&
            (answerTypeAgency === 4 || answerTypeExpert === 4)) ||
          ("لا يوجد".includes(value) &&
            (answerTypeAgency === 5 || answerTypeExpert === 5))
        );
      });
      setFilteredData(filtered);
    }
  };

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={previousSurveysBreadcumbs}>
      {getOldQiyasLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="flex-1">
                {!isAgency && (
                  <DropdownMultiSelect
                    placeholder={"اختر جهة حكومية"}
                    isClearable={true}
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedAgency}
                    handleChange={handleChangeAgency}
                    options={allAgenciesOptions || []}
                    isMultiple={false}
                    isRequired={false}
                    label={"اختر جهة حكومية"}
                  />
                )}
              </div>
              <div className="flex-1 flex justify-end gap-3 self-end">
                <DownloadButton
                  onClick={() =>
                    history.push({
                      pathname: `/previousSurveys/${
                        agencyId != "null" ? agencyId : selectedAgency?.value
                      }/download`,
                      state: { agencyName: oldQiyasObj?.name },
                    })
                  }
                >
                  {"تحميل الملفات السابقة"}
                </DownloadButton>

                <DownloadButton
                  onClick={() =>
                    handleDownload(
                      agencyId != "null" ? agencyId : selectedAgency?.value
                    )
                  }
                >
                  {"تحميل التقرير النهائي (ملف PDf)"}
                </DownloadButton>
              </div>
            </div>

            <SummaryAgencyAnswer oldQiyasObj={oldQiyasObj} />

            <div className="px-5 mb-5 ">
              <NewTable
                withSearch
                withExcel
                onExportExcel={() =>
                  downloadExcel(
                    `تقرير القياس السابق لجهة ${oldQiyasObj.name}`,
                    dataForExcel
                  )
                }
                onSearch={handleFilterData}
                loading={getOldQiyasLoading}
                columns={columns}
                data={filteredData}
                pagination={false}
              />
            </div>
          </div>
        </>
      )}

      <AppModal
        isFooter={false}
        headerTitle={"ملاحظة الفريق الوطني"}
        open={openNotesModal}
        setOpen={setOpenNotesModal}
      >
        <div className="text-xl h-full">{expertNotes}</div>
      </AppModal>
    </HeaderWithBreadcrumbLayout>
  );
};

export default PreviousSurveys;
