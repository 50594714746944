import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";

const ReviewerAnswerDegrees = () => {
  const { newReviewerAnswer, reviewerDegree } = useAnswerContext();

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newReviewerAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />

        <AnswerDegreeItem
          degree={
            newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.pocOfficerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />

        {/* <AnswerDegreeItem
          degree={
            newReviewerAnswer?.expertFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newReviewerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        /> */}

        <AnswerDegreeItem
          degree={newReviewerAnswer?.checkedReviewerDegree?.title || "لا يوجد"}
          circleColor={
            newReviewerAnswer?.checkedReviewerDegree?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />

        <AnswerDegreeItem
          degree={
            reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.color ||
            "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default ReviewerAnswerDegrees;
