import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import usePrevAgencyAnswer from "./usePrevAgencyAnswer";

const usePrevPocOfficerAnswer = () => {
  const { getPrevAgencyAnswer } = usePrevAgencyAnswer();

  const {
    setLoadPrePocOfficerAnswer,
    setNewPocOfficerAnswer,
    setPocOfficerAnswer,
    pocOfficerDegree,
    newPocOfficerAnswer,
    setPocOfficerDegree,
    getSelfEvaluation,
  } = useAnswerContext();

  const getDegrees = async (standardId) => {
    let degreeValues = [];
    const degreesResult = await axiosGetRequest(
      environment.getCommitmentLevels,
      {
        standardId,
      }
    );

    if (degreesResult?.success) {
      degreeValues = degreesResult?.result?.map((degree) => ({
        ...degree,
        label: degree.title,
        value: degree.id,
      }));
    }

    const surveyDegrees = [...degreeValues];

    return surveyDegrees;
  };
  // const { currentStage } = useContext(StageContext);

  const getPrevPocOfficerAnswer = async (
    standardId,
    entityId,
    currentStage,
    pocOfficerId
  ) => {
    setLoadPrePocOfficerAnswer(true);

    const agencyAnswer = await getPrevAgencyAnswer(
      standardId,
      entityId,
      currentStage
    );
    setNewPocOfficerAnswer(agencyAnswer);

    // TODO: ask backend to return title in pocOfficerAnswer -> pocOfficerRequirementAnswers -> standardRequirementTitle instead of null
    const res = await axiosGetRequest(environment.getPOCOfficerAnswer, {
      AgencyId: entityId,
      StageId: currentStage,
      StageId: localStorage.getItem("surveyStageId"),
      StandardId: standardId,
      POCOfficerId: pocOfficerId || localStorage.getItem("userId"),
    });

    if (res?.result) {
      const pocOfficerRequirementsAnswer =
        agencyAnswer?.standardRequirements?.map((requirement) => {
          const reqAnswer =
            res?.result?.pocOfficerAnswer?.pocOfficerRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.pocOfficerSubRequirementAnswers) {
            const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = pocOfficerSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...subReq,
                  ...sub,
                  id: subReq.id,
                };
              }
            );

            return {
              ...requirement,
              ...reqs,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        });

      const agencyPrevUploadedFilesAnswer = agencyAnswer?.standardProofs?.map(
        (proofFile) => {
          const proofNote =
            res?.result?.pocOfficerAnswer?.surveyPocOfficerAnswerStandardProofs?.find(
              (proofAns) =>
                proofAns.standardProofId === proofFile?.standardProofId
            );

          

          return {
            ...proofFile,
            ...proofNote,
            pocOfficerProofNotes: proofNote?.pocOfficerProofNotes || "",
            pastSelectedFiles: [],
          };
        }
      );

      const templateList = await axiosGetRequest(
        environment.getAllNoteTemplate
      );

      // * Get and select selected pocOfficer degree
      const surveyDegrees = (await getDegrees(standardId)).filter(
        (item) => item?.id !== noAnswerDegreeId
      );
      const donotApplyDegree =
        surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";
      const noCommitmentDegree =
        surveyDegrees?.filter((item) => item?.title == "عدم التزام")?.[0] || "";

      const checkedPocOfficerDegree =
        surveyDegrees?.filter(
          (item) =>
            item?.id == res?.result?.pocOfficerAnswer?.finalCommitmentLevel?.id
        )?.[0] || null;

      setPocOfficerDegree({
        ...pocOfficerDegree,
        pocOfficerSelfEvaluationCommitmentLevel:
          res?.result?.pocOfficerAnswer?.selfEvaluationCommitmentLevel ||
          noCommitmentDegree,
        pocOfficerSelfEvaluationDegree:
          res?.result?.pocOfficerAnswer?.selfEvaluationDegree || 0,
      });

      const reviewerRequirementsAnswer =
        agencyAnswer?.standardRequirements?.map((requirement) => {
          const reqAnswer =
            res?.result?.lastAnswer?.reviewerRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.reviewerSubRequirementAnswers) {
            const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = reviewerSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...subReq,
                  ...sub,
                  id: subReq.id,
                  decision: sub?.decision,
                  donotApply: sub?.donotApply,
                };
              }
            );

            return {
              ...requirement,
              ...reqs,
              decision: reqs?.decision,
              donotApply: reqs?.donotApply,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        });
      const expertRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
        (requirement) => {
          const reqAnswer =
            res?.result?.lastAnswer?.expertRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.expertSubRequirementAnswers) {
            const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = expertSubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...sub,
                  title: subReq?.title,
                  id: subReq.id,
                };
              }
            );

            return {
              ...reqs,
              title: requirement?.title,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((item) => ({
              ...item,
              decision: 0,
              donotApply: false,
            }));
          return {
            ...requirement,
            decision: 0,
            donotApply: false,
            subStandardRequirements,
          };
        }
      );

      

      const answer = {
        ...newPocOfficerAnswer,
        standardRequirements: pocOfficerRequirementsAnswer,
        checkedPocOfficerDegree,
        reviewerRequirementsAnswer,
        expertRequirementsAnswer,
        surveyDegrees,
        standardProofs: agencyPrevUploadedFilesAnswer,
        templateList: templateList?.result?.items,
        pocOfficerSelectedTemplate:
          res?.result?.pocOfficerAnswer?.noteForAgency?.template ||
          res?.result?.pocOfficerAnswer?.noteForAgency?.content ||
          "",
        agencySelfEvaluationCommitmentLevel:
          res?.result?.agencySelfEvaluationCommitmentLevel,

        agencyFinalCommitmentLevel: agencyAnswer?.agencyDonotApply
          ? donotApplyDegree
          : res?.result?.lastAnswer?.agencyFinalCommitmentLevel ||
            noCommitmentDegree ||
            null,
        reviewerFinalCommitmentLevel:
          res?.result?.lastAnswer?.reviewerFinalCommitmentLevel || null,
        expertFinalCommitmentLevel:
          res?.result?.lastAnswer?.expertFinalCommitmentLevel || null,
        pocOfficerAnswerNote:
          res?.result?.pocOfficerAnswer?.pocOfficerAnswerNote || "",
        noteForAgency:
          res?.result?.pocOfficerAnswer?.noteForAgency?.content || "",
        needVisit: res?.result?.pocOfficerAnswer?.needVisit,
        visitNote: res?.result?.pocOfficerAnswer?.visitNote,

        templateIdForAgency:
          res?.result?.pocOfficerAnswer?.noteForAgency?.noteTemplate?.id,

        reviewerRequirementAnswers: res?.result?.lastAnswer.reviewerRequirementAnswers,
        reviewerNoteTemplate: res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate?.templateContent || "",
        reviewerNotContent: res?.result?.lastAnswer?.reviewerNoteForAgency?.content,
        reviewerNoteForAgency:
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.unCheckRequirement ||
            "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.reviewerNoteForAgency?.template || ""),
        
        expertRequirementAnswers: res?.result?.lastAnswer.expertRequirementAnswers,
        expertNoteTemplate: res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || "",
        expertNotContent: res?.result?.lastAnswer?.expertNoteForAgency?.content,
        expertNoteForAgency:
          (res?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.unCheckRequirement ||
            "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.expertNoteForAgency?.template || ""),
        
        pocOfficerNoteTemplate: res?.result?.pocOfficerAnswer?.noteForAgency?.noteTemplate?.templateContent || "",
        pocOfficerNotContent: res?.result?.pocOfficerAnswer?.noteForAgency?.content,
        pocOfficerNoteForAgency:
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate
            ?.templateContent || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency
            ?.unCheckRequirement || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.content || "") +
          (res?.result?.lastAnswer?.pocOfficerNoteForAgency?.template || ""),
      }

      setNewPocOfficerAnswer(answer);
      setPocOfficerAnswer(structuredClone(answer));
    }

    setLoadPrePocOfficerAnswer(false);
  };

  return { getPrevPocOfficerAnswer };
};

export default usePrevPocOfficerAnswer;
