import {
  useBatchAddListener,
  useBatchFinishListener,
  useBatchProgressListener,
  useItemFinalizeListener,
  useItemFinishListener,
  useItemProgressListener,
  useUploady,
} from "@rpldy/uploady";
import FilesUploading from "../../upload/filesUploading";
import UploadItemsList from "../../upload/uploadItemsList";
import { useAnswerContext } from "../../../modules/main/features/standerds/standardAnswer/answerContext";
import { useUploadContext } from "../../upload/uploadFilesContext";
import {
  useChunkFinishListener,
  useChunkStartListener,
} from "@rpldy/chunked-uploady";
import environment from "../../../services/environment";
import { axiosPostRequest } from "../../../services/Request";
import { errorToast } from "../../../helpers/toast";
import { useDrop } from "react-dnd";
import { getFileExtension, getUniqueValues } from "../../../helpers/utils";
import { NativeTypes } from "react-dnd-html5-backend";

const UploadFiles = ({
  label,
  agencyId,
  from,
  proofId,
  standardId,
  standardProofId,
  document,
  relatedRequirementId,
}) => {
  const uploady = useUploady();
  const newAgencyAnswer = useAnswerContext();

  const {
    uploading,
    setUploading,
    uploadingProofId,
    setUploadingProofId,
    setFilesProgress,
    itemsCount,
    setItemsCount,
    setUploadedCount,
    files,
    setFiles,
    attachmentFilesCount,
    setAttachmentFilesCount,
  } = useUploadContext();

  const allowedExts = document?.standardProofFileTypeAssociations?.map((item) =>
    item?.standardProofFileTypeTitle?.toLowerCase()
  );

  const renderedAllowedFileExt = allowedExts?.map((item, indx) => (
    <span className="">
      {" "}
      {item} {indx !== allowedExts?.length - 1 && " - "}{" "}
    </span>
  ));

  

  useBatchAddListener((data) => {
    const docAttachment = getUniqueValues(document?.attachments, "id");
    const uploadedDocs = files?.filter((item) => item?.id == document?.id);

    

    const filesToUpload =
      files?.filter((item) => item?.proofId == proofId)?.length + 1;

    // PORetirement to disable this validation
    // if (docAttachment?.length + filesToUpload > document.fileCount) {
    //   errorToast(
    //     "يجب ان يكون عدد الملفات أقل من " + (document?.fileCount + 1) + " ملف "
    //   );
    //   return false;
    // }


    const allowedExts = document?.standardProofFileTypeAssociations?.map(
      (item) => item?.standardProofFileTypeTitle?.toLowerCase()
    );

    // * Files count
    // PORetirement to disable this validation
    // if (data?.items?.length > document?.fileCount) {
    //   errorToast(
    //     "يجب ان يكون عدد الملفات أقل من " + (document?.fileCount + 1) + " ملف "
    //   );
    //   return false;
    // }

    // validation
    // * File ext
    if (allowedExts) {
      let allowedExtensions = [];
      for (let item of allowedExts) {
        switch (item) {
          case "excel":
            allowedExtensions.push("xlsx");
            allowedExtensions.push("xls");
            break;
          case "word":
            allowedExtensions.push("doc");
            allowedExtensions.push("docx");
            break;
          default:
            allowedExtensions.push(item);
            break;
        }
      }

      const hasCorrectExt = data?.items?.filter((item) =>
        allowedExtensions?.includes(getFileExtension(item?.file?.name))
      );

      if (hasCorrectExt?.length == 0) {
        errorToast("خطأ فى نوع الملفات");
        return false;
      }
    }

    // * File size
    const filesSizeArr = data?.items?.map((item) => item?.file?.size);
    const filesSize = filesSizeArr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (document?.standardProofFileSizeTypeId != 1) {
      if (filesSize > document?.fileSize * 1024 * 1024) {
        errorToast(
          "يجب ان يكون حجم الملفات اقل من " + document?.fileSize + " ميجا "
        );
        return false;
      }
    }

    setFilesProgress(0);
    // setUploadedCount(0);

    if (attachmentFilesCount[proofId]) {
      setAttachmentFilesCount({
        ...attachmentFilesCount,
        [proofId]: {
          ...attachmentFilesCount[proofId],
          itemsCount:
            attachmentFilesCount[proofId].itemsCount + data?.items?.length,
        },
      });
    } else {
      setAttachmentFilesCount({
        ...attachmentFilesCount,
        [proofId]: {
          itemsCount: data?.items?.length,
          uploaded: 0,
        },
      });
    }

    const batchFiles = data?.items?.map((item) => ({
      id: item?.id,
      fileName: item?.file?.name,
      size: item?.file?.size,
      type: item?.file?.type,
      progress: 0,
      status: "",
      proofId,
      standardId,
      standardProofId,
      relatedRequirementId,
    }));

    setFiles([...files, ...batchFiles]);

    setItemsCount((prev) => prev + data?.orgItemCount);
    setUploadingProofId(standardProofId);
    setUploading(true);
  });

  useBatchProgressListener((data) => {
    setFilesProgress((data?.completed * 100).toFixed(2));
  });

  useBatchFinishListener(async (data) => {
    setUploadingProofId(null);
    setUploading(false);
  });

  useItemFinalizeListener(async (data) => {
    setUploadedCount((prev) => prev + 1);

    setAttachmentFilesCount({
      ...attachmentFilesCount,
      [proofId]: {
        ...attachmentFilesCount[proofId],
        uploaded: attachmentFilesCount[proofId].uploaded + 1,
      },
    });

    if (data?.file?.size < 1024 * 1024 * 1) {
      const newValues = files?.map((file) => {
        return !file?.attachmentId && data?.id == file?.id
          ? {
              ...file,
              progress: 100,
              status: "completed",
              attachmentId: data?.uploadResponse?.results[0]?.data?.result?.id,
            }
          : file;
      });
      setFiles(newValues);
    } else {
      // encodedName
      const res = await axiosPostRequest(
        environment?.uploadComplete,
        {
          OrginalName: data?.file?.name,
          FileName:
            data?.uploadResponse?.results?.[0]?.data?.result.encodedName,
        },
        {
          // OrginalName : data?.file?.name,
          // FileName : data?.uploadResponse?.results?.[0]?.data?.result.encodedName,
          agencyId,
          attachmentClassification: from,
          standardCode: newAgencyAnswer?.standard?.code,
        }
      );

      const newValues = files?.map((file) => {
        return !file?.attachmentId && data?.id == file?.id
          ? {
              ...file,
              progress: 100,
              status: "completed",
              attachmentId: res?.result?.id,
            }
          : file;
      });
      setFiles(newValues);
    }
  });
  useItemProgressListener((data) => {
    // 

    const updatedFiles = files?.map((item) => {
      if (item.id == data.id && data?.completed != 100) {
        return {
          ...item,
          progress: Math.floor(data?.completed),
          status: "uploading",
        };
      }
      return item;
    });
    setFiles(updatedFiles);
    // setFilesProgress(Math.ceil(data?.completed * 100));
  });

  useItemFinishListener(async (data) => {
    const updatedFiles = files?.map((item) => {
      if (item.id == data.id && data?.completed == 100) {
        return {
          ...item,
          progress: 100,
          status: "completed",
        };
      }
      return item;
    });
    setFiles(updatedFiles);
  });

  useChunkStartListener((data) => {
    const body = {
      name: data.chunkItem.file.name,
      type: data.chunkItem.file.type,
      file: data.chunkItem.file,
    };
    if (data?.item?.file?.size < 1024 * 1024 * 1) {
      return {
        url: `${process.env.REACT_APP_URL}/${environment.attachmentFile}`,
        sendOptions: {
          withCredentials: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          sendWithFormData: true,
          params: {
            agencyId: agencyId,
            attachmentClassification: from,
            standardCode: newAgencyAnswer?.standard?.code,
          },
        },
      };
    }

    return {
      url: `${process.env.REACT_APP_URL}/${environment.uploadChunks}`,
      sendOptions: {
        params: {
          id: data.chunk.index,
          fileName: data.chunkItem.file.name,
          chunkNumber: data.chunk.index,
          totalChunks: data.totalCount,
          originalname: data.chunkItem.file.name,
          standardCode: newAgencyAnswer?.standard?.code,
        },
        sendWithFormData: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    };
  });

  useChunkFinishListener((data) => {
    if (data?.item?.file?.size <= 1024 * 1024 * 10000) {
      const editedFiles = files?.map((item) => {
        if (item?.id == data?.item?.id) {
          const newItem = {
            ...item,
            attachmentId: data?.uploadData?.response?.data?.result?.id,
          };

          return newItem;
        }

        return item;
      });

      setFiles(editedFiles);
    }

    // 
  });

  const [{ isDragging }, dropRef] = useDrop({
    accept: NativeTypes.FILE,
    collect: (monitor) => ({
      isDragging: !!monitor.isOver(),
    }),
    drop: (item) => {
      uploady.upload(item.files);
    },
  });

  const onClick = () => {
    uploady.showFileUpload({});
  };

  

  return (
    <>
      <div className="flex w-full items-center justify-center">
        {uploading  && uploadingProofId ==  standardProofId ? (
          <FilesUploading label={label} proofId={proofId} />
        ) : (
          <div
            className="flex w-full items-center justify-center "
            ref={dropRef}
            onClick={onClick}
          >
            <label
              className={`${
                isDragging ? "bg-indigo-200  " : ""
              }   flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 mb-2 min-h-[170px]`}
            >
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className=" h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLineJoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-3  text-gray-900 dark:text-gray-400">
                  <span className="font-semibold">
                    {isDragging && "قم باسقاط الملفات هنا"}
                    {!isDragging && !uploading && "اختر او قم بسحب الملفات"}
                  </span>
                </p>
                <p className=" max-w-[90%] text-gray-700 text-center dark:text-gray-400">
                  {label}{" "}
                  {/* <span className="text-xs text-gray-500">
                    {" "}
                    ( {document?.fileCount} ملفات{" "}
                    {newAgencyAnswer?.agency.isConfidentialAgency ==
                      false &&
                      ` -
                    ${
                      document?.standardProofTypeId == 3 ? "اختيارى" : "إجبارى"
                    } `}{" "}
                    )
                  </span> */}
                </p>
                <p className="text-xs mt-3 text-gray-500 ">
                  <span className="text-gray-700">
                    {" "}
                    انواع الملفات المتاحة :{" "}
                  </span>{" "}
                  {renderedAllowedFileExt}
                </p>
              </div>
            </label>
          </div>
        )}
      </div>
      {filterByRequirementId(
        filterByProofId(files, proofId),
        relatedRequirementId
      )?.length > 0 && (
        <UploadItemsList
          proofId={proofId}
          standardId={standardId}
          standardProofId={standardProofId}
          document={document}
          relatedRequirementId={relatedRequirementId}
        />
      )}
    </>
  );
};

export default UploadFiles;


function filterByRequirementId(files, relatedRequirementId) {
  if (!relatedRequirementId) return files;
  return files?.filter((item) => item?.relatedRequirementId == relatedRequirementId);
}

function filterByProofId(files, proofId) {
  return files?.filter((item) => item?.proofId == proofId);
}
