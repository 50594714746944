import { Link } from "react-router-dom";
import NewTooltip from "../../components/NewTooltip";

const Breadcrumb = ({ icon, title, url }) => {
  return (
    <div className="flex flex-row items-center">
      {icon && (
        <img
          src={icon}
          alt="page icon"
          className="w-[26px] h-[30px] bg-transparent ml-3 "
        />
      )}
      <Link to={url} className="flex flex-row items-center -mt-3">
        <NewTooltip content={title}>
          <span className={"lg:text-[18px]  text-[#251E5C]"}>
            {/* {title?.length > 15 ? title.slice(0, 15) + "  ..." : title} */}
            {title}
          </span>
        </NewTooltip>
      </Link>
    </div>
  );
};

export default Breadcrumb;
