export function showRequirement(requirement) {
  // support for requirements without subStandardRequirements
  if(requirement.expertSubRequirementAnswers) {
    requirement.subStandardRequirements = requirement.expertSubRequirementAnswers;
  }

  if (
    !requirement.subStandardRequirements ||
    requirement.subStandardRequirements.length === 0
  ) {
    return (
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false
    );
  } else {
    return !!(
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false &&
      requirement.subStandardRequirements.find(
        (subReq) =>
          subReq.donotApply == false &&
          (subReq.decision == 0 || !subReq.decision)
      )
    );
  }
}
