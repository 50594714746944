import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-skeleton-loader";
import { AddStandardContext } from "./addStandardContext";
import NewDropdownMultiSelect from "../../../../../components/NewDropDownMultiSelect";

const StandardUploadOldFiles = ({ disabled = true }) => {
  const {
    selectedOldStandardList,
    setSelectedOldStandardList,
    getLastYearStandards,
    oldStandardList,
    addFinalValue,
    setAddFinalValue,
    loading,
  } = useContext(AddStandardContext);
  const [prevTitle, setPrevTitle] = useState([]);
  useEffect(() => {
    getLastYearStandards();
    const prevYearFiles = addFinalValue?.pastSurveyStandardAssociations?.map(
      (item) => ({
        label: item.pastSurveyStandardId?.toString() || "",
        value: item.pastSurveyStandardId,
        id: item.pastSurveyStandardId,
        title: item.pastSurveyStandardId,
      })
    );
    setSelectedOldStandardList(prevYearFiles || []);
  }, []);
  const oldStandardListOptions =
    oldStandardList?.length > 0
      ? oldStandardList?.map((item) => {
          return {
            label: item.title?.split("(")?.[1]?.split(")")?.[0] || "",
            value: item.id,
            id: item.id,
            title: item.title,
          };
        })
      : [];

  const handleChangeStandards = (value) => {
    
    setSelectedOldStandardList(value || []);
    setPrevTitle(value?.title);
    setAddFinalValue({
      ...addFinalValue,
      pastSurveyStandardAssociations: value || [],
    });
  };
  return (
    <div className="mt-8">
      <h2 className="font-semibold mb-4">ربط بملفات معيار من القياس السابق</h2>
      <div className="bg-white p-5 pb-7 rounded-lg">
        <div className="row">
          <div className="col-lg-6">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <>
                <NewDropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedOldStandardList || []}
                  handleChange={handleChangeStandards}
                  placeholder="اختر المعايير"
                  label={"معايير القياس السابق"}
                  options={oldStandardListOptions || []}
                  isMultiple={true}
                  isRequired={false}
                  disabled={disabled}
                  // errorMessage={errors.agencyStandards}
                />
                {/* <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedOldStandardList || []}
                handleChange={handleChangeStandards}
                placeholder="اختر المعايير"
                label={"معايير القياس السابق"}
                isRequired={false}
                options={oldStandardListOptions || []}
                isMultiple={true}
              /> */}
              </>
            )}
          </div>
          <div className="col-lg-6">
            <p className="mt-2">{prevTitle}</p>
          </div>
          {/* <div className="col-lg-6">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedStandards}
                handleChange={handleChangeStandards}
                placeholder="اختر الملفات"
                label={"الملفات"}
                options={standardListOptions || []}
                isMultiple={true}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StandardUploadOldFiles;
