import Skeleton from "react-skeleton-loader";

const IndicatorSurveyItemSkeleton = () => {
  return (
    <div className="w-full">
      <div
        className="border-[1px] bg-white  shadow hover:shadow-lg border-gray-200 rounded-2xl  p-3 px-5">
        <h3 className="text-blue">
          <Skeleton width="300px"/>
        </h3>

        <div className="flex justify-between items-center mt-3">
          <div className="flex justify-between flex-wrap  gap-x-8 gap-2">
            <div className="flex gap-4">
              <p className="text-blue text-sm">
                <Skeleton width="100px"/>
              </p>
              <p className=" text-green">
                {" "}
                <Skeleton width="30px"/>
              </p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">
                <Skeleton width="100px"/>
              </p>
              <p className=" text-green">
                <Skeleton width="30px"/>
              </p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">
                <Skeleton width="100px"/>
              </p>
              <p className=" text-green">
                <Skeleton width="30px"/>
              </p>
            </div>
          </div>

          <Skeleton width="140px" height="42px"/>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSurveyItemSkeleton;
