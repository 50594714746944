import Skeleton from "react-skeleton-loader";

const SCard = ({
  color,
  title,
  number,
  icon,
  onClick,
  tickets = false,
  loading,
  cursorPointer = false,
}) => {
  return (
    <div
      onClick={() => onClick?.()}
      className="h-[170px] flex flex-col bg-opacity-20 justify-between rounded-[20px] p-5"
      style={{
        cursor: tickets || cursorPointer ? "pointer" : "",
        backgroundColor: color,
      }}
    >
      <div className="flex justify-end ">
        <img src={icon} alt={icon} className="w-[40px] h-[40px] " />
      </div>
      <div className="flex justify-start flex-1">
        <div className="flex flex-col justify-end gap-1">
          <span class="text-right text-black text-2xl font-semibold">
            {loading ? (
              <Skeleton width={"100px"} height={"10px"} color={color} />
            ) : (
              number
            )}
          </span>
          <span class="text-slate-500 text-xl font-medium">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default SCard;
