import { tokenConfig } from "../constants/contents";
import { errorToast } from "../helpers/toast";
import { t } from "i18next";
import apiRequest from "./apiRequest";

export const get = async (api, config, callBack) => {
  let promise;

  if (checkIfRequestIsCached(api, config)) {
    promise = clonePromise(getCachedPromise(api, config));
  } else {
    promise = apiRequest.get(`${process.env.REACT_APP_URL}/${api}`, config);
    setCachedPromise(api, config, promise);
  }

  return promise
    .then((response) => {
      return callBack(response);
    })
    .catch((error) => {
      return callBack(error.response);
    })
    .finally(() => {
        deleteCachedPromise(api, config);
    });
};

export const post = (api, data, config, callBack) => {
  apiRequest
    .post(`${process.env.REACT_APP_URL}/${api}`, data, config)
    .then((response) => {
      return callBack(response);
    })
    .catch((error) => {
      return callBack(error.response);
    });
};

export const put = (api, data, config, callBack) => {
  apiRequest
    .put(`${process.env.REACT_APP_URL}/${api}`, data, config)
    .then((response) => {
      return callBack(response);
    })
    .catch((error) => {
      return callBack(error);
    });
};

export const remove = (api, config, callBack) => {
  apiRequest
    .delete(`${process.env.REACT_APP_URL}/${api}`, config)
    .then((response) => {
      return callBack(response);
    })
    .catch((error) => {
      return callBack(error);
    })
    .finally(function() {});
};

export const axiosGetRequest = async (
  api,
  params,
  defaultErrorMessage = true,
  signal
) => {
  try {
    const { data } = await apiRequest.get(
      `${process.env.REACT_APP_URL}/${api}`,
      {
        ...tokenConfig,
        params,
        signal,
      }
    );
    return data;
  } catch (error) {
    if (
      error?.response?.data?.error?.message ==
      "An internal error occurred during your request!"
    ) {
      errorToast("حدث خطأ،يرجى التحدث للدعم الفنى");
      return;
    }
    if (error?.response?.status == 400) {
      errorToast(error?.response?.data?.error?.details);
      return;
    }
    if (error?.response?.status == 403) {
      errorToast(t("general.authError"));
      return;
    }
    if (defaultErrorMessage) {
      errorToast(error?.response?.data?.error?.message);
      return;
    }
    return error;
  }
};
export const axiosPostRequest = async (
  api,
  data,
  params,
  defaultErrorMessage = true,
  defaultServerErrorMessage = true,
  responseBlob = false,
  signal
) => {
  try {
    const config = {
      ...tokenConfig,
      params: params,
      signal,
      responseType: responseBlob ? "blob" : "json",
    }; 

    const { data: res } = await apiRequest.post(
      `${process.env.REACT_APP_URL}/${api}`,
      data,
      config
    );

    return res;
  } catch (error) {
    if (
      error?.response?.data?.error?.message ==
        "An internal error occurred during your request!" &&
      defaultServerErrorMessage
    ) {
      errorToast("حدث خطأ،يرجى التحدث للدعم الفنى");
      return;
    }
    if (error?.response?.status == 400) {
      errorToast(error?.response?.data?.error?.details);
      return;
    }
    if (error?.response?.status == 403) {
      errorToast(t("general.authError"));
      return;
    }
    if (defaultErrorMessage) {
      errorToast(error?.response?.data?.error?.message);
      return;
    }

    return error;
  }
};
export const axiosPutRequest = async (
  api,
  data,
  params,
  defaultErrorMessage = true
) => {
  try {
    const { data: res } = await apiRequest.put(
      `${process.env.REACT_APP_URL}/${api}`,
      data,
      {
        ...tokenConfig,
        params,
      }
    );
    return res;
  } catch (error) {
    if (
      error?.response?.data?.error?.message ==
      "An internal error occurred during your request!"
    ) {
      errorToast("حدث خطأ،يرجى التحدث للدعم الفنى");
      return;
    }
    if (error?.response?.status == 400) {
      errorToast(error?.response?.data?.error?.details);
      return;
    }
    if (error?.response?.status == 403) {
      errorToast(t("general.authError"));
      return;
    }
    if (defaultErrorMessage) {
      errorToast(error?.response?.data?.error?.message);
      return;
    }
    return error;
  }
};
export const axiosDeleteRequest = async (
  api,
  params,
  defaultErrorMessage = true
) => {
  try {
    const { data: res } = await apiRequest.delete(
      `${process.env.REACT_APP_URL}/${api}`,
      {
        ...tokenConfig,
        params,
      }
    );
    return res;
  } catch (error) {
    if (
      error?.response?.data?.error?.message ==
      "An internal error occurred during your request!"
    ) {
      errorToast("حدث خطأ،يرجى التحدث للدعم الفنى");
      return;
    }
    if (error?.response?.status == 400) {
      errorToast(error?.response?.data?.error?.details);
      return;
    }
    if (error?.response?.status == 403) {
      errorToast(t("general.authError"));
      return;
    }
    if (defaultErrorMessage) {
      errorToast(error?.response?.data?.error?.message);
      return;
    }
    return error;
  }
};



// Promise Cache

const PromiseCache = {};

function checkIfRequestIsCached(api, config){
  const key = api + nestedObjectToString(config);
  return PromiseCache[key] ? true : false;
}

function getCachedPromise(api, config){
  const key = api + nestedObjectToString(config);
  return PromiseCache[key];
}

function setCachedPromise(api, config, promise){
  const key = api + nestedObjectToString(config);
  PromiseCache[key] = promise;
}

function deleteCachedPromise(api, config){
  const key = api + nestedObjectToString(config);
  delete PromiseCache[key];
}

function clonePromise(promise) {
  return new Promise((resolve, reject) => {
      promise.then(resolve).catch(reject).finally(() => {});
  });
}

function nestedObjectToString(obj) {
  return Object.keys(obj)
    .map((key) => {
      if (typeof obj[key] === "object") {
        return nestedObjectToString(obj[key]);
      }
      return `${key}:${obj[key]}`;
    })
    .join(",");
}
