import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";

const PocOfficerAnswerDegrees = () => {
  const { newPocOfficerAnswer, pocOfficerDegree } = useAnswerContext();

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.expertFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />

        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.checkedPocOfficerDegree?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.checkedPocOfficerDegree?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.color ||
            "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default PocOfficerAnswerDegrees;
