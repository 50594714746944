import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetAnsweredStandards = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingAnsweredStandards } = useQuery({
    queryKey: ["AnsweredStandards", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.PrepareAnsweredStandards,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        null,
        true,
        true,
        false,
        signal
      ),
  });

  const answeredStandards = data?.result?.items;
  const answeredStandardsCount = data?.result?.totalCount;
  const totalAnsweredNum =
    data?.result?.totalAnsweredAgenciesInAllAnsweredStandardsCount;
  const totalPages = Math.ceil(answeredStandardsCount / 4);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["AnsweredStandards", currentPage + 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosPostRequest(
          environment.PrepareAnsweredStandards,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            SkipCount: (currentPage + 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          null,
          true,
          true,
          false,
          signal
        ),
      enabled: !!phaseOneStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["AnsweredStandards", currentPage - 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosPostRequest(
          environment.PrepareAnsweredStandards,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            SkipCount: (currentPage - 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          null,
          true,
          true,
          false,
          signal
        ),
      enabled: !!phaseOneStageId,
    });
  }

  return {
    queryClient,
    data,
    answeredStandards,
    answeredStandardsCount,
    totalAnsweredNum,
    totalPages,
    isGettingAnsweredStandards,
  };
};
