import answer from "../../../../../../../../assets/icons/answer.svg";
import AppCheckbox from "../../../../../../../../components/form/appCheckbox";
import { ISurveyDegrees } from "../../../../types";


interface AgencyFinalAnswerViewOnlyProps {
  surveyDegrees: ISurveyDegrees[];
  selectedId: number;
  label?: string;
}

const AgencyFinalAnswerViewOnly = ({
  surveyDegrees,
  selectedId,
  label = "اجابة الجهة في مرحلة فتح النظام",
}: AgencyFinalAnswerViewOnlyProps) => {
  const firstStageRenderedDegrees = surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      label={item.label}
      checked={selectedId === item?.id}
      disabled

      column={null}
      defaultValue={null}
      pattern={null}
      title={null}
      required={null}
      onKeyPress={null}
      onChange={null}
    />
  ));

  return (
    <>
      <div>
        <div className="min-h-[440px] p-3">
          <div className="w-[100%] flex flex-row justify-between items-center">
            <div className="flex flex-row items-center">
              <img className="bg-transparent ml-2" src={answer} alt="answer" />
              <h1 className="text-[#292069] font-black text-[20px]">{label}</h1>
            </div>
          </div>
          <div className="w-[100%] mt-7 pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
            {firstStageRenderedDegrees}
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyFinalAnswerViewOnly;
