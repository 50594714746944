import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { StandardProgramContext } from "../../../../../../contexts/StandardProgramContext";
import openBook from "./../../../../../../assets/icons/openBook.svg";
import requirements from "./../../../../../../assets/icons/requirements.svg";
import lamp from "./../../../../../../assets/icons/lamp.svg";
import AppTextarea from "../../../../../../components/form/appTextarea";
import RequirementTextarea from "./requirementTextarea";
import NextBtn from "../components/buttons/nextBtn";
import AppSwitch from "../../../../../../components/form/appSwitch";
import NewTooltip from "../../../../../../components/NewTooltip";
import { permissionsCheck } from "./../../../../../../helpers/utils";
const StandardAnswerRequirement = ({
  menuClicked,
  setMenuClicked,
  setRequirementId,
  requirementId,
  idsSubRequirement,
  setIdsSubRequirement,
  idsRequirement,
  ids,
  setIds,
  setIdsRequirement,
  getSuggestedDegrees,
  setMenuElement,
  pocAnswer,
  donotApplyRequirementsIds,
  setDonotApplyRequirementsIds,
  donotApplySubRequirementsIds,
  setDonotApplySubRequirementsIds,
}) => {
  const standardProgramContext = useContext(StandardProgramContext);
  const [activeIds, setActiveIds] = useState(
    standardProgramContext?.requirements
      .filter((req) => req.subStandardRequirements.length)
      .map((req) => req.id)
  );

  

  const handleOpenCloseRequirement = (id) => {
    if (activeIds.includes(id)) {
      const newActiveIds = activeIds.filter((activeId) => id !== activeId);
      setActiveIds(newActiveIds);
    } else {
      setActiveIds((prev) => [...prev, id]);
    }
  };

  const handleRequirementsDonotApply = (req) => {
    standardProgramContext?.requirements?.forEach((item) => {
      if (item?.id == req?.id) {
        req.donotApply = !req?.donotApply;
      }
    });

    const donotApplyReqs = standardProgramContext?.requirements
      ?.filter((item) => item?.donotApply === true)
      ?.map((val) => val?.id);

    setDonotApplyRequirementsIds(donotApplyReqs);

    updateSuggestedDegrees(
      ids,
      standardProgramContext?.subRequirementListIds,
      donotApplyReqs,
      donotApplySubRequirementsIds
    );
  };

  const handleSubRequirementsDonotApply = (req, subReq) => {
    req?.subStandardRequirements?.forEach((item) => {
      if (item?.id == subReq?.id) {
        subReq.donotApply = !subReq?.donotApply;
      }
    });

    const donotApplySubReqs = req?.subStandardRequirements?.filter(
      (item) => item?.donotApply === true
    );

    let donotReqs = [...donotApplyRequirementsIds];
    if (donotApplySubReqs?.length == req?.subStandardRequirements?.length) {
      standardProgramContext?.requirements?.forEach((item) => {
        if (item?.id == req?.id) {
          item.donotApply = true;
          donotReqs.push(item?.id);
          setDonotApplyRequirementsIds(donotReqs);
        }
      });
    } else {
      standardProgramContext?.requirements?.forEach((item) => {
        if (item?.id == req?.id) {
          item.donotApply = false;
          donotReqs = donotReqs.filter((reqItem) => reqItem?.id != item?.id);
          setDonotApplyRequirementsIds(donotReqs);
        }
      });
    }

    const donotApplyAllSubReqs = standardProgramContext?.requirements
      ?.filter((item) => {
        return item?.subStandardRequirements?.filter(
          (val) => val?.donotApply == true
        );
      })
      ?.map((val) => val?.id);

    const allSubReqs = [];

    standardProgramContext?.requirements?.forEach((require) => {
      require?.subStandardRequirements?.forEach((subRequire) => {
        if (subRequire?.donotApply) allSubReqs?.push(subRequire?.id);
      });
    });

    setDonotApplySubRequirementsIds(allSubReqs);

    updateSuggestedDegrees(
      ids,
      standardProgramContext?.subRequirementListIds,
      donotReqs,
      allSubReqs
    );
    
  };

  const handleCheckboxRequirement = (event, reqId) => {
    const requirementId = reqId;
    const isChecked = event.target.checked;

    const updatedRequirements = isChecked
      ? ids?.includes(requirementId)
        ? [...ids]
        : [...ids, requirementId]
      : ids.filter((id) => id != requirementId);

    setIds(updatedRequirements);
    standardProgramContext.requirements?.forEach((req) => {
      if (req?.id == reqId) {
        req.decision = isChecked ? 1 : 0;
      }
    });
    

    standardProgramContext.setRequirements([
      ...standardProgramContext.requirements,
    ]);

    setIdsRequirement(updatedRequirements.map((i) => Number(i)));

    updateSuggestedDegrees(
      updatedRequirements,
      standardProgramContext?.subRequirementListIds,
      donotApplyRequirementsIds,
      donotApplySubRequirementsIds
    );
  };

  const handleCheckboxSubRequirement = (event, requirement, subReqId) => {
    const subRequirementId = subReqId;
    const isChecked = event.target.checked;

    let updatedSubRequirements = [];
    if (isChecked) {
      if (
        !standardProgramContext?.subRequirementListIds.includes(
          subRequirementId
        )
      ) {
        const addedSubReqs = [
          ...standardProgramContext?.subRequirementListIds,
          subRequirementId,
        ];
        updatedSubRequirements = addedSubReqs;
        standardProgramContext.setSubRequirementListIds(addedSubReqs);
      }
    } else {
      const filteredSubReqs =
        standardProgramContext?.subRequirementListIds?.filter(
          (item) => item != subRequirementId
        );
      updatedSubRequirements = filteredSubReqs;
      standardProgramContext.setSubRequirementListIds(filteredSubReqs);
    }

    requirement.subStandardRequirements.forEach((subItem) => {
      if (subItem.id.toString() == subRequirementId) {
        subItem.decision = isChecked ? 1 : 0;
      }
    });

    const updatedRequirements = [...ids];

    let finalReqs = Array.from(new Set(updatedRequirements));

    setIds(updatedRequirements.map((i) => Number(i)));

    requirement?.subStandardRequirements?.forEach((element) => {
      if (element?.decision == 0) requirement.decision = 0;
    });

    updateSuggestedDegrees(
      updatedRequirements,
      updatedSubRequirements,
      donotApplyRequirementsIds,
      donotApplySubRequirementsIds
    );
  };

  const updateSuggestedDegrees = async (
    currentRequirements,
    currentSubRequirements,
    donotApplyRequirementsIds,
    donotApplySubRequirementsIds
  ) => {
    await getSuggestedDegrees(
      currentRequirements,
      currentSubRequirements,
      donotApplyRequirementsIds,
      donotApplySubRequirementsIds
    );
  };

  const handleNoteRequirement = (e, body) => {
    standardProgramContext?.requirements.forEach((item) => {
      if (item.id == body.id) {
        body.recommendation = e.target.value;
      }
    });
  };

  const handleNoteSubRequirement = (e, body, requirement) => {
    requirement.subStandardRequirements.forEach((item) => {
      if (item.id == body.id) {
        body.recommendation = e.target.value;
      }
    });
  };

  const isCheckedCheckboxRequirement = (id) => {
    return idsRequirement.map((i) => Number(i)).includes(id);
  };

  const isCheckedCheckboxSubRequirement = (id) => {
    return idsSubRequirement.map((i) => Number(i)).includes(id);
  };

  useEffect(() => {
    const idsReq = standardProgramContext?.requirements?.map(
      (requirement) => requirement?.id
    );
    standardProgramContext?.setRequirementListIds(idsReq);

    let subReqs = [];

    const checkedIds = standardProgramContext?.requirements
      ?.filter((requirement) => {
        requirement?.subStandardRequirements?.forEach((item) => {
          if (item?.decision) {
            subReqs?.push(item?.id);
          }
        });
        return requirement?.decision;
      })
      .map((item) => item?.id);

    standardProgramContext?.setSubRequirementListIds(subReqs);
    setIds(checkedIds);
  }, []);

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%]  flex flex-row justify-between items-start">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={requirements}
              alt="requirements"
            />
            <div>
              <h1 className="text-[#292069] font-black text-[20px] mr-2">
                المتطلبات
              </h1>
              <div className="flex flex-row mr-2 mt-2">
                <img
                  className="bg-transparent ml-2"
                  src={openBook}
                  alt="book"
                />
                <p className="text-[#292069] text-[15px] mb-2">
                  تمكنك من مشاهدة إرشادات المؤسسة بجوار كل متطلب
                </p>
              </div>
              <div />
            </div>
          </div>
          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ""
                    ? "لا يوجد"
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-[90%] mt-7 p-2 mr-[88px] flex flex-col items-start overflow-y-scroll">
          <div className="flex flex-col items-start w-[100%]">
            {standardProgramContext?.requirements?.map((requirement, index) => (
              <div className="flex flex-col items-start w-[100%] mb-3">
                {/* <div> */}

                {/* <input className='ml-2 mr-1 bg-[#1CC081] border-none rounded-md w-[24px] h-[24px]' type="checkbox"/> */}
                {permissionsCheck("Pages.UserType.POC") ||
                permissionsCheck("Pages.UserType.CIO") ||
                permissionsCheck("Pages.UserType.Delegate") ? (
                  <div className="w-[100%] border-b-2 pb-3">
                    <div
                      className="flex flex-row w-full "
                      onClick={() => setRequirementId(requirement.id)}
                    >
                      <input
                        type="checkbox"
                        className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                        onChange={(e) =>
                          handleCheckboxRequirement(e, requirement?.id)
                        }
                        checked={
                          requirement.decision == 1
                            ? requirement.id
                            : isCheckedCheckboxRequirement(requirement.id)
                        }
                        disabled={false}
                        value={requirement.id}
                        id={requirement.id}
                        name={requirement.id}
                      />
                      <div
                        onClick={() => {
                          setRequirementId(requirement.id);
                        }}
                        className="flex items-center w-full select-none "
                      >
                        <label
                          onClick={() =>
                            handleOpenCloseRequirement(requirement.id)
                          }
                          className="text-[16px] w-[85%] mr-3 mb-1 flex-grow"
                        >
                          {requirement.title}
                        </label>

                        {requirement?.subStandardRequirements?.length > 0 && (
                          <div
                            className="bg-slate-300 rounded-lg cursor-pointer w-7 h-7 flex items-center justify-center"
                            onClick={() =>
                              handleOpenCloseRequirement(requirement.id)
                            }
                          >
                            <FontAwesomeIcon
                              className="text-[#292069]"
                              icon={faAngleDown}
                            />
                          </div>
                        )}

                        {/* <input
                          type="checkbox"
                          className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                          onChange={handleRequirementsDonotApply}
                          // checked={requirement?.subStandardRequirements?.map(item=>)}
                          disabled={false}
                          value={requirement.id}
                          id={requirement.id}
                          name={requirement.id}
                        /> */}
                        <div className="mr-3">
                          <NewTooltip content="لا ينطبق" placement="right">
                            <AppSwitch
                              checked={requirement?.donotApply}
                              handleChange={() =>
                                handleRequirementsDonotApply(requirement)
                              }
                            />
                          </NewTooltip>
                        </div>

                        {/* <img
                        className="bg-transparent mr-5"
                        src={openBook}
                        alt="book"
                      /> */}
                      </div>
                    </div>
                    {/* </div> */}
                    <div>
                      {permissionsCheck("Pages.UserType.Expert") ||
                      permissionsCheck("Pages.UserType.ExpertManager") ? (
                        <input
                          defaultValue={requirement.recommendation}
                          containerStyle={{ marginTop: 0 }}
                          withLabel={false}
                          onChange={(e) =>
                            handleNoteRequirement(e, requirement)
                          }
                          type={"text"}
                          className="w-4/5 rounded-3xl relative h-[60px] p-5 bg-[#F9F9FF] ml-4"
                          placeholder={"توصية"}
                        />
                      ) : (
                        requirement.recommendation
                      )}
                    </div>

                    {requirement?.subStandardRequirements?.length > 0 &&
                    activeIds.includes(requirement.id) ? (
                      <div className="bg-[#F9F9FF] w-[95%] rounded-lg py-2 pl-1 pr-2 flex flex-col justify-between mr-3 mb-3 mt-3">
                        {requirement?.subStandardRequirements?.map(
                          (subRequirement, i) => (
                            <div className="flex flex-row mb-3">
                              <input
                                type="checkbox"
                                className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                                onChange={(event) =>
                                  handleCheckboxSubRequirement(
                                    event,
                                    requirement,
                                    subRequirement?.id
                                  )
                                }
                                checked={
                                  subRequirement.decision == 1
                                    ? subRequirement.id
                                    : isCheckedCheckboxSubRequirement(
                                        subRequirement.id
                                      )
                                }
                                disabled={false}
                                value={subRequirement.id}
                                id={subRequirement.id}
                                name={subRequirement.id}
                              />
                              <div className="flex items-center w-full">
                                <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                                  {subRequirement.title}
                                </label>
                                {/* <img
                                  className="bg-transparent mr-5"
                                  src={openBook}
                                  alt="book"
                                />
                                <input
                                  type="checkbox"
                                  className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                                  onChange={handleSubRequirementsDonotApply}
                                  // checked={requirement.donotApply == true}
                                  disabled={false}
                                  value={subRequirement.id}
                                  id={subRequirement.id}
                                  name={subRequirement.id}
                                /> */}
                                <div className="mr-3">
                                  <NewTooltip
                                    content="لا ينطبق"
                                    placement="right"
                                  >
                                    <AppSwitch
                                      checked={subRequirement?.donotApply}
                                      handleChange={() =>
                                        handleSubRequirementsDonotApply(
                                          requirement,
                                          subRequirement
                                        )
                                      }
                                    />
                                  </NewTooltip>
                                </div>
                              </div>
                              <div>
                                {permissionsCheck("Pages.UserType.Expert") ||
                                permissionsCheck(
                                  "Pages.UserType.ExpertManager"
                                ) ? (
                                  <AppTextarea
                                    defaultValue={subRequirement.recommendation}
                                    onChange={(e) =>
                                      handleNoteSubRequirement(
                                        e,
                                        subRequirement,
                                        requirement
                                      )
                                    }
                                    placeholder={"توصية"}
                                  />
                                ) : (
                                  subRequirement.recommendation
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : permissionsCheck("Pages.UserType.Reviewer") ? (
                  <div className="w-[100%]">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                        onChange={(e) =>
                          handleCheckboxRequirement(e, requirement?.id)
                        }
                        checked={
                          requirement.decision == 1
                            ? requirement.id
                            : isCheckedCheckboxRequirement(requirement.id)
                        }
                        disabled={false}
                        value={requirement.id}
                        id={requirement.id}
                        name={requirement.id}
                      />
                      <div
                        onClick={() => {
                          handleOpenCloseRequirement(requirement.id);
                          setRequirementId(requirement.id);
                        }}
                        className="flex items-center w-full select-none "
                      >
                        <label className="text-[16px] flex-grow w-[85%] mr-3 mb-1">
                          {requirement.title}
                        </label>
                        {requirement.id &&
                          requirement?.subStandardRequirements?.length > 0 && (
                            <div className="bg-slate-300 rounded-lg cursor-pointer w-7 h-7 flex items-center justify-center">
                              <FontAwesomeIcon
                                className="text-[#292069]"
                                icon={faAngleDown}
                              />
                            </div>
                          )}
                        {/* <img
                        className="bg-transparent mr-5"
                        src={openBook}
                        alt="book"
                      /> */}
                      </div>
                    </div>
                    {/* </div> */}
                    <div>
                      {permissionsCheck("Pages.UserType.Expert") ||
                      permissionsCheck("Pages.UserType.ExpertManager") ? (
                        <RequirementTextarea
                          defaultValue={requirement.recommendation}
                          onChange={(e) =>
                            handleNoteRequirement(e, requirement)
                          }
                          placeholder="التوصية"
                        />
                      ) : (
                        requirement.recommendation
                      )}
                    </div>
                    {menuClicked == true && requirementId == requirement.id ? (
                      <div className="bg-[#F9F9FF] w-[95%] rounded-lg py-2 pl-1 pr-2 flex flex-col justify-between mr-3 mb-3 mt-3">
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <div className="flex flex-row mb-3">
                              <input
                                type="checkbox"
                                className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                                onChange={(event) =>
                                  handleCheckboxSubRequirement(
                                    event,
                                    requirement,
                                    subRequirement?.id
                                  )
                                }
                                checked={
                                  subRequirement.decision == 1
                                    ? subRequirement.id
                                    : isCheckedCheckboxSubRequirement(
                                        subRequirement.id
                                      )
                                }
                                disabled={false}
                                value={subRequirement.id}
                                id={subRequirement.id}
                                name={subRequirement.id}
                              />
                              <div className="flex items-center w-full">
                                <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                                  {subRequirement.title}
                                </label>
                                <img
                                  className="bg-transparent mr-5"
                                  src={openBook}
                                  alt="book"
                                />
                              </div>
                              <div className="mt-3">
                                {permissionsCheck("Pages.UserType.Expert") ||
                                permissionsCheck(
                                  "Pages.UserType.ExpertManager"
                                ) ? (
                                  <input
                                    defaultValue={subRequirement.recommendation}
                                    onChange={(e) =>
                                      handleNoteSubRequirement(
                                        e,
                                        subRequirement,
                                        requirement
                                      )
                                    }
                                    placeholder={"توصية"}
                                  />
                                ) : (
                                  subRequirement.recommendation
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : permissionsCheck("Pages.UserType.Expert") ||
                  permissionsCheck("Pages.UserType.ExpertManager") ? (
                  <div className="w-[100%]">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                        onChange={(e) =>
                          handleCheckboxRequirement(e, requirement?.id)
                        }
                        checked={
                          requirement.decision == 1
                            ? requirement.id
                            : isCheckedCheckboxRequirement(requirement.id)
                        }
                        disabled={false}
                        value={requirement.id}
                        id={requirement.id}
                        name={requirement.id}
                      />
                      <div
                        onClick={() => {
                          handleOpenCloseRequirement(requirement.id);
                          setRequirementId(requirement.id);
                        }}
                        className="flex items-center w-full select-none "
                      >
                        <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                          {requirement.title}
                        </label>
                        {requirement.id &&
                          requirement?.subStandardRequirements?.length > 0 && (
                            <div className="bg-slate-300 rounded-lg cursor-pointer w-7 h-7 flex items-center justify-center">
                              <FontAwesomeIcon
                                className="text-[#292069]"
                                icon={faAngleDown}
                              />
                            </div>
                          )}
                        {/* <img
                        className="bg-transparent mr-5"
                        src={openBook}
                        alt="book"
                      /> */}
                      </div>
                    </div>
                    {/* </div> */}
                    <div>
                      {permissionsCheck("Pages.UserType.Expert") ||
                      permissionsCheck("Pages.UserType.ExpertManager") ? (
                        <RequirementTextarea
                          defaultValue={requirement.recommendation}
                          onChange={(e) =>
                            handleNoteRequirement(e, requirement)
                          }
                          placeholder="التوصية"
                        />
                      ) : (
                        requirement.recommendation
                      )}
                    </div>
                    {menuClicked == true && requirementId == requirement.id ? (
                      <div className="bg-[#F9F9FF] w-[95%] rounded-lg py-2 pl-1 pr-2 flex flex-col justify-between mr-3 mb-3 mt-3">
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <div className="flex flex-col mb-3">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                                  onChange={(event) =>
                                    handleCheckboxSubRequirement(
                                      event,
                                      requirement,
                                      subRequirement?.id
                                    )
                                  }
                                  checked={
                                    subRequirement.decision == 1
                                      ? subRequirement.id
                                      : isCheckedCheckboxSubRequirement(
                                          subRequirement.id
                                        )
                                  }
                                  disabled={false}
                                  value={subRequirement.id}
                                  id={subRequirement.id}
                                  name={subRequirement.id}
                                />
                                <div className="flex items-center w-full">
                                  <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                                    {subRequirement.title}
                                  </label>
                                  <img
                                    className="bg-transparent mr-5"
                                    src={openBook}
                                    alt="book"
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                {permissionsCheck("Pages.UserType.Expert") ||
                                permissionsCheck(
                                  "Pages.UserType.ExpertManager"
                                ) ? (
                                  <RequirementTextarea
                                    defaultValue={subRequirement.recommendation}
                                    onChange={(e) =>
                                      handleNoteSubRequirement(
                                        e,
                                        subRequirement,
                                        requirement
                                      )
                                    }
                                    placeholder="التوصية"
                                  />
                                ) : (
                                  subRequirement.recommendation
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="w-[100%]">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        disabled={true}
                        className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                        onChange={(e) =>
                          handleCheckboxRequirement(e, requirement?.id)
                        }
                        checked={
                          requirement.decision == 1
                            ? requirement.id
                            : isCheckedCheckboxRequirement(requirement.id)
                        }
                        value={requirement.id}
                        id={requirement.id}
                        name={requirement.id}
                      />
                      <div
                        onClick={() => {
                          handleOpenCloseRequirement(requirement.id);
                          setRequirementId(requirement.id);
                        }}
                        className="flex items-center w-full select-none "
                      >
                        <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                          {requirement.title}
                        </label>
                        {requirement.id &&
                          requirement?.subStandardRequirements?.length > 0 && (
                            <div className="bg-slate-300 rounded-lg cursor-pointer w-7 h-7 flex items-center justify-center">
                              <FontAwesomeIcon
                                className="text-[#292069]"
                                icon={faAngleDown}
                              />
                            </div>
                          )}
                        {/* <img
                        className="bg-transparent mr-5"
                        src={openBook}
                        alt="book"
                      /> */}
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="mt-3">
                      {permissionsCheck("Pages.UserType.Expert") ||
                      permissionsCheck("Pages.UserType.ExpertManager") ? (
                        <RequirementTextarea
                          defaultValue={requirement.recommendation}
                          onChange={(e) =>
                            handleNoteRequirement(e, requirement)
                          }
                          placeholder="التوصية"
                        />
                      ) : (
                        requirement.recommendation
                      )}
                    </div>
                    {activeIds.includes(requirement.id) &&
                    requirement?.subStandardRequirements?.length > 0 ? (
                      <div className="bg-[#F9F9FF] w-[95%] rounded-lg py-2 pl-1 pr-2 flex flex-col justify-between mr-3 mb-3 mt-3">
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <div className="flex flex-row mb-3">
                              <input
                                disabled={true}
                                type="checkbox"
                                className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
                                onChange={(event) =>
                                  handleCheckboxSubRequirement(
                                    event,
                                    requirement,
                                    subRequirement?.id
                                  )
                                }
                                checked={
                                  subRequirement.decision == 1
                                    ? subRequirement.id
                                    : isCheckedCheckboxSubRequirement(
                                        subRequirement.id
                                      )
                                }
                                value={subRequirement.id}
                                id={subRequirement.id}
                                name={subRequirement.id}
                              />
                              <div className="flex items-center w-full">
                                <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
                                  {subRequirement.title}
                                </label>
                                <img
                                  className="bg-transparent mr-5"
                                  src={openBook}
                                  alt="book"
                                />
                              </div>
                              <div className="mt-3">
                                {permissionsCheck("Pages.UserType.Expert") ||
                                permissionsCheck(
                                  "Pages.UserType.ExpertManager"
                                ) ? (
                                  <RequirementTextarea
                                    defaultValue={subRequirement.recommendation}
                                    onChange={(e) =>
                                      handleNoteSubRequirement(
                                        e,
                                        subRequirement,
                                        requirement
                                      )
                                    }
                                    placeholder="التوصية"
                                  />
                                ) : (
                                  subRequirement.recommendation
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(4)} pocAnswer={pocAnswer} />
    </>
  );
};

export default StandardAnswerRequirement;
