import React from 'react';
import { Calendar } from 'react-date-range';

const SCalendar = ({ getDate, disabledDates, dateCalendar, available }) => {
  const handleSelect = (date) => {
    getDate(date);
    // alert(date.dateStr);
  };

  function getUnAvailableDates(availableDates) {
    const startDate = new Date();
    const endDate = new Date(2025, 1, 1);

    const datesInRange = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dateString = currentDate.toISOString().split("T")[0];
      if (!availableDates.includes(dateString)) {
        datesInRange.push(dateString);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesInRange;
  }

  const uniqueAvailableDates = [...new Set(available)];

  const allDisabelsDates = getUnAvailableDates(uniqueAvailableDates);

  const unAvailableDates = allDisabelsDates.map(
    (ele) => new Date(ele.slice(0, 10))
  );

  return (
    <div className="w-[100%]">
      {/* <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        dateClick={handleSelect}
        events={[
          { title: "event 1", date: "2024-02-20" },
          { title: "event 2", date: "2019-04-02" },
        ]}
      /> */}
      <Calendar
        minDate={new Date()}
        date={dateCalendar}
        onChange={handleSelect}
        disabledDates={unAvailableDates}
        color={"#554AA7"}
        className="w-[100%]"
        rangeColors={["#dfdfdf", "#E8F9F2", "#554AA7"]}
      />
    </div>
  );
};

export default SCalendar;
