import { useContext, useEffect, useState } from "react";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import AppTextarea from "../../../../../../../components/form/appTextarea";

const ExpertManagerFinalAnswer = ({ setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const {
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
    expertManagerDegree,
  } = useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? "" : degree);

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      checkedExpertManagerDegree: degree === checkedDegree ? "" : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newExpertManagerAnswer?.checkedExpertManagerDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      expertManagerAnswerNote: note,
    });
  };

  const renderedDegrees = newExpertManagerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة رئيس الخبراء"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>

        {expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
          ?.title !==
          newExpertManagerAnswer?.checkedExpertManagerDegree?.title && (
          <div className="mt-5 ml-8 min-w-[350px] grow">
            <AppTextarea
              rows={5}
              placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
              style={{
                marginBottom: "24px",
              }}
              value={newExpertManagerAnswer?.expertManagerAnswerNote || ""}
              onChange={(e) => handleChangeNote(e.target.value)}
            />
          </div>
        )}
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertManagerFinalAnswer;
