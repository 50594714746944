import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

function GlobalProvider(props) {
  const [surveyId, setSurveyId] = useState("");
  const [perspectiveId, setPerspectiveId] = useState("");
  const [indicatorId, setIndicatorId] = useState("");
  const [interlocutorId, setInterlocutorId] = useState("");
  const [standardId, setStandardId] = useState("");

  return (
    <GlobalContext.Provider
      value={{
        surveyId,
        setSurveyId,
        perspectiveId,
        setPerspectiveId,
        indicatorId,
        setIndicatorId,
        interlocutorId,
        setInterlocutorId,
        standardId,
        setStandardId,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };

export const useGlobalContext = () => useContext(GlobalContext);
