import { useTranslation } from "react-i18next";

import { FModal, InputLabel } from "../../../components";
import { useEffect, useRef, useState } from "react";
import environment from "../../../services/environment";
import { get, post, put, remove } from "../../../services/Request";
import { useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import DeleteIcon from "../../../components/DeleteIcon";
import EditIcon from "../../../components/EditIcon";
import { toast } from "react-toastify";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import { NewForm } from "../../../components/form/NewForm";
import { ErrorMessage } from "formik";
import { permissionsCheck } from "../../../helpers/utils";

const GroupsList = ({ open, setOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  // --------------------------------------------- //
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);
  const formRefEdit = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };
  const handleSubmitEdit = () => {
    if (formRefEdit.current) {
      formRefEdit.current?.handleSubmit();
    }
  };

  const initialValues = {
    name: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!name) {
      errors.name = "هذا الحقل مطلوب";
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const columns = [
    {
      name: t("modules.main.tickets.groupName"),
      selector: (row) => row.name,
      header: "name",
      accessorKey: "name",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">{row.name}</div>
      ),
    },
    {
      name: t("modules.main.tickets.description"),
      selector: (row) => row.description,
      header: "description",
      accessorKey: "description",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {row.description}
        </div>
      ),
    },
    {
      name: t("modules.main.mailbox.actions"),
      selector: (row) => row.id,
      header: "id",
      accessorKey: "id",
      footer: (props) => props.column.id,
      cell: (row) =>
        permissionsCheck("Pages.TicketGroups.Edit") && (
          <div className="flex">
            <EditIcon
              onClose={() => clear()}
              initialValuesFunction={() => editGroup(row)}
              loading={editLoading}
              editFunction={handleSubmitEdit}
              type={"submit"}
              editedName={`المجموعة (${row.name})`}
              editModalContent={
                <NewForm
                  initialValues={initialValues}
                  validate={validate}
                  innerRef={formRefEdit}
                  onSubmit={updateGroup}
                >
                  <div className="flex flex-col gap-3">
                    <div className="space-y-1">
                      <InputLabel
                        label={t("modules.main.tickets.groupName")}
                        type={"text"}
                        name={"name"}
                        value={name}
                        onChange={handleChangeName}
                      />

                      <ErrorMessage
                        className="text-red-700"
                        name="name"
                        component="div"
                      />
                    </div>

                    <InputLabel
                      type={"textarea"}
                      value={description}
                      onChange={handleChangeDescription}
                      name="description"
                      withoutLabel={false}
                      label={"الوصف"}
                      rows={3}
                      isRequired={false}
                    />

                    <NewDropdownMultiSelect
                      withoutLabel={false}
                      isSearchable={true}
                      value={selectedUsers}
                      handleChange={handleChangeSelectedUsers}
                      placeholder={t("modules.main.tickets.groupUsers")}
                      label={t("modules.main.tickets.groupUsers")}
                      options={experts}
                      isMultiple={true}
                      name="users"
                    />

                    <NewDropdownMultiSelect
                      withoutLabel={false}
                      isSearchable={true}
                      value={selectedManagers}
                      handleChange={handleChangeSelectedManagers}
                      placeholder={t("modules.main.tickets.groupManagers")}
                      label={t("modules.main.tickets.groupManagers")}
                      options={expertMangers}
                      isMultiple={true}
                      name="managers"
                    />
                  </div>
                </NewForm>
              }
            />
            {permissionsCheck("Pages.TicketGroups.Delete") ? (
              <DeleteIcon
                loading={deleteLoading}
                deleteFunction={() => deleteGroup(row.id)}
                deletedName={row.name}
              />
            ) : null}
          </div>
        ),
    },
  ];

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangeSelectedUsers = (value) => {
    setSelectedUsers(value);
  };

  const handleChangeSelectedManagers = (value) => {
    setSelectedManagers(value);
  };

  useEffect(() => {
    getAllUsers();
    //getAllList();
  }, []);

  const getAllList = () => {
    setGroups([]);
    getAllGroups();
  };

  const getAllGroups = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllGroups, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        setGroups(res.data.result.items);
        setLoading(false);
      }
    });
  };

  const [experts, setExperts] = useState([]);
  const [expertMangers, setExpertMangers] = useState([]);

  const getAllUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      params: {
        MaxResultCount: 99999,
      },
    };
    get(environment.getAllUsers, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              expertId: res.data.result.items[i].id,
              managerExpertId: res.data.result.items[i].id,
              staticRole: res.data.result.items[i]?.staticRole,
              label:
                res.data.result.items[i].name +
                " " +
                res.data.result.items[i].surname,
              isSelected: false,
            });
          }
          const expertUsers = array.filter((ele) => ele.staticRole == "Expert");
          const expertManagerUsers = array.filter(
            (ele) => ele.staticRole == "ExpertManager"
          );

          setExperts(expertUsers);
          setExpertMangers(expertManagerUsers);
          setUsers(array);
          setManagers(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const clear = () => {
    setId(0);
    setName("");
    setDescription("");
    setSelectedUsers([]);
    setSelectedManagers([]);
  };

  const editGroup = (row) => {
    setId(row.id);
    setName(row.name);
    setDescription(row.description);
    setSelectedUsers([]);
    setSelectedManagers([]);
    if (row.groupExperts && row.groupExperts.length > 0) {
      let array = [];
      for (var i = 0; i < row.groupExperts.length; i++) {
        array.push({
          value: row.groupExperts[i].expertId,
          expertId: row.groupExperts[i].expertId,
          label: row.groupExperts[i].expertFullName,
          isSelected: true,
        });
      }
      setSelectedUsers(array);
    }
    if (row.groupManagerExperts && row.groupManagerExperts.length > 0) {
      let array = [];
      for (var i = 0; i < row.groupManagerExperts.length; i++) {
        array.push({
          value: row.groupManagerExperts[i].managerExpertId,
          managerExpertId: row.groupManagerExperts[i].managerExpertId,
          label: row.groupManagerExperts[i].managerExpertFullName,
          isSelected: true,
        });
      }
      setSelectedManagers(array);
    }
  };

  const saveGroup = () => {
    if (id == 0) createGroup();
    else updateGroup();
  };

  const createGroup = () => {
    setAddLoading(true);
    if (!name) {
      toast.error(t("modules.main.calendar.nameRequired"));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      name: name,
      description: description,
      groupExperts: selectedUsers,
      groupManagerExperts: selectedManagers,
    };
    post(environment.createGroup, data, config, (res) => {
      setAddLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.success("تم اٍضافة مجموعة بنجاح");
        getAllList();
        setOpen(false);
        clear();
      }
    });
  };

  const updateGroup = () => {
    setEditLoading(true);
    if (!name) {
      toast.error(t("modules.main.calendar.nameRequired"));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    let groupExpertsBody = selectedUsers.map((ele) => {
      return {
        expertId: ele.expertId,
      };
    });
    let groupManagerExpertsBody = selectedManagers.map((ele) => {
      return {
        managerExpertId: ele.managerExpertId,
      };
    });

    let data = {
      id: id,
      name: name,
      description: description,
      groupExperts: groupExpertsBody,
      groupManagerExperts: groupManagerExpertsBody,
    };
    put(environment.updateGroup, data, config, (res) => {
      setEditLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.info(`تم تحديث المجموعة بنجاح`);
        clear();
        getAllList();
      }
    });
  };

  const deleteGroup = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteGroup + "?Id=" + id, config, (res) => {
      setDeleteLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.success("تم حذف المجموعة بنجاح");
        getAllList();
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset);
  };

  return (
    <div>
      <FModal
        loading={addLoading}
        submitLabel={"اٍضافة"}
        headerTitle={t("modules.main.tickets.addGroup")}
        action={handleSubmitAdd}
        type={"submit"}
        content={
          <NewForm
            initialValues={initialValues}
            validate={validate}
            innerRef={formRef}
            onSubmit={createGroup}
          >
            <div className="flex flex-col gap-3">
              <div className="space-y-1">
                <InputLabel
                  label={t("modules.main.tickets.groupName")}
                  type={"text"}
                  name={"name"}
                  value={name}
                  onChange={handleChangeName}
                />

                <ErrorMessage
                  className="text-red-700"
                  name="name"
                  component="div"
                />
              </div>

              <InputLabel
                type={"textarea"}
                value={description}
                onChange={handleChangeDescription}
                name="description"
                withoutLabel={false}
                label={"الوصف"}
                rows={3}
                isRequired={false}
              />

              <NewDropdownMultiSelect
                withoutLabel={false}
                isSearchable={true}
                value={selectedUsers}
                handleChange={handleChangeSelectedUsers}
                placeholder={t("modules.main.tickets.groupUsers")}
                label={t("modules.main.tickets.groupUsers")}
                options={users}
                isMultiple={true}
                name="users"
              />

              <NewDropdownMultiSelect
                withoutLabel={false}
                isSearchable={true}
                value={selectedManagers}
                handleChange={handleChangeSelectedManagers}
                placeholder={t("modules.main.tickets.groupManagers")}
                label={t("modules.main.tickets.groupManagers")}
                options={users}
                isMultiple={true}
                name="managers"
              />
            </div>
          </NewForm>
        }
        open={open}
        setOpen={() => setOpen(false)}
      />
      {permissionsCheck("Pages.TicketGroups.GetAll") ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={groups}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}
    </div>
  );
};

export default GroupsList;
