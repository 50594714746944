import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../helpers/isPermission";

export const useGetAuditorExpertAxis = ({ currentPage }) => {
  // const { currentStage } = useContext(StageContext);
  const surveyStageId = localStorage.getItem("surveyStageId");

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAuditorExpertAxis } = useQuery({
    queryKey: ["AuditorExpertAxis", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertAxis, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const axis = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["AuditorExpertAxis", currentPage + 1, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertAxis, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["AuditorExpertAxis", currentPage - 1, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertAxis, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    axis,
    totalCount,
    totalPages,
    data,
    isGettingAuditorExpertAxis,
  };
};
