import React from "react";
import AddStandardProofFiles from "./addStandardProofFiles";
import StandardProofFilesList from "./standardProofFilesList";

const StandardProofFiles = ({ disabled = true }) => {
  return (
    <div>
      <div className="mt-8">
        <h2 className="font-semibold mb-4">طريقة الإثبات</h2>
        {!disabled && <AddStandardProofFiles />}
      </div>
      <StandardProofFilesList disabled={disabled} />
    </div>
  );
};

export default StandardProofFiles;
