import Button from '../../../../../components/Button';
import { Stepper } from 'react-form-stepper';

import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, post } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { toast } from 'react-toastify';
import { InputLabel } from '../../../../../components';
import NewSwitch from '../../../../../components/NewSwitch';
import './index.css';
import { termsIcon, termsLogoAgency, termsLogoQiyas } from '../../../../../assets/icons/Index';

const Accept = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { surveyId } = useParams();
  const [showForms, setShowForms] = useState(false);
  const [content, setContent] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [isSeekHelp, setIsSeekHelp] = useState(true);
  const [consultingCompany, setConsultingCompany] = useState('');

  useEffect(() => {
    getSurvey();
  }, []);

  const getSurvey = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    };
    get(environment.getSurvey + '?Id=' + surveyId, config, (res) => {
      if (res.status == 403) {
        toast.error(t('general.authError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      } else if (res.status == 500) {
        toast.error(t('general.serverError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      } else if (res.status == 200) {
        setContent(res.data.result.termsAndConditions);
      }
    });
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const acceptTerms = () => {

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        // contentType: "application/json",
      }
    };
    if (isSeekHelp == true && consultingCompany == '') {
      toast.error('يجب اضافة اسم شركة', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    } else {
      let data = {
        surveyId: surveyId,
        agencyId: localStorage.getItem('agencyId'),
        userId: localStorage.getItem('userId'),
        isCompanyAssist: isSeekHelp
      };
      if (isSeekHelp == true) {
        data.companyName = consultingCompany;
      }
      post(environment.acceptTerms, data, config, (res) => {
        if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          });
        } else {
          toast.success('تم اضافة السياسات و الاحكام بنجاح', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          });
          window.location.href = '/';
        }
      });
    }
  };
  return (
    <div className="bg-white">
      <div className="bg-white p-5 rounded-[30px] flex flex-col justify-center">
        <div>
          <div className="bg-white p-5 mt-5 flex flex-col">
            <div className="bg-white p-4 flex flex-row justify-between items-center rounded-xl">
              <img src={termsLogoAgency} alt="pen"/>
              <img src={termsLogoQiyas} alt="pen"/>
            </div>
            <Stepper
              connectorStateColors={true}
              styleConfig={{
                activeBgColor: '#202969',
                completedBgColor: '#bbb',
                labelFontSize: 15,
                activeTextColor: 'white',
                inactiveBgColor: '#bbb',
                inactiveTextColor: 'white',
                size: 50
              }}
              connectorStyleConfig={{
                activeColor: '#202969',
                completedColor: '#202969',
                disabledColor: '#bbb',
                size: 2
              }}
              size={50}
              activeStep={currentStep} steps={[
              { label: 'الشروط و الاحكام', onClick: () => setCurrentStep(0) },
              { label: 'إقرار', onClick: () => setCurrentStep(1) }
            ]}/>
            {currentStep == 0 ? (
              <div>
                <section className="bg-white p-5 mb-5 flex flex-col rounded-xl">
                  <div className="mr-5 mt-5 bg-[#F9F9FF] rounded-xl p-5">
                    <div className="text-center">
                      <img src={termsIcon} alt="pen" className="w-[200px] mr-auto ml-auto"/>
                      <div className="font-black text-[20px] pt-3"> سياسة الاستخدام و الخصوصية</div>
                    </div>
                    <div className="text-[16px] text-[#1CC081] text-right pt-5">تمهيد</div>
                    <div className="text-[14px] text-[#7D8BAB] text-right pt-3">
                      إشارةً إلى قرار مجلس الوزراء رقم (418) وتاريخ 25/7/1442هـ، الصادر بالموافقة
                      على تنظيم هيئة الحكومة الرقمية، والذي نص في المادة (الثالثة) منه على أن
                      "الهيئة هي الجهة المختصة بكل ما يتعلق بالحكومة الرقمية، وتعد المرجع الوطني في
                      شؤونها". كما نصت الفقرة (الخامسة/ السادسة) من المادة (الرابعة) من ذات التنظيم
                      على أن تتولى الهيئة "إصدار القياسات والمؤشرات والأدوات والتقارير؛ لقياس أداء
                      الجهات الحكومية وقدراتها في مجال الحكومة الرقمية.."و"متابعة التزام الجهات
                      الحكومية بالقرارات والأوامر الصادرة في شأن التعاملات الحكومية الرقمية.".
                    </div>
                    <div className="text-[14px] text-[#7D8BAB] text-right pt-3">
                      تعمل هيئة الحكومة الرقمية على تعزيز الأداء الرقمي داخل الجهات الحكومية، والرفع
                      من جودة الخدمات المقدمة وتحسين تجربة المستفيد من تلك الخدمات، ودعم عملية
                      التحول الرقمي في المملكة العربية السعودية وتحقيق تطلعات رؤية (2030).
                    </div>
                    <div className="text-[14px] text-[#7D8BAB] text-right pt-3">
                      وتمهّد هيئة الحكومة الرقمية الطريق للجهات الحكومية، لتوفير خدمات حكومية رقمية
                      ذات جودة وكفاءة عالية تساهم في رفع العوائد الاستثمارية والقيمة الاقتصادية
                      الوطنية، والعمل على قياس أداء الجهات الحكومية وقدراتها في مجال الحكومة
                      الرقمية.
                    </div>
                    <div className="text-[14px] text-[#7D8BAB] text-right pt-3">
                      وانطلاقاً من كون الهيئة هي الجهة المختصة بكل ما يتعلق بالحكومة الرقمية،
                      والمرجع الوطني في شؤونها، وبناء على اختصاص الهيئة بتنظيم أعمال الحكومة الرقمية
                      بما في ذلك إصدار القياسات والمؤشرات والأدوات والتقارير؛ لقياس أداء الجهات
                      الحكومية وقدراتها في مجال الحكومة الرقمية ومتابعة التزام الجهات الحكومية
                      بالأوامر السامية وقرارات مجلس الوزراء والتعاميم الصادرة في شأن التعاملات
                      الحكومية الرقمية.
                    </div>
                    <div className="text-[14px] text-[#7D8BAB] text-right pt-3">
                      قامت الهيئة بتطوير النظام الرقمي لقياس وذلك بهدف تسهيل عملية تعبئة الاستبيانات
                      للجهات الحكومية، حيث يشمل النظام الجديد على رفع الاستفسارات المتعلقة
                      بالاستبيان من النظام نفسه، والتواصل مع فريق الدعم، بالإضافة إلى تحسين تجربة
                      المستخدم من خلال تحسين الواجهة التفاعلية، الأمر الذي سينعكس إيجاباً على سهولة
                      الوصول وتنظيم عملية الإجابة على المعايير.
                    </div>
                    <div className="text-[16px] text-[#1CC081] text-right pt-5">سياسة الاستخدام و
                      الخصوصية
                    </div>
                    <div className="text-right pt-1 text-[14px] text-[#7D8BAB]"
                         dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                </section>
                <section className="w-full flex justify-start">
                  <Button
                    title={'التالي'}
                    style={{
                      width: '15rem',
                      marginTop: '20px',
                      backgroundColor: '#1CC081',
                      borderColor: '#1CC081'
                    }}
                    onClick={() => next()}
                  />
                </section>
              </div>
            ) : (
              <div>
                <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5 rtl">
                  <NewSwitch
                    value={isSeekHelp}
                    setValue={setIsSeekHelp}
                    label={'هل الجهة تستعين بشركة استشارية لقياس التحول الرقمي؟'}
                  />

                  {isSeekHelp && (
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="flex-1 space-y-1">
                        <div className=" flex flex-col gap-3 ">
                          <InputLabel
                            value={consultingCompany}
                            type={'text'}
                            onChange={(e) => setConsultingCompany(e.target.value)}
                            name={'consultingCompany'}
                            label={'اسم الشركة الاستشارية'}
                          />
                        </div>
                      </div>
                      <div className="flex-1"></div>
                    </div>
                  )}
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <Button
                      title={'اوافق علي الشروط و الاحكام'}
                      style={{
                        width: '15rem',
                        marginTop: '20px',
                        backgroundColor: '#1CC081',
                        borderColor: '#1CC081'
                      }}
                      onClick={() => acceptTerms()}
                    />
                  </div>
                  <div>
                    <Button
                      title={'السابق'}
                      style={{
                        width: '15rem',
                        marginTop: '20px',
                        backgroundColor: '#1CC081',
                        borderColor: '#1CC081'
                      }}
                      onClick={() => prev()}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accept;
