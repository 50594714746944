import React, { createContext, useState } from 'react';

const StandardProgramContext = createContext();

function StandardProgramProvider(props) {
  let [title, setTitle] = useState("");
  let [standardId, setStandardId] = useState("");
  let [answerWay, setAnswerWay] = useState("");
  const [donotApply, setDonotApply] = useState(false);
  let [program, setProgram] = useState("");
  let [agency, setAgency] = useState("");
  let [attachments, setAttachments] = useState([]);
  let [attachmentFileExcel, setAttachmentFileExcel] = useState([]);

  let [models, setModels] = useState([]);
  let [requirements, setRequirements] = useState([]);
  let [subRequirements, setSubRequirements] = useState([]);

  let [files, setFiles] = useState([]);
  let [prevFiles, setPrevFiles] = useState([]);
  let [requirementsPrev, setRequirementsPrev] = useState([]);
  let [filesPrev, setFilesPrev] = useState([]);
  let [isRequiredFileModel, setIsRequiredFileModel] = useState(true);
  let [fileModelId, setFileModelId] = useState("");
  let [fileModel, setFileModel] = useState("");
  const [requirementListIds, setRequirementListIds] = useState([]);
  const [subRequirementListIds, setSubRequirementListIds] = useState([]);

  let [noteReviewer, setNoteReviewer] = useState("");
  let [noteExpert, setNoteExpert] = useState("");
  let [noteExpertForReviewer, setNoteExpertForReviewer] = useState("");

  let [standard, setStandard] = useState("");

  let [question, setQuestion] = useState({});
  let [comments, setComments] = useState([]);
  let [inquiries, setInquiries] = useState([]);
  let [degrees, setDegrees] = useState([]);

  return (
    <StandardProgramContext.Provider
      value={{
        models,
        setModels,
        requirements,
        setRequirements,
        files,
        setFiles,
        prevFiles,
        setPrevFiles,
        requirementsPrev,
        setRequirementsPrev,
        filesPrev,
        setFilesPrev,
        title,
        setTitle,
        isRequiredFileModel,
        setIsRequiredFileModel,
        fileModel,
        setFileModel,
        answerWay,
        setAnswerWay,
        standardId,
        setStandardId,
        noteReviewer,
        setNoteReviewer,
        noteExpert,
        setNoteExpert,
        question,
        setQuestion,
        comments,
        setComments,
        standard,
        setStandard,
        inquiries,
        setInquiries,
        degrees,
        setDegrees,
        noteExpertForReviewer,
        setNoteExpertForReviewer,
        program,
        setProgram,
        agency,
        setAgency,
        fileModelId,
        setFileModelId,
        attachments,
        setAttachments,
        attachmentFileExcel,
        setAttachmentFileExcel,
        requirementListIds,
        setRequirementListIds,
        donotApply,
        setDonotApply,
        subRequirements,
        setSubRequirements,
        subRequirementListIds,
        setSubRequirementListIds,
      }}
    >
      {props.children}
    </StandardProgramContext.Provider>
  );
}

export { StandardProgramContext, StandardProgramProvider };
