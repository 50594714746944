import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../../services/environment";
import { axiosGetRequest } from "../../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
} from "../../../../../helpers/isPermission";

export const useGetAgenciesByStandardId = ({
  currentPage,
  filterValue,
  reviewedStatus,
  standardId,
  maxResult,
}) => {
  const surveyStageId = localStorage.getItem('surveyStageId')
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAllAgenciesByStandardId } = useQuery({
    queryKey: [
      "AgenciesByStandardId",
      currentPage,
      filterValue,
      standardId,
      reviewedStatus,
      maxResult,
    ],
    queryFn: () =>
      axiosGetRequest(environment.getAgenciesByStandardId, {
        StageId: surveyStageId,
        standardId: standardId,
        SkipCount: currentPage * maxResult,
        MaxResultCount: maxResult,
        IsReviewed: reviewedStatus,
        KeyWord: filterValue || null,
        AssignedReviewerId: reviewerId,
        AssignedExpertId: expertId,
        AssignedPOCOfficerId: pocOfficerId,
      }),
      enabled: surveyStageId != null
  });

  const allAgenciesByStandardId = data?.result?.result;
  const totalCount = data?.result?.totalItemCountFilterd;
  const totalPages = Math.ceil(totalCount / maxResult);
  const allItemsCount = data?.result?.totalItemCountNotFilterd;
  const totalReviewedCount = data?.result?.totalReviewed;
  const totalNotReviewedCount = data?.result?.totalNotReviewed;
  const totalNotReviewedByExpert = data?.result?.totalNotReviewedByExpert;
  const totalNotReviewedByPoc = data?.result?.totalNotReviewedByPoc;
  const totalNotReviewedByReviewer = data?.result?.totalNotReviewedByReviewer;
  const totalReviewedByExpert = data?.result?.totalReviewedByExpert;
  const totalReviewedByPoc = data?.result?.totalReviewedByPoc;
  const totalReviewedByReviewer = data?.result?.totalReviewedByReviewer;

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesByStandardId",
        currentPage + 1,
        filterValue,
        standardId,
        reviewedStatus,
        maxResult,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAgenciesByStandardId, {
          StageId: surveyStageId,
          standardId: standardId,
          SkipCount: (currentPage + 1) * maxResult,
          MaxResultCount: maxResult,
          IsReviewed: reviewedStatus,
          KeyWord: filterValue,
          AssignedReviewerId: reviewerId,
          AssignedExpertId: expertId,
          AssignedPOCOfficerId: pocOfficerId,
        }),
        enabled: surveyStageId != null
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesByStandardId",
        currentPage - 1,
        filterValue,
        standardId,
        reviewedStatus,
        maxResult,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAgenciesByStandardId, {
          StageId: surveyStageId,
          standardId: standardId,
          SkipCount: (currentPage - 1) * maxResult,
          MaxResultCount: maxResult,
          IsReviewed: reviewedStatus,
          KeyWord: filterValue,
          AssignedReviewerId: reviewerId,
          AssignedExpertId: expertId,
          AssignedPOCOfficerId: pocOfficerId,
        }),
        enabled: surveyStageId != null
    });
  }

  return {
    queryClient,
    allAgenciesByStandardId,
    totalCount,
    totalPages,
    data,
    isGettingAllAgenciesByStandardId,
    totalReviewedCount,
    totalNotReviewedCount,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
  };
};
