import './Component.css';

const Input = ({
  type,
  placeholder,
  onChange,
  style,
  label,
  containerStyle,
  id,
  value,
  name,
  checked,
  defaultValue,
  min,
  max,
  disabled,
  multiple,
  withLabel,
  onKeyDown,
  onKeyPress,
  required,
  pattern,
  title,
  onFocus,
  onBlur,
  accept,
  className,
  inputRef,
}) => {
  return (
    <div className="container_input" style={containerStyle}>
      {withLabel == false ? null : (
        <label className="label_input" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        ref={inputRef}
        accept={accept}
        onBlur={onBlur}
        onFocus={onFocus}
        title={title}
        pattern={pattern}
        required={required}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        multiple={multiple}
        disabled={disabled}
        min={min}
        max={max}
        className={
          type == "color"
            ? `${className != "" ? className : "input_simple_witout_100"}`
            : `${className != "" ? className : "input_simple mt-3 "}`
        }
        checked={checked}
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        style={style}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
