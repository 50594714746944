import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";

function DownloadButton({ children, onClick, loading: initialLoading = false, ...props }) {
  const [loading, setLoading] = useState(initialLoading);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const handleClick = async () => {
    setLoading(true);
    await onClick?.();
    setLoading(false);
  };

  return (
    <button
      {...props}
      onClick={handleClick}
      disabled={loading}
      className={`${
        props.disabled ? "cursor-not-allowed" : ""
      } flex gap-3 pb-3 pt-1 px-5 text-emerald-400 items-center border-2 border-emerald-400 rounded-xl`}
    >
      {loading ? (
        <div className="flex flex-row  justify-center items-center">
          <Spinner
            aria-label="Info spinner example"
            className="text-emerald-400"
          />
          <span className="pl-3 pr-3 text-sm font-semibold text-emerald-400">
            جاري تحميل الملف
          </span>
        </div>
      ) : (
        <>
          <span>
            <FontAwesomeIcon icon={faFileArrowDown} />
          </span>

          <span className="font-semibold">{children}</span>
        </>
      )}
    </button>
  );
}

export default DownloadButton;
