import React, { createContext, useState } from 'react';

const UsersContext = createContext();

function UsersProvider(props) {
  let [selectedRole, setSelectedRole] = useState([]);
  let [selectedEntity, setSelectedEntity] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState({
    id: 1,
    label: "الحالة",
    labelEn: "Status",
    value: null,
  });

  return (
    <UsersContext.Provider
      value={{
        selectedRole,
        setSelectedRole,
        selectedStatus,
        setSelectedStatus,
        selectedEntity,
        setSelectedEntity,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

export { UsersContext, UsersProvider };
