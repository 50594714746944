import React from "react";
import {logoCard, userImage2} from "../assets/imgs/Index";
import {drawLeft, drawLeftGreen, iconDate, pen, removeIcon, settingDark,} from "../assets/icons/Index";
import {post} from "../services/Request";
import environment from "../services/environment";
import NewTooltip from "./NewTooltip";
import {permissionsCheck} from "../helpers/utils";
import NewSwitch from "./NewSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import returnedStandard from '../assets/icons/returnedStandard.svg'

const NewECard = ({
  status,
  statement,
  startDate,
  endDate,
  showMore,
  moreDetails,
  settings,
  items,
  image,
  title,
  description,
  from,
  getAgencies,
  isConfidentialAgency,
  entityId,
  openEdit,
  openDelete,
  titleAr,
  titleEn,
  goPageEntityDetails,
  answered,
  showButtonAnswered,
  openModalAssign,
  handleAssignReviewerAndExpertAndPOCOfficer,
  isReturnedStandard = false
}) => {

  const changeStatusAgency = (status) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    post(
      environment.changeStatusForAgency +
        "?agencyId=" +
        entityId +
        "&isConfidential=" +
        status,
      "",
      config,
      (res) => {
        getAgencies();
      }
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${logoCard})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className="flex flex-col justify-between border-[1px] hover:shadow-lg border-[#7D8BAB38] rounded-xl text-start p-5 m-1 cursor-pointer transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.05] duration-300 group"
    >
      <div className="relative flex flex-row justify-between items-center">
      
      {from == "standards" || from == "agency" ? (
        <div className="w-full flex flex-row items-center justify-between">
          {from == "standards" || from == "agency" ? null : (
            <img
              src={
                image == null
                  ? userImage2
                  : process.env.REACT_APP_FRONT_URL + image
              }
              className="rounded w-[52px] h-[52px]"
            />
          )}
          <div className="flex flex-col items-center pr-2 pl-2">
            <span className="text-[#292069] text-[16px]">{title}</span>
            <span className="text-[#7D8BAB] text-[12px]">{description}</span>
          </div>
          {from === "standards" &&  isReturnedStandard && (
            // <img className='absolute left-0' src={returnedStandard} alt='returnedStandard'/>
            <span className="bg-transparent border border-[#6BC09F] shadow-md 	--tw-shadow-color: currentColor;  text-[#6BC09F] text-[14px] pt-1 pb-2 px-2 rounded-lg ml-0">
              المعاد فتحها
            </span>
          )}
        </div>
      ) : null}
      {permissionsCheck("Pages.UserType.ExpertManager") ? (
           <NewTooltip content={"اضافة الجهات لمستخدمين"}>
           <div
             onClick={handleAssignReviewerAndExpertAndPOCOfficer}
             className="rounded-full p-1 bg-[#228B221A] cursor-pointer mr-2 ml-2 flex items-center"
           >
             <FontAwesomeIcon icon={faPlus} style={{fontSize:20,color:'#228B22'}}/>
           </div>
         </NewTooltip>
      ):null}
      {permissionsCheck("Pages.UserType.Expert") || permissionsCheck("Pages.UserType.Reviewer")? (
      <div>
      {showButtonAnswered ? (
      <div>
      {from == "standards" ? (
        <div className={`${answered == 2 ? "bg-[#22bb33] text-white text-[15px] p-2 rounded-2xl" : answered == 1 ? "bg-[#f0ad4e] text-white text-[15px] p-2 rounded-2xl" : "bg-[#bb2124] text-white text-[15px] p-2 rounded-2xl"}`}>{answered == 2 ? "تم الاجابة" : answered == 1 ? "في انتظار اجابتك" : "لم تتم الاجابة"}</div>
      ):null}
      </div>
      ):null}
      </div>
      ):null}
         {permissionsCheck("Pages.UserType.ExpertManager") && (
        <NewTooltip content={"الاٍعدادات"}>
          <div
            onClick={()=>openModalAssign()}
            className="rounded-full p-1 bg-[#EE5D501A] cursor-pointer"
          >
            <img src={settingDark} alt="setting icon" />
          </div>
        </NewTooltip>
      )}
      </div>
      {from == "standards" || from == "agency" ? (
        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:grid-cols-2 p-5">
          {items.map((item, index) => (
            <div className="flex flex-col justify-between mt-3">
              <span className="text-[#7D8BAB] text-[10px]">{item.title}</span>
              <span className="text-[#292069] text-[14px]">{item.detail}</span>
            </div>
          ))}
        </div>
      ) : null}
      {from == "programs" ? (
        <div className="flex flex-row justify-between items-center mt-2">
          <div className="relative flex">
            <span
              className={
                "w-1.5 h-1.5 self-center rounded-full ml-2 " +
                (status == "نشط" ? "bg-[#1CC081]" : "bg-red-500")
              }
            ></span>
            <span className="text-[10px]">
              {status == "" ? "لا يوجد" : status}
            </span>
          </div>
          {permissionsCheck("Pages.UserType.Admin") ? (
            <button
              onClick={settings}
              className="mr-2 bg-[#F9F9FF] w-10 h-10 flex items-center justify-center rounded-full"
            >
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5Z"
                  fill="#F9F9FF"
                />
                <path
                  d="M21.625 19.5002C21.6255 19.992 21.4555 20.4687 21.1438 20.8491C20.8322 21.2295 20.3983 21.49 19.9161 21.5863C19.4338 21.6826 18.9331 21.6086 18.4993 21.3771C18.0655 21.1455 17.7254 20.7706 17.5371 20.3164C17.3487 19.8622 17.3237 19.3567 17.4664 18.886C17.609 18.4154 17.9104 18.0089 18.3193 17.7356C18.7282 17.4624 19.2191 17.3394 19.7085 17.3877C20.1979 17.4359 20.6554 17.6524 21.003 18.0002C21.4007 18.3982 21.6244 18.9376 21.625 19.5002Z"
                  stroke="#1B1551"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.417 20.1229V18.8769C12.418 18.5202 12.5602 18.1785 12.8124 17.9263C13.0646 17.6741 13.4063 17.5319 13.763 17.5309C15.045 17.5309 15.569 16.6239 14.925 15.5119C14.7479 15.2032 14.7003 14.837 14.7925 14.4933C14.8847 14.1496 15.1092 13.8565 15.417 13.6779L16.646 12.9759C16.9166 12.8184 17.2386 12.7747 17.5414 12.8544C17.8442 12.934 18.103 13.1306 18.261 13.4009L18.339 13.5359C18.422 13.779 18.5789 13.99 18.7878 14.1394C18.9967 14.2889 19.2471 14.3692 19.504 14.3692C19.7608 14.3692 20.0113 14.2889 20.2202 14.1394C20.4291 13.99 20.586 13.779 20.669 13.5359L20.747 13.4009C20.905 13.1306 21.1638 12.934 21.4666 12.8544C21.7694 12.7747 22.0914 12.8184 22.362 12.9759L23.587 13.6759C23.8964 13.8531 24.1228 14.1459 24.2166 14.4899C24.3103 14.834 24.2637 15.2012 24.087 15.5109C23.442 16.6229 23.967 17.5299 25.249 17.5299C25.6056 17.5309 25.9474 17.6731 26.1996 17.9253C26.4518 18.1775 26.5939 18.5192 26.595 18.8759V20.1229C26.5939 20.4795 26.4518 20.8213 26.1996 21.0735C25.9474 21.3257 25.6056 21.4678 25.249 21.4689C23.967 21.4689 23.443 22.3759 24.087 23.4879C24.2637 23.7976 24.3103 24.1648 24.2166 24.5088C24.1228 24.8528 23.8964 25.1456 23.587 25.3229L22.362 26.0229C22.0914 26.1804 21.7694 26.2241 21.4666 26.1444C21.1638 26.0647 20.905 25.8682 20.747 25.5979L20.669 25.4629C20.586 25.2198 20.4291 25.0087 20.2202 24.8593C20.0113 24.7099 19.7608 24.6295 19.504 24.6295C19.2471 24.6295 18.9967 24.7099 18.7878 24.8593C18.5789 25.0087 18.422 25.2198 18.339 25.4629L18.261 25.5979C18.103 25.8682 17.8442 26.0647 17.5414 26.1444C17.2386 26.2241 16.9166 26.1804 16.646 26.0229L15.417 25.3229C15.1076 25.1456 14.8812 24.8528 14.7874 24.5088C14.6937 24.1648 14.7403 23.7976 14.917 23.4879C15.562 22.3759 15.037 21.4689 13.755 21.4689C13.3997 21.4657 13.06 21.3226 12.8096 21.0707C12.5591 20.8187 12.418 20.4782 12.417 20.1229Z"
                  stroke="#1B1551"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          ) : null}
        </div>
      ) : null}
      {from == "programs" ? (
        <div className="text-[14px] text-[#292069] leading-[30px] mt-2 ">
          {statement}
        </div>
      ) : null}
      {from == "programs" ? (
        <div className="flex flex-row items-center text-right mt-2">
          <img src={iconDate} />
          <div className="mr-4">
            <label className="text-[#7D8BAB] text-xs leading-[18px] ">
              تاريخ البدء
            </label>
            <div className="text-[#292069] text-[13px]">{startDate}</div>
          </div>
          <div className="mr-8">
            <label className="text-[#7D8BAB] text-xs leading-[18px] ">
              تاريخ الإنتهاء
            </label>
            <div className="text-[#292069] text-[13px]">{endDate}</div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-row items-center mt-2">
        {from == "agencies" ? null : (
          <button
            onClick={showMore}
            className={`relative w-full rounded-full flex items-center h-11 bg-[${
              from == "agency" ? "#E8E8F3" : "#1CC081"
            }]`}
          >
            {from == "standards" || from == "programs" ? (
              <div className="absolute w-full text-[16px] text-white text-center">
                المزيد
              </div>
            ) : (
              <span className="absolute w-full text-[16px] text-[#292069] text-center">
                المعيار
              </span>
            )}
            {from == "agency" ? null : (
              <img className="absolute left-2" src={drawLeft} />
            )}
          </button>
        )}
      </div>
      {from == "agencies" ? (
        <div>
          <div className="text-[16px] text-[#322A6F] ">{titleAr}</div>
          <div className="text-[16px] text-[#322A6F] ">{titleEn}</div>
        </div>
      ) : null}
      {from == "agencies" ? (
        <div>
        {permissionsCheck("Pages.Agencies.Status") ?
          <div className="w-[100%] mt-5">
               <NewSwitch
                   widthTrue={true}
                   widthTrueValue={"100%"}
                   widthFalse={true}
                   widthFalseValue={"100%"}
                    trueText="سرية"
                    falseText="غير سرية"
                    value={isConfidentialAgency}
                    setValue={(value)=>{changeStatusAgency(value)}}
                  />
          </div>
          :null}
          </div>
      ):null}

      {from == "agencies" ? (
        <div className="flex flex-row justify-between items-center pt-5">
          <div className="flex flex-row items-center justify-around w-[100%]">
            {permissionsCheck("Pages.Agencies.Edit") ?
            <NewTooltip content={"تعديل"}>
              <div
                className="flex items-center justify-center rounded-full bg-[#FFF6DC] w-[34px] h-[34px] cursor-pointer"
                onClick={openEdit}
              >
                <img src={pen} />
              </div>
            </NewTooltip>
            :null}
            {permissionsCheck("Pages.Agencies.Delete") ?
            <NewTooltip content={"حذف"}>
              <div
                className="flex items-center justify-center rounded-full bg-[#FEEDEF]w-[34px] h-[34px] mr-5 ml-5 cursor-pointer"
                onClick={openDelete}
              >
                <img src={removeIcon} />
              </div>
            </NewTooltip>
            :null}
            {permissionsCheck("Pages.Agencies.Get") ?
            <NewTooltip content={"عرض التفاصيل"}>
              <img
                src={drawLeftGreen}
                onClick={goPageEntityDetails}
                alt="goPageEntityDetails"
              />
            </NewTooltip>
            :null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewECard;
