import React, {useContext} from "react";
import {useHistory} from "react-router";
import {StageContext} from "../contexts/stageContext";
import NotFound from "../services/NotFound";

function arrayContainsValuesFromArray(arr1, arr2) {
  for (let i = 0; i < arr2.length; i++) {
    if (arr1.includes(arr2[i])) {
      return true;
    }
  }
  return false;
}



const CheckRoles = ({ children, permission }) => {
  const stageContext = useContext(StageContext);
  const history = useHistory();
  const gotPemission = JSON.parse(localStorage.getItem("perm"))?.some((obj) => {
    return Object.values(obj).some((value) => permission?.includes(value));
  });


  stageContext.setHasPermission(gotPemission);
  if (!stageContext.hasPermission && !permission?.includes("all")) {
    return <NotFound />;
  }

  return <>{children}</>;
};

export default CheckRoles;
