import {StandardProgramProvider} from "../../../../../contexts/StandardProgramContext";
import StandardAnswerMain from "./standardAnswer";

const StandardAnswerProgram = () => {
  return (
    <StandardProgramProvider>
      <StandardAnswerMain/>
    </StandardProgramProvider>
  );
};

export default StandardAnswerProgram;
