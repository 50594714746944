import React, {useContext, useEffect, useState} from "react";

import {StageContext} from "../../contexts/stageContext";

const StageTimer = () => {
  const {
    currentStage: data,
    endDate: endOfCurrentStage,
    getCurrentSurveyStage,
  } = useContext(StageContext);

  const currentStage = data;
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    getCurrentSurveyStage();
  }, []);

  useEffect(() => {
    // Update the remaining time every second
    const intervalId = setInterval(() => {
      if (currentStage) {
        const endDate = new Date(currentStage.endDate).getTime();
        const now = new Date().getTime();
        const timeDifference = endDate - now;

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setRemainingTime({
          days: days < 10 ? `0${days}` : days,
          hours: hours < 10 ? `0${hours}` : hours,
          minutes: minutes < 10 ? `0${minutes}` : minutes,
          seconds: seconds < 10 ? `0${seconds}` : seconds,
        });
      }
    }, 1000);

    return () => {
      if (new Date(endOfCurrentStage) > new Date()) {
        clearInterval(intervalId);
      }
    };
  }, [currentStage]);

  return (
    currentStage && (
      <div className="flex flex-col gap-7 items-center">
        <span className="font-bold text-[#2B2969] text-lg">
          {currentStage.title}
        </span>

        <div className="flex flex-row gap-3 items-center ">
          <span className="bg-sky-50 py-1  w-[63px] h-[63px] rounded-[20px] text-center">
            <h1 className="font-bold">{remainingTime.days}</h1>
            <p className="font-light text-slate-500 text-md">يوم</p>
          </span>
          <span className="font-bold text-2xl">:</span>
          <span className="bg-sky-50 py-1  w-[63px] h-[63px] rounded-[20px] text-center">
            <h1 className="font-bold">{remainingTime.hours}</h1>
            <p className="font-light text-slate-500 text-md">ساعة</p>
          </span>
          <span className="font-bold text-2xl">:</span>
          <span className="bg-sky-50 py-1  w-[63px] h-[63px] rounded-[20px] text-center">
            <h1 className="font-bold">{remainingTime.minutes}</h1>
            <p className="font-light text-slate-500 text-md">دقيقة</p>
          </span>
          <span className="font-bold text-2xl">:</span>
          <span className="bg-sky-50 py-1  w-[63px] h-[63px] rounded-[20px] text-center">
            <h1 className="font-bold">{remainingTime.seconds}</h1>
            <p className="font-light text-slate-500 text-md">ثانية</p>
          </span>
        </div>
      </div>
    )
  );
};

export default StageTimer;
