import { IExpertEvaluation, IStageOneAgencyAnswer, IStandardRequirement } from "../../../types";

export function trExpertRequirementAnswer(res: IExpertEvaluation): IStandardRequirement[] {
  return res.expertRequirementAnswers.map((requirement) => {
    return {
      id: requirement.id,
      title: requirement.standardRequirementTitle,
      decision: !!requirement.decision,
      donotApply: requirement.donotApply,
      subStandardRequirements: requirement.expertSubRequirementAnswers.map(
        (subRequirement) => {
          return {
            id: subRequirement.id,
            title: subRequirement.subStandardRequirementTitle,
            decision: !!subRequirement.decision,
            donotApply: subRequirement.donotApply,
            subStandardRequirements: [],
          };
        }
      ),
    };
  });
}


export function trAgencyRequirementAnswer(res: IStageOneAgencyAnswer): IStandardRequirement[] {
  return res.agencyRequirementAnswers.map((requirement) => {
    return {
      id: requirement.id,
      title: requirement.standardRequirementTitle,
      decision: !!requirement.decision,
      donotApply: requirement.donotApply,
      subStandardRequirements: requirement.agencySubRequirementAnswers.map(
        (subRequirement) => {
          return {
            id: subRequirement.id,
            title: subRequirement.subStandardRequirementTitle,
            decision: !!subRequirement.decision,
            donotApply: subRequirement.donotApply,
            subStandardRequirements: [],
          };
        }
      ),
    };
  });
}



export function trExpertFinalAnswer(res: IExpertEvaluation): number{
  return res?.finalCommitmentLevel?.id;
}

export function trAgencyFinalAnswer(res: IStageOneAgencyAnswer): number{
  return res?.finalCommitmentLevel?.id;
}
