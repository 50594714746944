import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../services/environment";
import { axiosGetRequest } from "../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../helpers/isPermission";

export const useGetAuditorExpertFinishedStandards = ({ currentPage }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");

  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAuditorExpertFinishedStandards } = useQuery(
    {
      queryKey: ["AuditorExpertFinishedStandards", currentPage, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertFinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    }
  );

  const finishedStandards = data?.result?.reviewedAgencies;
  const allStandardsCount = data?.result?.totalAssignedAgenciesCount;
  const finishedStandardsCount = data?.result?.totalReviewedAgenciesCount;
  const totalPages = Math.ceil(finishedStandardsCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertFinishedStandards",
        currentPage + 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertFinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AuditorExpertFinishedStandards",
        currentPage - 1,
        surveyStageId,
      ],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertFinishedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    finishedStandards,
    allStandardsCount,
    totalPages,
    data,
    finishedStandardsCount,
    isGettingAuditorExpertFinishedStandards,
  };
};
