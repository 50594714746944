import { useContext, useEffect, useState } from "react";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import AppTextarea from "../../../../../../../components/form/appTextarea";

const ExpertFinalAnswer = ({ setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const { newExpertAnswer, setNewExpertAnswer, expertDegree } =
    useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? "" : degree);

    setNewExpertAnswer({
      ...newExpertAnswer,
      checkedExpertDegree: degree === checkedDegree ? "" : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newExpertAnswer?.checkedExpertDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewExpertAnswer({
      ...newExpertAnswer,
      expertAnswerNote: note,
    });
  };

  const renderedDegrees = newExpertAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة الخبير"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>

        {expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
          newExpertAnswer?.checkedExpertDegree?.title && (
          <div className="mt-5 ml-8 min-w-[350px] grow">
            <AppTextarea
              rows={5}
              placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
              style={{
                marginBottom: "24px",
              }}
              value={newExpertAnswer?.expertAnswerNote || ""}
              onChange={(e) => handleChangeNote(e.target.value)}
            />
          </div>
        )}
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertFinalAnswer;
