import {ErrorMessage} from "formik";
import {NewForm} from "../../../components/form/NewForm";
import {InputLabel} from "../../../components";

function AddVisitTargetModal({ inputs, onChange, onSubmit, innerRef }) {
  // ---------------------- validation -------------------
  const initialValues = {
    nameInArabic: "",
  };

  const validate = () => {
    const errors = {};

    if (!inputs.nameInArabic.trim()) errors.nameInArabic = "هذا الحقل مطلوب";

    return errors;
  };
  // -----------------------------------------------------
  return (
    <NewForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={innerRef}
    >
      <div className="flex flex-col gap-3">
        <div className="space-y-1">
          <InputLabel
            label={"نوع الزيارة"}
            name={"nameInArabic"}
            type={"text"}
            value={inputs.nameInArabic}
            onChange={onChange}
          />

          <ErrorMessage
            className="text-red-700"
            name="nameInArabic"
            component="div"
          />
        </div>
      </div>
    </NewForm>
  );
}

export default AddVisitTargetModal;
