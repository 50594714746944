import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component, ...rest }) => {
  const surveyId = localStorage.getItem("surveyId");

  return (
    <Route
      {...rest}
      render={(props) =>
        surveyId ? component : <Redirect to="/ChooseCurruntIndicator" />
      }
    />
  );
};

export default ProtectedRoute;
