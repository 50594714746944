import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { surveyId } from "../../../../helpers/isPermission";

export const useGetPhase2Standards = ({ currentPage, filterValue }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAllStandards } = useQuery({
    queryKey: ["Phase2AllStandards", currentPage, filterValue],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetStandardStatusBasedOnReviewOperation,
        {
          KeyWord: filterValue,
          StageId: surveyStageId,
          SurveyId: surveyId(),
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
        },
        true,
        signal
      ),
    enabled: surveyStageId != null,
  });

  const allStandards = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["Phase2AllStandards", currentPage + 1, filterValue],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.GetStandardStatusBasedOnReviewOperation,
          {
            KeyWord: filterValue,
            StageId: surveyStageId,
            SurveyId: surveyId(),
            SkipCount: (currentPage + 1) * 5,
            MaxResultCount: 5,
          },
          true,
          signal
        ),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["Phase2AllStandards", currentPage - 1, filterValue],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.GetStandardStatusBasedOnReviewOperation,
          {
            KeyWord: filterValue,
            StageId: surveyStageId,
            SurveyId: surveyId(),
            SkipCount: (currentPage - 1) * 5,
            MaxResultCount: 5,
          },
          true,
          signal
        ),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    allStandards,
    totalCount,
    totalPages,
    data,
    isGettingAllStandards,
  };
};
