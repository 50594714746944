import {UsersProvider} from "../../../../contexts/UsersContext"
import UsersList from "./UsersList"

const Users=()=>{
  return(
    <UsersProvider>
      <UsersList/>
    </UsersProvider>
  )
}

export default Users
