import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { LogoHeader } from "../../../assets/imgs/Index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp ,faChevronLeft,faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  CModal,
  DropdownMultiSelect,
  Ecard,
  FModal,
  Input,
  InputLabel,
  SModal,
  Title
} from "../../../components";
import NewECard from '../../../components/NewECard.jsx';
import NewInput from '../../../components/NewInput.jsx';
import NewTable from '../../../components/NewTable.jsx';
import AnswerStandards from  '../../../components/AnswerStandards.jsx';
import Filter from "../../../components/Filter";
import NewTitle from '../../../components/NewTitle';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, post, remove, put } from "../../../services/Request";
import environment from "../../../services/environment";
import Skeleton from "react-skeleton-loader";
import { formatDate, permissionsCheck } from "../../../helpers/utils";
import ReactPaginate from "react-paginate";
import { programsIcon, search, removeIcon, viewNew, pen } from "../../../assets/icons/Index";
import icon from "../../../assets/icons/programsIcon.svg";
import activeBlocks from "../../../assets/icons/activeBlocks.svg";
import blocks from "../../../assets/icons/blocks.svg";
import table from "../../../assets/icons/table.svg";
import activeTable from "../../../assets/icons/activeTable.svg";
import NewHeader from "../../../layouts/header/NewHeader";


const IndicatorsList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [message, setMessage] = useState(false);
  const [showMenu, setShowMenu] = useState(false)
  const [totalPages, setTotalPages] = useState(null);
  const [totalCounts, setTotalCounts] = useState(null);
  const [currentPage, setCurrentPage] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showTable, setShowTable] = useState(false);
  
  useEffect(() => {
    getAllIndicators(0);
  }, []);

  const getAllIndicators = (page) => {
    setCurrentPage(page);
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllIndicators + "?SkipCount=" + (page * 6) +"&MaxResultCount=" + 6,config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          
          setTotalPages(res.data.result.totalCount / 6);
          setTotalCounts(res.data.result.totalCount);
          setIndicators(res.data.result.items);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    );
  };

  function handleMenu () {
    setShowMenu(!showMenu)
  }

  const handleChangeEntitiesFilter = (value) => {
    
    setSelectedEntities(value);
  };
  
  const handlePageClick = (event) => {
    
    const newOffset = event.selected;
    
    setCurrentPage(newOffset);
    getAllIndicators(newOffset);
  };
  
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const addIndicator = () => {
    setOpen(true);
    setId(0);
    setTitle("");
    setDescription("");
  };

  const editIndicator = (row) => {
    setOpen(true);
    setId(row.id);
    setTitle(row.title);
    setDescription(row.description);
  };

  const saveIndicator = () => {
    if (id == 0) createIndicator();
    else updateIndicator();
  };

  const createIndicator = () => {
    if (!title) {
      setMessage({
        type: "error",
        message: t("اسم المؤشر مطلوب"),
      });
      setOpenConfirmModal(true);
      return;
    }
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
        title: title,
        description: description
    };
    
    post(environment.createIndicator, data, config, (res) => {
      
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenConfirmModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenConfirmModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        getAllIndicators(currentPage);
        setTimeout(() => {
          setOpen(false);
          setOpenConfirmModal(true);
        }, 500);
      }
    });
  };

  const updateIndicator = () => {
    setLoadingModel(true);
    if (!title) {
      setMessage({
        type: "error",
        message: t("اسم المؤشر مطلوب"),
      });
      setOpenConfirmModal(true);
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: id,
      title: title,
      description: description
    };
    put(environment.updateIndicator, data, config, (res) => {
      
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenConfirmModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenConfirmModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        getAllIndicators(currentPage);
        setTimeout(() => {
          setOpen(false);
          setOpenConfirmModal(true);
        }, 500);
      }
    });
  };

  const deleteIndicator = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteIndicator + "?id=" + id, config, (res) => {
      
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenConfirmModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenConfirmModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        setOpenConfirmModal(true);
        setTimeout(() => {
            getAllIndicators(currentPage);
        }, 500);
      }
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader/>
      <Title title={"المؤشرات"} withoutButton={false} onClick={() => addIndicator()}
        titleButton={"إضافة مؤشر "} iconTitle={programsIcon} single={true} />
      <SModal open={openConfirmModal} setOpen={() => setOpenConfirmModal(false)} type={message.type} subTitle={message.message} />
      <CModal open={openDelete} setOpen={() => setOpenDelete(false)}
        confirmModal={() => { setOpenDelete(false); setOpenConfirmModal(true); }} />
      <FModal
        content={
          <div>
            <InputLabel type={"text"}
              label={"إسم المؤشر"}
              placeholder={"إسم المؤشر"}
              value={title}
              onChange={handleChangeTitle}
            />
            <InputLabel type={"textarea"}
              label={"الوصف"}
              placeholder={"الوصف"}
              value={description}
              onChange={handleChangeDescription}
            />
            <Button
              loading={loadingModel}
              title={t("modules.main.calendar.save")}
              typeColor={"dark"}
              className="pt-3 mt-3"
              withIcon={false}
              style={{ backgroundColor: "#00114E", width: 160, marginTop: 10 }}
              onClick={() => saveIndicator()}
            />
          </div>
        }
        open={open}
        titleButton={t("modules.main.calendar.save")}
        withoutButton={false}
        width={160}
        setOpen={() => setOpen(false)}
        headerTitle={id == 0 ? "إضافة مؤشر" : "تعديل المؤشر"}
      />
      <div className="px-5 bg-white rounded-2xl">
        {loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {indicators.map((indicator, index) => {
              
             return (<Skeleton
                key={index}
                animated={true}
                width={"100%"}
                height={"200px"}
                style={{ marginTop: 10 }}
                color={"#2B296969"}
                count={1}
              />)
              })
            }
          </div>
        ) : (
          <div>
          {indicators.length == 0 ? (
            <div className="flex justify-center items-center">
              <span className="text-lg text-[#2B2969]">
                {t("لا يوجد مؤشرات")}
              </span>
            </div>
          ) : (
            <>
            <div className="flex flex-row justify-between items-center">
              <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3>
              <div className="flex flex-row justify-between items-center">
                <div onClick={() => setShowTable(true)} className="rounded cursor-pointer">
                  <img src={showTable ? activeTable : table} alt="table view" className="w-12 h-12"/>
                </div>
                <div onClick={() => setShowTable(false)} className="rounded mr-2 cursor-pointe w-12 h-12r">
                  <img src={showTable ? blocks : activeBlocks} alt="blocks view" className="w-12 h-12"/>
                </div>
              </div>
            </div>
          {!showTable ? <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {indicators.map((indicator, index) => (
              <NewECard openEdit={() => editIndicator(indicator)} openDelete={() => deleteIndicator(indicator.id)}
                from="indicators"
                showMore={() =>history.push(`/programs`)}
                title={indicator.title}
                description={indicator.description}
              />
            ))}
            </div> 
          : <div className="bg-white">
        <NewTable
            data={indicators}
            columns={[
                {name:'أسم المؤشر',accessorKey: 'title', selector: row => row.title, sortable: true,cell:info => <div className="text-sm text-[#292069]">{info.title}</div>},
                {name:'الوصف', accessorKey: 'description', selector: row => row.description,cell:info => <div className="text-sm text-[#1CC081] p-5">{info.description}</div> , sortable: false},
                {name:'إجراءات', selector: row => row.id, sortable: true,cell:info => 
                {permissionsCheck("Pages.UserType.Admin") ? <div className="flex flex-row items-center justify-around w-[100%]">
                    <div onClick={()=>history.push(`/programs`)}>
                        <img src={viewNew} className="w-[40px] cursor-pointer"/>
                    </div>
                    <div onClick={() => editIndicator(info)}>
                        <img src={pen} className="w-[40px] cursor-pointer"/>
                    </div>
                    <div onClick={() => deleteIndicator(info.id)}>
                        <img src={removeIcon} className="w-[40px] cursor-pointer"/>
                    </div>
                </div>:<div></div>}}
              ]}
            onSelectedRowsChange={handleChangeEntitiesFilter}
            pagination={false}
        />
      </div>}
          </>
          )}
          </div>
        )}
        <div className="py-5">
          <ReactPaginate
            nextLabel={loading == true ? null :  <FontAwesomeIcon icon={faChevronLeft} style={{color:'#A1A9C4',fontSize:14}}/>}
            onPageChange={handlePageClick}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={loading == true ? null :  <FontAwesomeIcon icon={faChevronRight} style={{color:'#A1A9C4',fontSize:14}}/>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

export default IndicatorsList;
