import React, { useContext, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../addStandardContext";
import { produce } from "immer";
import { addStandardRequirementValidations } from "../addStandardValidation";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";

const AddStandardRequirements = () => {
  const {
    addRequirmentsObj,
    setAddRequirmentsObj,
    selectedRequirementAgencies,
    setSelectedRequirementAgencies,
    classifications,
    requirmentList,
    setRequirmentList,
    selectedAgencies,
    addFinalValue,
    setAddFinalValue,
    setSelectedRequirementAgenciesForEdit,
  } = useContext(AddStandardContext);
  const [selectedClassifications, setSelectedClassifications] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(true);

  const [errors, setErrors] = useState({
    title: "",
    recommendation: "",
    standardRequirementClassificationId: "",
    agencyStandardRequirements: "",
  });

  const classificationOptions = classifications?.map((item) => ({
    label: item.title,
    value: item.id,
    id: item.id,
  }));
  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardRequirementValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
      setDisabledBtn(false);
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const handleChangeClassifications = async (value) => {
    setSelectedClassifications(value);
    const classificationId = value?.value || "";
    if (!value) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: "يجب اختيار التصنيف",
      });
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: "" });
    }
    setAddRequirmentsObj((prev) => ({
      ...prev,
      standardRequirementClassificationId: classificationId,
    }));
  };

  const handleChangeRequirementAgencies = (value) => {
    setSelectedRequirementAgencies(value);

    if (!value || value?.length == 0) {
      setErrors({
        ...errors,
        agencyStandardRequirements: "يجب اختيار جهة حكومية على الأقل للمتطلب",
      });
    } else {
      setErrors({
        ...errors,
        agencyStandardRequirements: "",
      });
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setAddRequirmentsObj({
      ...addRequirmentsObj,
      [name]: value,
    });

    validateInput(name, value);
  };

  const handleSubmit = async () => {
    const { title, recommendation } = addRequirmentsObj;
    const { validForm, formErrors } = await handleFormValidation(
      addStandardRequirementValidations,
      {
        title,
        recommendation,
      }
    );
    if (
      !selectedRequirementAgencies ||
      selectedRequirementAgencies?.length == 0
    ) {
      setErrors({
        ...errors,
        agencyStandardRequirements: "يجب اختيار جهة حكومية على الأقل للمتطلب",
      });
      return;
    }
    if (!selectedClassifications) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: "يجب اختيار التصنيف",
      });
      return;
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: "" });
    }
    if (validForm) {
      const requirementAgencies =
        selectedRequirementAgencies?.map((item) => ({
          agencyId: item?.value,
        })) || [];

      setSelectedRequirementAgenciesForEdit(selectedRequirementAgencies);
      // setAllAgenciesOptionsForEdit(selectedAgencies);

      // setAddRequirmentsObj(
      //   produce((draft) => {
      //     draft.agencyStandardRequirements = requirementAgencies;
      //   })
      // );

      setAddFinalValue(
        produce((draft) => {
          draft.standardRequirements.unshift({
            ...addRequirmentsObj,
            agencyStandardRequirements: requirementAgencies,
          });
          
        })
      );

      setAddRequirmentsObj({
        title: "",
        recommendation: "",
        standardRequirementClassificationId: "",
        subStandardRequirements: [],
      });
      setSelectedRequirementAgencies([]);
      setSelectedClassifications("");
    } else if (formErrors) {
      setErrors({ ...errors, ...formErrors });
    }
  };

  

  return (
    <div className="bg-white p-5 pb-7 rounded-lg">
      <h2 className="font-semibold mb-4">إضافة متطلب</h2>

      <div className="row gap-y-3">
        <div className="col-md-5 ">
          <AppInput
            variant="gray"
            label="عنوان المتطلب"
            placeholder="اكتب هنا"
            name="title"
            value={addRequirmentsObj.title}
            onChange={handleChange}
            errorMessage={errors.title}
          />
        </div>
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اختر تصنيف"
            options={classificationOptions}
            isMultiple={false}
            isRequired={true}
            errorMessage={errors.standardRequirementClassificationId}
          />
        </div>
      </div>
      <div className="row gap-y-4 mt-8">
        <div className="col-xl-5">
          <AppInput
            variant="gray"
            label="التوصية"
            placeholder="اكتب هنا"
            name="recommendation"
            value={addRequirmentsObj.recommendation}
            onChange={handleChange}
            errorMessage={errors.recommendation}
          />
        </div>

        <div className="col-md-5">
          <NewDropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedRequirementAgencies || []}
            handleChange={handleChangeRequirementAgencies}
            label="الجهات الحكومية"
            // placeholder="اختر الجهات الحكومية للمتطلب"
            options={selectedAgencies}
            isMultiple={true}
            isRequired={true}
            errorMessage={errors?.agencyStandardRequirements}
          />
        </div>

        <div className="col-xl align-self-end mr-auto">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة متطلب</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStandardRequirements;
