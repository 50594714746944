import {
  faFile,
  faFileAudio,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const UploadFileIcon = ({ fileType }) => {
  let fileIcon = <FontAwesomeIcon icon={faUpload} />;

  if (
    fileType?.startsWith("application/vnd.ms-powerpoint") ||
    fileType?.startsWith(
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    )
  )
    fileIcon = (
      <FontAwesomeIcon icon={faFilePowerpoint} className="text-[#e64a19]" />
    );
  else if (
    fileType?.startsWith("application/vnd.ms-excel") ||
    fileType?.startsWith(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
  )
    fileIcon = (
      <FontAwesomeIcon icon={faFileExcel} className="text-[#0f723c]" />
    );
  else if (
    fileType?.startsWith("application/msword") ||
    fileType?.startsWith(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
  )
    fileIcon = <FontAwesomeIcon icon={faFileWord} className="text-[#3673d6]" />;
  else if (fileType?.startsWith("text/csv"))
    fileIcon = <FontAwesomeIcon icon={faFileCsv} className="text-[#00a14e]" />;
  else if (fileType?.startsWith("application/pdf"))
    fileIcon = <FontAwesomeIcon icon={faFilePdf} className="text-red-600" />;
  else if (fileType?.startsWith("application") || fileType?.startsWith("text"))
    fileIcon = <FontAwesomeIcon icon={faFile} className="text-red-600" />;
  else if (fileType?.startsWith("image"))
    fileIcon = (
      <FontAwesomeIcon icon={faFileImage} className="text-[#caa023]" />
    );
  else if (fileType?.startsWith("video"))
    fileIcon = (
      <FontAwesomeIcon icon={faFileVideo} className="text-[#9e2e22]" />
    );
  else if (fileType?.startsWith("audio"))
    fileIcon = (
      <FontAwesomeIcon icon={faFileAudio} className="text-[#2797b1]" />
    );

  return (
    <span className="w-8 h-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700">
      <div className="text-xl mb-2">{fileIcon}</div>
    </span>
  );
};

export default UploadFileIcon;
