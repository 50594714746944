import React from 'react'
import { useHistory } from "react-router-dom"

const Card = ({id, name, ratio, date, reviewed,openModal}) => {
  const history = useHistory()
    // debugger;
  return (
    <div className='p-3 border-[#7D8BAB38] border rounded-2xl flex flex-col items-center'>
      <section className='text-[16px] text-[#292069] text-start w-full mb-2'>{name}</section>
      <section className='flex flex-row w-full justify-between items-center mb-2'>
       <div className='text-[#292069] text-sm'>
        تم الرفع في {date}
       </div>
       <div className='relative flex flex-row items-center'>
        <span className={'absolute w-1.5 h-1.5 rounded-full ' + (reviewed == "completed" ? "bg-[#1CC081]" : (reviewed == "not started" ? "bg-[#FF304A]" : "bg-[#FFC000]"))}></span>
        <p className='mr-4 text-[#7D8BAB] text-[12px]'>
          {reviewed == "completed" ? "تم التقييم" : (reviewed == "not started" ? " لم يتم التقييم" : "جارى التقييم")}
        </p>
       </div>
      </section>
      <section className='w-full flex flex-row items-center justify-between mb-2'>
        <div className='text-[12px] text-[#7A86A1]'>نسبة الإكتمال</div>
        <div className='relative w-3/5'>
          <span className='w-full rounded-xl bg-[#EAEAEA] h-[5px] absolute'>
            <span className={'h-[5px] rounded-xl absolute ' + (reviewed == "completed" ? "bg-[#1CC081]" : (reviewed == "not started" ? "bg-[#FF304A]" : "bg-[#FFC000]"))} style={{width: `${ratio}%`}}></span>
          </span>
        </div>
        <div className={'text-[#7D8BAB] text-[12px] ' + (reviewed == "completed" ? "text-[#1CC081]" : (reviewed == "not started" ? "text-[#FF304A]" : "text-[#FFC000]"))}>{ratio}%</div>
      </section>
      <section className='w-full flex flex-row'>
        <button onClick={openModal} className='w-2/3 relative rounded-full bg-[#2A206A] h-11 text-white flex flex-row items-center'>
            <p className='absolute w-full text-center'>تعيين مسؤول</p>
            <svg className='absolute left-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M9.15981 10.8703C9.05003 10.8603 8.93958 10.8603 8.82981 10.8703C7.66601 10.8271 6.56567 10.3289 5.76542 9.48276C4.96518 8.63665 4.52895 7.51028 4.55057 6.34588C4.57219 5.18149 5.04992 4.07208 5.88103 3.25627C6.71213 2.44045 7.83021 1.9834 8.99481 1.9834C10.1594 1.9834 11.2775 2.44045 12.1086 3.25627C12.9397 4.07208 13.4174 5.18149 13.439 6.34588C13.4607 7.51028 13.0244 8.63665 12.2242 9.48276C11.4239 10.3289 10.3236 10.8271 9.15981 10.8703Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4098 4C17.338 4 18.2283 4.36875 18.8847 5.02513C19.541 5.6815 19.9098 6.57174 19.9098 7.5C19.9104 8.40617 19.5596 9.27726 18.931 9.93002C18.3025 10.5828 17.4453 10.9663 16.5398 11C16.4534 10.99 16.3662 10.99 16.2798 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.16021 14.561C1.74021 16.181 1.74021 18.821 4.16021 20.431C5.67348 21.3341 7.40293 21.811 9.16522 21.811C10.9275 21.811 12.657 21.3341 14.1702 20.431C16.5902 18.811 16.5902 16.171 14.1702 14.561C12.6553 13.6626 10.9265 13.1885 9.16522 13.1885C7.40394 13.1885 5.67513 13.6626 4.16021 14.561Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3398 20C19.05 19.8603 19.7198 19.563 20.2998 19.13C20.6585 18.901 20.9537 18.5854 21.1581 18.2123C21.3626 17.8391 21.4698 17.4205 21.4698 16.995C21.4698 16.5695 21.3626 16.1509 21.1581 15.7777C20.9537 15.4046 20.6585 15.089 20.2998 14.86C19.7255 14.4384 19.0674 14.1451 18.3698 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </button>
        <button onClick={() => history.push(`/creativeCards/${id}/details`)} className='w-1/3 relative rounded-full bg-[#1CC081] h-11 text-white flex flex-row items-center mr-2'>
            <p className='absolute w-[50%] text-center text-[13px]'>المزيد</p>
            <svg className='absolute left-2' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16.6362" cy="16.6362" r="16.6362" fill="white"/>
            <path d="M21.5495 16.6726H13.9653" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.4933 20.4648L12.7012 16.6727L16.4933 12.8806" stroke="#1CC081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
      </section>
    </div>
  )
}

export default Card