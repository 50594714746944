import { useContext, useEffect, useState } from "react";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import useCreatePocOfficerAnswer from "../useCreateAnswer/useCreatePocOfficerAnswer";

const PocOfficerFinalAnswer = ({ setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { createPocOfficerAnswer } = useCreatePocOfficerAnswer();
  const { newPocOfficerAnswer, setNewPocOfficerAnswer, pocOfficerDegree } =
    useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? "" : degree);

    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      checkedPocOfficerDegree: degree === checkedDegree ? "" : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newPocOfficerAnswer?.checkedPocOfficerDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      pocOfficerAnswerNote: note,
    });
  };

  const renderedDegrees = newPocOfficerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createPocOfficerAnswer={createPocOfficerAnswer}
      label="إجابة ضابط الاتصال"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>

        {pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
          newPocOfficerAnswer?.checkedPocOfficerDegree?.title && (
          <div className="mt-5 ml-6 min-w-[350px] grow">
            <AppTextarea
              rows={5}
              placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
              style={{
                marginBottom: "24px",
              }}
              value={newPocOfficerAnswer?.pocOfficerAnswerNote || ""}
              onChange={(e) => handleChangeNote(e.target.value)}
            />
          </div>
        )}
      </div>
    </StandardFinalAnswer>
  );
};

export default PocOfficerFinalAnswer;
