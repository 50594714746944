import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";

export const useGetPocOfficers = ({ currentPage, filterValue }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAllPocOfficers } = useQuery({
    queryKey: ["PocOfficers", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllPOCOfficersWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
    enabled: surveyStageId != null,
  });

  const allPocOfficers = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["PocOfficers", currentPage + 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllPocOfficersWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          POCOfficerId: pocOfficerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["PocOfficers", currentPage - 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllPocOfficersWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId,
          ReviewerId: reviewerId,
          POCOfficerId: pocOfficerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
      enabled: surveyStageId != null,
    });
  }

  return {
    queryClient,
    allPocOfficers,
    totalCount,
    totalPages,
    data,
    isGettingAllPocOfficers,
  };
};
