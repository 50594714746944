import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { programsIcon } from "../../../assets/icons/Index";
import { DropdownMultiSelect, Title } from "../../../components";
import NewHeader from "../../../layouts/header/NewHeader";
import { faBuilding, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import VisitorsStatsCard from "./VisitorsStatsCard";
import VisitorsChart from "./VisitorsChart";
import NewTable from "../../../components/NewTable";
import { Badge } from "flowbite-react";
import { useEffect, useState } from "react";
import { axiosGetRequest, axiosPostRequest } from "../../../services/Request";
import environment from "../../../services/environment";
import DrawerFilter from "../../../components/DrawerFilter";
import { subDays, subYears } from "date-fns";
import { format } from "date-fns";
import { subMonths } from "date-fns";
import useExcelExport from "../../../helpers/customHooks/useExcelExport";
import { generateRandomString } from "../features/answersReport/utils";
import { toast } from "react-toastify";

function VisitorsAnalytics() {
  const [visitorsAnalyticsData, setVisitorsAnalyticsData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [dateInputs, setDateInputs] = useState({
    from: "",
    to: "",
  });
  const [cardsData, setCardsData] = useState({
    agencyCount: "",
    allUserCount: "",
  });

  const getVisitorsAnalytics = async () => {
    setLoading(true);
    const requestId = generateRandomString(10);
    const promise = axiosPostRequest(environment.getVisitorsAnalytics, {
      maxResultCount: 1000,
      skipCount: 0,
      startDate: selectedTime.value ? selectedTime.value : dateInputs.from,
      endDate: selectedTime.value ? getDateToday() : dateInputs.to,
      requestId,
    });

    try {
      const delayedPromise = new Promise((resolve, reject) => {
        promise.then(() => {
          document.addEventListener("reportReady", (data) => {
            console.log("data", data);
            if (data.detail.isSuccess && data.detail.requestId === requestId) {
              console.log("success", data.detail);
              resolve(data.detail.result);
            } else if (
              !data.detail.isSuccess &&
              data.detail.requestId === requestId
            ) {
              console.log("error", data.detail);
              // setExcelLoading(false);
              // setExcelExtraLoading(false);
              reject(data.detail);
            }
          });
        });
      });

      toast.promise(delayedPromise, {
        pending: "...جاري تحميل الملف قد يستغرق بعض الوقت",
        success: "تم تحميل الملف بنجاح",
        error: "حدث خطأ أثناء تحميل البيانات",
      });

      const res = await delayedPromise;
      // setExcelLoading(false);
      // setExcelExtraLoading(false);
      // downloadFile(res, "سجل الاٍجابات.xlsx");

      console.log("res", res);
      setVisitorsAnalyticsData(res?.data);
      setFilteredData(res?.data);
      setChartData(res?.chart);
      setCardsData({
        agencyCount: res?.agencyCount,
        allUserCount: res?.allUserCount,
      });
    } catch (error) {
      
      // setExcelLoading(false);
      // setExcelExtraLoading(false);
    }
  
  
    setLoading(false);
  };

  useEffect(() => {
    getVisitorsAnalytics();
  }, []);

  const columns = [
    {
      name: "اسم الجهة",
      selector: (row) => "agencyName",
      header: "agencyName",
      accessorKey: "agencyName",
      cell: (row) => (
        <div className="whitespace-nowrap truncate">{row.agencyName}</div>
      ),
      width: "350px",
    },
    {
      name: "عدد ممثلي الجهة",
      selector: (row) => "usersAgencyCount",
      header: "usersAgencyCount",
      accessorKey: "usersAgencyCount",
      cell: (row) => row.usersAgencyCount,
      center: true,
      width: "150px",
    },
    {
      name: "ممثلو الجهة",
      selector: (row) => "users",
      header: "users",
      accessorKey: "users",
      cell: (row) => (
        <div className="flex-wrap flex gap-2">
          {row?.users?.map((ele) => (
            <div className="flex flex-col gap-1 m-1">
              <Badge
                className="text-white font-medium bg-[#3a8ebe] pb-3 px-5 rounded-xl"
                size="sm"
              >
                <div>الاسم : {ele.userName}</div>
                <div>الدور : {ele.role}</div>
              </Badge>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(visitorsAnalyticsData);
    } else {
      const filtered = visitorsAnalyticsData.filter((ele) => {
        const { agencyName, usersAgencyCount, users } = ele;
        return (
          agencyName.includes(value) ||
          usersAgencyCount == value ||
          users?.some((user) =>
            user.role?.toLowerCase().includes(value.toLowerCase())
          ) ||
          users?.some((user) =>
            user.userName?.toLowerCase().includes(value.toLowerCase())
          )
        );
      });
      setFilteredData(filtered);
    }
  };

  // Filter
  const timeOptions = [
    {
      label: "اليوم",
      value: getDateToday(),
    },
    {
      label: "اخر أسبوع",
      value: getDateWeekAgo(),
    },
    {
      label: "اخر شهر",
      value: getDateMonthAgo(),
    },
    {
      label: "اخر شهرين",
      value: getDateTwoMonthsAgo(),
    },
  ];

  function getDateFirstOfYear() {
    return "2024-01-01";
  }
  function getDateToday() {
    return format(new Date(), "yyyy-MM-dd");
  }
  function getDateWeekAgo() {
    return format(subDays(new Date(), 7), "yyyy-MM-dd");
  }
  function getDateMonthAgo() {
    return format(subMonths(new Date(), 1), "yyyy-MM-dd");
  }
  function getDateTwoMonthsAgo() {
    return format(subMonths(new Date(), 2), "yyyy-MM-dd");
  }

  const search = () => {
    getVisitorsAnalytics();
  };

  const clearSearch = () => {
    setSelectedTime("");
    setDateInputs({
      from: "",
      to: "",
    });
  };

  const handleChangeDate = (e) => {
    setDateInputs({ ...dateInputs, [e.target.name]: e.target.value });
    if (!dateInputs.from && !dateInputs.to) {
      setSelectedTime("");
    }
  };

  // excel
  const downloadExcel = useExcelExport();

  const dataForExcel = filteredData.map((item) => {
    return {
      ...item,
      users: item.users
        ?.map((user) => `[${user?.userName} : ${user?.role}]`)
        .join(" - "),
    };
  });

  const handleExportToExcel = () =>
    downloadExcel(`تحليلات الزوار`, dataForExcel);

  return (
    <div className="bg-white rounded-[30px] m-5 pb-2 overflow-hidden">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withSwitch={false}
        title={"تحليلات الزوار [ الجهة ]"}
        withoutButton={true}
        single={true}
      />

      <div className="px-5 space-y-10">
        {/* filter */}
        <div className="flex justify-end">
          <DrawerFilter handleSearch={() => search()} handleClear={clearSearch}>
            {!dateInputs.from && !dateInputs.to && (
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedTime}
                options={timeOptions}
                isMultiple={false}
                name="selectedTime"
                handleChange={(value) => {
                  setSelectedTime(value || "");
                  if (!selectedTime.value) {
                    setDateInputs({
                      from: "",
                      to: "",
                    });
                  }
                }}
                label={"اختر مدة محددة"}
              />
            )}

            {!selectedTime.value && (
              <div className="w-full flex gap-3">
                <div className="flex flex-1 flex-col space-y-1 ">
                  <label className="label_input text-lg text-[#292069] ">
                    أو اختر الفترة من:
                  </label>

                  <input
                    style={{ border: "2px solid #a8b2d2" }}
                    onChange={handleChangeDate}
                    value={dateInputs.from}
                    type="date"
                    name="from"
                    className="text-[14px] text-[#7D8BAB] h-[42px] border-none rounded-3xl py-3 px-5"
                    placeholder="22-10-2023"
                  />
                </div>

                <div className="flex flex-1 flex-col space-y-1 ">
                  <label className="label_input text-lg text-[#292069] ">
                    اٍلي:
                  </label>

                  <input
                    style={{ border: "2px solid #a8b2d2" }}
                    onChange={handleChangeDate}
                    value={dateInputs.to}
                    type="date"
                    name="to"
                    className="text-[14px] text-[#7D8BAB] h-[42px] border-none rounded-3xl py-3 px-5"
                    placeholder="22-10-2023"
                  />
                </div>
              </div>
            )}
          </DrawerFilter>
        </div>

        {/* cards */}
        <div className="flex flex-col xl:flex-row gap-10 items-center">
          <div className="flex flex-col md:flex-row xl:flex-col gap-5 w-full xl:w-1/4  ">
            <VisitorsStatsCard
              loading={loading}
              icon={faPeopleGroup}
              number={cardsData?.allUserCount || 0}
              text={"عدد ممثلي الجهات"}
              bgColor={"bg-[#0cc2aa]"}
              iconBgColor={"bg-[#0ba792]"}
              skeletonColor={"#0de2c6"}
            />
            <VisitorsStatsCard
              loading={loading}
              icon={faBuilding}
              number={cardsData?.agencyCount || 0}
              text={"عدد الجهات"}
              bgColor={"bg-[#fcc100]"}
              iconBgColor={"bg-[#e2ae03]"}
              skeletonColor={"#ffcf31"}
            />
          </div>

          <div className="flex-1 h-full flex justify-center items-center">
            <VisitorsChart chartData={chartData} />
          </div>
        </div>

        <NewTable
          withExcel
          onExportExcel={handleExportToExcel}
          withSearch
          onSearch={handleFilterData}
          loading={loading}
          columns={columns}
          data={filteredData}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default VisitorsAnalytics;
