import React, {useRef} from "react";
import {downIcon} from "../../assets/icons/Index";

const AppColorInput = ({ onChange, name, label, value = "#000000" }) => {
  const inputRef = useRef();

  const handleInputClick = () => {
    inputRef.current.click();
  };
  return (
    <div className="py-2 pt-1">
      {label && <p className="pb-2">{label}</p>}
      <div
        className="h-[48px] border-light border-[1px] bg-white flex rounded-full items-center overflow-hidden relative min-w-[215px] w-full "
        onClick={handleInputClick}
      >
        <input
          type="color"
          ref={inputRef}
          className="absolute bottom-0 left-0 w-full -z-10"
          onChange={onChange}
          value={value}
          name={name}
        />
        <div
          className="w-[63px] h-full"
          style={{ backgroundColor: value }}
        ></div>
        <div className="flex items-center justify-between min-w-[125px]  pl-8">
          <p className="text-light px-4 mb-1">{value}</p>
        </div>
        <div className="pr-2 shrink-0 absolute left-4 top-1/2">
          <img src={downIcon} alt="arrow down" />
        </div>
      </div>
    </div>
  );
};

export default AppColorInput;
