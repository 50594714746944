import React from "react";
import answer from "./../../../../../../../assets/icons/answer.svg";
import NextBtn from "../../components/buttons/nextBtn";
import { useAnswerContext } from "../../answerContext";
import { showRequirement } from "../utils";

const NotesForAgency = ({ setMenuElement }) => {
  const { newExpertAnswer, expertAnswer } = useAnswerContext();

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.reviewerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {expertAnswer?.reviewerRequirementAnswers.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.reviewerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.reviewerNotContent || "",
              }}
            ></div>
            {
              !expertAnswer?.reviewerNoteTemplate &&
              !expertAnswer?.reviewerNotContent &&
              !expertAnswer?.reviewerRequirementAnswers.length &&
              <div>لا يوجد ملاحظات</div>
            }
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {expertAnswer?.standardRequirements.map((requirement, index) => (
                <li>
                  {showRequirement(requirement) ? requirement.title : ""}
                  <ul>
                    {requirement.subStandardRequirements.map(
                      (subRequirement, i) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">{subRequirement.title}</li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.expertNotContent || "",
              }}
            ></div>
            {
              !expertAnswer?.standardRequirements.length &&
              !expertAnswer?.expertNoteTemplate &&
              !expertAnswer?.expertNotContent &&
              <div>لا يوجد ملاحظات</div>
            }
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {expertAnswer?.pocOfficerRequirementAnswers.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.pocOfficerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNotContent || "",
              }}
            ></div>
            {
              !expertAnswer?.pocOfficerNoteTemplate &&
              !expertAnswer?.pocOfficerNotContent &&
              !expertAnswer?.pocOfficerRequirementAnswers.length &&
              <div>لا يوجد ملاحظات</div>
            }
          </div>
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(7)} />
    </>
  );
};

export default NotesForAgency;
