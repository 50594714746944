import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import CModal from "./CModal";
import NewTooltip from "./NewTooltip";

const DeleteIcon = ({
  loading,
  deleteFunction,
  deletedName,
  subTitle = "",
  tooltipContent = "",
}) => {
  const [openDelete, setOpenDelete] = useState(false);

  return (
    <>
      <NewTooltip content={tooltipContent || "حذف"}>
        <FontAwesomeIcon
          icon={faX}
          onClick={() => {
            setOpenDelete(true);
          }}
          className="text-red-700 bg-rose-50 mr-1 rounded-full p-2 cursor-pointer"
        />
      </NewTooltip>

      <CModal
        loading={loading}
        confirmModal={deleteFunction}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={subTitle ? subTitle : `هل أنت متأكد من حذف ${deletedName} !`}
      />
    </>
  );
};

export default DeleteIcon;
