import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import {
  isAdmin,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { useAnswerContext } from "../../answerContext";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateExpertManagerAnswer from "../useCreateAnswer/useCreateExpertManagerAnswer";
import usePrevExpertManagerAnswer from "../useGetPrevAnswer/usePrevExpertManagerAnswer";
import ExpertManagerFinalAnswer from "./expertManagerFinalAnswer";
import ExpertManagerNoteTemplate from "./expertManagerNoteTemplates";
import ExpertManagerProofFiles from "./expertManagerProofFiles";
import ExpertManagerRequirementAnswer from "./expertManagerRequirementAnswer";
import ExpertManagerRequirementAnswerConfidential from "./expertManagerRequirementAnswerConfidential";
import NotesForAgency from "./notesForAgency";

const ExpertManagerAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();
  const { loadPreExpertManagerAnswer, newExpertManagerAnswer } =
    useAnswerContext();
  const { createExpertManagerAnswer } = useCreateExpertManagerAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency
  const { entityId, standardId } = useParams();

  useEffect(() => {
    if (currentStage) {
      getPrevExpertManagerAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);

  if (menuElement === 3 && isConfidentialAgency) {
    if(loadPreExpertManagerAnswer && menuElement !== 0){
      return (
        <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <div className="min-h-[400px] flex justify-center items-center">
            <AppLoader />
          </div>
        </div>
      );
    }else{
      return (
        <ExpertManagerRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }


  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertManagerAnswer}
        />
      )}

      {loadPreExpertManagerAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <ExpertManagerProofFiles setMenuElement={setMenuElement} />
          )}
          {menuElement === 3 && (
            <ExpertManagerRequirementAnswer setMenuElement={setMenuElement} />
          )}
          {!isAdmin && !isInquiryManager && menuElement === 4 && (
            <ExpertManagerFinalAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 6 && (
            <NotesForAgency setMenuElement={setMenuElement} />
          )}
          {!isAdmin && !isInquiryManager && menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertManagerAnswer(
                  standardId,
                  entityId,
                  currentStage?.id
                )
              }
            >
              <ExpertManagerNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertManagerAnswer;
