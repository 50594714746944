import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function TopContent({percentage, iconColor}) {
  return (
    <div className="absolute left-[50%] -translate-x-1/2 top-[-97px]">
      <div className="text-[#2a206a] flex-col-reverse flex justify-center items-center">
        <FontAwesomeIcon
          icon={faCaretDown}
          className={`${iconColor} text-[40px] -mt-1`}
        />
        <p className="whitespace-nowrap  font-semibold ">{`${percentage}%`}</p>
        <p className="whitespace-nowrap font-semibold text-[#2a2069] ">
          قياس 2023
        </p>
      </div>
    </div>
  );
}

export default TopContent;
