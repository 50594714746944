import environment from "../../../../../services/environment";
import useAxiosGetRequest from "../../../../../helpers/customHooks/useAxiosGetRequest";
import { useLocation, useParams } from "react-router";
import StanderdItem from "./StanderdItem";
import StanderdItemSkeleton from "./StanderdItemSkeleton";
import { useEffect, useState } from "react";
import DrawerFilter from "../../../../../components/DrawerFilter";
import { AppInput, DropdownMultiSelect } from "../../../../../components";
import { permissionsCheck } from "../../../../../helpers/utils";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { axiosGetRequest } from "../../../../../services/Request";

const StanderdList = () => {
  const { interlocutorId, surveyId, perspectiveId } = useParams();
  const [getStanderds, Standerds, loading] = useAxiosGetRequest();
  const [reviewers, setReviewers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [pocOfficers, setPocOfficers] = useState([]);
  const { indicatorId } = useLocation();

  const [perspectiveName, setPerspectiveName] = useState({});
  const [interlocutorName, setInterlocutorName] = useState({});
  const [standerdName, setStanderdName] = useState("");
  const [standardCode, setStandardCode] = useState("");

  const [currentPage, setCurrentPage] = useState(0);

  const getExperts = async () => {
    const res = await axiosGetRequest(environment.getAllExpertLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setExperts(arr);
    if (res?.success) {
    }
  };

  const getPOCOfficers = async () => {
    const res = await axiosGetRequest(environment.getAllPocOfficerLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setPocOfficers(arr);
    if (res?.success) {
    }
  };

  const getReviewers = async () => {
    const res = await axiosGetRequest(environment.getAllReviewersLookUp);
    let arr = [];
    //  setTotalPages(res.data.result.totalCount / 6)
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });

    setReviewers(arr);
    if (res?.success) {
    }
  };

  const handlePerspectiveName = (value) => {
    setPerspectiveName(value);
  };
  const handleInterlocutorName = (value) => {
    setInterlocutorName(value);
  };
  // const handleStanderdName = (value) => {
  //   setStanderdName(value);
  // };

  // for multiSelect
  const [getPerspective, allPerspectives] = useAxiosGetRequest();

  useEffect(() => {
    getPerspective(environment.getEndoscopes, { SurveyId: surveyId });
    getInterlocutor(environment.getInterviewers, { SurveyId: surveyId });
    getAllStanderds();
    getExperts();
    getReviewers();
    getPOCOfficers();
    getPerspective(environment.getEndoscopes, { SurveyId: surveyId });
  }, []);

  const allPerspectivesOptions =
    allPerspectives?.result?.items &&
    allPerspectives?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  // for multiSelect
  const [getInterlocutor, allInterlocutors] = useAxiosGetRequest();

  useEffect(() => {
    getInterlocutor(environment.getInterviewers, { SurveyId: surveyId });
  }, []);

  const allInterlocutorsOptions =
    allInterlocutors?.result?.items &&
    allInterlocutors?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const getAllStanderds = (page = 0) => {
    getStanderds(environment.getStandards, {
      InterlocutorsIds: interlocutorId,
      SurveyId: surveyId,
      PerspectivesIds: perspectiveId,
      SkipCount: page * 6,
      MaxResultCount: 6,
    });
  };

  // const handleGetStanderds = async (page) => {
  //   await getStanderds(environment.getStandards, {
  //     InterlocutorsIds: interlocutorId,
  //     SurveyId: surveyId,
  //     PerspectivesIds: perspectiveId,
  //     SkipCount: page * 6,
  //     MaxResultCount: 6,
  //   });
  // };

  const renderedStanderds =
    Standerds?.result?.items &&
    Standerds?.result?.items?.map((item) => (
      <StanderdItem
        key={item.id}
        {...item}
        experts={experts}
        setExperts={setExperts}
        reviewers={reviewers}
        setReviewers={setReviewers}
        pocOfficers={pocOfficers}
        setPocOfficers={setPocOfficers}
        indicatorId={indicatorId}
        getAllStanderds={getAllStanderds}
        standardTitle={item.title}
        // assignedReviewers={assignedReviewers}
        // assignedExperts={assignedExperts}
        // assignedPocOfficers={assignedPocOfficers}
      />
    ));

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);

    getAllStanderds(newOffset);
  };

  const clearSearch = () => {
    setStanderdName("");
    setInterlocutorName(null);
    setPerspectiveName(null);
  };
  return (
    <div>
      <div className="my-5 mb-10 flex justify-end">
        <DrawerFilter
          handleSearch={() =>
            getStanderds(environment.getStandards, {
              SurveyId: surveyId,
              InterlocutorsIds: interlocutorName?.value,
              PerspectivesIds: perspectiveName?.value,
              Keyword: standerdName,
              StandardCode: standardCode,
              SkipCount: 0,
              MaxResultCount: 6,
            })
          }
          handleClear={clearSearch}
        >
          <div className="w-full">
            <AppInput
              type={"text"}
              value={standardCode}
              name={"standardCode"}
              onChange={(e) => setStandardCode(e.target.value)}
              label={"رقم المعيار"}
              variant="gray"
              isRequired={false}
            />
          </div>

          <div className="w-full">
            <div className="w-full">
              <AppInput
                type={"text"}
                value={standerdName}
                name={"standerdName"}
                onChange={(e) => setStanderdName(e.target.value)}
                label={"اسم المعيار"}
                variant="gray"
                isRequired={false}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={perspectiveName}
                options={allPerspectivesOptions}
                isMultiple={false}
                name="perspectiveName"
                handleChange={handlePerspectiveName}
                label={"المنظور"}
              />
            </div>

            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={interlocutorName}
              options={allInterlocutorsOptions}
              isMultiple={false}
              name="interlocutorName"
              handleChange={handleInterlocutorName}
              label={"اسم المحور"}
            />
          </div>
        </DrawerFilter>
      </div>
      {permissionsCheck("Pages.Standards.GetAll") ? (
        <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {loading ? (
            <>
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
            </>
          ) : (
            <>{renderedStanderds}</>
          )}
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}
      <div className="py-8 flex justify-center">
        <ReactPaginate
          nextLabel={
            loading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: "#A1A9C4", fontSize: 14 }}
              />
            )
          }
          onPageChange={handlePageClick}
          initialPage={currentPage}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(Standerds?.result?.totalCount / 6)}
          previousLabel={
            loading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: "#A1A9C4", fontSize: 14 }}
              />
            )
          }
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item-previous"
          previousLinkClassName="page-link-previous"
          nextClassName="page-item-next"
          nextLinkClassName="page-link-next"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default StanderdList;
