import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { LogoHeader } from "../../../assets/imgs/Index";
import { FModal } from "../../../components";
import Button from "../../../components/Button";
import CModal from "../../../components/CModal";
import ECard from "../../../components/ECard";
import Input from "../../../components/Input";
import InputLabel from "../../../components/InputLabel";
import PCard from "../../../components/PCard";
import SModal from "../../../components/SModal";
import Title from "../../../components/Title";
import useText from "../../../helpers/customHooks/useText";
import environment from "../../../services/environment";
import { get, post, put, remove } from "../../../services/Request";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import Skeleton from "react-skeleton-loader";
import { plusLight } from "../../../assets/icons/Index";
import NewHeader from "../../../layouts/header/NewHeader";
const InnovativeFormIdeaTypes = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(0);
  const [values, handleChange] = useText();
  const [role, setRole] = useState("");
  const [message, setMessage] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [innovativeFormIdeaTypes, setInnovativeFormIdeaTypes] = useState([]);
  const [innovativeFormIdeaType, setInnovativeFormIdeaType] = useState("");

  const [ids, setIds] = useState([]);

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids.map((i) => i).includes(id);
  };

  useEffect(() => {
    getInnovativeFormIdeaTypes();
  }, []);
  const getInnovativeFormIdeaTypes = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getInnovativeFormIdeaType, config, (res) => {
      debugger;
      if (res.status == 403) {
        setMessage(
          { type: "error", message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        setInnovativeFormIdeaTypes(res.data.result.items);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const addInnovativeFormIdeaType = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      title: values.title,
      description: values.description,
    };

    post(environment.addInnovativeFormIdeaType, data, config, (res) => {
      if (res.status == 500) {
        setMessage(
          { type: "error", message: res.data?.error?.message },
          setOpenConfirmModal(
            true,
            setOpenEditModal(
              false,
              setIds([], setLoading(false, getInnovativeFormIdeaTypes()))
            )
          )
        );
      } else {
        setMessage(
          { type: "success", message: t("تم اضافة نوعية فكرة") },
          setOpenConfirmModal(
            true,
            setOpenEditModal(
              false,
              setIds([], setLoading(false, getInnovativeFormIdeaTypes()))
            )
          )
        );
      }
    });
  };

  const deleteInnovativeFormIdeaType = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(
      environment.deleteInnovativeFormIdeaType +
        "?id=" +
        innovativeFormIdeaType.id,
      config,
      (res) => {
        setMessage(
          { type: "success", message: t("تم حذف نوعية فكرة") },
          setOpenConfirmModal(
            true,
            setOpenDelete(
              false,
              setOpenEditModal(
                false,
                setLoading(false, getInnovativeFormIdeaTypes())
              )
            )
          )
        );
      }
    );
  };

  const editInnovativeFormIdeaType = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: innovativeFormIdeaType.id,
      title: values.title,
      description: values.description,
    };

    put(environment.editInnovativeFormIdeaType, data, config, (res) => {
      setMessage(
        { type: "success", message: t("تم تعديل نوعية فكرة") },
        setOpenConfirmModal(
          true,
          setOpenEditModal(
            false,
            setOpenEditModal(
              false,
              setLoading(false, getInnovativeFormIdeaTypes())
            )
          )
        )
      );
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <CModal
        confirmModal={() => deleteInnovativeFormIdeaType()}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={
          t("هل انت متاكد من حذف نوعية فكرة") +
          " " +
          innovativeFormIdeaType.title
        }
      />
      <Title
        title={t("النماذج الابداعية")}
        seconed={true}
        onClick={() => setInnovativeFormIdeaType("", setOpenEditModal(true))}
        subTitle={t("نوعية فكرة")}
        withoutButton={false}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton={t("اضافة نوعية فكرة")}
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969" }}
      />
      <div className="m-5 p-5 bg-white rounded-xl">
        {loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            {innovativeFormIdeaTypes.map(
              (getInnovativeFormIdeaTypes, index) => (
                <Skeleton
                  key={index}
                  animated={true}
                  width={"100%"}
                  height={"100px"}
                  style={{ marginTop: 10 }}
                  color={"#2B296969"}
                  count={1}
                />
              )
            )}
          </div>
        ) : (
          <div>
            {innovativeFormIdeaTypes.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {"لا يوجد نوعية فكرة"}
                </span>
              </div>
            ) : (
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3 mt-5">
                {" "}
                {innovativeFormIdeaTypes.map(
                  (innovativeFormIdeaTypes, index) => (
                    <PCard
                      openDelete={() =>
                        setInnovativeFormIdeaType(
                          innovativeFormIdeaType,
                          setOpenDelete(true)
                        )
                      }
                      openEdit={() =>
                        setInnovativeFormIdeaType(
                          innovativeFormIdeaType,
                          setOpenEditModal(true)
                        )
                      }
                      title={innovativeFormIdeaTypes.title}
                    />
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <FModal
        content={
          <div className="mt-5 bg-white rounded p-5 m-2 w-[100%]">
            <Formik
              initialValues={{
                title:
                  innovativeFormIdeaType.title == null
                    ? ""
                    : innovativeFormIdeaType.title,
                description:
                  innovativeFormIdeaType.description == null
                    ? ""
                    : innovativeFormIdeaType.description,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.title) {
                  errors.title = t("اسم نوعية فكرة مطلوب");
                }
                if (!values.description) {
                  errors.description = t("وصف نوعية فكرة مطلوب");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (innovativeFormIdeaType.title == null) {
                  addInnovativeFormIdeaType(values);
                } else {
                  editInnovativeFormIdeaType(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <InputLabel
                    style={{
                      borderColor: errors.title
                        ? "red"
                        : values.title != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    label={t("اسم نوعية فكرة")}
                    placeholder={t(
                      "modules.main.users.permissions.form.placeholderTitle"
                    )}
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.title && touched.title && errors.title}
                  </div>
                  <InputLabel
                    style={{
                      borderColor: errors.description
                        ? "red"
                        : values.description != ""
                        ? "#22bb33"
                        : "transparent",
                      resize: "none",
                    }}
                    rows={6}
                    type={"textarea"}
                    value={values.description}
                    onChange={handleChange}
                    name={"description"}
                    label={t("وصف نوعية فكرة")}
                    placeholder={t("ادخل وصف نوعية فكرة")}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>

                  <div className="text-center">
                    <Button
                      type={"submit"}
                      loading={loading}
                      withIcon={true}
                      icon={plusLight}
                      title={
                        innovativeFormIdeaType == ""
                          ? t("اضافة نوعية فكرة")
                          : t("تعديل نوعية فكرة")
                      }
                      typeColor={"dark"}
                      style={{ backgroundColor: "#00114E", width: 160 }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        }
        open={openEditModal}
        titleButton={
          innovativeFormIdeaType == ""
            ? t("اضافة نوعية فكرة")
            : t("تعديل نوعية فكرة")
        }
        widthButton={false}
        width={160}
        confirm={editInnovativeFormIdeaType}
        setOpen={() => setOpenEditModal(false)}
        headerTitle={
          innovativeFormIdeaType == ""
            ? t("اضافة نوعية فكرة جديد")
            : t("تعديل نوعية فكرة")
        }
      />
    </div>
  );
};

export default InnovativeFormIdeaTypes;
