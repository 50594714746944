import {Form, Formik} from "formik";

export const NewForm = ({
  children,
  initialValues,
  validate,
  onSubmit,
  innerRef,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      innerRef={innerRef}
    >
      {({ handleChange, values }) => <Form>{children}</Form>}
    </Formik>
  );
};

// const initialValues = {
//   content: "",
//   correctorDegrees: "",
//   agencyDegrees: "",
//   attachment: "",
// };

// const validate = (values) => {
//   const errors = {};
//   if (!content) {
//     errors.content = "هذا الحقل مطلوب";
//   }
//   if (!correctorDegrees) {
//     errors.correctorDegrees = "هذا الحقل مطلوب";
//   }
//   if (!agencyDegrees) {
//     errors.agencyDegrees = "هذا الحقل مطلوب";
//   }
//   if (!attachment) {
//     errors.attachment = "هذا الحقل مطلوب";
//   }

//   return errors;
// };

{
  /* <ErrorMessage className="text-red-700" name="content" component="div" />; */
}
