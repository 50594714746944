import React from "react";
import Skeleton from "react-skeleton-loader";

const TableSkeleton = (numofCols = 5, numOfRows = 4) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 rounded-2xl border-[2px] border-gray-200">
      <thead>
        <tr className="bg-[#e8e8f3]">
          {Array.from({ length: 5 }, (_, index) => (
            <th key={index} className="p-2.5">
              <Skeleton height={"30px"} width={"100%"} color={"#fefeff"} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 4 }, (_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: 5 }, (_, colIndex) => (
              <td key={colIndex} className="p-2.5 py-6">
                <Skeleton height={"20px"} width={"100%"} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeleton;
