import {useTranslation} from "react-i18next";
import NewHeader from "../../../layouts/header/NewHeader";

import {Title,} from "../../../components";
import {useState} from "react";
import {
  categoryIcon,
  GroupIcon,
  priorityIcon,
  programsIcon,
  settingsIcon,
} from "../../../assets/icons/Index";
import CategoriesList from "./CategoriesList";
import PrioritiesList from "./PrioritiesList";
import PrioritiesSlaList from "./PrioritiesSlaList";
import GroupsList from "./GroupsList";
import AppButton from "../../../components/buttons/appButton";
import {permissionsCheck} from "../../../helpers/utils";

const TicketSettings = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      icon: categoryIcon,
      title: t("modules.main.tickets.categories"),
      table: "categories",
      content: <CategoriesList open={open} setOpen={setOpen} />,
    },
    {
      icon: priorityIcon,
      title: t("modules.main.tickets.priorities"),
      table: "priorities",
      content: <PrioritiesList open={open} setOpen={setOpen} />,
    },
    {
      icon: priorityIcon,
      title: t("modules.main.tickets.slaPriorities"),
      table: "slaPriorities",
      content: <PrioritiesSlaList open={open} setOpen={setOpen} />,
    },
    {
      icon: GroupIcon,
      title: t("modules.main.tickets.groups"),
      table: "groups",
      content: <GroupsList open={open} setOpen={setOpen} />,
    },
  ];

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={settingsIcon}
        style={{ backgroundColor: "#2B2969" }}
        single={true}
        title={"اٍعدادات الاستفسارات"}
        withIcon={true}
        withoutButton={true}
      />
      <div className="w-full bg-white pb-5 my-5 flex flex-col">
        {activeTab !== 2 && (
          <div className="w-full flex justify-end h-[50px] px-5">
            {/* {permissionsCheck("Pages.TicketsCategory.Create") || permissionsCheck("Pages.Priority.Create") || permissionsCheck("Pages.Groups.Create") || permissionsCheck("Pages.SLA.Create") ?  */}
            <AppButton
              disabled={
                permissionsCheck("Pages.TicketsCategory.Create")
                  ? false
                  : permissionsCheck("Pages.Priority.Create")
                  ? false
                  : permissionsCheck("Pages.Groups.Create")
                  ? false
                  : permissionsCheck("Pages.SLA.Create")
                  ? false
                  : true
              }
              onClick={() => setOpen(true)}
            >
              {activeTab === 0
                ? t("modules.main.tickets.addCategory")
                : activeTab === 1
                ? t("modules.main.tickets.addPriority")
                : activeTab === 3
                ? t("modules.main.tickets.addGroup")
                : ""}
            </AppButton>
            {/* :null} */}
          </div>
        )}
        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  "mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] " +
                  (activeTab === index
                    ? "bg-emerald-500 text-white"
                    : "text-slate-400 bg-slate-50")
                }
                onClick={() => setActiveTab(index)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={"24px"}
                  height={"24px"}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>
                {activeTab === index &&
                  tab.content /*<NewTable columns={columns} data={categories}/>*/}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSettings;
