import Skeleton from "react-skeleton-loader";

const IndicatorItemSkeleton = () => {
  return (
    <div className="p-2 flex-grow md:flex-grow-0">
      <div className="border-[1px] h-full shadow border-gray-200 rounded-lg  p-4 pb-6">
        <div className="flex pt-8 flex-col h-full justify-between">
          <div className="mb-6">
            <h3 className="text-blue">
              <Skeleton width="100%"/>
            </h3>
            <p className="text-light text-sm pt-3 pb-5">
              <Skeleton width="100%" count={4}/>
            </p>
          </div>
          <Skeleton width="100%"/>
        </div>
      </div>
    </div>
  );
};

export default IndicatorItemSkeleton;
