import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";

export const useGetAnswersAnalysis = ({ currentPage, maxResult, agencyId }) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAnswersAnalysis } = useQuery({
    queryKey: ["GetAnswersAnalysis", currentPage, maxResult, agencyId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetAgencyAnswerByAgencyId,
        {
          AgencyId: agencyId,
          SkipCount: currentPage * maxResult,
          MaxResultCount: maxResult,
        },
        true,
        signal
      ),
    enabled: agencyId != null,
  });

  const agencyDetails = data?.result;
  const agencyAnswers = data?.result?.agencyAnswer;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / maxResult);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["GetAnswersAnalysis", currentPage + 1, maxResult, agencyId],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyAnswerByAgencyId, {
          AgencyId: agencyId,
          SkipCount: (currentPage + 1) * maxResult,
          MaxResultCount: maxResult,
        }),
      enabled: agencyId != null,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["GetAnswersAnalysis", currentPage - 1, maxResult, agencyId],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyAnswerByAgencyId, {
          AgencyId: agencyId,

          SkipCount: (currentPage - 1) * maxResult,
          MaxResultCount: maxResult,
        }),
      enabled: agencyId != null,
    });
  }

  return {
    agencyDetails,
    queryClient,
    agencyAnswers,
    totalCount,
    totalPages,
    data,
    isGettingAnswersAnalysis,
  };
};
