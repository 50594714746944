import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";

const useExcelExport = () => {
  const filetype =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";

  const downloadExcel = async (filename, jsonData) => {
    try {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      const data = new Blob([excelBuffer], { type: filetype });

      saveAs(data, `${filename}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  return downloadExcel;
};

export default useExcelExport;
