import DeleteIcon from "../../../../components/DeleteIcon";
import NewTable from "../../../../components/NewTable";
import {useRef, useState} from "react";
import environment from "../../../../services/environment";
import EditIcon from "../../../../components/EditIcon";
import {InputLabel} from "../../../../components";
import {axiosDeleteRequest, axiosPutRequest,} from "../../../../services/Request";
import {toast} from "react-toastify";
import {NewForm} from "../../../../components/form/NewForm";
import {ErrorMessage} from "formik";
import {permissionsCheck} from './../../../../helpers/utils'

function FQAClassificationsTable({
  getAllFQAClassifications,
  allFQAClassifications,
  loading,
}) {
  const [inputs, setInputs] = useState({
    title: "",
  });

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = "هذا الحقل مطلوب";
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const columns = [
    {
      name: "اسم التصنيف",
      selector: (row) => row.title,
      header: "title",
      accessorKey: "title",
      footer: (props) => props.column.title,
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck("Pages.FaqSettings.Edit") && (
            <div className="flex gap-2">
              <EditIcon
                initialValuesFunction={() =>
                  setInputs({
                    title: row.title,
                  })
                }
                loading={editLoading}
                editFunction={handleFormik}
                type={"submit"}
                editedName={row.title}
                editModalContent={
                  <NewForm
                    initialValues={initialValues}
                    validate={validate}
                    innerRef={formRef}
                    onSubmit={(e) => handleUpdateFQAClassification(row)}
                  >
                    <div className="flex flex-col gap-3">
                      <InputLabel
                        label={"اسم التصنيف"}
                        name={"title"}
                        type={"text"}
                        value={inputs.title}
                        onChange={handleChangeInputs}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="title"
                        component="div"
                      />
                    </div>
                  </NewForm>
                }
              />
              {permissionsCheck("Pages.FaqSettings.Delete") ?
              <DeleteIcon
                loading={deleteLoading}
                id={row.id}
                deleteFunction={() => handleDeleteFQAClassification(row.id)}
                deletedName={row.title}
              />
              :null}
            </div>
          )
        );
      },
    },
  ];

  // handleUpdateFQAClassification
  const handleUpdateFQAClassification = async (row) => {
    const body = {
      id: row.id,
      title: inputs.title,
    };

    setEditLoading(true);
    const res = await axiosPutRequest(
      environment.updateFQAClassification,
      body
    );
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث التصنيف بنجاح`);
      getAllFQAClassifications(environment.getAllClassificationsFaq);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  // handleDeleteFQAClassification
  const handleDeleteFQAClassification = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteFQAClassification, {
      Id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getAllFQAClassifications(environment.getAllClassificationsFaq);
    }
  };

  return (
    <div className="">
      {permissionsCheck("Pages.FaqSettings.GetAll") ?
      <NewTable
        loading={loading}
        columns={columns}
        data={allFQAClassifications?.result?.items || []}
        pagination={false}
      />
      :<div className="text-[#bb2124] text-xl text-center flex justify-center items-center">{"ليس لديك صلاحية لهذا"}</div>}
    </div>
  );
}

export default FQAClassificationsTable;
