import { useState } from "react";
import DownloadButton from "../../components/DownloadButton";
import NewTable from "../../components/NewTable";
import useFileDownloader from "../../hooks/useFileDownloader";
import { axiosPostRequest } from "../../services/Request";
import environment from "../../services/environment";
import { arabicFormatForDateTime } from "../../helpers/utils";
import useFileDownloaderAsZip from "../../hooks/useDownloadZipFile";
import AppModal from "../../components/Models/appModal";
import NewTooltip from "../../components/NewTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function AgencyAttachments({
  loading,
  attachedFiles,
  currentPage,
  setCurrentPage,
  totalPages,
  agencyName,
}) {
  const [openShowModal, setOpenShowModal] = useState(false);
  // const [agencyNote, setAgencyNote] = useState("");
  const { downloadPdf } = useFileDownloader();

  const { downloadFileAsZip } = useFileDownloaderAsZip();

  const handleDownload = async (filePath, fileName) => {
    const api = `${process.env.REACT_APP_URL}/Reports/DawnloadAgencyOldFileByName`;
    const params = { fileName: filePath };

    await downloadPdf(api, params, fileName, null);
  };

  const columns = [
    {
      name: "اسم الملف",
      selector: (row) => row.fileName,
      header: "اسم الملف",
      accessorKey: "fileName",
      sortable: true,
    },
    {
      name: "مستند الاٍثبات",
      selector: (row) => row.standardProof,
      header: "مستند الاٍثبات",
      accessorKey: "standardProof",
      sortable: true,
      center: true,
      cell: (row) => <div className="text-center">{row.standardProof}</div>,
    },
    {
      name: "تاريخ رفع الملف",
      selector: (row) => row.fileUploadDate,
      header: "تاريخ رفع الملف",
      accessorKey: "fileUploadDate",
      sortable: true,
      center: true,
      cell: (row) => <div className="text-center">{row.fileUploadDate}</div>,
    },
    {
      name: "نوع الملف",
      selector: (row) => row.fromPastYear,
      header: "نوع الملف",
      accessorKey: "fromPastYear",
      sortable: true,
      center: true,
      cell: (row) => (row.fromPastYear ? "ملف سابق" : "ملف جديد"),
    },
    // {
    //   name: "ملاحظات الجهة",
    //   selector: (row) => row.note,
    //   header: "نوع الملف",
    //   accessorKey: "note",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (
    //     <NewTooltip content={"الملاحظات"}>
    //       <FontAwesomeIcon
    //         icon={faEye}
    //         className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
    //         onClick={() => {
    //           setOpenShowModal(true);
    //           setAgencyNote(row.note);
    //         }}
    //       />
    //     </NewTooltip>
    //   ),
    // },
    {
      name: "تحميل الملف",
      selector: (row) => row.name,
      header: "اسم الملف",
      accessorKey: "name",
      sortable: true,
      center: true,
      cell: (row) => (
        <DownloadButton
          onClick={() => handleDownload(row.filePath, row.fileName)}
        >
          تحميل الملف
        </DownloadButton>
      ),
    },
    {
      name: "تاريخ تحميل الملف",
      selector: (row) => row.fileDownLoadDate,
      header: "تاريخ تحميل الملف",
      accessorKey: "fileDownLoadDate",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="text-center">
          {row.fileDownLoadDate ? row.fileDownLoadDate : "لم يتم التحميل"}
        </div>
      ),
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const handleDownloadAllFilesAsZip = async () => {
    const filesIds = attachedFiles.map((file) => file.fileId);
    await downloadFileAsZip(`ملفات جهة ${agencyName}`, filesIds);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="text-xl mb-3">المستندات المرفقة بواسطة الجهة</h2>

        <div>
          <DownloadButton onClick={() => handleDownloadAllFilesAsZip()}>
            تحميل جميع الملفات ( ZIP File )
          </DownloadButton>
        </div>
      </div>
      <NewTable
        // withSearch
        // withExcel
        // onExportExcel={() =>
        //   downloadExcel(`الملفات السابقة_${agencyId}`, dataForExcel)
        // }
        // onSearch={handleFilterData}
        loading={loading}
        columns={columns}
        data={attachedFiles || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />

      {/* <AppModal
        open={openShowModal}
        setOpen={setOpenShowModal}
        headerTitle={"ملاحظات الجهة"}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2">{agencyNote}</div>
      </AppModal> */}
    </>
  );
}

export default AgencyAttachments;
