import { useTranslation } from 'react-i18next';
import './Component.css';
import { useRef } from 'react';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const InputLabel = ({
  type,
  placeholder,
  onChange,
  style,
  label,
  id,
  name,
  value,
  items,
  isCheckedCheckbox,
  handleCheckbox,
  handleRadio,
  isCheckedRadio,
  rows,
  message,
  defaultValue,
  max,
  min,
  disabled,
  isImage,
  containerStyle,
  multiple,
  onKeyPress,
  required,
  pattern,
  title,
  onBlur,
  onFocus,
  accept,
  column,
  withoutLabel,
  fileInputWidth = "90%",
  checkboxWidth = "w-[100%]",
  fileLabel,
  isRequired = true,
  fileName,
  checkboxStyle = "flex flex-col pt-5 items-center justify-between",
}) => {
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };
  return (
    <div
      className={
        type == "checkbox" && column
          ? checkboxStyle
          : type == "checkbox"
          ? "flex flex-row pt-5 items-center justify-between"
          : "flex flex-col"
      }
    >
      {withoutLabel ? null : (
        <label className="label_input text-lg text-[#292069]">
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}
      {type == "radio" ? (
        <div
          className={
            column ? "flex flex-col items-center" : "flex flex-row mt-3"
          }
        >
          {items.map((item, index) => (
            <div className="flex flex-row items-center">
              <Input
                defaultValue={defaultValue}
                pattern={pattern}
                title={title}
                required={required}
                onKeyPress={onKeyPress}
                style={{ marginTop: 0 }}
                type="radio"
                checked={isCheckedRadio(item.id)}
                onChange={handleRadio}
                id={item.id}
                name={item.name}
                value={item.id}
              />
              <span
                for="specifyColor"
                className="pr-5 pl-5 pt-5 text-base text-[#7A7A7A]"
              >
                {item.label}
              </span>
            </div>
          ))}
        </div>
      ) : type == "checkbox" ? (
        <div
          className={
            column
              ? "flex flex-col w-[100%] mt-3 " + checkboxWidth
              : "flex flex-row w-[100%] mt-3 grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 ms:grid-cols-2 " +
                checkboxWidth
          }
        >
          {items.length == 0 ? (
            <div className="flex justify-center items-center text-base text-[#2B2969]">
              {message}
            </div>
          ) : (
            <>
              {items.map((item, index) => (
                <div className="flex flex-row mt-3">
                  <Input
                    disabled={disabled}
                    className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] h-[24px] my-1"
                    defaultValue={defaultValue}
                    pattern={pattern}
                    title={title}
                    required={required}
                    onKeyPress={onKeyPress}
                    style={{ marginTop: 0 }}
                    type="checkbox"
                    checked={isCheckedCheckbox(item.id)}
                    onChange={handleCheckbox}
                    id={item.id}
                    name={item.label}
                    value={item.id}
                  />
                  <span for="specifyColor" className="text-[16px] mb-2">
                    {item.label}
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
      ) : type == "textarea" ? (
        <textarea
          defaultValue={defaultValue}
          rows={rows}
          className="input_simple"
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          style={style}
          onChange={onChange}
        />
      ) : (
        <div className="text-center">
          {type == "file" ? (
            <>
              <div
                className="flex w-full items-center justify-center"
                onClick={handleClick}
              >
                <label className="dark:hover:bg-bray-800 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600 mb-2">
                  <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    <svg
                      className=" h-8 w-8 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLineJoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-3  text-gray-900 dark:text-gray-400">
                      <span className="font-semibold">{fileName}</span>
                    </p>
                    <p className="mb-3  text-gray-900 dark:text-gray-400">
                      <span className="font-semibold">
                        اضغط لاختيار الملفات
                      </span>
                    </p>
                    <p className="text-sm max-w-[90%] text-gray-500 dark:text-gray-400">
                      {fileLabel}
                    </p>
                  </div>
                </label>
              </div>

              <Input
                inputRef={inputRef}
                accept={accept}
                defaultValue={defaultValue}
                pattern={pattern}
                title={title}
                required={required}
                onKeyPress={onKeyPress}
                multiple={multiple}
                id={id}
                className={
                  type == "file" ? "input_file w-[70%]" : "input_simple w-[70%]"
                }
                name={name}
                value={value}
                type={type}
                placeholder={placeholder}
                style={style}
                onChange={onChange}
              />
            </>
          ) : (
            // </div>
            <Input
              onFocus={onFocus}
              onBlur={onBlur}
              defaultValue={defaultValue}
              pattern={pattern}
              title={title}
              required={required}
              onKeyPress={onKeyPress}
              multiple={multiple}
              containerStyle={containerStyle}
              disabled={disabled}
              id={id}
              min={min}
              max={max}
              className={
                type == "file"
                  ? "input_file w-[70%]"
                  : type == "date" || type == "time"
                  ? "input_simple_date_time w-[70%] h-[46px]"
                  : "input_simple w-[70%] h-[46px]"
              }
              name={name}
              value={value}
              type={type}
              placeholder={placeholder}
              style={style}
              onChange={onChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InputLabel;
