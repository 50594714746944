import {IdentitiesProvider} from "../../../../contexts/IdentitiesContext"
import IdentitiesList from "./IdentitiesList"

const Identities=({permission})=>{
  return(
    <IdentitiesProvider>
      <IdentitiesList permission={permission}/>
    </IdentitiesProvider>
  )
}

export default Identities
