const environment = {
  login: "TokenAuth/Authenticate",
  getAllAgency: "services/app/Agency/GetAll",
  addAgency: "services/app/Agency/Create",
  editAgency: "services/app/Agency/Update",
  getNotAssignedProgramAgenciesByStandardId:
    "services/app/Agency/GetNotAssignedProgramAgenciesByStandardId",
  getAgencyProgram: "services/app/Agency/GetAgenciesByStandardId",
  getAllAgencyWithoutPagination:
    "services/app/Agency/GetAllAgenciesWithoutPagination",
  deleteAgency: "services/app/Agency/Delete",
  getAgency: "services/app/Agency/Get",
  RaqamiAuthenticate: "TokenAuth/RaqamiAuthenticate",
  changeStatusForAgency: "services/app/Agency/SetAgencyConfidentialityStatus",
  getAgenciesByStandardId: "services/app/Agency/GetAgenciesByStandardId",
  getAllStandardsAgency: "services/app/Agency/GetStandards",
  getAllRoles: "services/app/Role/GetAll",
  addRole: "services/app/Role/Create",
  deleteRole: "services/app/Role/Delete",
  editRole: "services/app/Role/Update",
  getRole: "services/app/Role/Get",
  getAllTypes: "services/app/StandardType/GetAll",
  addType: "services/app/StandardType/Create",
  deleteType: "services/app/StandardType/Delete",
  editType: "services/app/StandardType/Update",
  getAllUsers: "services/app/User/GetAll",
  getAllUsersWithoutPagination: "services/app/GetForSelection/Users",

  getAllUsersExceptAgencyUsers:
    "services/app/User/GetAllUsersExceptAgencyUsers",
  deleteUser: "services/app/User/Delete",
  getAllExpertLookUp: "services/app/User/GetAllExpertLookUp",
  getAllPocOfficerLookUp: "services/app/User/GetAllPocOfficerLookUp",
  getAllReviewersLookUp: "services/app/User/GetAllReviewersLookUp",
  ActiveUser: "services/app/User/Activate",
  getUser: "services/app/User/Get",
  UnActiveUser: "services/app/User/DeActivate",
  addUser: "services/app/User/Create",
  editUser: "services/app/User/Update",
  getSurveys: "services/app/Survey/GetAll",
  addSurvey: "services/app/Survey/Create",
  getCurrentSurveyStage: "services/app/Survey/GetCurrentSurveyStage",
  getCurrentSurvey: "services/app/Survey/GetCurrentSurvey",
  editSurvey: "services/app/Survey/Update",
  deleteSurvey: "services/app/Survey/Delete",
  getSurvey: "services/app/Survey/Get",
  copySurvey: "services/app/Survey/Copy",
  updateSurveySettings: "services/app/Survey/UpdateSurveySettings",
  UpdateSurveyCalculatingAgencyFinalDegreeStrategy:
    "services/app/Survey/UpdateSurveyCalculatingAgencyFinalDegreeStrategy",
  getInterviewers: "services/app/Interlocutor/GetAll",
  addInterviewer: "services/app/Interlocutor/Create",
  editInterviewer: "services/app/Interlocutor/Update",
  deleteInterviewer: "services/app/Interlocutor/Delete",
  getEndoscopes: "services/app/Perspective/GetAll",
  addEndoscope: "services/app/Perspective/Create",
  editEndoscope: "services/app/Perspective/Update",
  deleteEndoscope: "services/app/Perspective/Delete",
  getDepartments: "services/app/Department/GetAll",
  addDepartment: "services/app/Department/Create",
  editDepartment: "services/app/Department/Update",
  deleteDepartment: "services/app/Department/Delete",
  updateStdReqAndSubStdReqClassification:
    "services/app/Standard/UpdateStdReqAndSubStdReqClassification",
  getStandards: "services/app/Standard/GetAll",
  getStandardList: "services/app/Standard/GetAllList",
  getQiyas23Standards: "services/app/GetForSelection/GetQiyas23Standards",
  publishQiyas23AttachmentsForAgency:
    "services/app/GetForSelection/PublishQiyas23AttachmentsForAgency",
  getAllStandardAgencies: "services/app/Standard/GetAllStandardAgencies",
  getStandardsAgenciesInquiries:
    "services/app/Standard/GetStandardAgencyInquries",
  getStandardSurveySummarizedDegrees:
    "services/app/Standard/GetStandardSurveySummarizedDegrees",
  getStandard: "services/app/Standard/Get",
  updateStandard: "services/app/Standard/Update",
  updateStandardProofRequirementRelation:
    "services/app/Standard/UpdateStandardProofRequirementRelation",
  getStandardDegrees: "services/app/Standard/GetStandardSurveyDegrees",
  addStandard: "services/app/Standard/Create",
  getStandardTemplate: "services/app/Standard/GetStandardSurvey",
  editStandard: "services/app/Standard/Update",
  assignStandardToExpert: "services/app/Standard/AssignStandardToExpert",
  assignStandardToPocOfficer:
    "services/app/Standard/AssignStandardToPocOfficier",
  assignStandardToReviewer: "services/app/Standard/AssignStandardToReviewer",
  deleteStandard: "services/app/Standard/Delete",
  getStandardExpertAnswer: "services/app/Standard/GetStandardExpertAnswer",
  getStandardAgencyAnswer: "services/app/Standard/GetStandardAgencyAnswer",
  getStandardWithRequirement:
    "services/app/Standard/GetStandardWithRequirement",
  getSuggestedDegree: "services/app/SurveyAnswer/GetCommitmentLevel",
  getDegree: "services/app/SurveyAnswer/GetDegree",
  getAgencyPreviuosAnswer:
    "services/app/SurveyAnswer/GetFirstStageAgencyAnswer",
  uploadChunks: "services/app/Attachment/UploadChunks",
  uploadComplete: "services/app/Attachment/UploadComplete",
  getAllPermissions: "services/app/Permission/GetAllPermissions",
  attachmentFile: "services/app/Attachment/AttachmentFile",
  downloadFileAsZIP: "services/app/Attachment/DownloadFilesAsZip",
  getNotifications: "services/app/Notification/GetUserNotifications",
  deleteNotification: "services/app/Notification/DeleteNotification",
  getActivityLogs: "services/app/AuditLog/GetAuditLogs",
  getAllPrograms: "services/app/Program/GetAll",
  getCurrentProgramPhase: "services/app/Program/GetCurrentProgramPhase",
  // "assignStandardToExpert":"services/app/Program/AssignStandardToExpert",
  addProgramTerm: "services/app/Program/CreateProgramTerm",
  deleteProgramExpert: "services/app/Program/RemoveProgramExpert",
  deleteProgramReviewer: "services/app/Program/RemoveProgramReviewer",
  deleteProgramAgency: "services/app/Program/RemoveProgramAgency",
  deleteProgramStandardExpert:
    "services/app/Program/RemoveProgramStandardFromExpert",
  assignAgencyToReviewer: "services/app/Program/AssignAgencyToReviewer",
  getProgram: "services/app/Program/Get",
  addProgram: "services/app/Survey/PublishSurvey",
  getAllProgramStandardAgencies:
    "services/app/Program/GetAllProgramStandardAgencies",
  getAllProgramStandards: "services/app/Program/GetAllProgramStandards",
  getAllNotAssignedProgramStandards:
    "services/app/Program/GetAllNotAssignedProgramStandards",
  getAllNotAssignedProgramAgencies:
    "services/app/Program/GetNotAssignedProgramAgencies",
  getProgramReviewersByExpertId:
    "services/app/Program/GetProgramReviewersByExpertId",
  getProgramStandardAgenciesByExpertId:
    "services/app/Program/GetProgramStandardAgenciesByExpertId",
  getProgramStandardDetails: "services/app/Program/GetProgramStandardDetails",
  assignAgencyToExpert: "services/app/Program/AssignAgencyToExpert",
  getAllProgramAgencies: "services/app/Program/GetAllProgramAgencies",
  getProgramStandardsByExpertId:
    "services/app/Program/GetProgramStandardsByExpertId",
  addProgramReviewer: "services/app/Program/CreateProgramReviewer",
  assignUsersToProgram: "services/app/Program/AssignUsersToProgram",
  getReviewersByExpert: "services/app/Program/GetReviewersByExpertId",
  getReviewersByExpertProgram:
    "services/app/Program/GetProgramReviewersByProgramExpertId",
  addProgramExperts: "services/app/Program/CreateProgramExpert",
  getProgramExperts: "services/app/Program/GetProgramExperts",
  getAllProgramExperts: "services/app/Program/GetAllProgramExperts",
  fillExpertRelatedData: "services/app/Program/FillExpertRelatedData",
  fillRelevantData: "services/app/Program/FillRelevantData",
  getExpertDetail: "services/app/Program/GetExpertDetail",
  getSurveyClassifications:
    "services/app/StandardRequirementClassification/GetBySurveyId",
  getAllClassifications:
    "services/app/StandardRequirementClassification/GetAll",
  deleteClassification: "services/app/StandardRequirementClassification/Delete",
  updateClassification: "services/app/StandardRequirementClassification/Update",
  getSurveyDegree: "services/app/SummarizedDegree/GetBySurveyId",
  createNoteTemplate: "services/app/NoteTemplate/Create",
  addNoteTemplate: "services/app/NoteSurvey/Create",
  getAllNoteTemplate: "services/app/NoteTemplate/GetAll",
  deleteNoteTemplate: "services/app/NoteTemplate/DeleteById",
  getAllStandardsProgram: "services/app/Program/GetStandards",
  getCurrentProgram: "services/app/Program/GetCurrentProgramId",
  getAllEntitiesProgram: "services/app/Program/GetAgencies",
  updateProgramSettings: "services/app/Program/UpdateProgramSettings",
  getProgramSettings: "services/app/Program/GetProgramSettings",
  getProgramPrespectiveAndInterlocator:
    "services/app/Program/GetProgramPerpsectivesInterlocators",
  addProgramAnswer: "services/app/SurveyAnswer/Create",
  addAgencyAnswer: "services/app/SurveyAnswer/CreateAgencyAnswer",
  addExpertAnswer: "services/app/SurveyAnswer/CreateExpertAnswer",
  addExpertManagerAnswer: "services/app/SurveyAnswer/CreateExpertManagerAnswer",
  addReviewerAnswer: "services/app/SurveyAnswer/CreateReviewerAnswer",
  addPocOfficerAnswer: "services/app/SurveyAnswer/CreatePOCOfficerAnswer",
  getAgencyAnswer: "services/app/SurveyAnswer/GetAgencyAnswer",
  prepareStandardForAnswer: "services/app/Standard/PrepareStandardForAnswer",
  getReviewerAnswer: "services/app/SurveyAnswer/GetReviwerAnswer",
  getPOCOfficerAnswer: "services/app/SurveyAnswer/GetPOCOfficerAnswer",
  getExpertAnswer: "services/app/SurveyAnswer/GetExpertAnswer",
  getExpertManagerAnswer: "services/app/SurveyAnswer/GetExpertManagerAnswer",
  addProgramAnswerReviewer:
    "services/app/SurveyAnswer/createProgramReviewerAnswer",
  getProgramAnswerReviewer:
    "services/app/SurveyAnswer/getProgramReviewerAnswer",
  addProgramAnswerExpert: "services/app/SurveyAnswer/createProgramExpertAnswer",
  getProgramAnswerExpert: "services/app/SurveyAnswer/getProgramExpertAnswer",
  getProgramAnswerAgency:
    "services/app/SurveyAnswer/GetSurveyAnswerByStandardId",
  downloadFile: "services/app/Attachment/DownlaodAttachmentFile",
  getAttachment: "services/app/Attachment/Get",
  RaqamiAuthenticate: "TokenAuth/RaqamiAuthenticate",
  getStandardRequirementClassification:
    "services/app/StandardRequirementClassification/Get",
  createStandardRequirementClassification:
    "services/app/StandardRequirementClassification/Create",
  users: "users",
  getAllClassificationsFaq: "services/app/FqaClassifications/GetAll",
  createFQAClassification: "services/app/FqaClassifications/Create",
  updateFQAClassification: "services/app/FqaClassifications/Update",
  deleteFQAClassification: "services/app/FqaClassifications/Delete",
  getAllFaq: "services/app/FrequentlyQuestionedAnswers/GetAllList",
  createFaq: "services/app/FrequentlyQuestionedAnswers/Create",
  updateFaq: "services/app/FrequentlyQuestionedAnswers/Update",
  deleteFaq: "services/app/FrequentlyQuestionedAnswers/Delete",
  updateRateFaq: "services/app/FrequentlyQuestionedAnswers/UpdateRate",
  createStandardInquiry: "services/app/Standard/CreateStandardInquiry",
  getStandardInquiryList: "services/app/Standard/GetStandardInquiryList",
  createInquiryComment: "services/app/Standard/CreateInquiryComment",
  updateInquiryStatus: "services/app/Standard/UpdateInquiryStatus",
  getInbox: "services/app/Integration/GetInbox",
  getSent: "services/app/Integration/GetSent",
  getDrafts: "services/app/Integration/GetDrafts",
  getAllMailCount: "services/app/Integration/GetAllCount",
  sendMail: "services/app/Integration/SendMail",
  saveDraft: "services/app/Integration/SaveDraft",
  makeMailRead: "services/app/Integration/MakeMailRead",
  makeMailUnRead: "services/app/Integration/MakeMailUnRead",
  deleteMail: "services/app/Integration/DeleteMail",
  deleteDraft: "services/app/Integration/DeleteDraft",
  getMailDetails: "services/app/Integration/GetMailDetails",
  createMeetingRequest: "services/app/MeetingRequest/Create",
  updateMeetingRequest: "services/app/MeetingRequest/Update",
  deleteMeetingRequest: "services/app/MeetingRequest/Delete",
  getAllMeetingRequest: "services/app/MeetingRequest/GetAll",
  createOnlineMeeting: "services/app/OnlineMeeting/Create",
  updateOnlineMeeting: "services/app/OnlineMeeting/Update",
  deleteOnlineMeeting: "services/app/OnlineMeeting/Delete",
  getAllOnlineMeeting: "services/app/OnlineMeeting/GetAll",
  getAllAvailableMeetingRoom:
    "services/app/OnlineMeeting/GetAllAvailableMeetingRoom",
  reserveAvailableRoomToAgency:
    "services/app/OnlineMeeting/ReserveAvailableRoomToAgency",
  createFieldVisit: "services/app/FieldVisit/Create",
  updateFieldVisit: "services/app/FieldVisit/Update",
  deleteFieldVisit: "services/app/FieldVisit/Delete",
  getAllFieldVisit: "services/app/FieldVisit/GetAll",
  getAllAvailableFieldVisits: "services/app/FieldVisit/GetAllAvailableMeeting",
  reserveAvailableSlotToAgency:
    "services/app/FieldVisit/ReserveAvailableSlotToAgency",
  updateMeetingRequestStatus:
    "services/app/MeetingRequest/AcceptOrRejectRequestFromAdminSide",
  createTicket: "services/app/Ticket/Create",
  deleteTicket: "services/app/Ticket/Delete",
  getAllTickets: "services/app/Ticket/GetAll",
  createCategory: "services/app/Category/Create",
  createAgencyCategory: "services/app/AgencyCategory/Create",
  updateCategory: "services/app/AgencyCategory/Update",
  deleteCategory: "services/app/AgencyCategory/Delete",
  getCategoriesForFilter: "services/app/Category/GetAll",
  getAllCategories: "services/app/AgencyCategory/GetAll",
  createPriority: "services/app/Priority/Create",
  updatePriority: "services/app/Priority/Update",
  deletePriority: "services/app/Priority/Delete",
  getAllPriorities: "services/app/Priority/GetAll",
  getTicketById: "services/app/Ticket/GetById",
  updateTicketStatus: "services/app/Ticket/UpdateTicketStatus",
  CloseTicketStatus: "services/app/Ticket/CloseTicketStatus",
  updateTicket: "services/app/Ticket/Update",
  replyOnTicket: "services/app/TicketHistory/ReplyOnTicket",
  createGroup: "services/app/Group/Create",
  updateGroup: "services/app/Group/Update",
  deleteGroup: "services/app/Group/Delete",
  getAllGroups: "services/app/Group/GetAll",
  saveSLAPoliciy: "services/app/SLAPoliciy/UpdateList",
  getAllSLAPolicies: "services/app/SLAPoliciy/GetAll",
  getInnovativeFormIdeaType: "services/app/InnovativeFormIdeaType/GetAll",
  addInnovativeFormIdeaType: "services/app/InnovativeFormIdeaType/Create",
  deleteInnovativeFormIdeaType: "services/app/InnovativeFormIdeaType/Delete",
  editInnovativeFormIdeaType: "services/app/InnovativeFormIdeaType/Update",
  getInnovativeFormIdeaMaturity:
    "services/app/InnovativeFormIdeaMaturity/GetAll",
  addnnovativeFormIdeaMaturity:
    "services/app/InnovativeFormIdeaMaturity/Create",
  deletennovativeFormIdeaMaturity:
    "services/app/InnovativeFormIdeaMaturity/Delete",
  editnnovativeFormIdeaMaturity:
    "services/app/InnovativeFormIdeaMaturity/Update",
  getAllStandard: "services/app/Standard/GetAll",
  getAllStandardList: "services/app/Standard/GetAllList",
  getStandardForAnswer: "services/app/Standard/GetStandardForAnswer",
  getStandardForEdit: "services/app/Standard/GetStandardForEdit",

  getStandardsLookUp: "services/app/Standard/GetAllLookUp",
  createInnovativeFormAgencyAnswer:
    "services/app/InnovativeFormAgencyAnswer/Create",
  getInnovativeFormAgencyAnswerGetAll:
    "services/app/InnovativeFormAgencyAnswer/GetAll",

  getAllInnovativeFormAgencyAnswer:
    "services/app/InnovativeFormAgencyAnswer/GetAllInnovativeForms",

  getInnovativeFormAgencyAnswer: "services/app/InnovativeFormAgencyAnswer/Get",
  innovativeFormAgencyAnswerCorrectionCreate:
    "services/app/innovativeFormAgencyAnswerCorrection/Create",
  getIndicatorDetails: "services/app/Indicator/Get",
  getAllIndicators: "services/app/Indicator/GetAll",
  createIndicator: "services/app/Indicator/Create",
  deleteIndicator: "services/app/Indicator/DeleteById",
  updateIndicator: "services/app/Indicator/Update",
  innovativeFormAgencyAnswerCorrectorCreate:
    "services/app/innovativeFormAgencyAnswerCorrector/Create",
  innovativeFormAgencyAnswerCorrectors:
    "services/app/innovativeFormAgencyAnswerCorrector/GetAll",
  addInquiriesForStandard: "services/app/Ticket/CreateTicketForStandard",
  addCommentForStandard: "services/app/TicketHistory/CreateTicketForStandard",
  listInquiriesForStandard: "services/app/Ticket/GetAllTicketsByStandardId",
  getCommitmentLevels:
    "services/app/Standard/GetStandardSurveyCommitmentLevels",
  getSurveyStagesBySuveyId: "services/app/Survey/GetSurveyStagesBySuveyId",
  updateSurveyStage: "services/app/Survey/UpdateSurveyStage",
  addVisitTeam: "services/app/VisitTeams/Create",
  getVisitTeam: "services/app/VisitTeams/GetAll",
  addVisitSlot: "services/app/VisitSlots/Create",
  deleteVisitSlot: "services/app/VisitSlots/DeleteById",
  getVisitSlot: "services/app/VisitSlots/GetAll",
  reserveSlot: "services/app/VisitSlots/ReserveSlot",
  getAllNonReservedVisitSlotsBySpecificDate:
    "services/app/VisitSlots/GetAllNonReservedVisitSlotsBySpecificDate",
  createSurveyStage: "services/app/Survey/CreateSurveyStage",
  extendAgencyAnswerStage: "services/app/Survey/ExtendAgencyAnswerStage",
  getPerspectiveList: "services/app/Perspective/GetAll",
  getPerspective: "services/app/Perspective/Get",
  getAllStandardVisitSlot:
    "services/app/VisitSlots/GetAllStandardsRelatedToVisists",
  addVisitAnswer: "services/app/VisitSlots/CreateVisitsAnswer",
  getAllVisitsAnswerAgency:
    "services/app/SurveyAnswer/GetAllVisistsAnswersByAgencyId",
  createNotice: "services/app/Notice/Create",
  getAllNotices: "services/app/Notice/GetAll",
  getCommitmentLevelBySurveyId: "services/app/CommitmentLevel/GetBySurveyId",
  addCommitmentLevel: "services/app/CommitmentLevel/Create",
  updateCommitmentLevel: "services/app/CommitmentLevel/Update",
  deleteCommitmentLevel: "services/app/CommitmentLevel/Delete",
  SetSurveyTerms: "services/app/Survey/SetSurveyTerms",
  getStandardDelegates: "services/app/Standard/GetStandardDelegates",
  getStandardDelegatesRewrite:
    "services/app/Standard/GetStandardDelegatesRewrite",
  getAgencyUsers: "services/app/Agency/GetAgencyUsers",
  createStandardDelegates: "services/app/Standard/CreateStandardDelegates",
  getAgencyStatistics: "services/app/AgencyStatistics/GetAll",
  getAllAgencyStatstics: "services/app/AgencyStatistics/GetAllAgencyStatstics",
  getAgencyPerspectiveStatus:
    "services/app/AgencyStatistics/GetAgencyPerspectiveStatus",
  getAgencyInterlocutorStatus:
    "services/app/AgencyStatistics/GetAgencyInterlocutorStatus",
  getAnsweredStandards: "services/app/AgencyStatistics/GetAnsweredStandards",
  getAnsweredStandardForAgency:
    "services/app/AgencyStatistics/GetAnsweredStandardForAgency",
  getNotAnsweredStandards:
    "services/app/AgencyStatistics/GetNotAnsweredStandards",
  getNotAnsweredStandardForAgency:
    "services/app/AgencyStatistics/GetNotAnsweredStandardForAgency",
  UpdateSurveyInnovationFormConfiguration:
    "services/app/Survey/UpdateSurveyInnovationFormConfiguration",
  createNotice: "services/app/Notice/Create",
  getAllNotices: "services/app/Notice/GetAll",
  getAllUnreadNoticeCount: "services/app/Notice/GetAllUnreadNoticeCount",
  markAllNoticeAsRead: "services/app/Notice/MarkAllAsRead",
  createNotification: "services/app/Notification/Create",
  getAllNotifications: "services/app/Notification/GetAllNotifications",
  getHomeNotifications: "services/app/HomeNotification/GetAllNotifications",
  getUserNotifications: "services/app/Notification/GetUserNotifications",
  setAllNotificationsAsRead:
    "services/app/Notification/SetAllNotificationsAsRead",
  // getAllRegion: "services/app/Region/GetAll",
  setNotificationAsRead: "services/app/Notification/SetNotificationAsRead",
  // getAllRegion: "services/app/Region/GetAll",
  getAllRegion: "services/app/Region/GetAllAgencyRegion",
  // getAllAgencySectors: "services/app/AgencySector/GetAll",
  getAllAgencySectors: "services/app/AgencySector/GetAllAgencySector",
  createAgencySector: "services/app/AgencySector/Create",
  updateAgencySector: "services/app/AgencySector/Update",
  deleteAgencySector: "services/app/AgencySector/Delete",
  // getAllAgencyClassification: "services/app/AgencyClassification/GetAll",
  getAllAgencyClassification:
    "services/app/AgencyClassification/GetAllAgencyClassification",
  GetAllAgencyCategory: "services/app/AgencyCategory/GetAllAgencyCategory",

  updateExtraFileds: "services/app/Agency/UpdateExtraFileds",
  getAgency: "services/app/Agency/getAgencyById",
  getNotAnsweredAgencyStandards:
    "services/app/AgencyStatistics/GetNotAnsweredStandards",
  submitAllAgencyAnswers: "services/app/SurveyAnswer/SubmitAllAgencyAnswers",
  createAgencyClassification: "services/app/AgencyClassification/Create",
  updateAgencyClassification: "services/app/AgencyClassification/Update",
  deleteAgencyClassification: "services/app/AgencyClassification/Delete",
  updateRegions: "services/app/Region/Update",
  deleteRegions: "services/app/Region/Delete",
  createRegion: "services/app/Region/Create",

  GetPastSurveyAttachmentForStandard:
    "services/app/SurveyAnswer/GetPastSurveyAttachmentForStandard",

  acceptTerms: "services/app/AcceptedTermsUser/Create",
  checkUserAcceptTerms: "services/app/AcceptedTermsUser/ChecUserAcceptTerms",
  getAllAgenciesTerms: "services/app/AcceptedTermsUser/GetAll",
  getTicketsStats: "services/app/Ticket/GetAllTicketsCountBasedOnStatus",
  getRoleForEdit: "services/app/Role/GetRoleForEdit",
  getMeetingsType: "services/app/MeetingAndVisitType/GetAll",
  getAllPermissions: "services/app/Role/GetAllPermissions",
  switchRoleStatus: "services/app/Role/SwitchRoleStatus",
  getCountsVisitSlots: "services/app/VisitSlots/GetAllVisitSlotsCount",
  getAllDeterminants: "services/app/Determinant/GetAll",
  getStandardById: "services/app/Standard/GetStandardById",
  updateExtraDataForAgency: "services/app/Agency/UpdateExtraDataForAgency",
  addCategoryInMailBox: "services/app/EmailCategory/Create",
  getCategoriesInMailbox: "services/app/EmailCategory/GetAllList",
  addLinkedEmailInMailBox: "services/app/LinkedEmail/CreateList",
  sendReplyEmail: "services/app/Integration/SendReplayMail",
  addIdentity: "services/app/Identity/Create",
  getAllIdentity: "services/app/Identity/GetAll",
  deleteIdentity: "services/app/Identity/Delete",
  editIdentity: "services/app/Identity/Update",
  getAllStandardsForSelection: "services/app/GetForSelection/Standards",
  getAllMeetingAndVisitGoal: "services/app/MeetingAndVisitGoal/GetAll",
  createMeetingAndVisitGoal: "services/app/MeetingAndVisitGoal/Create",
  deleteMeetingAndVisitGoal: "services/app/MeetingAndVisitGoal/Delete",
  updateMeetingAndVisitGoal: "services/app/MeetingAndVisitGoal/Update",
  closeMeeting: "services/app/VisitSlots/CloseMeeting",

  getTotalStandardsCount: "Reports/GetTotalStandardsCount",
  getInterlocutorsCount: "Reports/GetInterlocutorsCount",
  getPerspectiveCount: "Reports/GetPerspectiveCount",
  getStandardsCount: "Reports/GetStandardsCount",
  getIncludedAgencies: "Reports/GetIncludedAgencies",
  getAgenciesStartedAnswering: "Reports/GetAgenciesStartedAnswering",
  getAgenciesFinishedAnswering: "Reports/GetAgenciesFinishedAnswering",
  getNewAgencies: "Reports/GetNewAgencies",
  getAnsweredStandardsReports: "Reports/GetAnsweredStandards",
  getStandardsReport: "services/app/Report/GetStandardsReport",
  getDashboardVisitors: "services/app/AuditLog/AgencyReportDashboard",
  getVisitorsAnalytics: "services/app/AuditLog/AgencyReportDashboardDetails",
  getDashboardStaticsReport: "services/app/AuditLog/DashboardStaticsReport",
  updateReplyComment: "services/app/TicketHistory/UpdateReplyComment",
  rejectTicketReply: "services/app/TicketHistory/RejectTicketReply",
  approveTicketReply: "services/app/TicketHistory/ApproveTicketReply",
  getAgenciesTopFiveUsers: "Reports/GetAgenciesTopFiveUsers",
  getAgenciesFiles: "Reports/GetAgenciesFiles",
  getAgencyOldFiles: "Reports/GetAgencyOldFiles",
  getExpertAndReviewerAndPocOfficerToStandard:
    "services/app/Report/GetAssignedExpertAndPOCAndReviewerForStandards",
  getExpertAndReviewerAndPocOfficerByStandardId:
    "services/app/Report/GetAssignedExpertAndPOCAndReviewerByStandardId",
  getNotStartedAgencies:
    "services/app/AuditLog/AgencyReportThatNotLoggedDetails",
  getAuditorExpertPerspectives:
    "services/app/Report/GetAssignedPerspectivesForExpertReviewerPOCOfficer",
  getAuditorExpertAxis:
    "services/app/Report/GetAssignedInterlocutorsForExpertReviewerPOCOfficer",
  getAuditorExpertStandards:
    "services/app/Report/GetAssignedStandardsForExpertReviewerPOCOfficer",
  getAuditorExpertFinishedStandards:
    "services/app/Report/GetAnsweredStandardsStatusForExpertReviewerPocOfficer",
  getAuditorExpertUnfinishedStandards:
    "services/app/Report/GetNotAnsweredStandardsStatusForExpertReviewerPocOfficer",
  getAdminDashboardStats: "services/app/Report/GetDashboardsCardsStatistics",
  getAuditorExpertDashboardStats:
    "services/app/Report/GetDashboardsCardsStatistics",
  getAllReviewersThatAssignedToStandard:
    "services/app/Standard/GetAllReviewersThatAssignedToStandard",
  getAllExpertsThatAssignedToStandard:
    "services/app/Standard/GetAllExpertsThatAssignedToStandard",
  assignAgencyToExpert: "services/app/Standard/AssignAgencyToExpert",
  assignAgencyToReviewer: "services/app/Standard/AssignAgencyToReviewer",
  GetStandardsOfSurvey: "services/app/Report/GetStandardsOfSurvey",
  GetFinalStandardAnswersForAgency:
    "services/app/Report/GetFinalStandardAnswersForAgency",
  GetFinalStandardAnswersDetailsForAgency:
    "services/app/Report/GetFinalStandardAnswersDetailsForAgency",
  GetAnswerHistory: "services/app/Report/GetAnswerHistory",
  DownloadFilesAsZip: "services/app/Attachment/DownloadFilesAsZip",
  assignAgencyToPOCOfficer: "services/app/Standard/AssignAgencyToPOCOfficer",
  getAllPOCOfficersThatAssignedToStandard:
    "services/app/Standard/GetAllPOCOfficerThatAssignedToStandard",
  getNoteTemplate: "services/app/NoteTemplate/GetById",
  editNoteTemplate: "services/app/NoteTemplate/Update",
  getCheckAnswerdStandard: "services/app/Standard/CheckAnsweredStandard",
  GetAllReviewersWithAssignedStandards:
    "services/app/Report/GetAllReviewersWithAssignedStandards",
  GetAllExpertsWithAssignedStandards:
    "services/app/Report/GetAllExpertsWithAssignedStandards",
  GetAllPOCOfficersWithAssignedStandards:
    "services/app/Report/GetAllPOCOfficersWithAssignedStandards",
  getBestFiveAgencies: "services/app/DashboardReport/DashboardStaticsReport",
  PrepareAnsweredStandards:
    "services/app/DashboardReport/PrepareAnsweredStandards",
  GetTopFiveAgencyAnswered:
    "services/app/DashboardReport/GetTopFiveAgencyAnswered",
  GetPhase2Stats: "services/app/Report/GetCountQiyasTeamInCards",
  GetStandardStatusBasedOnReviewOperation:
    "services/app/Report/GetStandardStatusBasedOnReviewOperation",
  GetAnswersReport: "services/app/DashboardReport/GetAnswersReport",
  getTicketStatistics: "services/app/Ticket/GetTicketsStatistics",
  GetAgencyAnswerByAgencyId:
    "services/app/SurveyAnswer/GetAgencyAnswerByAgencyId",
  exportAnswerReports: "services/app/DashboardReport/ExportAnswerReports",
  exportLatestExpertAnswers: "services/app/AnswersReport/ExportLatestExpertAnswers",
  getClassificationCurrentResults: "services/app/DashboardStatistics/GetClassificationCurrentResults",
  getSpecificConclusionResult: "services/app/DashboardStatistics/GetSpecificConclusionResult",
  getAgenciesCurrentResults :"services/app/DashboardStatistics/GetAgenciesCurrentResults",
  getSimulatedAgenciesCurrentResults :"services/app/DashboardStatistics/GetSimulatedAgenciesCurrentResults",
  getStandardDetailsById :"services/app/Standard/GetStandardDetailsById",
  getAllStandardRequirementClassification :"services/app/Standard/GetAllStandardRequirementClassification",
  getAllAgencies :"services/app/Agency/GetAllAgencies",
  getAllAgencyClassifications:"services/app/AgencyClassification/GetAllAgencyClassifications",
  perspectiveGetAll:"services/app/Perspective/GetAll",
  GetAgenciesThatStartedAnsweringStandards:"services/app/DashboardStatistics/GetAgenciesThatStartedAnsweringStandards",
  GetAgenciesThatAnsweredAllStandards:"services/app/DashboardStatistics/GetAgenciesThatAnsweredAllStandards",
  GetAgenciesProofAnswersStatistics:"services/app/DashboardStatistics/GetAgenciesProofAnswersStatistics",
  GetStandardSurveyAgencyAnswersForAgency:"services/app/DashboardStatistics/GetStandardSurveyAgencyAnswersForAgency",
  GetStandardAgenciesCommitmentLevelsList:"services/app/DashboardStatistics/GetStandardAgenciesCommitmentLevelsList",
};

export default environment;
