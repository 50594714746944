import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetNotEnteredAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingNotEnteredAgencies } = useQuery({
    queryKey: ["NotEnteredAgencies", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getNotStartedAgencies,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
  });

  const notStartedAgencies = data?.result?.items;
  const notStartedAgenciesCount = data?.result?.totalCount;
  const totalPages = Math.ceil(notStartedAgenciesCount / 4);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["NotEnteredAgencies", currentPage + 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getNotStartedAgencies,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            SkipCount: (currentPage + 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabled: !!phaseOneStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["NotEnteredAgencies", currentPage - 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getNotStartedAgencies,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            SkipCount: (currentPage - 1) * 4,
            MaxResultCount: 4,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabled: !!phaseOneStageId
    });
  }

  return {
    queryClient,
    data,
    notStartedAgencies,
    notStartedAgenciesCount,
    totalPages,
    isGettingNotEnteredAgencies,
  };
};
