import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";

function AppPagination({ loading, handlePageClick, currentPage, totalPages }) {
  return (
    <div className="pb-5 pt-5">
      <ReactPaginate
        nextLabel={
          loading == true ? null : (
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ color: "#A1A9C4", fontSize: 14 }}
            />
          )
        }
        onPageChange={handlePageClick}
        // initialPage={currentPage}
        forcePage={currentPage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPages}
        previousLabel={
          loading == true ? null : (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ color: "#A1A9C4", fontSize: 14 }}
            />
          )
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item-previous"
        previousLinkClassName="page-link-previous"
        nextClassName="page-item-next"
        nextLinkClassName="page-link-next"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default AppPagination;
