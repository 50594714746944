import "./Component.css";
import Button from "./Button";
import { Modal } from "flowbite-react";
import { trash2, updateStatus } from "../assets/icons/Index";
import { useTranslation } from "react-i18next";

const CModal = ({
  open,
  setOpen,
  title,
  subTitle,
  id,
  confirmModal,
  status,
  loading,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="bg-[#FFFFFF99] shadow-2xl"
      theme={{
        content: {
          base: "relative h-full p-4 md:h-auto",
        },
      }}
      show={open}
      size="lg"
      popup
      onClose={() => setOpen(false)}
    >
      <Modal.Header />
      <Modal.Body className="flex flex-col gap-10 w-fit">
        <div className="text-center flex flex-col justify-center items-center">
          {status ? (
            <img src={updateStatus} alt="update" />
          ) : (
            <img src={trash2} alt="delete" />
          )}
          <p className="text-2xl text-[#1B1651]">{subTitle}</p>
        </div>
        <div className="flex flex-row items-center w-[69%] mr-auto ml-auto pt-2 justify-between">
          <Button
            loading={loading}
            onClick={() => {
              confirmModal();
            }}
            title={t("general.yes")}
            typeColor={"dark"}
            style={{
              backgroundColor: "#2B2969",
              borderRadius: "50px",
              width: 130,
              borderColor: "#2B2969",
            }}
          />
          <Button
            title={t("general.no")}
            onClick={() => {
              setOpen(false);
              onClose?.();
            }}
            typeColor={"light"}
            styleText={{ fontSize: 16, color: "black" }}
            style={{
              backgroundColor: "rgb(185 28 28)",
              borderRadius: "50px",
              color: "white",
              borderColor: "rgb(185 28 28)",
              width: 130,
              marginLeft: "20%",
              marginRight: "20%",
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CModal;
