import React, {useEffect, useState} from "react";
import {axiosGetRequest} from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";
import DegreeItem from "./degreeItem";
import {useParams} from "react-router";
import DegreeItemSkeleton from "./DegreeItemSkeleton";
import AddServeyDegree from "../addDegree";
import AppDivider from "../../../../../../components/appDivider";

const SurveyDegreeList = () => {
  const {surveyId} = useParams();
  const [loading, setLoading] = useState(false);
  const [degreeList, setDegreeList] = useState([]);

  const getDegreeList = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getCommitmentLevelBySurveyId,
      {
        SurveyId: surveyId,
      }
    );
    if (res?.success) {
      setDegreeList(res?.result?.items);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDegreeList();
  }, []);

  const renderedDegreeList = degreeList?.map((item) => (
    <DegreeItem
      {...item}
      key={item.id}
      surveyId={surveyId}
      setDegreeList={setDegreeList}
      degreeList={degreeList}
    />
  ));
  return (
    <>
      <AddServeyDegree degreeList={degreeList} setDegreeList={setDegreeList}/>
      <AppDivider/>
      {loading ? (
        <>
          <DegreeItemSkeleton/>
          <DegreeItemSkeleton/>
        </>
      ) : (
        renderedDegreeList
      )}
    </>
  );
};

export default SurveyDegreeList;
