import AnswersReportPage from "./AnswersReportPage";

function AnswersReport() {
  return (
    <>
      <AnswersReportPage />
    </>
  );
}

export default AnswersReport;
