import {
  answerdStandard,
  interlocator,
  percentages,
  prespictive,
  standards,
  unAnswerdStandard,
} from "../../assets/icons/dashboard";
import DashboardStats from "../../components/dashboard/DashboardStats";
import { useGetAccountMangerAgencyStats } from "./api/useGetAccountMangerAgencyStats";

function AgencyDashboardStats() {
  const { agencyStats, isGettingDashboardStats } = useGetAccountMangerAgencyStats();

  const list = [
    {
      id: 1,
      title: "نسبة الإنجاز",
      number: `${Math.round(agencyStats?.SURVEY_COMPLETION_PERCENTAGE) || 0}%`,
      icon: percentages,
      color: "rgba(248, 37, 30, 0.15)",
    },
    {
      id: 2,
      title: "عدد المعايير الكلية",
      number: agencyStats?.TOTAL_STANDARDS_COUNT,
      icon: standards,
      color: "#0046de26",
    },
    {
      id: 3,
      title: "المناظير",
      number: agencyStats?.TOTAL_PERSPECTIVES_COUNT,
      icon: prespictive,
      color: "rgba(103, 212, 255, 0.15)",
    },
    {
      id: 4,
      title: "المحاور",
      number: agencyStats?.TOTAL_INTERLOCUTORS_COUNT,
      icon: interlocator,
      color: "rgba(0, 70, 222, 0.15)",
    },
    {
      id: 5,
      title: "معايير تم انجازها",
      number: agencyStats?.COMPLETED_STANDARDS_COUNT,
      icon: answerdStandard,
      color: "rgba(255, 162, 23, 0.15)",
    },
    {
      id: 6,
      title: "معايير غير منجزة",
      number: agencyStats?.UNCOMPLETED_STANDARDS_COUNT,
      icon: unAnswerdStandard,
      color: "rgba(195, 43, 255, 0.15)",
    },
  ];
  return <DashboardStats lists={list} loading={isGettingDashboardStats} />;
}

export default AgencyDashboardStats;
