import { createContext, useContext, useState } from "react";

const AgencyDashboardContext = createContext();

function AgencyDashboardContextProvider({ children }) {
  const [dashboardStats, setDashboardStats] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <AgencyDashboardContext.Provider
      value={{
        dashboardStats,
        setDashboardStats,
        setLoading,
        loading,
      }}
    >
      {children}
    </AgencyDashboardContext.Provider>
  );
}

const useAgencyDashboardContext = () => {
  const context = useContext(AgencyDashboardContext);
  if (!context)
    throw new Error(
      "Calling useAgencyDashboardContext Outside AgencyDashboardContextProvider"
    );
  return context;
};

export { useAgencyDashboardContext, AgencyDashboardContextProvider };
