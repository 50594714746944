import {useRef, useState} from "react";

import {toast} from "react-toastify";
import {ErrorMessage} from "formik";
import EditIcon from "../../../components/EditIcon";
import {NewForm} from "../../../components/form/NewForm";
import {InputLabel} from "../../../components";
import DeleteIcon from "../../../components/DeleteIcon";
import NewTable from "../../../components/NewTable";
import environment from "../../../services/environment";
import {axiosDeleteRequest, axiosPutRequest} from "../../../services/Request";
import {permissionsCheck} from "../../../helpers/utils";

function TypeOfVisitTable({ getAllVisitTargets, allVisitTargets, loading }) {
  const [inputs, setInputs] = useState({
    title: "",
    nameInArabic: "",
  });

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    nameInArabic: "",
  };

  const validate = () => {
    const errors = {};

    if (!inputs.nameInArabic) errors.nameInArabic = "هذا الحقل مطلوب";

    return errors;
  };

  // ----------------------------------------------------- //

  const columns = [
    {
      name: "نوع الزيارة",
      selector: (row) => row.nameInArabic,
      header: "nameInArabic",
      accessorKey: "nameInArabic",
      footer: (props) => props.column.nameInArabic,
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck("Pages.UserType.Admin") && (
            <div className="flex gap-2">
              <EditIcon
                initialValuesFunction={() =>
                  setInputs({
                    title: row.nameInArabic,
                    nameInArabic: row.nameInArabic,
                  })
                }
                loading={editLoading}
                editFunction={handleFormik}
                type={"submit"}
                editedName={row.nameInArabic}
                editModalContent={
                  <NewForm
                    initialValues={initialValues}
                    validate={validate}
                    innerRef={formRef}
                    onSubmit={() => handleUpdateTeagetOfVisit(row)}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="space-y-1">
                        <InputLabel
                          label={"نوع الزيارة"}
                          name={"nameInArabic"}
                          type={"text"}
                          value={inputs.nameInArabic}
                          onChange={handleChangeInputs}
                        />

                        <ErrorMessage
                          className="text-red-700"
                          name="nameInArabic"
                          component="div"
                        />
                      </div>
                    </div>
                  </NewForm>
                }
              />
              <DeleteIcon
                loading={deleteLoading}
                id={row.id}
                deleteFunction={() => handleDeleteTeagetOfVisit(row.id)}
                deletedName={row.nameInArabic}
              />
            </div>
          )
        );
      },
    },
  ];

  // handleUpdateTeagetOfVisit
  const handleUpdateTeagetOfVisit = async (row) => {
    const body = {
      id: row.id,
      nameInArabic: inputs.nameInArabic,
      name: inputs.nameInArabic,
    };

    setEditLoading(true);
    const res = await axiosPutRequest(
      environment.updateMeetingAndVisitGoal,
      body
    );
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث التصنيف بنجاح`);
      getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  // handleDeleteTeagetOfVisit
  const handleDeleteTeagetOfVisit = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(
      environment.deleteMeetingAndVisitGoal,
      {
        Id: id,
      }
    );
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
    }
  };

  return (
    <div className="">
      <NewTable
        loading={loading}
        columns={columns}
        data={allVisitTargets?.result?.items || []}
        pagination={false}
      />
    </div>
  );
}

export default TypeOfVisitTable;
