import {Formik} from "formik";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {Button, InputLabel, SModal, Title} from "../../../../components";
import NewHeader from "../../../../layouts/header/NewHeader";
import environment from "../../../../services/environment";
import {get, put} from "../../../../services/Request";
import {plusLight, programsIcon} from "./../../../../assets/icons/Index";

const Edit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [message, setMessage] = useState({});
  const [modules, setModules] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [role, setRole] = useState("");

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getAllPermissions();
  }, []);
  const getRole = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getRole + "?Id=" + id, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        setRole(res.data.result.role);

        setIds(res.data.result.permissions);
      }
    });
  };
  const getAllPermissions = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getRoleForEdit + "?Id=" + id, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        let newPermissions = [];
        setRole(res.data.result.role);
        res.data.result.permissions.forEach((permission, index) => {
            permission.label = permission.displayName;
            permission.value = permission.name;
            permission.id = permission.name;
          });
        setRoles(res.data.result.permissions)
      }
    });
  };

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids?.map((i) => i).includes(id);
  };
  const editRole = (values) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: role.id,
      name: values.name,
      grantedPermissions: ids.concat(modules),
      displayName: values.name,
      normalizedName: values.name,
      description: values.name,
    };
    put(environment.editRole, data, config, (res) => {
      setMessage(
        {
          type: "success",
          message: t("modules.main.users.permissions.messages.editPermission"),
        },
        setOpenConfirmModal(
          true,
          setLoading(false, history.push("/permissions"))
        )
      );
    });
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={t("modules.main.users.permissions.title")}
        subTitleSeconed={role.name}
        toPageSeconed={() => history.push("/permissions")}
        thired={true}
        subTitle={t("modules.main.users.permissions.subTitle")}
        withoutButton={true}
        onClick={() => history.push("/permissions/add")}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton={t("modules.main.users.permissions.titleAddButton")}
        style={{ backgroundColor: "#2B2969" }}
      />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <div className="p-5">
        <Formik
          initialValues={{
            name: role.name,
            grantedPermissions: role.grantedPermissions,
            displayName: role.name,
            normalizedName: role.normalizedName,
            description: role.name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = t("modules.main.users.permissions.messages.title");
            }
            if (ids.length == 0) {
              errors.grantedPermissions = t(
                "modules.main.users.permissions.messages.listEntites"
              );
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            editRole(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-[40%]">
                <p className="label_input text-lg text-[#0D062D]">
                  {t("modules.main.users.permissions.form.title")}
                </p>
                <input
                  className="input_simple mt-5"
                  name="name"
                  type={"text"}
                  id="name"
                  placeholder={t(
                    "modules.main.users.permissions.form.placeholderTitle"
                  )}
                  defaultValue={role.name}
                  onChange={handleChange}
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.name && touched.name && errors.name}
                </div>
              </div>
              {roles.map((role, index) => (
                <InputLabel
                  handleCheckbox={handleCheckbox}
                  message={"لا يوجد صلاحيات"}
                  isCheckedCheckbox={isCheckedCheckbox}
                  label={role.displayName}
                  type={"checkbox"}
                  items={role.permissions}
                />
              )) || []}
              <div className="text-sm text-red-600 mt-3">
                {errors.grantedPermissions &&
                  touched.grantedPermissions &&
                  errors.grantedPermissions}
              </div>
              <div className="text-center">
                <Button
                  type={"submit"}
                  loading={loading}
                  withIcon={true}
                  icon={plusLight}
                  title={t("modules.main.users.permissions.titleEditButton")}
                  typeColor={"dark"}
                  style={{ backgroundColor: "#00114E", width: 160 }}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Edit;
