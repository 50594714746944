import AuditorRow from "./AuditorRow";
import ExpertRow from "./ExpertRow";
import PocOfficerRow from "./PocOfficerRow";

export const ExpandableRowsComponent = ({ data }) => {
  return (
    <div className="flex flex-col gap-3 py-3 bg-gray-50">
      <AuditorRow data={data} />
      <ExpertRow data={data} />
      <PocOfficerRow data={data} />
    </div>
  );
};
