import { ErrorMessage } from "formik";
import { InputLabel } from "../../../../components";
import { NewForm } from "../../../../components/form/NewForm";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import { useEffect } from "react";
import environment from "../../../../services/environment";

function AddAgencyCategoryModal({
  inputs,
  onChange,
  onSubmit,
  innerRef,
  setAgenceisIds,
  agenceisIds,
}) {
  const handleAgenciesChange = (value) => {
    setAgenceisIds(value || []);
  };
  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination, {});
  }, []);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item.nameInArabic || "",
        value: item.id,
        name: item.name || "",
      };
    });

  // ---------------------- validation -------------------
  const initialValues = {
    title: "",
    agencies: [],
  };

  const validate = () => {
    const errors = {};

    if (!inputs.title) errors.title = "هذا الحقل مطلوب";

    if (!agenceisIds.length) errors.agencies = "هذا الحقل مطلوب";

    return errors;
  };
  // -----------------------------------------------------
  return (
    <NewForm
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={innerRef}
    >
      <div className="flex flex-col gap-3">
        <div className="space-y-1">
          <InputLabel
            label={"اسم الفئة"}
            name={"title"}
            type={"text"}
            value={inputs.title}
            onChange={onChange}
          />

          <ErrorMessage className="text-red-700" name="title" component="div" />
        </div>

        <div className="space-y-1">
          <InputLabel
            label={"الوصف"}
            name={"description"}
            type={"text"}
            value={inputs.description}
            onChange={onChange}
            isRequired={false}
          />
        </div>

        <div className="space-y-1">
          <NewDropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={agenceisIds}
            handleChange={handleAgenciesChange}
            placeholder="ادخل اسم الجهات"
            label="الجهات الخاصة بهذه الفئة"
            options={allAgenciesOptions || []}
            isMultiple={true}
          />

          <ErrorMessage
            className="text-red-700"
            name="agencies"
            component="div"
          />
        </div>
      </div>
    </NewForm>
  );
}

export default AddAgencyCategoryModal;
