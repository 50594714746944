import { useHistory } from "react-router";
import { useUploadContext } from "../../../../../../../components/upload/uploadFilesContext";
import { successToast } from "../../../../../../../helpers/toast";
import { axiosGetRequest, axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { StageContext } from "../../../../../../../contexts/stageContext";

const useCreateAgencyAnswer = ({ entityId, standardId, programId }) => {
  const history = useHistory();

  const { currentStage } = useContext(StageContext);
  const { newAgencyAnswer, agencyDegree } = useAnswerContext();
  const { files } = useUploadContext();
  const { setCreateAnswerLoading } = useAnswerContext();
  const isThirdStage = currentStage?.displayOrder === 3;

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.id,
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });

  const createAgencyAnswer = async (standardId, entityId, currentStage) => {
    const agencyRequirementAnswers = newAgencyAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          donotApply: req?.donotApply,
          agencySubRequirementAnswers: subStandardRequirements,
        };
      }
    );

    // * Proof Files

    const agencyProofAnswers = newAgencyAnswer?.standardProofs?.map((file) => {
      const prevUploadedFiles =
        file?.standardProofAttachments?.length > 0
          ? file?.standardProofAttachments?.map((prevFile) => ({
              attachmentId: prevFile?.attachment?.id,
              fromPastYear: prevFile?.fromPastYear,
            }))
          : [];
      const pastYearUploadedFiles = file?.pastSelectedFiles?.filter(item => item.standardProofId === file.standardProofId ).map(
        (prevFile) => ({
          attachmentId: prevFile?.value,
          fromPastYear: true,
        })
      );
      // * New files
      const newUploadedFiles = files
        ?.filter((newFile) => newFile?.proofId == file?.id && newFile?.standardProofId == file.standardProofId)
        .map((newFile) => ({
          attachmentId: newFile?.attachmentId,
          fromPastYear: false,
          relatedRequirementId: newFile?.relatedRequirementId ?? null,
        }));

      const standardProofAttachments = [
        ...prevUploadedFiles,
        ...newUploadedFiles,
        ...pastYearUploadedFiles,
      ];

      
      return {
        standardProofId: file?.standardProofId || file?.id,
        standardProofAttachments: [
          ...prevUploadedFiles,
          ...newUploadedFiles,
          ...pastYearUploadedFiles,
        ],
        note: file?.note || "",
      };
    });


    const standerRequirements = standardRequirementsPhaseThree?.result?.standardRequirements
    const standerRequirementsIds = standerRequirements?.map((req) => req.id);
    const standardProofs =
      !isThirdStage || !standerRequirements
        ? newAgencyAnswer?.standardProofs
        : newAgencyAnswer?.standardProofs.filter((proof) => includeProof(proof, standerRequirementsIds) );

    if(!checkIfValidStandardProofs(agencyProofAnswers, standardProofs)){
      toast.error("يجب رفع جميع الملفات المطلوبة");
      return;
    }

    const data = {
      surveyStageId: Number(currentStage),
      standardId: Number(standardId),
      agencyId: Number(entityId),
      agencyRequirementAnswers,
      agencyProofAnswers,
      selfEvaluationCommitmentLevelId:
        agencyDegree?.agencySelfEvaluationCommitmentLevel?.id || null,
      finalCommitmentLevelId: newAgencyAnswer?.checkedAgencyDegree?.id,
      selfEvaluationDegree: agencyDegree?.agencySelfEvaluationDegree || 0,
      finalDegree: newAgencyAnswer?.checkedAgencyDegree?.fixedDegree,
      donotApply: false,
      pocAnswer: false,
      isDraft: false,
    };

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addAgencyAnswer, data);
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");
    }
    setCreateAnswerLoading(false);


    if (res?.success) {
      history.goBack();
    }
  };
  return { createAgencyAnswer };
};

export default useCreateAgencyAnswer;


function checkIfValidStandardProofs(agencyProofAnswers, standardProofs) {
  const requiredProofs = standardProofs.filter(
    (proof) => proof?.standardProofTypeId === 1
  );
  const requiredProofsIds = requiredProofs.map((proof) => proof?.standardProofId || proof?.id);

  const uploadedProofs = agencyProofAnswers
    .filter((proof) => requiredProofsIds.includes(proof?.standardProofId))
    .filter((proof) => proof?.standardProofAttachments.length > 0);

  return requiredProofs.length <= uploadedProofs.length;
}


/**
 * Checks if a proof includes any of the specified standard requirement IDs.
 *
 * @param {Object} proof - The proof object to check.
 * @param {Array} standerRequirementsIds - The array of standard requirement IDs to check against.
 * @returns {boolean} - Returns true if the proof includes any of the specified standard requirement IDs, otherwise returns false.
 */
function includeProof(proof, standerRequirementsIds){
  return proof.standardProofRequirements.some((req) =>
    standerRequirementsIds.includes(req.standardRequirementId)
  )
}
