import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateExpertAnswer from "../useCreateAnswer/useCreateExpertAnswer";
import usePrevExpertAnswer from "../useGetPrevAnswer/usePrevExpertAnswer";
import ExpertRequirementAnswer from "./ExpertRequirementAnswer";
import ExpertRequirementAnswerConfidential from "./ExpertRequirementAnswerConfidential";
import ExpertFinalAnswer from "./expertFinalAnswer";
import ExpertNoteTemplate from "./expertNoteTemplates";
import ExpertProofFiles from "./expertProofFiles";
import ExpertVisit from "./expertVisit";
import NotesForAgency from "./notesForAgency";

const ExpertAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertAnswer, newExpertAnswer } = useAnswerContext();
  const { createExpertAnswer } = useCreateExpertAnswer();
  const { programId, entityId, standardId } = useParams();
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency

  useEffect(() => {
    if (currentStage) {
      getPrevExpertAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);

  if (menuElement === 3 && isConfidentialAgency) {
    if(loadPreExpertAnswer && menuElement !== 0){
      return (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      );
    }else{
      return (
        <ExpertRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }


  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertAnswer}
        />
      )}

      {loadPreExpertAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <ExpertProofFiles setMenuElement={setMenuElement} />
          )}
          {menuElement === 3 && (
            <ExpertRequirementAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 4 && (
            <ExpertFinalAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 5 && (
            <ExpertVisit
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 6 && (
            <NotesForAgency
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <ExpertNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertAnswer;
