import { Badge } from "flowbite-react";
import NoAnswerBadge from "../../components/NoAnswerBadge";
import AppBadge from "../../components/AppBadge";

function AgencyAnswer({ badgeColor, finalAgencyAnswer }) {
  return (
    <div className="flex items-center gap-3">
      <h2 className="text-xl mb-3">اٍجابة الجهة :</h2>
      {finalAgencyAnswer ? (
        <AppBadge badgeColor={badgeColor}>{finalAgencyAnswer}</AppBadge>
      ) : (
        <NoAnswerBadge />
      )}
    </div>
  );
}

export default AgencyAnswer;
