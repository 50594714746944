import {AddStandardProvider} from "../addStandard/addStandardContext";
import EditStandard from "./editStandard";

const EditStandardContainer = () => {
  return (
    <AddStandardProvider>
      <EditStandard/>
    </AddStandardProvider>
  );
};

export default EditStandardContainer;
