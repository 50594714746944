import React from "react";
import FilePreview from "../../../../../../components/upload/filePreview";

const PrevFilesDownload = ({prevFiles}) => {
  const renderedPrevFiles = prevFiles?.map((item) => (
    <div className="flex justify-between border-b-2 pb-2 items-center">
      <p className="my-2">- {item?.name}</p>

      <FilePreview id={item.id} fileName={item.name}/>
    </div>
  ));
  return <div className="mt-3">{renderedPrevFiles}</div>;
};

export default PrevFilesDownload;
