import {createContext, useContext, useState} from "react";

const EntityContext = createContext();

function EntityContextProvider({ children }) {
  const [agencyDetails, setAgencyDetails] = useState({});

  return (
    <EntityContext.Provider
      value={{
        agencyDetails,
        setAgencyDetails,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
}

const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context)
    throw new Error("Calling useEntityContext Outside EntityContextProvider");
  return context;
};

export { useEntityContext, EntityContextProvider };
