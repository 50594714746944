import AppMoreButton from "../../../../../components/buttons/appMoreButton";
import DeleteIcon from "../../../../../components/DeleteIcon";
import { useEffect, useRef, useState } from "react";
import { axiosPutRequest, remove } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { toast } from "react-toastify";
import { InputLabel } from "../../../../../components";
import AppModal from "../../../../../components/Models/appModal";
import { pen } from "../../../../../assets/icons/Index";
import NewTooltip from "../../../../../components/NewTooltip";
import { NewForm } from "../../../../../components/form/NewForm";
import { ErrorMessage } from "formik";
import { useHistory } from "react-router";
import { permissionsCheck } from "../../../../../helpers/utils";
import { useGlobalContext } from "../../../../../contexts/globalContext";

const InterlocutorItem = ({
  id,
  surveyTitle,
  title,
  perspectiveTitle,
  perspectiveId,
  onDataUpdate,
  description,
  weight,
  code,
  surveyId,
  target,
  indicatorId,
  standardsCount,
}) => {
  const {
    setSurveyId,
    setPerspectiveId,
    setIndicatorId,
    setInterlocutorId,
  } = useGlobalContext();
  const history = useHistory();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [inputs, setInputs] = useState({
    title: title || "",
    description: description || "",
    target: target || "",
    weight: weight || "",
    code: code || "",
  });

  useEffect(() => {
    setSurveyId(surveyId);
    setPerspectiveId(perspectiveId);
    setIndicatorId(indicatorId);
    setInterlocutorId(id);
  }, []);
  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  let body = {
    id: id,
    title: inputs.title,
    target: inputs.target,
    weight: inputs.weight,
    description: inputs.description,
    perspectiveId: perspectiveId,
    code: inputs.code,
  };

  const handleEdit = async () => {
    setEditLoading(true);
    const res = await axiosPutRequest(environment.editInterviewer, body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث محور ${title} بنجاح`);
      setOpenEditModal(false);
      onDataUpdate();
    } else {
      toast.error("حدث خطأ");
    }
  };

  // deleteFunction
  const deleteInterlocutor = () => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteInterviewer + "?id=" + id, config, (res) => {
      if (res.status == 200) {
        toast.success(`تم حذف محور ${title} بنجاح`);
        setDeleteLoading(false);
        onDataUpdate();
      } else {
        toast.error("حدث خطأ");
      }
    });
  };

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
    weight: "",
    target: "",
    code: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = "هذا الحقل مطلوب";
    }
    if (!inputs.target) {
      errors.target = "هذا الحقل مطلوب";
    }
    if (!inputs.code) {
      errors.code = "هذا الحقل مطلوب";
    } else if (!inputs.code.match(/^\d+\.\d+$/)) {
      errors.code = "تنسيق الكود على الطريقة 1.0";
    }
    if (!inputs.weight) {
      errors.target = "هذا الحقل مطلوب";
    }

    return errors;
  };
  // ----------------------------------------------------- //

  return (
    <div className="p-1">
      <div className="p-5 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.02] duration-300 hover:shadow-lg border-brdr border-[1px] rounded-lg ">
        <div className="flex justify-between">
          <h2 className="text-blue_text">{title}</h2>
          <div className="flex flex-2">
            {permissionsCheck("Pages.Interlocutors.Edit") ? (
              <NewTooltip content={"تعديل"}>
                <div
                  onClick={() => setOpenEditModal(true)}
                  className="rounded-full p-1 bg-[#FFF6DC] cursor-pointer mr-2 ml-2"
                >
                  <img src={pen} alt="edit icon" />
                </div>
              </NewTooltip>
            ) : null}
            {permissionsCheck("Pages.Interlocutors.Delete") ? (
              <NewTooltip content={"حذف"}>
                <DeleteIcon
                  loading={deleteLoading}
                  deletedName={`محور ${title}`}
                  deleteFunction={() => deleteInterlocutor(id)}
                />
              </NewTooltip>
            ) : null}
          </div>
        </div>

        <div className="mt-5 mb-12">
          <div className="">
            <p className="text-light text-xs mb-1">الاستبيان</p>
            <h3 className="text-sm">{surveyTitle}</h3>
          </div>
          <div className="mt-4">
            <p className="text-light text-xs mb-1">المنظور</p>
            <h3 className="text-sm">{perspectiveTitle}</h3>
          </div>
          <div className="flex">
            <div className="mt-4 min-w-[120px]">
              <p className="text-light text-xs mb-1">رقم المحور</p>
              <h3 className="text-sm">{code}</h3>
            </div>
            <div className="mt-4">
              <p className="text-light text-xs mb-1">الوزن</p>
              <h3 className="text-sm">{weight?.toFixed(2)}</h3>
            </div>
          </div>
        </div>

        <AppModal
          loading={editLoading}
          handleSubmit={handleFormik}
          type={"submit"}
          headerTitle={`تحديث محور ${title}`}
          open={openEditModal}
          setOpen={setOpenEditModal}
          submitLabel={"تحديث"}
        >
          <NewForm
            initialValues={initialValues}
            validate={validate}
            innerRef={formRef}
            onSubmit={handleEdit}
          >
            <div className="flex flex-col gap-3">
              <div className="space-y-1">
                <InputLabel
                  label={"اسم المحور"}
                  name="title"
                  value={inputs.title}
                  onChange={handleInputChange}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  value={inputs.code}
                  onChange={handleInputChange}
                  name={"code"}
                  label={"الكود"}
                  type={"number"}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="code"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  value={inputs.target}
                  onChange={handleInputChange}
                  name={"target"}
                  label={"الهدف"}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="target"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  value={inputs.weight}
                  onChange={handleInputChange}
                  name={"weight"}
                  label={"الوزن"}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="target"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  rows={4}
                  type={"textarea"}
                  value={inputs.description}
                  onChange={handleInputChange}
                  name={"description"}
                  label={"الوصف"}
                  isRequired={false}
                />
              </div>
            </div>
          </NewForm>
        </AppModal>
        <div>
          <AppMoreButton
            fullwidth
            onClick={() =>
              history.push({
                pathname: `/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${id}/standards`,
                indicatorId: indicatorId,
                perspectiveTitle,
                interlocutor: id,
              })
            }
          >
            المعايير ( {standardsCount} )
          </AppMoreButton>
        </div>
      </div>
    </div>
  );
};

export default InterlocutorItem;
