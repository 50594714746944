import { Link } from "react-router-dom";
import { drawLeftGreen } from "../../../../assets/icons/Index";
import NewTable from "../../../../components/NewTable";
import NewCard from "../../../../components/dashboard/NewCard";
import { useContext, useState } from "react";
import { Badge } from "flowbite-react";
import { useGetAllStandards } from "../api/useGetAllStandards";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { getCompletionRateProgressBar } from "../../../../helpers/utils";
import { StageContext } from "../../../../contexts/stageContext";

function AllStandards({ isPhase2 = false }) {
  const { currentStage } = useContext(StageContext)
  const surveyId = localStorage.getItem("surveyId");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const {
    allStandards,
    isGettingAllStandards,
    totalCount,
    totalPages,
  } = useGetAllStandards({ currentPage, filterValue });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // tables columns
  const standardsColumn = [
    {
      name: "كود المعيار",
      accessorKey: "standardCode",
      selector: (row) => row.standardCode,
      sortable: true,
      width: "150px",
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.standardCode}</div>
      ),
    },
    {
      name: "اسم المعيار",
      accessorKey: "standardTitle",
      selector: (row) => row.standardTitle,
      sortable: true,
      center: true,
      cell: (info) => <div className="text-center">{info.standardTitle}</div>,
    },
    {
      name: "المنظور",
      accessorKey: "perspectiveTitle",
      selector: (row) => row.perspectiveTitle,
      center: true,
      cell: (row) => (
        <Badge
          className="text-white text-center font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.perspectiveTitle}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "المحور",
      accessorKey: "interlocutorTitle",
      selector: (row) => row.interlocutorTitle,
      center: true,
      sortable: true,
      cell: (row) => (
        <Badge
          className="text-white text-center font-medium bg-[#1cc081] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.interlocutorTitle}
        </Badge>
      ),
    },
    isPhase2 && {
      name: "نسبة اكتمال التدقيق",
      accessorKey: "interlocutorTitle",
      selector: (row) => row.interlocutorTitle,
      center: true,
      sortable: true,
      cell: (row) => getCompletionRateProgressBar(row.completionRate),
    },
    !isPhase2 && {
      name: "اجابات الجهات على المعيار",
      selector: (row) => row.status,
      sortable: true,
      center: true,
      cell: (row) => (
        <Link to={`/Standard/${row.standardId}/agencies/answer/${phaseOneStageId}`}>
          <img
            src={drawLeftGreen}
            alt="viewNew"
            className="w-[40px] cursor-pointer"
          />
        </Link>
      ),
    },
  ].filter(Boolean);

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.GetStandardsOfSurvey, {
      surveyId: surveyId,
      KeyWord: filterValue,
      SkipCount: 0,
      MaxResultCount: 50000,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "كود المعيار": ele?.standardCode,
        "اسم المعيار": ele?.standardTitle,
        المنظور: ele?.perspectiveTitle,
        المحور: ele?.interlocutorTitle,
      };
    });

    downloadExcel("المعايير", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={`المعايير (${totalCount})`}>
      <NewTable
        withSearch
        onSearch={handleFilter}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAllStandards}
        columns={standardsColumn}
        data={allStandards || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
}

export default AllStandards;
