import ReactSwitch from "react-switch";

const AppSwitch = ({ checked, handleChange, disabled = false }) => {
  return (
    <div>
      <ReactSwitch
        onChange={handleChange}
        checked={checked}
        onColor={"#1CC081"}
        offColor="#bbbfcc"
        disabled={disabled}
      />
    </div>
  );
};

export default AppSwitch;
