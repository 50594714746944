import { AgencyDashboardContextProvider } from "../../contexts/AgencyDashboardContext";
import AgencyDashboardCards from "./AgencyDashboardCards";
import AgencyDashboardStats from "./AgencyDashboardStats";

function AgencyDashboard() {
  return (
    <AgencyDashboardContextProvider>
      <AgencyDashboardStats />
      <AgencyDashboardCards />
    </AgencyDashboardContextProvider>
  );
}

export default AgencyDashboard;
