import { useTranslation } from "react-i18next";
import NewHeader from "../../../../layouts/header/NewHeader";
import { plusLight, programsIcon } from "../../../../assets/icons/Index";
import { SModal, Title } from "../../../../components";
import { useEffect, useState } from "react";
import { get } from "../../../../services/Request";
import { useHistory, useParams } from "react-router";
import { tokenConfig } from "../../../../constants/contents";
import Skeleton from "react-skeleton-loader";
import EntityDetailsContent from "./entityDetails/entityDetailsContent";
import { useEntityContext } from "../../../../contexts/entityDetailsContext";
import { permissionsCheck } from "../../../../helpers/utils";
import EntityDetailsLoading from "./entityDetails/EntityDetailsLoading";

const Details = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { agencyDetails, setAgencyDetails } = useEntityContext();

  const getAgencyData = async () => {
    setLoading(true);
    const res = get(
      `services/app/Agency/getAgencyById?id=${id}`,
      tokenConfig,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setAgencyDetails(res.data.result.items);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        setAgencyDetails({ ...res?.data?.result });
      }
    );
  };

  useEffect(() => {
    getAgencyData();
  }, []);

  const test = () => {
    
  };

  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={t("الجهات")}
        // seconed={true}
        toPage={() => history.push("/entities")}
        subTitle={t("بيانات الجهة")}
        textStyle={{ color: "white", fontSize: 18 }}
        seconed={true}
        subTitleSeconed={agencyDetails?.nameInArabic || ""}
        // titleButton={t("modules.main.users.entities.titleAddButton")}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969", width: 200 }}
      />

      {loading ? (
        <EntityDetailsLoading />
      ) : (
        <EntityDetailsContent agencyDetails={agencyDetails} />
      )}
    </div>
  );
};

export default Details;
