import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppInput from "./form/appInput";
import { useMemo } from "react";
import { debounce } from "../helpers/utils";

function TableSearch({ onSearch, placeholder = "ابحث هنا ..." }) {
  const debouncedOnSearch = useMemo(() => debounce(onSearch, 300), [onSearch]);
  return (
    <div className="relative">
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <FontAwesomeIcon
          color="white"
          className="text-slate-400"
          icon={faSearch}
        />
      </div>
      <AppInput
        type="text"
        placeholder={placeholder}
        className="w-full border-2 rounded bg-[#F9F9ff] text-slate-400 placeholder:text-slate-400 py-2 px-3 pr-10"
        onChange={(e) => debouncedOnSearch(e.target.value || "")}
      />
    </div>
  );
}

export default TableSearch;
