// import Select from "react-tailwindcss-select";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";

const NewDropdownMultiSelect = ({
  value,
  options,
  handleChange,
  isSearchable,
  isMultiple,
  placeholder,
  label,
  withoutLabel,
  key,
  isClearable,
  styleButton,
  filter,
  className,
  name,
  errorMessage,
  formatOptionLabel,
  disabled,
  loading,
  isRequired = true,
}) => {
  return (
    <>
      <div
        className={
          filter
            ? "flex flex-col container_dropdown_mult_select_white"
            : "flex flex-col container_dropdown_mult_select"
        }
      >
        {withoutLabel ? null : (
          <label className="label_input text-[#292069] mb-3">
            {label}
            {isRequired && (
              <span className="mr-1">
                <FontAwesomeIcon
                  className="w-2 text-red-700"
                  icon={faAsterisk}
                />
              </span>
            )}
          </label>
        )}
        <MultiSelect
          value={value}
          key={key}
          onChange={handleChange}
          isMultiple={isMultiple}
          options={options}
          disableSearch={false}
          isClearable={isClearable}
          hasSelectAll={isMultiple}
          className={"multiSelectDropdown"}
          disabled={loading || disabled}
          isLoading={loading}
          placeholder={placeholder}
          // options={options}
          // value={value}
          // onChange={handleChange}
          labelledBy="اختر"
          overrideStrings={{
            allItemsAreSelected: "تم اختيار الكل",
            clearSearch: "Clear Search",
            clearSelected: "Clear Selected",
            noOptions: "لا يوجد بيانات",
            search: "بحث",
            selectAll: "اختيار الكل",
            selectAllFiltered: "اختيار الكل (Filtered)",
            selectSomeItems: "اختر ...",
            create: "Create",
          }}
        />
      </div>
      {errorMessage && <p className="text-error">{errorMessage}</p>}
    </>
  );
};

export default NewDropdownMultiSelect;
