import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";

export const useGetAuditors = ({ currentPage, filterValue }) => {
  // const surveyStageId = localStorage.getItem("surveyStageId");
  const { currentStage } = useContext(StageContext)
  const surveyStageId = currentStage?.id;
  const queryClient = useQueryClient();
  const { data, isLoading: isGettingAllAuditors } = useQuery({
    queryKey: ["Auditors", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllReviewersWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
      }),
      enabled: !!surveyStageId
  });

  const allAuditors = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["Auditors", currentPage + 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllReviewersWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          POCOfficerId: pocOfficerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage + 1) * 5,
          MaxResultCount: 5,
        }),
        enabled: !!surveyStageId
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["Auditors", currentPage - 1, filterValue],
      queryFn: () =>
        axiosGetRequest(environment.GetAllReviewersWithAssignedStandards, {
          SurveyStageId: surveyStageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          POCOfficerId: pocOfficerId,
          ExpertId: expertId,
          KeyWord: filterValue,
          SkipCount: (currentPage - 1) * 5,
          MaxResultCount: 5,
        }),
        enabled: !!surveyStageId
    });
  }

  return {
    queryClient,
    allAuditors,
    totalCount,
    totalPages,
    data,
    isGettingAllAuditors,
  };
};
