import React, { useState } from "react";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { formatDate, formatTime, permissionsCheck } from "./../helpers/utils";
import DropdownMultiSelect from "./DropdownMultiSelect";
import Skeleton from "react-skeleton-loader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReactSwitch from "react-switch";
import InputLabel from "./InputLabel";
import FilePreview from "./upload/filePreview";

const Inquiries = ({
  handleChange,
  inquiries,
  code,
  statusesInquiry,
  getStandardInquiryList,
  getSelectedStatus,
  loading,
  getComment,
  addComment,
  changeStatusInquiry,
  getSelectedFile,
}) => {
  const [selectedStatusInquiry, setSelectedStatusInquiry] = useState("");
  const [comment, setComment] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let name = localStorage.getItem("userName");

  let initials = [...name.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  const searchStatus = () => {
    getStandardInquiryList();
  };

  const handleStatusInquiry = (value) => {
    
    setSelectedStatusInquiry(value);
    getSelectedStatus(value);
  };

  const createComment = (inquiryId) => {
    addComment(inquiryId);
  };

  const getCommentInquiry = (data) => {
    setComment(data, getComment(data));
  };

  let inqueriesStatus = {};
  inquiries?.length > 0 &&
    inquiries?.forEach((ele) => (inqueriesStatus[ele.id] = ele.ticketStatus));
  const [statusChange, setStatusChange] = useState(inqueriesStatus);

  const handleChangeStatusInquiry = (checked, inquiryId) => {
    setStatusChange({ ...statusChange, [inquiryId]: checked == 4 ? 2 : 4 });
    changeStatusInquiry(inquiryId, checked == 4 ? 2 : 4);
  };

  function getStatusStyle(status) {
    if (status === 4) {
      return "bg-[#5bc0de] text-white";
    } else if (status === 1) {
      return "bg-[#22bb33] text-white";
    } else if (status === 2) {
      return "bg-[#bb2124] text-white";
    } else if (status === 3) {
      return "bg-[#f0ad4e] text-white";
    } else {
      return "bg-[#5bc0de] text-white";
    }
  }

  const onChangeFile = (event) => {
    
    
    setSelectedFile(event.target.files[0]);
    getSelectedFile(event.target.files[0]);
  };

  return (
    <div className="bg-[#F9F9FF] flex flex-col items-center justify-evenly mr-3 rounded-xl p-3">
      <div className="flex flex-row items-center justify-evenly w-full py-3 px-1">
        <h1 className="text-base text-[#292069]">حالة الإستفسار</h1>
        <div className="w-[40%]">
          <DropdownMultiSelect
            filter={true}
            withoutLabel={false}
            isSearchable={false}
            value={selectedStatusInquiry}
            handleChange={handleStatusInquiry}
            placeholder={"أختر حالة"}
            options={statusesInquiry}
            isMultiple={false}
          />
        </div>
        <Button
          onClick={() => searchStatus()}
          title={"بحث"}
          style={{
            width: "167px",
            fontSize: "18px",
            height: "50px",
            borderRadius: "40px",
            marginTop: 30,
          }}
        />
      </div>
      <div className="bg-white h-[545px] rounded-xl flex flex-col justify-between p-5 w-full overflow-y-auto">
        {loading ? (
          <div className="w-[100%]">
            {inquiries?.map((inquiry, index) => (
              <Skeleton
                key={index}
                animated={true}
                width={"100%"}
                height={"100px"}
                style={{ marginTop: 10 }}
                color={"#2B296969"}
                count={1}
              />
            ))}
          </div>
        ) : (
          <div className="w-[100%]">
            {inquiries?.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {"لا يوجد استفسارات"}
                </span>
              </div>
            ) : (
              <div className="w-[100%]">
                {inquiries?.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col justify-between p-3 w-full"
                  >
                    <div className="flex flex-col w-full mb-7">
                      <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex flex-row items-center justify-start">
                          <div className="bg-[#292069]/[.15] ml-3 mr-3 text-[#292069] p-2 rounded-full w-[40px] h-[40px] text-center flex justify-center items-center">
                            {item.creatorUserName
                              .split(" ")
                              .map((word) => word[0])
                              .join("")
                              .toUpperCase()}
                          </div>

                          <div>
                            <h1 className="text-[15px] text-black">
                              {item.creatorUserName}
                            </h1>
                            <h2 className="text-sm text-[#7D8BAB]">
                              {item.creationTime}
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          {permissionsCheck("Pages.UserType.Expert") ? (
                            <div className="mr-3 ml-3">
                              <ReactSwitch
                                onChange={() =>
                                  handleChangeStatusInquiry(
                                    statusChange[item.id],
                                    item.id
                                  )
                                }
                                // checked={item.ticketStatus}statusChange
                                checked={statusChange[item.id] == 4}
                                onColor={"#1F9254"}
                                offColor="#E92C2C"
                              />
                            </div>
                          ) : null}
                          <div
                            className={
                              "relative rounded-2xl px-5 py-2 flex flex-row items-center " +
                              getStatusStyle(item.ticketStatus)
                            }
                          >
                            <h4
                              className={
                                "text-sm opacity-100 mr-4 " +
                                getStatusStyle(item.ticketStatus)
                              }
                            >
                              {item.ticketStatus == 1
                                ? "تحت الاجراء"
                                : item.ticketStatus == 2
                                ? "مغلقة"
                                : item.ticketStatus == 3
                                ? "تجاوز مستوي الخدمة"
                                : item.ticketStatus == 4
                                ? "جديد"
                                : "تحت الاجراء"}
                            </h4>
                            <span
                              className={
                                "w-1.5 h-1.5 absolute rounded-full bg-white"
                              }
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="pr-14 mt-2 text-[#292069] text-sm">
                        <span className="bg-[#F9F9FF] p-2 ml-2 rounded-full text-[#2A206A] text-[14px]">
                          {code}
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></div>
                        {item.attachmentUri && (
                          <div className="flex flex-row items-center">
                            <div
                              className={
                                "relative rounded-2xl px-5 py-2 flex flex-row items-center "
                              }
                            >
                              <span
                                className={
                                  "w-1.5 h-1.5 absolute rounded-full cursor-pointer"
                                }
                              >
                                <FilePreview
                                  id={item.attachmentId}
                                  fileName={item.attachmentName}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-between">
                      {item.ticketHistories.map((comment) => (
                        <div
                          key={comment.id}
                          className="p-5 bg-[#F9F9FF] rounded-xl flex flex-col justify-between mb-3"
                        >
                          <div className="flex flex-row items-center justify-start">
                            <div className="bg-[#292069]/[.15] ml-3 mr-3 text-[#292069] p-2 rounded-full w-[40px] h-[40px] flex justify-center items-center">
                              {comment?.creatorUserName
                                ?.split(" ")
                                ?.map((word) => word[0])
                                .join("")
                                .toUpperCase()}
                            </div>

                            <div>
                              <h1 className="text-[15px] text-black">
                                {comment.creatorUserName}
                              </h1>
                              <h2 className="text-sm text-[#7D8BAB]">
                                {formatDate(new Date(comment.creationTime)) ==
                                formatDate(new Date())
                                  ? "Today" +
                                    " " +
                                    formatTime(new Date(comment.creationTime))
                                  : formatDate(new Date(comment.creationTime)) +
                                    "  " +
                                    formatTime(new Date(comment.creationTime))}
                              </h2>
                            </div>
                          </div>
                          <div
                            className="mr-12 text-sm"
                            dangerouslySetInnerHTML={{
                              __html: comment.comment,
                            }}
                          ></div>
                          {comment.attachmentId && (
                            <div className="flex flex-row items-center mb-5">
                              <div
                                className={
                                  "relative rounded-2xl px-5 py-2 flex flex-row items-center "
                                }
                              >
                                <span
                                  className={
                                    "w-1.5 h-1.5 absolute rounded-full cursor-pointer"
                                  }
                                >
                                  <FilePreview
                                    id={comment.attachmentId}
                                    fileName={comment.attachmentName}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {(permissionsCheck("Pages.UserType.Expert") ||
                      permissionsCheck("Pages.UserType.ExpertManager") ||
                      permissionsCheck("Pages.UserType.Reviewer") ||
                      permissionsCheck("Pages.UserType.Admin") ||
                      permissionsCheck("Pages.UserType.POC") ||
                      permissionsCheck("Pages.UserType.CIO") ||
                      permissionsCheck("Pages.UserType.Delegate")) && (
                      <div className="flex flex-row items-center justify-between">
                        {item.ticketStatus == 2 ? null : (
                          <>
                            <div className="bg-[#292069]/[.15] ml-3 mr-3 text-[#292069] p-2 rounded-full w-[40px] h-[40px] flex justify-center items-center">
                              {initials}
                            </div>
                          </>
                        )}

                        <div className="flex flex-col">
                          {item.ticketStatus == 2 ? null : (
                            <div className="pl-5 pr-5 w-[100%]">
                              <CKEditor
                                editor={ClassicEditor}
                                data={comment}
                                onReady={(editor) => {
                                  getCommentInquiry(editor.getData());
                                }}
                                config={{
                                  language: "ar",

                                  simpleUpload: {
                                    uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                                    withCredentials: true,

                                    headers: {
                                      Authorization: `Bearer ${localStorage.getItem(
                                        "token"
                                      )}`,
                                    },
                                  },
                                }}
                                onChange={(event, editor) => {
                                  getCommentInquiry(editor.getData());
                                }}
                                onBlur={(event, editor) => {}}
                                onFocus={(event, editor) => {}}
                              />
                            </div>
                          )}
                          <div className="p-5 w-full">
                            <InputLabel
                              style={{ display: "none" }}
                              fileInputWidth={"100%"}
                              type={"file"}
                              id={"fileUpload"}
                              label={"ارسال ملف"}
                              isImage={true}
                              multiple={false}
                              onChange={onChangeFile}
                              fileName={selectedFile.name}
                              isRequired={false}
                            />
                          </div>
                        </div>
                        {item.ticketStatus == 2 ? null : (
                          <div>
                            <button
                              onClick={() => createComment(item.id)}
                              className="bg-[#1CC081] w-[48px] h-[48px] rounded-lg flex justify-center items-center"
                            >
                              <FontAwesomeIcon
                                className="w-[full] text-white"
                                style={{ fontSize: "28px" }}
                                icon={faChevronCircleLeft}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="w-full h-0.5 border-[1px] border-[#EAEAEA] border-dashed my-5"></div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Inquiries;
