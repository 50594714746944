import { DropdownMultiSelect } from "../../components";
import {
  AccountMangerDashboardContextProvider,
  useAccountMangerDashboardContext,
} from "../../contexts/AccountMangerDashboardContext";
import AgencyDashboardCards from "./AgencyDashboardCards";
import AgencyDashboardStats from "./AgencyDashboardStats";

function AccountMangerDashboard() {
  return (
    <AccountMangerDashboardContextProvider>
      <AccountMangerDashboardHeader />
      <AgencyDashboardStats />
      <AgencyDashboardCards />
    </AccountMangerDashboardContextProvider>
  );
}

export default AccountMangerDashboard;

function AccountMangerDashboardHeader() {
  const { selectedAgency, handleSelectAgency, allAgenciesOptions } =
    useAccountMangerDashboardContext();
  return (
    <div className="flex justify-start">
      <div className="w-1/2">
        <DropdownMultiSelect
          placeholder={"أختر جهة حكومية"}
          isClearable={true}
          withoutLabel={false}
          isSearchable={true}
          value={selectedAgency}
          handleChange={handleSelectAgency}
          options={allAgenciesOptions || []}
          isMultiple={false}
          isRequired={false}
          label={"أختر جهة حكومية"}
        />
      </div>
    </div>
  );
}
