import React, { useContext, useState } from 'react';
import { Progress } from 'flowbite-react';
import { post } from '../services/Request';
import environment from '../services/environment';
import InputLabel from './InputLabel';
import { StandardProgramContext } from './../contexts/StandardProgramContext';
import AppButton from './buttons/appButton';

const FileUpload = ({ multiple, document, from, update, label }) => {
  const standardProgramContext = useContext(StandardProgramContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const handleFileChange = (event) => {
    const file = event?.target?.files;
    setSelectedFile(file);
  };

  const uploadComplete = (fileName) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let type = "";
    if (from == "files") {
      type = "Standards_Proofs";
    } else {
      type = "Innovative_Forms";
    }

    post(
      `${environment?.uploadComplete}?fileName=${fileName}&agencyId=${standardProgramContext?.agency?.id}&attachmentClassification=${type}`,
      "",
      config,
      (res) => {
        if (from == "fileExcel") {
          standardProgramContext.fileModel = res?.data?.result?.id;
        } else if (from == "files") {
          standardProgramContext.files.forEach((element) => {
            if (element && document && element?.id == document?.id) {
              element?.attachments?.push({
                attachmentId: res?.data?.result?.id,
              });
            }
          });
          standardProgramContext.setFiles(standardProgramContext?.files);
          update(standardProgramContext?.files);
        }
      }
    );
  };

  const handleFileUpload = () => {
    let files = selectedFile;
    for (const file of files) {
      if (!file) {
        alert("Please select a file to upload.");
        return;
      }

      const chunkSize = 1024 * 1024;
      const totalChunks = Math.ceil(file.size / chunkSize);
      const chunkProgress = 100 / totalChunks;
      let chunkNumber = 0;
      let start = 0;
      let end = chunkSize;
      const uploadNextChunk = async () => {
        if (end <= file.size && start != end) {
          const chunk = file.slice(start, end);
          const formData = new FormData();
          formData.append("file", chunk);
          formData.append("chunkNumber", chunkNumber);
          formData.append("totalChunks", totalChunks);
          formData.append("originalname", file.name);
          fetch(
            `${process.env.REACT_APP_URL}/${environment.uploadChunks}?id=${chunkNumber}&fileName=${file.name}`,
            {
              method: "POST",
              body: formData,
            }
          )
            .then((response) => response.json())
            .then((data) => {
              const temp = `Chunk ${chunkNumber +
                1}/${totalChunks} uploaded successfully`;
              setStatus(temp);
              setProgress(Number((chunkNumber + 1) * chunkProgress));
              start = end;
              if (file.size - end > chunkSize) {
                end = start + chunkSize;
              } else {
                end = file.size;
              }
              chunkNumber++;
              uploadNextChunk();
            })
            .catch((error) => {
              console.error("Error uploading chunk:", error);
            });
        } else {
          uploadComplete(file.name);
          setProgress(100);
          setSelectedFile(null);
          setStatus("File upload completed");
        }
      };
      uploadNextChunk();
    }
  };

  
  return (
    <div className="pt-1">
      <div className="mb-2">
        <h3>{status}</h3>
        {progress > 0 && <Progress value={45} />}
      </div>
      <InputLabel
        isImage={false}
        name={"files"}
        style={{ display: "none" }}
        id={"fileUpload"}
        onChange={handleFileChange}
        type={"file"}
        multiple={multiple}
        fileLabel={label}
        withoutLabel={true}
      />

      <div className="w-[100%] mt-1">
        <AppButton
          onClick={handleFileUpload}
          disabled={progress && progress != 100}
          fullwidth
        >
          رفع الملفات
        </AppButton>
      </div>
    </div>
  );
};

export default FileUpload;
