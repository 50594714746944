import {t} from "i18next";
import {programsIcon} from "../../../../../assets/icons/Index";
import {Title} from "../../../../../components";
import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import {serveySettingTabs} from "../data";
import SurveyTemplateList from "./templateList";
import AppButton from "../../../../../components/buttons/appButton";
import {Link, useParams} from "react-router-dom";
import {useHistory} from "react-router";

const SurveyTemplates = () => {
  const {surveyId} = useParams();
  const history = useHistory();

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"اعدادات الاستبيان"}
            thired={true}
            subTitleSeconed={"قوالب الملاحظة"}
            title={t("المؤشرات")}
            withoutButton={true}
            toPage={() => history.push(`/indicators`)}
          />
        </div>
      }
      bgWhite
    >
      <div className="bg-gray_light p-6 flex justify-between items-center rounded-lg">
        <h3 className="text-blue_text text-lg">القوالب</h3>
        <div className="flex justify-end flex-grow">
          <Link to={`/surveys/${surveyId}/settings/templates/add`}>
            <AppButton>إضافة قالب</AppButton>
          </Link>
        </div>
      </div>
      <SurveyTemplateList/>
    </SecondaryTapsLayout>
  );
};

export default SurveyTemplates;
