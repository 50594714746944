import React, {useEffect, useRef, useState} from "react";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
// import { prespectiveBreadcumbs } from "./content";
import PrespectiveList from "./prespectiveList";
import {DropdownMultiSelect, InputLabel} from "../../../../components";
import {ErrorMessage} from "formik";
import environment from "../../../../services/environment";
import {axiosPostRequest} from "../../../../services/Request";
import {useLocation, useParams} from "react-router-dom";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import AppModal from "../../../../components/Models/appModal";
import {toast} from "react-toastify";
import PrespectiveItem from "./prespectiveList/prespectiveItem";
import ReactPaginate from "react-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight,} from "@fortawesome/free-solid-svg-icons";
import {NewForm} from "../../../../components/form/NewForm";
import {programsIcon} from "../../../../assets/icons/Index";
import {permissionsCheck} from "../../../../helpers/utils";

const PrespectivePage = (props) => {
  const {surveyId} = useParams();
  const {indicatorId} = useLocation();

  // 

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const initialInputsState = {
    title: "",
    description: "",
    weight: "",
    code: "",
  };
  const [inputs, setInputs] = useState(initialInputsState);

  const handleInputChange = (e) => {
    setInputs({...inputs, [e.target.name]: e.target.value});
  };

  const handleChangeDepartment = (value) => {
    setSelectedDepartment(value);
  };

  // for multiSelect
  const [getAllDepartments, allDepartments] = useAxiosGetRequest();

  useEffect(() => {
    getAllDepartments(environment.getDepartments);
  }, []);

  const allDepartmentsOptions =
    allDepartments?.result?.items &&
    allDepartments?.result?.items?.map((item) => {
      return {label: item.title, value: item.id};
    });

  // fetch the data
  const [
    getPerspectives,
    perspectives,
    getPerspectivesLoading,
  ] = useAxiosGetRequest();

  // refetch the data when i need
  const handleDataUpdate = async () => {
    await getPerspectives(environment.getPerspectiveList, {
      SurveyId: surveyId,
    });
  };

  const renderedPerspective =
    perspectives?.result?.items &&
    perspectives?.result?.items?.map((item) => (
      <PrespectiveItem
        departmentId={item?.department?.id}
        department={item?.department}
        {...item}
        templateId={surveyId}
        key={item.id}
        onDataUpdate={handleDataUpdate}
        selectedDepartmentValue={selectedDepartment}
        indicatorId={indicatorId}
        code={item?.code}
      />
    ));

  const body = {
    surveyId: surveyId,
    ...inputs,
    departmentId: selectedDepartment?.value ? selectedDepartment.value : "",
  };

  // add new Perspective
  const handleSubmit = async () => {
    setAddLoading(true);
    const res = await axiosPostRequest(environment.addEndoscope, body);
    setAddLoading(false);
    if (res?.success) {
      toast.success("تم إضافة منظور بنجاح");
      getPerspectives(environment.getPerspectiveList, {
        SurveyId: surveyId,
      });
      setOpenAddModal(false);
      setInputs(initialInputsState);
    } else {
      // errorToast(res?.response?.data?.error?.message);
      // setOpenAddModal(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getPerspectives(environment.getPerspectiveList, {
      SurveyId: surveyId,
      SkipCount: 0,
      MaxResultCount: 6,
    });
  }, [surveyId]);

  const handleGetAllPrespectives = async (page) => {
    await getPerspectives(environment.getPerspectiveList, {
      SurveyId: surveyId,
      SkipCount: page * 6,
      MaxResultCount: 6,
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;

    setCurrentPage(newOffset);
    handleGetAllPrespectives(newOffset);
  };

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
    weight: "",
    code: "",
    selectedDepartment: "",
  };

  const validate = () => {
    const errors = {};
    if (!inputs.title) {
      errors.title = "هذا الحقل مطلوب";
    }
    if (!inputs.weight || inputs.weight < 0 || inputs.weight > 100) {
      errors.weight = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
    }
    if (!inputs.code) {
      errors.code = "هذا الحقل مطلوب";
    } else if (!inputs.code.match(/^\d+$/)) {
      errors.code = "تنسيق الكود على الطريقة 1";
    }
    if (!selectedDepartment) {
      errors.selectedDepartment = "هذا الحقل مطلوب";
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const pageCount = Math.ceil(perspectives?.result?.totalCount / 6 || 1);

  const prespectiveBreadcumbs = [
    {
      icon: programsIcon,
      title: "استبيان التحول الرقمى",
      url: `/indicators/${indicatorId || 1}`,
    },
    {
      title: "المناظير",
      // url: "",
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={prespectiveBreadcumbs}
      btnLabel="إضافة منظور"
      handleBtnClick={() => setOpenAddModal(true)}
      hideBtn={permissionsCheck("Pages.Perspectives.Create") ? false : true}
    >
      {permissionsCheck("Pages.Perspectives.GetAll") ? (
        <PrespectiveList
          loading={getPerspectivesLoading}
          renderedPerspective={renderedPerspective}
          getPerspectives={getPerspectives}
        />
      ) : null}

      {/* {perspectives?.result?.totalCount / 9 > 9 && ( */}
      {permissionsCheck("Pages.Perspectives.GetAll") ? (
        <div className="py-5">
          <ReactPaginate
            nextLabel={
              getPerspectivesLoading == true ? null : (
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{color: "#A1A9C4", fontSize: 14}}
                />
              )
            }
            onPageChange={handlePageClick}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={
              getPerspectivesLoading == true ? null : (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{color: "#A1A9C4", fontSize: 14}}
                />
              )
            }
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : null}
      {/* )} */}

      <AppModal
        loading={addLoading}
        handleSubmit={handleSubmitAdd}
        type={"submit"}
        headerTitle={"إضافة منظور جديد"}
        open={openAddModal}
        setOpen={setOpenAddModal}
        submitLabel={"اٍضافة"}
      >
        <NewForm
          initialValues={initialValues}
          validate={validate}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-3">
            <div className="space-y-1">
              <InputLabel
                label={"اسم المنظور"}
                name="title"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                value={inputs.weight}
                type={"number"}
                onChange={handleInputChange}
                name={"weight"}
                label={"الوزن"}
              />
              <ErrorMessage
                className="text-red-700"
                name="weight"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                value={inputs.code}
                onChange={handleInputChange}
                name={"code"}
                type="number"
                label={"الكود"}
              />
              <ErrorMessage
                className="text-red-700"
                name="code"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <DropdownMultiSelect
                withoutLabel={false}
                isSearchable={false}
                value={selectedDepartment}
                handleChange={handleChangeDepartment}
                placeholder={""}
                label={"القسم"}
                name={"selectedDepartment"}
                options={allDepartmentsOptions}
                isMultiple={false}
              />
              <ErrorMessage
                className="text-red-700"
                name="selectedDepartment"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                rows={4}
                type={"textarea"}
                value={inputs.description}
                onChange={handleInputChange}
                name={"description"}
                label={"الوصف"}
                isRequired={false}
              />
            </div>
          </div>
        </NewForm>
      </AppModal>
    </HeaderWithBreadcrumbLayout>
  );
};

export default PrespectivePage;
