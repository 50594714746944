import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import usePrevReviewerAnswer from "../useGetPrevAnswer/usePrevReviewerAnswer";
import NotesForAgency from "./notesForAgency";
import ReviewerFinalAnswer from "./reviewerFinalAnswer";
import ReviewerNoteTemplate from "./reviewerNoteTemplates";
import ReviewerProofFiles from "./reviewerProofFiles";
import ReviewerRequirementAnswer from "./reviewerRequirementAnswer";
import ReviewerRequirementAnswerConfidential from "./reviewerRequirementAnswerConfidential";
import ReviewerVisit from "./reviewerVisit";

const ReviewerAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();
  const { loadPreReviewerAnswer } = useAnswerContext();
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency

  const { programId, entityId, standardId } = useParams();

  useEffect(() => {
    if (currentStage) {
      getPrevReviewerAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);

  if (menuElement === 3 && isConfidentialAgency) {
    if(loadPreReviewerAnswer && menuElement !== 0){
      return (
        <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
          <div className="min-h-[400px] flex justify-center items-center">
            <AppLoader />
          </div>
        </div>
      );
    }else{
      return (
        <ReviewerRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreReviewerAnswer}
        />
      )}

      {loadPreReviewerAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <ReviewerProofFiles setMenuElement={setMenuElement} />
          )}
          {menuElement === 3 && (
            <ReviewerRequirementAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 4 && (
            <ReviewerFinalAnswer setMenuElement={setMenuElement} />
          )}

          {/* {menuElement === 5 && (
                  <StandardAnswerTemplates
                    answers={answers}
                    setAnswers={setAnswers}
                    setMenuElement={setMenuElement}
                  />
                )} */}
          {menuElement === 5 && (
            <ReviewerVisit
              //  loading={loadingData}
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 6 && (
            <NotesForAgency
              //  loading={loadingData}
              setMenuElement={setMenuElement}
            />
          )}
          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createReviewerAnswer(standardId, entityId, currentStage?.id)
              }
            >
              <ReviewerNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewerAnswer;
