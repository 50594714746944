function SubTable({ columns, data, headerColor = "#1cc081" }) {
  return (
    <div className="w-full px-[48px]">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  style={{
                    backgroundColor: headerColor,
                  }}
                  key={index}
                  className={`px-6 pt-2 pb-4 border-b text-nowrap border-slate-600 text-center text-sm leading-4 text-gray-50 uppercase tracking-wider ${
                    column.center ? "text-center" : ""
                  }`}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map((column, cellIndex) => (
                <td
                  key={cellIndex}
                  className="px-6 py-3 pb-4 whitespace-no-wrap border-b border-gray-200 text-center text-sm leading-5 text-gray-700"
                >
                  {column.cell(data)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SubTable;
