import { useTranslation } from "react-i18next";
import NewHeader from "../../../layouts/header/NewHeader";
import { InputLabel, Title } from "../../../components";
import { useEffect, useState } from "react";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../components/UploadAdapter";
import { Formik } from "formik";
import { useParams } from "react-router";
import { programsIcon } from "../../../assets/icons/Index";
import { permissionsCheck } from "../../../helpers/utils";
import NotificationSkeleton from "../../../components/notifications/NotificationSkeleton";
import AppButton from "../../../components/buttons/appButton";
import { toast } from "react-toastify";
import TicketDetailsSkeleton from "./TicketDetailsSkeleton";
import { emptyTable } from "../../../assets/imgs/Index";
import StatusAction from "./StatusAction";
import SingleComment from "./SingleComment";
import FilePreview from "../../../components/upload/filePreview";

const TicketDetails = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [data, setData] = useState({});
  const params = useParams();

  const onChangeFile = (event) => {
    
    
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    
    getDetails(params.id);
  }, []);

  const getDetails = (id) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getTicketById + "?id=" + id, config, (res) => {
      
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        setData(res.data.result);
        setLoading(false);
      }
    });
  };

  const onFileUpload = () => {
    setAddCommentLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();
    formData.append("Name", selectedFile.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", selectedFile);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddCommentLoading(false);
      sendTicket(res.data.result.id);
      setSelectedFile("");
    });
  };

  const sendTicket = (attachmentId) => {
    if (!description || description == "") {
      toast.error(t("modules.main.mailbox.descriptionRequired"));
      return;
    }
    setAddCommentLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      ticketId: params.id,
      comment: description,
      attachmentId: attachmentId,
    };
    post(environment.replyOnTicket, data, config, (res) => {
      setAddCommentLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        toast.success(t("تم اٍرسال الرد بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getDetails(params.id);
        setDescription("");
      }
    });
  };

  function getStatusText(status) {
    switch (status) {
      // case null:
      //   return t("modules.main.tickets.status.newTicket");
      case 1:
        return "تحت الاٍجراء";
      case 2:
        return "مغلقة";
      case 3:
        return "تجاوز مستوى الخدمة";
      default:
        return "تحت الاٍجراء";
    }
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <div className="p-5 flex flex-col">
        <Title
          iconTitle={programsIcon}
          title={t("الاستفسارات")}
          subTitleSeconed={"اضافة دور"}
          toPage={() => history.push("/tickets")}
          seconed={true}
          subTitle={t("تفاصيل التذكرة")}
          withoutButton={true}
          onClick={() => history.push("/permissions/add")}
          textStyle={{ color: "white", fontSize: 18 }}
          titleButton={t("modules.main.users.permissions.titleAddButton")}
          style={{ backgroundColor: "#2B2969" }}
        />

        {loading ? (
          <TicketDetailsSkeleton />
        ) : (
          <div
            style={{
              height: "calc(60dvh - 1.25rem)",
            }}
            className="flex flex-row p-5 gap-5 overflow-y-hidden"
          >
            {/* Right Side */}
            <div className="flex-1 overflow-y-auto flex flex-col gap-5">
              <div className="flex justify-between items-center px-4">
                <label className="text-violet-950 text-lg">التفاصيل</label>

                {permissionsCheck("Pages.Tickets.Close") &&
                data.isHasApprovedReply == true ? (
                  <div className="flex gap-2 items-center">
                    <StatusAction
                      ticket={data}
                      onSuccess={() => getDetails(params.id)}
                    />

                    <p>
                      {data.ticketStatus === 2
                        ? "فتح الاستفسار"
                        : "اٍغلاق الاستفسار"}
                    </p>
                  </div>
                ) : null}
              </div>
              {/* Top */}
              <div className="pt-[18px] pb-[25px] px-[25px] bg-slate-50 rounded-xl flex flex-col gap-4">
                <div className="flex gap-5">
                  <label className="text-violet-950 text-lg">تذكرة رقم</label>
                  <label className="text-emerald-500 text-lg">
                    {data.ticketNumber}
                  </label>
                </div>

                <div className="flex gap-5">
                  <label className="text-violet-950 text-sm ml-2">
                    الموضوع:
                  </label>
                  <label className="text-slate-400 text-sm">
                    {data.subject}
                  </label>
                </div>

                <div className="flex gap-5">
                  <label className="text-violet-950 text-sm ml-2">
                    الحالة:
                  </label>
                  <label className="text-slate-400 text-sm">
                    {getStatusText(data.ticketStatus)}
                  </label>
                </div>

                {data.agencyTitle && (
                  <div className="flex flex-col">
                    <div className="flex flex-row w-full">
                      <label className="text-violet-950 text-sm ml-2">
                        الجهه:
                      </label>
                      <label className="text-slate-400 text-sm">
                        {data.agencyTitle}
                      </label>
                    </div>
                  </div>
                )}

                <div>
                  <label className="text-violet-950 text-sm ml-2">
                    تاريخ ارسال الإستفسار:
                  </label>
                  <label className="text-slate-400 text-sm">
                    {data.creationTime}
                  </label>
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <label className="text-violet-950 text-sm ml-2">
                      الأولوية:
                    </label>
                    <label className="text-slate-400 text-sm">
                      {data.priorityName}
                    </label>
                  </div>
                </div>

                {data.timing && (
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <label className="text-violet-950 text-sm ml-2">
                        التوقيت:
                      </label>
                      <label className="text-slate-400 text-sm">
                        {data.timing}
                      </label>
                    </div>
                  </div>
                )}

                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <label className="text-violet-950 text-sm ml-2">
                      الفئة:
                    </label>
                    <label className="text-slate-400 text-sm">
                      {data.categoryName}
                    </label>
                  </div>
                </div>

                {data.standardTitle && (
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <label className="text-violet-950 text-sm ml-2">
                        المعيار:
                      </label>
                      <label className="text-slate-400 text-sm">
                        {data.standardTitle}
                      </label>
                    </div>
                  </div>
                )}
                {data.standardCode && (
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <label className="text-violet-950 text-sm ml-2">
                        رقم المعيار:
                      </label>
                      <label className="text-slate-400 text-sm">
                        {data.standardCode}
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* Bottom */}
              <div className="w-full flex flex-col gap-5">
                <div className="flex justify-between pl-5">
                  <label className="text-violet-950 text-lg ml-4">الوصف</label>

                  <div>
                    {data.attachmentUri && (
                      <FilePreview
                        id={data.attachmentId}
                        fileName={data.attachmentName}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="w-full min-h-[106px] py-[18px] px-[25px] bg-slate-50 rounded-xl text-right text-black text-base"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </div>

            {/* Left Side */}
            <div className="flex-1 overflow-y-auto px-10">
              <div className="">
                {data.ticketHistories && (
                  <div className="flex flex-col gap-5">
                    <div className="">
                      <label className="text-violet-950 text-lg ml-4">
                        التعليقات
                      </label>
                    </div>
                    {data.ticketHistories.length == 0 ? (
                      <div className="flex flex-col items-center content-center p-5">
                        <img src={emptyTable} alt="emptyTable" />
                        <span className="text-base text-[#656565]">
                          {t("general.noFoundTable")}
                        </span>
                      </div>
                    ) : (
                      <div className=" divide-y-2 divide-gray-100 h-[326px] overflow-y-auto w-full py-[10px] px-[10px] bg-slate-50 rounded-xl text-right text-black text-base">
                        {loading ? (
                          <div className="w-full flex flex-col gap-2">
                            {Array.from({ length: 6 }, (_, index) => index).map(
                              (item) => (
                                <NotificationSkeleton key={item} />
                              )
                            )}
                          </div>
                        ) : (
                          data.ticketHistories.map((item) => {
                            if (
                              permissionsCheck("Pages.UserType.CIO") ||
                              permissionsCheck("Pages.UserType.POC") ||
                              permissionsCheck("Pages.UserType.Delegate")
                            ) {
                              return (
                                <>
                                  {(item.status === 2 ||
                                    item.isCreatorUserRoleEqualLoginUserRole) && (
                                    <SingleComment
                                      ticketStatus={data.ticketStatus}
                                      item={item}
                                      reFetching={() => getDetails(params.id)}
                                    />
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <SingleComment
                                    ticketStatus={data.ticketStatus}
                                    item={item}
                                    reFetching={() => getDetails(params.id)}
                                  />
                                </>
                              );
                            }
                          })
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="w-full flex flex-col justify-between items-start -mt-4">
                {data.ticketStatus != 2 && (
                  <Formik
                    initialValues={{ reply: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!description) {
                        errors.reply = t(
                          "modules.main.mailbox.descriptionRequired"
                        );
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!selectedFile) sendTicket(undefined);
                      else onFileUpload();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="w-full flex flex-col"
                      >
                        <div className="w-full flex flex-col justify-between items-start mt-4">
                          <div className="py-5 px-1">
                            <label className="text-violet-950 text-lg ml-4">
                              الرد
                            </label>
                          </div>

                          <div className="p-0 w-full ">
                            <CKEditor
                              editor={ClassicEditor}
                              data={description}
                              onReady={(editor) => {
                                setDescription(editor.getData());
                                editor.plugins.get(
                                  "FileRepository"
                                ).createUploadAdapter = function(loader) {
                                  
                                  return new UploadAdapter(loader);
                                };
                              }}
                              config={{
                                language: "ar",
                                simpleUpload: {
                                  uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                                  withCredentials: true,

                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "token"
                                    )}`,
                                  },
                                },
                                styles: {
                                  height: "100px",
                                  margin: "50px",
                                },
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                                editor.plugins.get(
                                  "FileRepository"
                                ).createUploadAdapter = function(loader) {
                                  
                                  return new UploadAdapter(loader);
                                };
                              }}
                              onBlur={(event, editor) => {}}
                              onFocus={(event, editor) => {}}
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <InputLabel
                            fileInputWidth={"100%"}
                            type={"file"}
                            id={"fileUpload"}
                            label={t("modules.main.tickets.file")}
                            isImage={true}
                            multiple={false}
                            onChange={onChangeFile}
                            style={{
                              display: "none",
                            }}
                            fileName={selectedFile.name}
                          />
                        </div>
                        {permissionsCheck("Pages.Tickets.Replay") ? (
                          <div className="w-full flex justify-end mt-6 mb-8">
                            <AppButton
                              type={"submit"}
                              loading={addCommentLoading}
                            >
                              اٍرسال
                            </AppButton>
                          </div>
                        ) : null}
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketDetails;
