import {useState} from "react";

const useCheckBox=()=>{
  const [state,setState] = useState({})

  const handlechangeCheckbox=(e)=>{
    e.persist()
    setState(state => ({...state, [e.target.name] : e.target.checked }))
  }

  return [state,handlechangeCheckbox]
}

export default useCheckBox
