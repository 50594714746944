import { produce } from "immer";
import { useContext, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import { AddStandardContext } from "../addStandardContext";
import { addStandardProofsValidations } from "../addStandardValidation";

const defaultValues = {
  title: "",
  standardProofTypeId: "",
  fileCount: "",
  standardProofFileSizeTypeId: "",
  standardProofFileTypeAssociations: [
    {
      standardProofFileTypeId: "",
    },
  ],
  fileSize: "",
  isRequired: true,
};
const AddStandardProofFiles = () => {
  const {
    chooseDocType,
    fileTypes,
    sizeType,
    addFinalValue,
    setAddFinalValue,
    selectedAgencies,
  } = useContext(AddStandardContext);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({
    title: "",
    standardProofTypeId: "",
    fileCount: "",
    standardProofFileSizeTypeId: "",
    standardProofFileTypeAssociations: [],
    fileSize: "",
    agencyStandardProofs: [],
  });
  const [selectedProofFileAgencies, setSelectedProofFileAgencies] = useState(
    []
  );

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardProofsValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [selectedSizeType, setSelectedSizeType] = useState("");

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validateInput(name, value);
  };

  const handleChangeDocType = (value) => {
    setSelectedDocType(value);
    validateInput("standardProofTypeId", value?.label);
    setValues({
      ...values,
      standardProofTypeId: value?.id ? value?.id?.toString() : "",
    });
  };

  const handleChangeFileTypes = (value) => {
    setSelectedFileTypes(value);
    validateInput("standardProofFileTypeAssociations", value || []);

    const types =
      (value?.length > 0 &&
        value.map((item) => ({
          standardProofFileTypeId: item.id,
        }))) ||
      [];

    setValues({
      ...values,
      standardProofFileTypeAssociations: [...types],
    });
  };
  const handleChangeSizeType = (value) => {
    setSelectedSizeType(value);
    validateInput("standardProofFileSizeTypeId", value?.value);

    setValues({
      ...values,
      standardProofFileSizeTypeId: value?.id ? value?.id : "",
    });
  };

  const handleChangeProofFileAgencies = (value) => {
    setSelectedProofFileAgencies(value);
    validateInput("agencyStandardProofs", value);

    setValues({ ...values, agencyStandardProofs: value });
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      addStandardProofsValidations,
      values
    );
    if (formErrors || !validForm) {
      setErrors({ ...errors, ...formErrors });
      return;
    }

    const agencyStandardProofs = values?.agencyStandardProofs?.map((item) => ({
      agencyId: item?.value,
      agencyNameInArabic: item?.label,
    }));

    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs.unshift({ ...values, agencyStandardProofs });
      })
    );
    setValues(defaultValues);
    setSelectedDocType("");
    setSelectedFileTypes([]);
    setSelectedSizeType("");
    setSelectedProofFileAgencies([]);
  };

  return (
    <div className="bg-white p-5 pb-7 rounded-lg">
      <div className="row gap-y-3">
        <div className="col-md-6 col-xl-3">
          <AppInput
            variant="gray"
            label="اسم المستند"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleInputChange}
            errorMessage={errors.title}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedDocType}
            handleChange={handleChangeDocType}
            label="القيود"
            placeholder="اختر القيود"
            options={chooseDocType || []}
            isMultiple={false}
            errorMessage={errors.standardProofTypeId}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedSizeType}
            handleChange={handleChangeSizeType}
            label="نوع حجم الملف"
            placeholder="اختر نوع حجم الملف"
            options={sizeType || []}
            isMultiple={false}
            errorMessage={errors.standardProofFileSizeTypeId}
          />
        </div>

        <div className="col-md-6 col-xl-3">
          <AppInput
            variant="gray"
            label="العدد"
            placeholder="اكتب هنا"
            name="fileCount"
            type="number"
            value={values.fileCount || ""}
            onChange={handleInputChange}
            errorMessage={errors.fileCount}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <AppInput
            variant="gray"
            label="حجم الملف"
            placeholder="اكتب هنا"
            name="fileSize"
            type="number"
            value={values.fileSize}
            onChange={handleInputChange}
            errorMessage={errors.fileSize}
          />
        </div>
        <div className="col-md-3">
          <NewDropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedFileTypes}
            handleChange={handleChangeFileTypes}
            label="نوع الملف"
            placeholder="اختر نوع الملف"
            options={fileTypes || []}
            isMultiple={true}
            errorMessage={errors.standardProofFileTypeAssociations}
          />
        </div>
        <div className="col-md-3">
          <NewDropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedProofFileAgencies || []}
            handleChange={handleChangeProofFileAgencies}
            label="الجهات الحكومية"
            options={selectedAgencies}
            isMultiple={true}
            isRequired={true}
            errorMessage={errors?.agencyStandardProofs}
          />
        </div>
        <div className="col-md-6 col-xl-3 mr-auto self-end">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة مستند</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStandardProofFiles;
