import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetAllStandards = ({ currentPage, filterValue }) => {
  const surveyId = localStorage.getItem("surveyId");
  const queryClient = useQueryClient();
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingAllStandards } = useQuery({
    queryKey: ["AdminAllStandards", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetStandardsOfSurvey,
        {
          KeyWord: filterValue,
          SurveyId: surveyId,
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
      enabled: !!phaseOneStageId,
  });

  const allStandards = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: ["AdminAllStandards", currentPage + 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.GetStandardsOfSurvey,
          {
            KeyWord: filterValue,
            SurveyId: surveyId,
            SkipCount: (currentPage + 1) * 5,
            MaxResultCount: 5,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabeld: !!phaseOneStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: ["AdminAllStandards", currentPage - 1, filterValue, phaseOneStageId],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.GetStandardsOfSurvey,
          {
            KeyWord: filterValue,
            SurveyId: surveyId,
            SkipCount: (currentPage - 1) * 5,
            MaxResultCount: 5,
            surveyStageId: phaseOneStageId,
          },
          true,
          signal
        ),
      enabeld: !!phaseOneStageId,
    });
  }

  return {
    queryClient,
    allStandards,
    totalCount,
    totalPages,
    data,
    isGettingAllStandards,
  };
};
