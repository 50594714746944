import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import RequirementHeader from "../../../standardAnswerRequirements/ui/requirementHeader";
import SubRequirement from "../../../standardAnswerRequirements/ui/subRequirement";

interface IStandardRequirement {
  id: number;
  title: string;
  decision: boolean;
  donotApply: boolean;
  subStandardRequirements: IStandardRequirement[];
}

interface IProps {
  standardRequirements: IStandardRequirement[];
  label: string
}

export default function AgencyRequirementAnswerViewOnly({
  standardRequirements,
  label
}: IProps) {
  const renderedAgencyRequirements =
    standardRequirements?.length > 0 &&
    standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <MainRequirement
          key={requirement?.id}
          title={requirement?.title}
          hasSubRequirements={hasSubRequirements}
          checked={requirement?.decision}
          requirementDonotApplyChecked={requirement?.donotApply}
          readOnly={true}
          disableDonotApply={true}
          onChange={undefined}
          handleDonotApplyRequirementChange={undefined}
        >
          {hasSubRequirements &&
            requirement?.subStandardRequirements?.map((subRequirement) => {
              return (
                <SubRequirement
                  key={subRequirement?.id}
                  title={subRequirement?.title}
                  checked={subRequirement?.decision}
                  subRequirementDonotApplyChecked={subRequirement?.donotApply}
                  readOnly={true}
                  disableDonotApply={true}
                  onChange={undefined}
                  handleDonotApplySubRequirementChange={undefined}
                />
              );
            })}
        </MainRequirement>
      );
    });

  return (
    <>
      <div className="min-h-[440px] p-3">
        <RequirementHeader label={label} />
        <div className="w-[90%] mt-3 p-2 mr-[88px] flex flex-col items-start overflow-y-scroll">
          <div className="flex flex-col items-start w-[100%]">
            {renderedAgencyRequirements}
          </div>
        </div>
      </div>
    </>
  );
}
