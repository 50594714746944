import Skeleton from "react-skeleton-loader";

const StandardEntitiesItemSkeleton = () => {
  return (
    <div className="col-4">
      <div className="border-brdr h-full min-h-[180px] border-[1px] p-4 px-5 rounded-lg">
        <h2 className="text-blue_text ">
          <Skeleton width="80%"/>
        </h2>
        <h2 className="text-blue_text mt-2">
          <Skeleton width="90%"/>
        </h2>
      </div>
    </div>
  );
};

export default StandardEntitiesItemSkeleton;
