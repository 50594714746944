import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import { useHistory } from "react-router";
import usePrevExpertAnswer from "../useGetPrevAnswer/usePrevExpertAnswer";

const useCreateExpertAnswer = () => {
  const history = useHistory();
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newExpertAnswer, setCreateAnswerLoading, expertDegree } =
    useAnswerContext();

  const createExpertAnswer = async (standardId, entityId, currentStage) => {
    const expertRequirementAnswers = newExpertAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || "",
            recommendation: req?.subReq || "",
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          recommendation: req?.note || "",
          donotApply: req?.donotApply,
          expertSubRequirementAnswers: subStandardRequirements,
        };
      }
    );

    

    const surveyExpertAnswerStandardProofs =
      newExpertAnswer?.standardProofs
        ?.filter((proof) => proof?.expertProofNotes)
        .map((proof) => {
          
          return {
            standardProofId: proof?.standardProofId || null,
            expertProofNotes: proof?.expertProofNotes || "",
          };
        }) || [];

    
    
    
    // validation
    if (newExpertAnswer?.checkedExpertDegree?.value == null) {
      return errorToast("لابد من وجود اجابة للتدقيق");
    }

    // newExpertAnswer?.selectedTemplatesForReviewer +
    
    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      expertRequirementAnswers,
      surveyExpertAnswerStandardProofs,

      noteForAgency: {
        unCheckRequirement:
          newExpertAnswer?.newRequirementsAgency == undefined
            ? ""
            : `<ul style="padding : 6px 0 ">` +
              newExpertAnswer?.newRequirementsAgency +
              "</ul>",
        template: "",
        content: newExpertAnswer?.expertSelectedTemplateForAgency,
        noteTemplateId: newExpertAnswer?.selectedTemplatesForAgency,
      },
      noteForReviewer: {
        content: "<ul>" + newExpertAnswer?.newRequirementsReviewer + "</ul>",
        template: newExpertAnswer?.expertSelectedTemplateForReviewer,
      },

      expertId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree: newExpertAnswer?.checkedExpertDegree?.fixedDegree,
      donotApply: newExpertAnswer?.donotApply,
      finalCommitmentLevelId: newExpertAnswer?.checkedExpertDegree?.value,
      selfEvaluationCommitmentLevelId:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree: expertDegree?.expertSelfEvaluationDegree || 0,

      expertAnswerNote:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title
          ? newExpertAnswer?.expertAnswerNote
          : null,

      needVisit: newExpertAnswer?.needVisit,
      visitNote: newExpertAnswer?.visitNote,
    };

    
    // return true

    if (
      expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title &&
      !newExpertAnswer?.expertAnswerNote
    ) {
      errorToast(
        "الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة الخبير"
      );
      return;
    }

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addExpertAnswer, data);
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");
      getPrevExpertAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createExpertAnswer };
};

export default useCreateExpertAnswer;
