import Skeleton from "react-skeleton-loader";

const DegreeItemSkeleton = () => {
  return (
    <div className="bg-white rounded-lg row gap-y-6 mb-4 mx-1 p-6">
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            <Skeleton width="60px"/>
          </p>
          <p className="px-3 text-sm text-light">
            <Skeleton width="80px"/>
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            <Skeleton width="60px"/>
          </p>
          <p className="px-3 text-sm text-light">
            <Skeleton width="100px"/>
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            <Skeleton width="60px"/>
          </p>
          <p className="px-3 text-sm text-light">
            <Skeleton width="80px"/>
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            <Skeleton width="60px"/>
          </p>
          <p className="px-3 text-sm text-light">
            <Skeleton width="80px"/>
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">
            <Skeleton width="60px"/>
          </p>
          <p className="px-3 text-sm text-light">
            <Skeleton width="80px"/>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DegreeItemSkeleton;
