import React, { useContext, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import UploadAdapter from "../../../../../../../components/UploadAdapter";
import NextBtn from "../../components/buttons/nextBtn";
import NewSwitch from "../../../../../../../components/NewSwitch";
import { useAnswerContext } from "../../answerContext";
import strategicPlanning from "./../../../../../../../assets/icons/strategicPlanning.svg";

const ReviewerVisit = ({ loading, setMenuElement }) => {
  const { newReviewerAnswer, setNewReviewerAnswer } = useAnswerContext();
  const [isVisit, setIsVisit] = useState(newReviewerAnswer.needVisit);
  const [descriptionSiteVisit, setDescriptionSiteVisit] = useState("");
  const handleChangeIsVisit = (value) => {
    
    setIsVisit(
      value,
      setNewReviewerAnswer({ ...newReviewerAnswer, needVisit: value })
    );
  };
  

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={strategicPlanning}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              زيارة ميدانية
            </h1>
          </div>
        </div>
        <div>
          <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5 rtl">
            <NewSwitch
              value={isVisit}
              setValue={handleChangeIsVisit}
              label={"هل تريد زيارة ميدانية ؟"}
            />

            {isVisit && (
              <CKEditor
                editor={ClassicEditor}
                data={descriptionSiteVisit || newReviewerAnswer.visitNote}
                onReady={(editor) => {
                  //  setDescriptionSiteVisit(editor.getData());
                  setNewReviewerAnswer({
                    ...newReviewerAnswer,
                    visitNote: editor.getData(),
                  });
                  editor.plugins.get("FileRepository").createUploadAdapter =
                    function (loader) {
                      return new UploadAdapter(loader);
                    };
                }}
                config={{
                  language: "ar",
                  simpleUpload: {
                    uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                    withCredentials: true,

                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  },
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  //  setDescriptionSiteVisit(data);
                  setNewReviewerAnswer({
                    ...newReviewerAnswer,
                    visitNote: editor.getData(),
                  });
                  editor.plugins.get("FileRepository").createUploadAdapter =
                    function (loader) {
                      return new UploadAdapter(loader);
                    };
                }}
              />
            )}
          </div>
        </div>
      </div>

      <NextBtn onClick={() => setMenuElement(6)} />
    </>
  );
};

export default ReviewerVisit;
