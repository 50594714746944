import { Badge } from "flowbite-react";

function NoAnswerBadge() {
  return (
    <Badge
      className="text-white text-center bg-black font-medium pb-3 px-5 rounded-xl"
      size="sm"
    >
      لم يتم الاٍجابة
    </Badge>
  );
}

export default NoAnswerBadge;
