import React, {useContext} from "react";
import {StandardProgramContext} from "../../../../../../contexts/StandardProgramContext";

import procedures from "../../../../../../assets/icons/procedures.svg";
import lamp from "../../../../../../assets/icons/lamp.svg";
import {permissionsCheck} from "../../../../../../helpers/utils";

const StandardAnswerTemplates = ({answers, setAnswers}) => {
  const standardProgramContext = useContext(StandardProgramContext);

  const addModel = () => {
    if (standardProgramContext?.models.length == 0) {
      standardProgramContext?.models.forEach((model) => {
        setAnswers([...model.standardFormAnswerDetails, {answer: ""}]);
      });
      //  setAnswers(allModels)
      //  setModels(allModels)
    } else {
      standardProgramContext?.models.forEach((model) => {
        model.standardFormAnswerDetails.push({answer: ""});
        setAnswers([...model.standardFormAnswerDetails]);
      });
    }
  };
  const handleChangeModelTitle = (e, item, index, ind) => {
    standardProgramContext?.models.forEach((model, i) => {
      if (index == i) {
        model.standardFormAnswerDetails.forEach((form, k) => {
          if (k == ind) {
            form.answer = e.target.value;
          }
        });
      }
    });
  };

  return (
    <>
      <div className="w-[100%] flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <img
            className="bg-transparent ml-2"
            src={procedures}
            alt="procedures"
          />
          <h1 className="text-[#292069] font-black text-[20px]">
            نماذج حصر الإجراءات
          </h1>
        </div>
        <div>
          <div className="group cursor-pointer relative inline-block  w-28 text-center">
            {/* <img src={lamb} className="w-[25px]"/> */}
            <img className="bg-transparent" src={lamp} alt="lamp"/>
            <div
              className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
              {standardProgramContext.standard?.answerWay == ""
                ? "لا يوجد"
                : standardProgramContext.standard?.answerWay}
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-[100%] mt-5 mr-[88px] flex flex-col justify-evenly items-start overflow-y-auto h-[220px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
        {permissionsCheck("Pages.UserType.POC") ||
        permissionsCheck("Pages.UserType.CIO") ||
        permissionsCheck("Pages.UserType.Delegate") ? (
          <h3 className="text-[#292069] text-[14px] -mb-8">إضافة نماذج</h3>
        ) : (
          <h3 className="text-[#292069] text-[14px] -mb-8">عرض نماذج</h3>
        )}
        {permissionsCheck("Pages.UserType.POC") ||
        permissionsCheck("Pages.UserType.CIO") ||
        permissionsCheck("Pages.UserType.Delegate") ? (
          <div className="flex flex-row justify-between items-center w-[85%]">
            {standardProgramContext.models.map((model, index) => (
              <div
                key={index}
                // className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:grid-cols-4"
              >
                {answers.map((answer, i) => (
                  <div key={i} className="m-3">
                    <input
                      key={`answer-${model.standardFormId}-${answer.answer}`}
                      defaultValue={answer.answer}
                      type={model.standardFormTypeTitle}
                      onChange={(e) =>
                        handleChangeModelTitle(e, answer, index, i)
                      }
                      className="w-4/5 rounded-3xl relative h-[60px] p-5 bg-[#F9F9FF] ml-4"
                      placeholder="اكتب هنا"
                    />
                  </div>
                ))}
              </div>
            ))}
            {standardProgramContext.isRequiredFileModel &&
            standardProgramContext.models.length != 0 ? (
              <button
                onClick={() => addModel()}
                className="bg-[#7D8BAB] rounded-full w-[144px] h-[60px] text-white text-[16px]"
              >
                إضافة
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default StandardAnswerTemplates;
