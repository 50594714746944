import React from "react";
import NewTooltip from "../../../../../../../components/NewTooltip";
import AppSwitch from "../../../../../../../components/form/appSwitch";

const SubRequirement = ({
  title = "",
  checked,
  onChange,
  subRequirementDonotApplyChecked,
  handleDonotApplySubRequirementChange,
  readOnly = false,
  disableDonotApply = false,
}) => {
  return (
    <div className="px-5 my-3 flex flex-row mb-3">
      <input
        type="checkbox"
        className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] mt-2 h-[24px]"
        checked={checked}
        onChange={onChange}
        disabled={readOnly}
        // value={subRequirement.id}
        // id={subRequirement.id}
        // name={subRequirement.id}
      />
      <div className="flex items-center w-full">
        <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
          {title}
        </label>
        <div className="mr-3">
          <NewTooltip content="لا ينطبق" placement="right">
            <AppSwitch
              checked={subRequirementDonotApplyChecked}
              handleChange={handleDonotApplySubRequirementChange}
              disabled={disableDonotApply}
            />
          </NewTooltip>
        </div>
      </div>
    </div>
  );
};

export default SubRequirement;
