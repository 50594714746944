import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true
};
const Forms = () => {
  const creator = new SurveyCreator(creatorOptions);
  return (
    <div className="h-[80vh]">
      <SurveyCreatorComponent creator={creator} />
    </div>
  );
};

export default Forms;
