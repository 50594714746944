import {useState} from "react";
import environment from "../../../../../services/environment";
import PrespectiveItemSkeleton from "./prespectiveItemSkeleton";
import {InputLabel} from "../../../../../components";
import DrawerFilter from "../../../../../components/DrawerFilter";

import {useParams} from "react-router";

const PrespectiveList = ({getPerspectives, renderedPerspective, loading}) => {
  const [perspectiveName, setPerspectiveName] = useState("");
  const {surveyId} = useParams();

  const clearSearch = () => {
    setPerspectiveName("");
  };

  return (
    <div>
      <div className="my-5 mb-10 flex justify-end">
        <DrawerFilter
          handleSearch={() =>
            getPerspectives(environment.getPerspectiveList, {
              Keyword: perspectiveName,
              SurveyId: surveyId,
            })
          }
          handleClear={clearSearch}
        >
          <div className="w-full">
            <InputLabel
              type={"text"}
              name="title"
              value={perspectiveName}
              onChange={(e) => setPerspectiveName(e.target.value)}
              label={"اسم المنظور"}
              isRequired={false}
            />
          </div>
        </DrawerFilter>
      </div>

      <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ">
        {loading ? (
          <>
            <PrespectiveItemSkeleton/>
            <PrespectiveItemSkeleton/>
            <PrespectiveItemSkeleton/>
            <PrespectiveItemSkeleton/>
            <PrespectiveItemSkeleton/>
            <PrespectiveItemSkeleton/>
          </>
        ) : (
          renderedPerspective
        )}
      </div>
    </div>
  );
};

export default PrespectiveList;
