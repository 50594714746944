import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "flowbite-react";

function ReplyActionsBadge({ item }) {
  return (
    <>
      {item.status === 2 && (
        <Badge
          className="text-[#166a45] border-[2px] border-[#abefc6] flex items-center gap-1 font-semibold bg-[#ecfdf3] pb-2 px-5 rounded-xl"
          size="xs"
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="ml-2 text-[#166a45]"
          />
          <sapn>تمت الموافقة</sapn>
        </Badge>
      )}

      {item.status === 3 && (
        <Badge
          className="text-[#b42318] font-semibold border-[2px] border-[#fecdca] bg-[#fef3f2] pb-2 px-5 rounded-xl"
          size="xs"
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="ml-2 text-[#b42318]"
          />
          <sapn>تم الرفض</sapn>
        </Badge>
      )}
    </>
  );
}

export default ReplyActionsBadge;
