import Skeleton from "react-skeleton-loader";

const PrespectiveItemSkeleton = () => {
  return (
    <div className="p-4 border-brdr border-[1px] rounded-lg ">
      <h2 className="text-blue_text">
        <Skeleton width="90%"/>
      </h2>
      <div className="flex justify-between items-center mt-5 mb-12">
        <div className="">
          <p className="text-light text-sm mb-1">
            <Skeleton width="50px"/>
          </p>
          <h3>
            {" "}
            <Skeleton width="200px"/>
          </h3>
        </div>
      </div>
      <Skeleton width="100%" height="43px"/>
    </div>
  );
};

export default PrespectiveItemSkeleton;
