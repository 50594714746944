import { v4 } from 'uuid';

export const serveySettingTabs = (dynamicUrl = '') => {
  return [
    {
      id: v4(),
      title: 'التصنيف والاوزان للمتطلبات',
      url: dynamicUrl + '/settings'
    },
    {
      id: v4(),
      title: 'الإلتزامات والدرجات للمعيار',
      url: `${dynamicUrl}/settings/degrees`
    },
    {
      id: v4(),
      title: 'آلية الإحتساب للإستبيان',
      url: `${dynamicUrl}/settings/strategy`
    },
    {
      id: v4(),
      title: 'قوالب الملاحظة',
      url: `${dynamicUrl}/settings/templates`
    },
    {
      id: v4(),
      title: 'المراحل',
      url: `${dynamicUrl}/settings/phases`
    },
    {
      id: v4(),
      title: 'السياسات والاحكام',
      url: `${dynamicUrl}/settings/polices`
    },
    {
      id: v4(),
      title: 'اعدادات النموذج الابداعي',
      url: `${dynamicUrl}/settings/creative`
    }
  ];
};
