import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";

export const useGetAgenciesStartedAnswering = ({
  currentPage,
  filterValue,
}) => {
  const queryClient = useQueryClient();
  const { stageForDashboard } = useContext(StageContext);
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingAgenciesStartedAnswering } = useQuery({
    queryKey: [
      "AgenciesStartedAnswering",
      currentPage,
      filterValue,
      phaseThreeStageId
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesStartedAnswering,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          StageId: stageForDashboard || 0,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          stageId: phaseThreeStageId,
        },
        true,
        signal
      ),
    enabled: !!stageForDashboard,
  });

  const agenciesStartedAnswering = data?.result?.items;
  const agenciesStartedAnsweringCount = data?.result?.totalCount;
  const totalPages = Math.ceil(agenciesStartedAnsweringCount / 4);

  // Prefetch next page
  if (currentPage < totalPages - 1) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesStartedAnswering",
        currentPage + 1,
        filterValue,
        stageForDashboard,
        phaseThreeStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesStartedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage + 1) * 4,
            MaxResultCount: 4,
            stageId: phaseThreeStageId,
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseThreeStageId,
    });
  }

  if (currentPage > 0) {
    queryClient.fetchQuery({
      queryKey: [
        "AgenciesStartedAnswering",
        currentPage - 1,
        filterValue,
        stageForDashboard,
        phaseThreeStageId
      ],
      queryFn: ({ signal }) =>
        axiosGetRequest(
          environment.getAgenciesStartedAnswering,
          {
            KeyWord: filterValue,
            surveyId: surveyId,
            StageId: stageForDashboard || 0,
            SkipCount: (currentPage - 1) * 4,
            MaxResultCount: 4,
            stageId: phaseThreeStageId,
          },
          true,
          signal
        ),
      enabled: !!stageForDashboard && !!phaseThreeStageId,
    });
  }

  return {
    queryClient,
    data,
    agenciesStartedAnswering,
    agenciesStartedAnsweringCount,
    totalPages,
    isGettingAgenciesStartedAnswering,
  };
};
