import {plusLight, programsIcon} from "../../../../assets/icons/Index";
import {Title} from "../../../../components";
import NewHeader from "../../../../layouts/header/NewHeader";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import environment from "./../../../../services/environment";
import {get} from "./../../../../services/Request";
import {toast} from "react-toastify";
import {permissionsCheck} from "../../../../helpers/utils";

const UserDetails = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getUser + "?Id=" + userId, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        setUserData(res.data.result);
      }
    });
  };

  const getInitials = (fullName) => {
    if (fullName === null) return "- -";
    const initials = fullName
      .split(" ")
      .map((word) => word[0])
      .join(" ")
      .toUpperCase();
    return initials;
  };
  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={t("بيانات المستخدم")}
        subTitle={t("الصلاحيات")}
        textStyle={{ color: "white", fontSize: 18 }}
        single={true}
        titleButton={t("اضافة صلاحيات")}
        withoutButton={true}
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969", width: 200 }}
      />
      {permissionsCheck("Pages.Administration.Users.Get") ? (
        <div>
          <div className="flex justify-center flex-col items-center">
            {userData.profilePicture == null ? (
              <div
                className="rounded-[50px] w-[100px] h-[100px]"
                style={{
                  backgroundColor: "#7337AE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="text-white">
                  {getInitials(localStorage.getItem("fullName"))}
                </span>
              </div>
            ) : (
              <img
                src={process.env.REACT_APP_FRONT_URL + userData.profilePicture}
                className={"rounded-[50px] w-[100px] h-[100px]"}
              />
            )}
            <div className="text-lg text-[#292069] pt-5">
              {userData.fullName}
            </div>
          </div>
          <div className="pt-5">
            <div className="flex flex-row items-center">
              <div className="text-lg text-[#292069] mr-5 ml-5">
                رقم الهاتف :
              </div>
              <div className="text-base text-[#108AE0]">
                {userData.mobile == null ? "لا يوجد" : userData.mobile}
              </div>
            </div>
            <div className="flex flex-row items-center pt-5">
              <div className="text-lg text-[#292069] mr-5 ml-5">
                البريد الالكتروني :
              </div>
              <div className="text-base text-[#108AE0]">
                {userData.emailAddress}
              </div>
            </div>
            <div className="flex flex-row items-center pt-5">
              <div className="text-lg text-[#292069] mr-5 ml-5">
                الجهة التابع لها :
              </div>
              <div className="text-base text-[#108AE0]">
                {userData.agencyTitle == null
                  ? "لا يوجد"
                  : userData.agencyTitle}
              </div>
            </div>
            <div className="flex flex-row items-center pt-5">
              <div className="text-lg text-[#292069] mr-5 ml-5">الدور :</div>
              <div className="text-base text-[#108AE0]">
                {userData.staticRole}
              </div>
            </div>
            <div className="flex flex-row items-center pt-5">
              <div className="text-lg text-[#292069] mr-5 ml-5">الحالة :</div>
              <div className="text-base text-[#108AE0]">
                {userData.isActive ? "نشط" : "غير نشط"}
              </div>
            </div>
            {/* <div className="flex flex-row items-center pt-5">
              <div className="text-lg text-[#292069] mr-5 ml-5">
                الصلاحيات :
              </div>
              <div className="flex flex-row">
                {userData.roleNames?.map((role, index) => (
                  <div key={index} className="text-base text-[#108AE0]">
                    {role + "        " + "    ,    "}
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}
    </div>
  );
};

export default UserDetails;
