import React from "react";
import {logout} from "../../../assets/icons/header";
import {logoutUser} from "../../../helpers/utils";

const NoPermission = () => {
  return (
    <div>
      <h2>you Don't has a permission </h2>
      <div
        className="cursor-pointer w-[50px] h-[50px]"
        onClick={() => {
          logoutUser();
        }}
      >
        <img src={logout} alt="logout Icon" />
      </div>
    </div>
  );
};

export default NoPermission;
