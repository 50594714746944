import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import App from "./App";
import { UploadFilesProvider } from "./components/upload/uploadFilesContext";
import { StageContextProvider } from "./contexts/stageContext";
import "./grid.min.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <StageContextProvider>
      <UploadFilesProvider>
        <App />
      </UploadFilesProvider>
    </StageContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
