import { useEffect, useState } from "react";
import {
  expertId,
  isExpert,
  isExpertManager,
  isPocOfficer,
  isReviewer,
  reviewerId,
} from "../../../helpers/isPermission";
import { useGetAuditorExpertStandards } from "../api/useGetAuditorExpertStandards";
import { Badge } from "flowbite-react";
import NewCard from "../../../components/dashboard/NewCard";
import NewTable from "../../../components/NewTable";
import {
  getCompletionRateProgressBar,
  getStandardRatioBadge,
} from "../../../helpers/utils";
import { drawLeftGreen } from "../../../assets/icons/Index";
import { Link } from "react-router-dom";
import { useDashboardContext } from "../../../contexts/DashboardContext";

function AuditorStandards({ title }) {
  const { setAuditorsDashboardStats, setLoading } = useDashboardContext();

  const [currentPage, setCurrentPage] = useState(0);

  const {
    standards,
    isGettingAuditorExpertStandards,
    totalCount,
    totalPages,
  } = useGetAuditorExpertStandards({ currentPage, reviewerId, expertId });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      standardsCount: totalCount,
    }));
    setLoading(isGettingAuditorExpertStandards);
  }, [totalCount, isGettingAuditorExpertStandards]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // tables columns
  const standardColumn = [
    {
      name: "كود المعيار",
      accessorKey: "standardCode",
      selector: (row) => row.standardCode,
      sortable: true,
      width: "100px",
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.standardCode}</div>
      ),
    },
    // {
    //   name: "اسم المعيار",
    //   accessorKey: "standardTitle",
    //   selector: (row) => row.standardTitle,
    //   sortable: true,
    //   center: true,
    //   cell: (info) => <div className="text-center">{info.standardTitle}</div>,
    // },
    {
      name: "المنظور",
      accessorKey: "perspectiveTitle",
      selector: (row) => row.perspectiveTitle,
      center: true,
      cell: (row) => (
        <Badge
          className="text-white text-center font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.perspectiveTitle}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "المحور",
      accessorKey: "interlocutorTitle",
      selector: (row) => row.interlocutorTitle,
      center: true,
      sortable: true,
      cell: (row) => (
        <Badge
          className="text-white text-center font-medium bg-[#1cc081] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.interlocutorTitle}
        </Badge>
      ),
    },
    {
      name: "عدد الجهات",
      center: true,
      selector: (row) => row.assignedAgenciesCountOnStandard,
    },
    (isExpert || isExpertManager || isPocOfficer) && {
      name: "تم التدقيق بواسطة المدققين",
      center: true,
      selector: (row) => row.reviewedAgenciesCountByReviewers,
    },
    (isReviewer || isExpertManager || isPocOfficer) && {
      name: "تم التدقيق بواسطة الخبراء",
      center: true,
      selector: (row) => row.reviewedAgenciesCountByExperts,
    },
    (isExpert || isExpertManager || isReviewer) && {
      name: "تم التدقيق بواسطة ضباط الاتصال",
      center: true,
      selector: (row) => row.reviewedAgenciesCountByPocOfficers,
    },
    {
      name: "نسبة اٍنجازي",
      center: true,
      cell: (row) => getStandardRatioBadge(row.completionRate),
    },
    {
      name: "اجابات الجهات على المعيار",
      selector: (row) => row.details,
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          <Link to={`Standard/${row.standardId}/agencies/answer`}>
            <img
              src={drawLeftGreen}
              alt="viewNew"
              className="w-[40px] cursor-pointer"
            />
          </Link>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <NewCard title={title || `المعايير (${totalCount})`}>
      <NewTable
        loading={isGettingAuditorExpertStandards}
        columns={standardColumn}
        data={standards || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
}

export default AuditorStandards;
