import {notFound} from "../assets/icons/Index"
import {Button} from "../components"
import {useHistory} from "react-router"

const NotFound=()=>{
    const history = useHistory()
   return(
       <div className="bg-white rounded-[30px] m-5 flex justify-center items-center flex-col p-10">
             <img src={notFound}/>
             <div className={"text-xl text-[#202969] mt-5"}>هذه الصفحة غير موجودة</div>
             <Button title={"الانتقال الي الرئيسية"} onClick={()=>history.push("/")}  style={{backgroundColor:'#1CC081',borderColor:'#1CC081',marginTop:50}}/>
       </div>
   )
}
export default NotFound
