import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { StandardProgramContext } from "../../../../../contexts/StandardProgramContext";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../services/Request";
import environment from "../../../../../services/environment";

export const useGetInquiryList = (
  selectedStatusInquiry,
  setInquiries,
  setStandard,
  setLoading
) => {
  const { standardId, entityId } = useParams();

  const [currentPhase, setCurrentPhase] = useState("");
  const [previousSurveyFiles, setPreviousSurveyFiles] = useState([]);
  const [roles, setRoles] = useState([]);

  const standardProgramContext = useContext(StandardProgramContext);

  //  const onTrigger = (data) => {
  //     parentCallback(
  //         data
  //     );
  // };

  // useEffect(()=>{
  //   onTrigger(getAlert)
  // },[])
  const getAlert = () => {
    getStandardInquiryList();
  };

  const getStandardInquiryList = async () => {
    setLoading(true);
    let newStatus = "";
    if (selectedStatusInquiry?.value != null) {
      newStatus = "&TicketStatus=" + selectedStatusInquiry?.value;
    }

    const res = await axiosGetRequest(
      environment.listInquiriesForStandard +
        "?StandardId=" +
        standardId +
        "&AgencyId=" +
        entityId +
        newStatus
    );
    setLoading(false);

    if (res?.success) {
      // standardProgramContext?.setInquiries(res?.result?.items);
      setInquiries(res?.result?.items);
      getCurrentProgramPhase();
    }
  };

  const getAgency = async (programPhase) => {
    // setLoading(true)
    const res = await axiosGetRequest(environment.getAgency, { Id: entityId });

    if (res?.success) {
      standardProgramContext?.setAgency(res?.result);
      getDegrees(programPhase);
    }
  };

  const getStandard = async (programPhase) => {
    // setLoadingData(true);
    const res = await axiosGetRequest(environment.getStandardForAnswer, {
      id: standardId,
      agencyId: entityId,
    });
    if (res?.success) {
      const pastSurveyStandardsIds = [];
      if (res?.result.pastSurveyStandardAssociations) {
        res?.result.pastSurveyStandardAssociations.forEach((element) => {
          pastSurveyStandardsIds.push(element.pastSurveyStandardId);
        });
      }

      // const standardsQuery =
      //   pastSurveyStandardsIds.length > 0
      //     ? pastSurveyStandardsIds.map((id) => `standardsIds=${id}`).join("&")
      //     : "standardsIds=0";
      const standardsQuery = pastSurveyStandardsIds?.map((id) => id) || [];

      // const fullUrl = `${environment.getQiyas23AttachmentsForAgency}?agencyId=${entityId}&${standardsQuery}`;
      if (standardsQuery?.length > 0) {
        const getPrevFiles = await axiosPostRequest(
          environment.publishQiyas23AttachmentsForAgency,
          standardsQuery,
          { agencyId: entityId }
        );

        setPreviousSurveyFiles(getPrevFiles?.result);
      }

      res?.result?.standardProofs.forEach((element) => {
        element.note = "";
        element.attachments = [];
      });
      standardProgramContext?.setFiles(res?.result?.standardProofs);
      standardProgramContext?.setStandardId(res?.result?.id);
      res?.result?.standardRequirements.forEach((element) => {
        element.decision = 0;
        element.recommendation = "";
        element.donotApply = false;
        element.subStandardRequirements.forEach((subRequirement) => {
          subRequirement.decision = 0;
          subRequirement.recommendation = "";
          subRequirement.donotApply = false;
        });
      });
      standardProgramContext?.setRequirements(
        res?.result?.standardRequirements
      );
      standardProgramContext?.setRequirementsPrev(
        res?.result?.standardRequirements
      );

      // standardProgramContext?.setFileModelId(res?.result?.standardExcelFile?.id)

      standardProgramContext?.setAnswerWay(res?.result?.answerWay);

      standardProgramContext?.setTitle(res?.result?.title);
      // res?.result?.standardForms.forEach(element => {
      //   element.standardFormAnswerDetails = []
      // });
      // standardProgramContext?.setModels(res?.result?.standardForms)
      standardProgramContext?.setStandard(res?.result);
      setStandard(res?.result);
      let arr = [];
      // programPhase.roles.forEach(element => {
      //     arr.push(element.roleName)
      // });
      programPhase?.surveyStageRoles?.forEach((element) => {
        arr.push(element.role.name);
      });
      setRoles(arr);
      programPhase?.surveyStageRoles?.forEach((role) => {
        if (localStorage.getItem("userType") != role.role.name) {
          // getStandardAgency(res?.result, role, programPhase);
        }
      });
    }

    // setLoadingData(false);
  };

  const getDegrees = async (programPhase) => {
    const res = await axiosGetRequest(environment.getCommitmentLevels, {
      standardId,
    });

    if (res?.success) {
      res?.result?.forEach((degree) => {
        degree.label = degree.title;
        degree.value = degree.id;
      });
      standardProgramContext?.setDegrees([
        ...res?.result,
        {
          label: "لا ينطبق",
          title: "لا ينطبق",
          value: -1,
          id: -1,
        },
      ]);
      getStandard(programPhase);
    }
  };

  const getCurrentProgramPhase = async () => {
    const res = await axiosGetRequest(environment.getCurrentSurveyStage, {
      surveyId: localStorage.getItem("surveyId"),
    });

    if (res?.success) {
      setCurrentPhase(res?.result);
      getAgency(res?.result);
    }
  };

  return { getStandardInquiryList };
};
