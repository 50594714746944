import './Component.css';
import { logoCard } from '../assets/imgs/Index';
import { pen, removeIcon } from '../assets/icons/Index';
import NewTooltip from './NewTooltip';

const PCard = ({ isStatic, title, openDelete, openEdit, onClick }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${logoCard})`,
        backgroundPosition: "center",
      }}
      className="shadow-lg	bg-contain bg-no-repeat bg-[#F6F6F6] hover:bg-[#2B2969] rounded-xl p-5 m-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-[#2B2969] duration-300 group"
    >
      <div className="flex flex-row justify-between items-center">
        <span
          onClick={onClick}
          className="text-xl text-[#322E65] break-all group-hover:text-white"
        >
          {title}
        </span>
        <div className="flex flex-row items-center">
          <NewTooltip content={"تعديل"}>
            <div
              className="rounded-full bg-[#FFF6DC] group-hover:bg-white  p-3 mr-5 ml-5 cursor-pointer"
              onClick={openEdit}
            >
              <img src={pen} />
            </div>
          </NewTooltip>
          {isStatic == true ? null : (
            <NewTooltip content={"حذف"}>
              <div
                className="rounded-full bg-[#FEEDEF] group-hover:bg-white p-3 cursor-pointer"
                onClick={openDelete}
              >
                <img src={removeIcon} />
              </div>
            </NewTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default PCard;
