import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { plusLight } from "../../../../assets/icons/Index";
import { Button, InputLabel } from "../../../../components";
import environment from "../../../../services/environment";
import { get, post, put } from "../../../../services/Request";
import { toast } from "react-toastify";
import { Accordion } from "flowbite-react";
import { permissionsCheck } from "../../../../helpers/utils";

const PermissionsDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [checked, setChecked] = useState(false);
  const [modules, setModules] = useState([]);
  useEffect(() => {
    getRole();
  }, [id]);

  const getAllPermissions = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllPermissions, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        let allModule = [];
        res.data.result.items.forEach((element) => {
          allModule.push(element.name);
          setModules(allModule);
          let arr = [];
          element.children.forEach((permission, index) => {
            permission.label = permission.displayName;
            permission.value = permission.name;
            permission.id = permission.name;
          });
        });
        setRoles(res.data.result.items);
      }
    });
  };

  const getRole = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getRoleForEdit + "?Id=" + id, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        let newPermissions = [];
        setRole(res.data.result.role);
        setChecked(res.data.result.role.isStatic);
        let arr = [];
        res.data.result.permissions.forEach((permission, index) => {
          permission.label = permission.displayName;
          permission.value = permission.name;
          permission.id = permission.name;
          arr.push(permission.name);
        });
        setIds(arr, getAllPermissions());
      }
    });
  };

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids?.map((i) => i).includes(id);
  };

  const removeDuplicates = (arr) => {
    return arr.filter(
      (obj, index, self) => index === self.findIndex((t) => t === obj)
    );
  };

  const editRole = (values) => {
    setLoading(true);
    
    //  return true
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let arr = [];
    ids.forEach((item) => {
      // let modified_page_name = ""
      // modified_page_name = item.replace(".", "", 2)
      //  
      var result = item.replace(/(\.[^.]*)\..*/, "$1");
      
      arr.push(result);
    });
    
    // ids.unshift("Pages.Users")
    // ids.unshift("Pages.Roles")
    // ids.unshift("Pages.Indicators")
    // ids.unshift("Pages.Dashboard")

    let data = {
      id: role.id,
      name: role.name,
      grantedPermissions: ids.concat(removeDuplicates(arr)),
      displayName: role.name,
      normalizedName: role.name,
      description: role.name,
      isStatic: true,
    };
    
    // return true

    put(environment.editRole, data, config, (res) => {
      toast.success("تم تعديل الدور بنجاح");
      getRole();
    });
  };

  const changeRoleStatus = (values) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    post(
      environment.switchRoleStatus + "?roleId=" + role.id,
      "",
      config,
      (res) => {
        toast.success("تم تغيير  حالة الدور بنجاح");
        getRole();
      }
    );
  };

  const handleChangeRoleStatus = (value) => {
    setChecked(value, changeRoleStatus());
  };
  return (
    <div>
      {/* {role.name == "Admin" ? null : (
        <ReactSwitch disabled={true} onChange={handleChangeRoleStatus} checked={checked} />
      )} */}
      <Accordion>
        {roles.map((role, index) => (
          <Accordion.Panel key={index}>
            <Accordion.Title>{role.displayName}</Accordion.Title>
            <Accordion.Content>
              <InputLabel
                handleCheckbox={handleCheckbox}
                message={"لا يوجد صلاحيات"}
                isCheckedCheckbox={isCheckedCheckbox}
                type={"checkbox"}
                items={role.children}
                disabled={id == 1 ? true : false}
              />
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
      {permissionsCheck("Pages.Roles.Edit") ? (
        <div className="text-left mt-5">
          <Button
            onClick={() => editRole()}
            withIcon={true}
            icon={plusLight}
            title={t("modules.main.users.permissions.titleEditButton")}
            typeColor={"dark"}
            style={{ backgroundColor: "#00114E", width: 160 }}
          />
        </div>
      ) : null}
    </div>
  );
};
export default PermissionsDetail;
