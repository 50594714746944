import React, { useContext, useEffect, useState } from "react";
import NewCard from "../../../components/dashboard/NewCard";
import NewTable from "../../../components/NewTable";
import environment from "../../../services/environment";
import { StageContext } from "../../../contexts/stageContext";
import { Badge } from "flowbite-react";
import { Link, useHistory } from "react-router-dom";
import { axiosGetRequest } from "../../../services/Request";

const UnstanderedCard = ({ title }) => {
  const history = useHistory();

  const { currentStage, stageForDashboard } = useContext(StageContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [notAnsweredStandards, setNotAnsweredStandards] = useState([]);
  const [loading, setLoading] = useState(false);

  const agencyId = localStorage.getItem("agencyId");
  const surveyId = localStorage.getItem("surveyId");

  const getNotAnsweredStandards = async (page) => {
    setLoading(true);

    if (currentStage?.id) {
      const res = await axiosGetRequest(environment.getNotAnsweredStandardForAgency, {
        surveyStageId: stageForDashboard,
        agencyId: agencyId,
        MaxResultCount: 5,
        SkipCount: page * 5,
      });
      if (res?.success) {
        setNotAnsweredStandards(res?.result?.items);
        setCount(res?.result?.totalCount);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getNotAnsweredStandards(0);
  }, [stageForDashboard]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getNotAnsweredStandards(newOffset);
  };

  const columns = [
    {
      name: "رقم المعيار",
      selector: (row) => row.standardCode,
      header: "رقم المعيار",
      accessorKey: "standardCode",
      footer: (props) => props.column.standardCode,
      width: "150px",
    },
    {
      name: "اسم المعيار",
      selector: (row) => row.standardTitle,
      header: "اسم المعيار",
      accessorKey: "standardTitle",
      footer: (props) => props.column.standardTitle,
    },

    {
      name: "عدد المتطلبات",
      selector: (row) => row.requirementsCount,
      header: "عدد المتطلبات",
      accessorKey: "requirementsCount",
      footer: (props) => props.column.requirementsCount,
      center: true,
    },
    {
      name: "عدد المستندات",
      selector: (row) => row.proofsCount,
      header: "عدد المستندات",
      accessorKey: "proofsCount",
      footer: (props) => props.column.proofsCount,
      center: true,
    },
    {
      name: "", // Empty column
      selector: (row) => "الإطــلاع",
      header: "",
      center: true,
      cell: (row) => (
        <Link
          className="z-50 relative"
          to={`/programs/${surveyId}/program/${agencyId}/entity/${row?.standardId}/standard`}
        >
          <Badge
            className="cursor-pointer text-white font-medium bg-violet-800 pb-3 px-5 rounded-xl"
            size="sm"
          >
            الإطــلاع
          </Badge>
        </Link>
      ),
    },
  ];
  return (
    <NewCard title={title || "نسبة الانجاز"}>
      <NewTable
        loading={loading}
        columns={columns}
        data={notAnsweredStandards || []}
        pagination={true}
        totalPages={Math.ceil(count / 5)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default UnstanderedCard;
