import { DropdownMultiSelect, FModal } from "../../../components";
import { InputLabel } from "./../../../components";
import { ErrorMessage, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { programsIcon } from "../../../assets/icons/Index";
// import  {Rating}  from 'react-simple-star-rating'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import environment from "../../../services/environment";
import { axiosPutRequest, get, post, put } from "../../../services/Request";
import ReactPaginate from "react-paginate";
import Skeleton from "react-skeleton-loader";
import {
  faAsterisk,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import UploadAdapter from "../../../components/UploadAdapter";
import "./faq.css";
import DrawerFilter from "../../../components/DrawerFilter";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import { toast } from "react-toastify";
import NewAccordion from "../../../components/NewAccordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { permissionsCheck } from "../../../helpers/utils";
import NewTable from "../../../components/NewTable";
import Cheerio from "cheerio";
import AppActions from "../../../components/appActions";
import { successToast } from "../../../helpers/toast";
import AppDeleteItemModal from "../../../components/Models/appDeleteItemModal";
import NewTooltip from "../../../components/NewTooltip";
// import AppTable from "../../../components/table";
import activeBlocks from "../../../assets/icons/activeBlocks.svg";
import blocks from "../../../assets/icons/blocks.svg";
import table from "../../../assets/icons/table.svg";
import activeTable from "../../../assets/icons/activeTable.svg";

const Faq = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const [loading, setLoading] = useState(false);
  const [addFqaLoading, setAddFqaLoading] = useState(false);

  const [description, setDescription] = useState("");
  const [classifications, setClassifications] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedFaqId, setSelectedFaqId] = useState("");
  const formRef = useRef(null);

  

  // ---------------------------------------------- //
  const handleSubmitAddFqa = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };
  const [selectedClassification, setSelectedClassification] = useState("");
  const [selectedClassificationOption, setSelectedClassificationOption] =
    useState("");

  useEffect(() => {
    getAllClassifications();
  }, []);

  

  const [getClassificationsForFilter, classificationsForFilter] =
    useAxiosGetRequest();

  useEffect(() => {
    getClassificationsForFilter(environment.getAllClassificationsFaq);
  }, []);

  const classificationsOptions =
    classificationsForFilter?.result?.items &&
    classificationsForFilter?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  // ---------------------------------------------- //

  const getAllFaq = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let classification = "";
    let searchText = "";
    if (selectedClassificationOption.value) {
      classification =
        "&ClassificationId=" + selectedClassificationOption.value;
    }

    if (search) {
      searchText = "&Keyword=" + search;
    }
    get(
      environment.getAllFaq +
        "?SkipCount=" +
        page * 6 +
        "&MaxResultCount=" +
        6 +
        classification +
        searchText,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message);
        } else if (res.status == 500) {
          toast.error(res.data.error.message);
        } else if (res.status == 200) {
          
          setTotalPages(res.data.result.totalCount / 6);
          setFaqs(res.data.result.items);
          setLoading(false);
        }
      }
    );
  };
  const getAllClassifications = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllClassificationsFaq, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = { label: item.title, value: item.id, id: item.id };
          arr.push(obj);
        });
        setClassifications(arr, getAllFaq(currentPage));
        setLoading(false);
      }
    });
  };

  const addFaq = (values) => {
    setAddFqaLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      question: values.question,
      classificationId: selectedClassification.id,
      answer: description,
    };

    // return true
    post(environment.createFaq, data, config, (res) => {
      setAddFqaLoading(false);
      setDescription("");
      setSelectedClassification("");
      setSelectedClassificationOption("");
      setOpen(false);
      toast.success("تم اٍضافة سؤال بنجاح");
      getAllFaq(currentPage);
    });
  };

  const changeRating = (newRating, faq) => {
    setSelectedFaqId(faq.id);
    setRating(newRating, updateRateFaq(newRating, faq.id));
  };

  const handleChangeClassification = (value) => {
    setSelectedClassification(value);
  };

  const handleChangeClassificationOption = (value) => {
    setSelectedClassificationOption(value);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllFaq(newOffset);
  };

  const updateRateFaq = (rate, faqId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    put(
      environment.updateRateFaq + "?id=" + faqId + "&rate=" + rate,
      "",
      config,
      (res) => {
        toast.success("تم اٍضافة تقييم بنجاح");
        getAllFaq(currentPage);
      }
    );
  };

  const clearSearch = () => {
    setSelectedClassification("");
    setSearch("");
  };

  const [showTable, setShowTable] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleEditChange = (e) => {
    setSelectedFaq({ ...selectedFaq, [e.target.name]: e.target.value });
  };

  const handleEditFaq = async (values) => {
    const vals = {
      id: selectedFaq?.id,
      question: selectedFaq?.question,
      answer: selectedFaq?.answer,
      classificationId: selectedFaq?.classificationId,
      rate: selectedFaq?.rate,
    };

    const res = await axiosPutRequest(environment?.updateFaq, vals);

    if (res?.success) {
      successToast("تم تعديل السؤال");
      setOpenEditModal(false);
      setSelectedClassificationOption("");
      getAllFaq(currentPage);
    }
  };

  const columns = [
    {
      name: "السؤال",
      selector: (row) => row.question,
      header: "question",
      accessorKey: "question",
      width: "400px",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {row.question}
        </div>
      ),
    },
    {
      name: "الإجابة",
      selector: (row) => row.answer,
      header: "answer",
      accessorKey: "answer",
      footer: (props) => props.column.id,
      cell: (row) => {
        const $ = Cheerio.load(row.answer);
        const text = $("body").text();
        return (
          <div className="text-slate-400 text-[15px] text-right">
            {text?.split(" ")?.length > 20
              ? text?.split(" ").slice(0, 20).join(" ") + "..."
              : text?.split(" ").slice(0, 20).join(" ")}
          </div>
        );
      },
    },
    {
      name: "الإجراءات",
      selector: (row) => row.id,
      header: "id",
      accessorKey: "id",
      width: "150px",
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] w-20 text-right">
          {permissionsCheck("Pages.Faq.Delete") ||
          permissionsCheck("Pages.Faq.Edit") ? (
            <AppActions
              hideDelete={permissionsCheck("Pages.Faq.Delete") ? false : true}
              hideEdit={permissionsCheck("Pages.Faq.Edit") ? false : true}
              handleEditClick={() => {
                setSelectedFaq(row);
                setOpenEditModal(true);
                const classification = classifications?.filter(
                  (item) => item?.value == row?.classificationId
                );
                setSelectedClassification(classification?.[0]);
              }}
              handleDeleteClick={() => {
                setSelectedFaq(row);
                setOpenDeleteModal(true);
              }}
            />
          ) : (
            <p> -- </p>
          )}
        </div>
      ),
    },
  ];

  const handleDeleteFaq = () => {
    getAllClassifications();
  };

  const fqaBreadcumbs = [
    {
      icon: programsIcon,
      title: "الاسئلة الشائعة",
    },
  ];

  // function uploadPlugin(editor) {
  //   editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
  //     return new UploadAdapter(loader);
  //   };
  // }
  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={fqaBreadcumbs}
      btnLabel={"اضافة سؤال"}
      handleBtnClick={() => setOpen(true)}
      hideBtn={permissionsCheck("Pages.Faq.Create") ? false : true}
    >
      <FModal
        content={
          <div className="h-'60%' overflow-scroll-y">
            <Formik
              innerRef={formRef}
              initialValues={{
                question: "",
                classificationId: "",
                answer: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.question) {
                  errors.question = t("العنوان مطلوب");
                }
                if (!selectedClassification.value) {
                  errors.classificationId = t("التصنيف مطلوب ");
                }
                if (!description) {
                  errors.answer = t("الاجابة مطلوبة");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                addFaq(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <div className=" flex flex-col gap-3 mb-5">
                    <div>
                      <InputLabel
                        value={values.question}
                        type={"text"}
                        onChange={handleChange}
                        name={"question"}
                        label={"العنوان"}
                        withoutLabel={false}
                      />
                      <ErrorMessage
                        className="text-red-700 mt-2"
                        name="question"
                        component="div"
                      />
                    </div>
                  </div>
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={selectedClassification}
                    handleChange={handleChangeClassification}
                    placeholder={""}
                    label={t("التصنيفات")}
                    options={classifications}
                    isMultiple={false}
                  />
                  <div className=" text-red-700 mt-1">
                    {errors.classificationId &&
                      touched.classificationId &&
                      errors.classificationId}
                  </div>
                  <div className="flex flex-col mt-5">
                    <label className="label_input text-lg text-[#292069] mb-2">
                      الاٍجابة
                      <span className="mr-1">
                        <FontAwesomeIcon
                          className="w-2 text-red-700"
                          icon={faAsterisk}
                        />
                      </span>
                    </label>

                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        setDescription(editor.getData());
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function (loader) {
                          
                          return new UploadAdapter(loader);
                        };
                      }}
                      config={{
                        language: "ar",

                        simpleUpload: {
                          uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                          withCredentials: true,
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        },
                      }}
                      // config={{
                      //   extraPlugins:[uploadPlugin]
                      // }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                        editor.plugins.get(
                          "FileRepository"
                        ).createUploadAdapter = function (loader) {
                          
                          return new UploadAdapter(loader);
                        };
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                    <div className="text-red-600 mt-3">
                      {errors.answer && touched.answer && errors.answer}
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        }
        open={open}
        submitLabel={"اٍضافة"}
        titleButton={"اضافة سؤال"}
        widthButton={false}
        width={160}
        action={handleSubmitAddFqa}
        loading={addFqaLoading}
        type={"submit"}
        setOpen={() => setOpen(false)}
        headerTitle={t("اضافة سؤال")}
      />
      <div className="bg-white rounded-xl">
        <div className="mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => getAllFaq(currentPage)}
            handleClear={clearSearch}
          >
            <div className="w-full">
              <div className=" flex flex-col gap-3">
                <label className="label_input text-lg text-[#292069] ">
                  اسم السؤال
                </label>
                <InputLabel
                  value={search}
                  type={"text"}
                  onChange={(e) => handleChangeSearch(e)}
                  name={"question"}
                  label={"اسم السؤال"}
                  withoutLabel={true}
                />
              </div>
            </div>

            <div className="w-full">
              <DropdownMultiSelect
                withoutLabel={false}
                isSearchable={false}
                value={selectedClassificationOption}
                options={classificationsOptions}
                isMultiple={false}
                name="classifications"
                handleChange={handleChangeClassificationOption}
                placeholder=""
                label={"التصنيفات"}
                isRequired={false}
              />
            </div>
          </DrawerFilter>
        </div>
        {permissionsCheck("Pages.Faq.GetAll") ? (
          <div className="flex flex-row justify-end mb-5 items-center">
            {/* <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3> */}
            <div className="flex flex-row justify-between items-center">
              {/* <div onClick={() => setShowTable(true)} className={"flex flex-col justify-evenly items-center ml-2 p-2 cursor-pointer rounded-lg " + (showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
              <NewTooltip content={"عرض في جدول"}>
                <div
                  onClick={() => setShowTable(true)}
                  className="rounded cursor-pointer"
                >
                  <img
                    src={showTable ? activeTable : table}
                    alt="table view"
                    className="w-12 h-12"
                  />
                </div>
              </NewTooltip>
              {/* </div>
                <div onClick={() => setShowTable(false)} className={"p-[6px] cursor-pointer rounded-lg " + (!showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
              <NewTooltip content={"عرض في بطاقات"}>
                <div
                  onClick={() => setShowTable(false)}
                  className="rounded mr-2 cursor-pointe w-12 h-12r"
                >
                  <img
                    src={showTable ? blocks : activeBlocks}
                    alt="blocks view"
                    className="w-12 h-12"
                  />
                </div>
              </NewTooltip>
              {/* </div> */}
            </div>
          </div>
        ) : null}
        {permissionsCheck("Pages.Faq.GetAll") ? (
          <div>
            {loading ? (
              <div className="flex flex-col gap-10 p-5 py-10 w-full border-2 border-gray-200 rounded-[30px]">
                <div className="flex flex-col gap-2  w-full">
                  <div className="flex justify-between">
                    <Skeleton width={"700px"} height={"20px"} />
                    <Skeleton width={"200px"} height={"20px"} />
                  </div>

                  <div>
                    <Skeleton width={"300px"} height={"20px"} />
                  </div>
                </div>

                <div className="flex flex-col gap-2  w-full">
                  <div className="flex justify-between">
                    <Skeleton width={"700px"} height={"20px"} />
                    <Skeleton width={"200px"} height={"20px"} />
                  </div>

                  <div>
                    <Skeleton width={"300px"} height={"20px"} />
                  </div>
                </div>

                <div className="flex flex-col gap-2  w-full">
                  <div className="flex justify-between">
                    <Skeleton width={"700px"} height={"20px"} />
                    <Skeleton width={"200px"} height={"20px"} />
                  </div>

                  <div>
                    <Skeleton width={"300px"} height={"20px"} />
                  </div>
                </div>
              </div>
            ) : faqs.length == 0 ? (
              <div className="text-center text-[#2B2969] text-lg mt-5">
                لا يوجد اسئلة
              </div>
            ) : showTable ? (
              <NewTable
                columns={columns}
                data={faqs}
                pagination={false}

                // items={faqs}
                // changeRating={changeRating}
                // selectedFaqId={selectedFaqId}
                // rating={rating}
              />
            ) : (
              <NewAccordion
                items={faqs}
                changeRating={changeRating}
                selectedFaqId={selectedFaqId}
                rating={rating}
              />
            )}
          </div>
        ) : null}

        <FModal
          content={
            <div className="h-'60%' overflow-scroll-y">
              <Formik
                innerRef={formRef}
                initialValues={selectedFaq}
                validate={(values) => {
                  const errors = {};
                  if (!values.question) {
                    errors.question = t("العنوان مطلوب");
                  }
                  if (!selectedClassification.value) {
                    errors.classificationId = t("التصنيف مطلوب ");
                  }
                  if (!description) {
                    errors.answer = t("الاجابة مطلوبة");
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  handleEditFaq();
                }}
              >
                {({
                  values,
                  errors,
                  touched,

                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div>
                    <div className=" flex flex-col gap-3 mb-5">
                      <div>
                        <InputLabel
                          value={selectedFaq.question}
                          type={"text"}
                          onChange={handleEditChange}
                          name={"question"}
                          label={"العنوان"}
                          withoutLabel={false}
                        />
                        <ErrorMessage
                          className="text-red-700 mt-2"
                          name="question"
                          component="div"
                        />
                      </div>
                    </div>
                    <DropdownMultiSelect
                      isSearchable={true}
                      value={selectedClassification}
                      handleChange={handleChangeClassification}
                      placeholder={""}
                      label={t("التصنيفات")}
                      options={classifications}
                      isMultiple={false}
                    />
                    <div className=" text-red-700 mt-1">
                      {errors.classificationId &&
                        touched.classificationId &&
                        errors.classificationId}
                    </div>
                    <div className="flex flex-col mt-5">
                      <label className="label_input text-lg text-[#292069] mb-2">
                        الاٍجابة
                        <span className="mr-1">
                          <FontAwesomeIcon
                            className="w-2 text-red-700"
                            icon={faAsterisk}
                          />
                        </span>
                      </label>

                      <CKEditor
                        editor={ClassicEditor}
                        data={selectedFaq?.answer}
                        onReady={(editor) => {
                          setDescription(editor.getData());
                          editor.plugins.get(
                            "FileRepository"
                          ).createUploadAdapter = function (loader) {
                            
                            return new UploadAdapter(loader);
                          };
                        }}
                        config={{
                          language: "ar",

                          simpleUpload: {
                            uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                            withCredentials: true,
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          },
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                          editor.plugins.get(
                            "FileRepository"
                          ).createUploadAdapter = function (loader) {
                            
                            return new UploadAdapter(loader);
                          };
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                      />
                      <div className="text-red-600 mt-3">
                        {errors.answer && touched.answer && errors.answer}
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          }
          open={openEditModal}
          submitLabel={"تحديث"}
          titleButton={"تعديل السؤال"}
          widthButton={false}
          width={160}
          action={handleEditFaq}
          loading={addFqaLoading}
          type={"submit"}
          setOpen={() => setOpenEditModal(false)}
          headerTitle={t("تعديل السؤال")}
        />

        <AppDeleteItemModal
          id={selectedFaq?.id}
          itemTitle={selectedFaq?.question}
          modalTitle="حذف سؤال"
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          api={environment?.deleteFaq}
          params={{ Id: selectedFaq?.id }}
          removeItemFromList={handleDeleteFaq}
        />

        {/* <AppModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          handleSubmit={handleEditFaq}
        ></AppModal> */}
        {permissionsCheck("Pages.Faq.GetAll") ? (
          <div className="pb-5 pt-5 my-5">
            <ReactPaginate
              nextLabel={
                loading === true ? null : (
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ color: "#A1A9C4", fontSize: 14 }}
                  />
                )
              }
              onPageChange={handlePageClick}
              initialPage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(totalPages)}
              previousLabel={
                loading === true ? null : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: "#A1A9C4", fontSize: 14 }}
                  />
                )
              }
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item-previous"
              previousLinkClassName="page-link-previous"
              nextClassName="page-item-next"
              nextLinkClassName="page-link-next"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        ) : null}
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default Faq;
