import { useEffect, useState } from "react";
import NewTable from "../../../../components/NewTable";
import NewCard from "../../../../components/dashboard/NewCard";
import { getStandardRatioBadge } from "../../../../helpers/utils";
import { useGetAuditors } from "../api/useGetAuditors";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useDashboardContext } from "../../../../contexts/DashboardContext";

function AuditorsCard() {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const { setLoading, setAuditorsDashboardStats } = useDashboardContext();

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const {
    allAuditors,
    totalPages,
    isGettingAllAuditors,
    totalCount,
  } = useGetAuditors({
    currentPage,
    filterValue,
  });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      reviewersCount: totalCount,
    }));
    setLoading(isGettingAllAuditors);
  }, [totalCount, isGettingAllAuditors]);

  const columns = [
    {
      name: "اسم المدقق",
      sortable: true,
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.name}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المسندة الي المدقق",
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.assignedAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المجابة بواسطة الجهات",
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="text-center">{row.answeredAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المدققة",
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.reviewedAgenciesCount}</div>
      ),
    },
    {
      name: "نسبة اٍنجاز المدقق",
      accessorKey: "reviewRatio",
      selector: (row) => row.reviewRatio,
      center: true,
      sortable: true,
      cell: (row) => getStandardRatioBadge(row.reviewRatio),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.GetAllReviewersWithAssignedStandards,
      {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: 0,
        MaxResultCount: 10000,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "اسم المدقق": ele?.name,
        "عدد المعايير الكلية المسندة الي المدقق": ele?.assignedAgenciesCount,
        "عدد المعايير الكلية المجابة بواسطة الجهات": ele?.answeredAgenciesCount,
        "عدد المعايير الكلية المدققة": ele?.reviewedAgenciesCount,
        "نسبة اٍنجاز المدقق": ele?.reviewRatio,
      };
    });

    downloadExcel("المدققين", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={`المدققين (${totalCount})`}>
      <NewTable
        withSearch
        onSearch={handleFilter}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAllAuditors}
        columns={columns}
        data={allAuditors || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
}

export default AuditorsCard;
