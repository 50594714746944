import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AppModal from "../../../components/Models/appModal";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import environment from "../../../services/environment";
import { axiosGetRequest, axiosPostRequest } from "../../../services/Request";
import {
  isAdmin,
  isAgency,
  isExpert,
  isExpertManager,
  isPocOfficer,
  isReviewer,
} from "../../../helpers/isPermission";

const AssignStandardToExpertManager = ({
  showAssignModal,
  setShowAssignModal,
  standardId,
}) => {
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [selectedPOCOfficers, setSelectedPOCOfficers] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [pocOfficers, setPocOfficers] = useState([]);

  const handleChangeExperts = (value) => {
    setSelectedExperts(value || []);
  };
  const handleChangeReviewers = (value) => {
    setSelectedReviewers(value || []);
  };

  const handleChangePOCOfficers = (value) => {
    setSelectedPOCOfficers(value || []);
  };

  useEffect(() => {
    if (isAdmin || isExpertManager) {
      getExperts();
      getReviewers();
      getPOCOfficers();
    }

    if (standardId) {
      setSelectedExperts([]);
      setSelectedReviewers([]);
      setSelectedPOCOfficers([]);
      getAssignedUsers();
    }
  }, [standardId]);

  const getExperts = async () => {
    const res = await axiosGetRequest(environment.getAllExpertLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setExperts(arr);
    if (res?.success) {
    }
  };

  const getPOCOfficers = async () => {
    const res = await axiosGetRequest(environment.getAllPocOfficerLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setPocOfficers(arr);
    if (res?.success) {
    }
  };

  const getReviewers = async () => {
    const res = await axiosGetRequest(environment.getAllReviewersLookUp);
    let arr = [];

    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });

    setReviewers(arr);
    if (res?.success) {
    }
  };

  const getAssignedUsers = async () => {
    const res = await axiosGetRequest(
      environment.getExpertAndReviewerAndPocOfficerByStandardId,
      {
        standardId: standardId,
      }
    );

    if (res?.success) {
      let newExperts = [];
      let newPocOfficers = [];
      let newReviewers = [];
      res?.result?.assignedExperts.forEach((expert, i) => {
        newExperts.push({
          label: expert.fullName,
          value: expert.id,
          id: expert.id,
        });
      });
      setSelectedExperts(newExperts);
      res?.result?.assignedPocOfficiers.forEach((pocOfficer, j) => {
        newPocOfficers.push({
          label: pocOfficer.fullName,
          value: pocOfficer.id,
          id: pocOfficer.id,
        });
      });
      setSelectedPOCOfficers(newPocOfficers);
      res?.result?.assignedReviewer.forEach((reviewer, j) => {
        newReviewers.push({
          label: reviewer.fullName,
          value: reviewer.id,
          id: reviewer.id,
        });
      });
      setSelectedReviewers(newReviewers);
    }
  };

  const assignStandardToReviewer = async () => {
    setLoading(true);
    let newAssignedReviewers = [];
    selectedReviewers.forEach((reviewer, index) => {
      newAssignedReviewers.push({ reviewerId: reviewer.id });
    });
    let data = {
      standardId: standardId,
      assignedReviewers: newAssignedReviewers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToReviewer,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setReviewers(arr);
      toast.success("تم تعيين مدققين بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };
  const assignStandardToExpert = async () => {
    setLoading(true);
    let newAssignedExperts = [];

    selectedExperts.forEach((expert, index) => {
      newAssignedExperts.push({ expertId: expert.id });
    });
    let data = {
      standardId: standardId,
      assignedExperts: newAssignedExperts,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToExpert,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setExperts(arr);
      toast.success("تم تعيين خبراء بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const assignStandardToPOCOfficer = async () => {
    setLoading(true);
    let newAssignedPOCOfficers = [];
    selectedPOCOfficers.forEach((reviewer, index) => {
      newAssignedPOCOfficers.push({ pocOfficierId: reviewer.id });
    });
    let data = {
      standardId: standardId,
      assignedPocOfficiers: newAssignedPOCOfficers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToPocOfficer,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setPocOfficers(arr);
      toast.success("تم تعيين ضباط اتصال بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false, setShowAssignModal(false));
    }
  };

  const handleAssignStandard = () => {
    if (selectedExperts && selectedExperts.length != 0) {
      assignStandardToExpert();
    }
    if (selectedReviewers && selectedReviewers.length != 0) {
      assignStandardToReviewer();
    }
    if (selectedPOCOfficers && selectedPOCOfficers.length != 0) {
      assignStandardToPOCOfficer();
    }
  };
  return (
    <div>
      <AppModal
        handleSubmit={handleAssignStandard}
        open={showAssignModal}
        setOpen={setShowAssignModal}
        loading={loading}
        submitLabel="إضافة"
      >
        <NewDropdownMultiSelect
          label={"الخبراء"}
          placeholder={"اختر خبراء"}
          options={experts}
          handleChange={handleChangeExperts}
          value={selectedExperts}
          isMultiple={true}
        />
        {/* <div className="text-sm text-red-600 mt-3">
            {errors.experts && touched.experts && errors.experts}
          </div> */}
        <div className="mt-3">
          <NewDropdownMultiSelect
            label={"المدققين"}
            placeholder={"اختر مدققين"}
            options={reviewers}
            handleChange={handleChangeReviewers}
            value={selectedReviewers}
            isMultiple={true}
          />
        </div>

        <div className="mt-3">
          <NewDropdownMultiSelect
            label={"ضباط الاتصال"}
            placeholder={"اختر ضباط اتصال"}
            options={pocOfficers}
            handleChange={handleChangePOCOfficers}
            value={selectedPOCOfficers}
            isMultiple={true}
          />
        </div>
      </AppModal>
    </div>
  );
};
export default AssignStandardToExpertManager;
