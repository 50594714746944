import axios from 'axios';
import environment from '../services/environment';

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload = async () => {
    let file = null;
    try {
      file = await this.loader.file;
      
    } catch (err) {}

    const data = new FormData();
    // data.append("typeOption", "upload_image");
    // data.append("upload", file);
    

    data.append("Name", file.name);
    data.append("FileType", "image/*");
    data.append("EntityType", "");
    data.append("File", file);

    return new Promise((resolve, reject) => {
      if (file) {
        axios({
          url: `${process.env.REACT_APP_URL}/${environment.attachmentFile}`,
          method: "post",
          data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
          },
          withCredentials: false,
        })
          .then((res) => {
            // var resData = res.data;
            var resData = res.data.result
            // resData.uri = `${process.env.REACT_APP_URL}`+resData.uri;
            
            // resolve({
            //   default: `https://qiyas-api.wakeb.tech/${resData.uri}`
            // })
            resolve({
              default: process.env.REACT_APP_URL+resData.uri
            });
          })
          .catch((error) => {
            
            reject(error);
          });
      } else {
        reject("Cannot fetch file");
      }
    });
  };

  abort() {
    // Reject promise returned from upload() method.
  }
}
