import { useContext, useEffect, useState } from "react";
import {
  AppInput,
  CModal,
  CSkeleton,
  DropdownMultiSelect,
  FModal,
  InputLabel,
  NewECard,
  SModal,
  Title,
} from "../../../components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import { programsIcon, viewNew ,settingDark} from "../../../assets/icons/Index";
import NewHeader from "../../../layouts/header/NewHeader";
import NewTable from "../../../components/NewTable";
import DrawerFilter from "../../../components/DrawerFilter";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import StanderdItemSkeleton from "../features/standerds/standerdsList/StanderdItemSkeleton";
import AppModal from "../../../components/Models/appModal";
import NewTooltip from "../../../components/NewTooltip";
import { permissionsCheck } from "../../../helpers/utils";
import NewSwitch from "../../../components/NewSwitch";
import { Badge } from "flowbite-react";
import TableCardSwitch from "../../../components/TableCardSwitch";
import AppPagination from "../../../components/Pagination";
import AssignStandardToExpertManager from "./AssignStandardToExpertManager";
import {toast} from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { errorToast } from "../../../helpers/toast";
import AppSwitch from "../../../components/form/appSwitch";
import { StageContext } from "../../../contexts/stageContext";

const AllEntities = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { programId, entityId } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingStandards, setLoadingStandards] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [standardId,setStandardId] = useState("")
  const [checkedAnswer, setCheckedAnswer] = useState(false);
  const [standardCode, setStandardCode] = useState("");

  const [showTable, setShowTable] = useState(false);
  const [totalCounts, setTotalCounts] = useState(null);
  const [currentPhase, setCurrentPhase] = useState("");

  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);

  const [program, setProgram] = useState(false);
  const [countStandards, setCountStandards] = useState([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [standards, setStandards] = useState([
    // { id: 1, title: "معيار ١" },
    // { id: 2, title: "معيار ٢" },
    // { id: 3, title: "معيار ٣" },
  ]);
  const { currentStage } = useContext(StageContext);
  const isThirdStage = currentStage?.displayOrder === 3;

  const [reOpenedStandardChecked, setReOpenedStandardChecked] = useState(
    (permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.Delegate")) && isThirdStage
  );

  // Hack to Know current stage number
  const stageNumber = currentStage?.displayOrder; 
  

  const [
    getInterlocutorForFilter,
    interlocutorForFilter,
  ] = useAxiosGetRequest();
  const [
    getPerspectivesForFilter,
    perspectivesForFilter,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getInterlocutorForFilter(environment.getInterviewers, {
      SurveyId: surveyId,
      MaxResultCount: 10000,
    });
    getPerspectivesForFilter(environment.getPerspectiveList, {
      SurveyId: surveyId,
      MaxResultCount: 10000,
    });
  }, []);

  const PerspectivesOptions =
    perspectivesForFilter?.result?.items &&
    perspectivesForFilter?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const interlocutorOptions =
    interlocutorForFilter?.result?.items &&
    interlocutorForFilter?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const [perspectives, setPerspectives] = useState([]);
  const [interlocutors, setInterlocators] = useState([]);
  const [selectedPerspective, setSelectedPerspective] = useState([]);
  const [selectedInterlocator, setSelectedInterlocator] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  let perspectiveQuery;
  if (selectedPerspective?.length > 0) {
    perspectiveQuery = selectedPerspective
      .map((obj) => `&PerspectivesIds=${obj.value}`)
      .join("");
  } else {
    perspectiveQuery = "";
  }
  let interlocutorQuery;
  if (selectedInterlocator?.length > 0) {
    interlocutorQuery = selectedInterlocator
      .map((obj) => `&InterlocutorsIds=${obj.value}`)
      .join("");
  } else {
    interlocutorQuery = "";
  }

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleChangeEntitiesFilter = (value) => {
    setSelectedEntities(value);
  };

  const handleChangeInterlocators = (value) => {
    setSelectedInterlocator(value);
  };

  const handleChangePerspectives = (value) => {
    setSelectedPerspective(value);
  };

  useEffect(() => {
    getCurrentProgramPhase()
  }, []);

  const getAllFilterData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getProgramPrespectiveAndInterlocator +
        "?programId=" +
        programId,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          let arrInterlocutors = [];
          res.data.result?.interlocutors?.forEach((interlocutor) => {
            arrInterlocutors.push({
              id: interlocutor.id,
              label: interlocutor.title,
              value: interlocutor.id,
            });
          });
          setInterlocators(arrInterlocutors);
          let arrPerspectives = [];
          res.data.result?.perspectives?.forEach((perspective) => {
            arrPerspectives.push({
              id: perspective.id,
              label: perspective.title,
              value: perspective.id,
            });
          });
          if (permissionsCheck("Pages.UserType.Admin")) {
            setPerspectives(arrPerspectives, getProgram());
          } else {
            setPerspectives(arrPerspectives);
          }
        }
      }
    );
  };

  const getProgram = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    get(environment.getProgram + "?Id=" + programId, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        setProgram(res.data.result);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const surveyId = localStorage.getItem("surveyId");

  // complexity is crying at the corner 🙂 
  const getAllStandards = (page, currentPhase, reOpenedStandardChecked) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let interlocators = [];
    let arrInterlocutors = [];
    if (selectedInterlocator != null) {
      selectedInterlocator.forEach((item, index) => {
        arrInterlocutors.push(item.id);
      });
    } else {
      arrInterlocutors = [];
    }
    if (arrInterlocutors.length != 0) {
      arrInterlocutors.forEach((item, index) => {
        interlocators = "&InterlocutorsIds=" + item;
      });
    }

    let perspectives = [];
    let arrPerspectives = [];
    if (selectedPerspective != null) {
      selectedPerspective.forEach((item, index) => {
        arrPerspectives.push(item.id);
      });
    } else {
      arrPerspectives = [];
    }
    if (arrPerspectives.length != 0) {
      arrPerspectives.forEach((item, index) => {
        perspectives = "&PerspectivesIds=" + item;
      });
    }
    let agencies = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      agencies = "&AgencyId=" + [localStorage.getItem("agencyId")];
    }

    let delegateId = "";
    if (permissionsCheck("Pages.UserType.Delegate")) {
      delegateId = "&DelegatedId=" + localStorage.getItem("userId");
    }

    let expertId = "";
    if (permissionsCheck("Pages.UserType.Expert")) {
      expertId = "&AssignedExpertId=" + localStorage.getItem("userId");
    }

    let reviewerId = "";
    if (permissionsCheck("Pages.UserType.Reviewer")) {
      reviewerId = "&AssignedReviewerId=" + localStorage.getItem("userId");
    }

    let pocOfficerId = "";
    if (permissionsCheck("Pages.UserType.POCOfficer")) {
      pocOfficerId = "&AssignedPocOfficierId=" + localStorage.getItem("userId");
    }
    let code = "";
    if (standardCode) {
      code = "&StandardCode=" + standardCode;
    }

    let newCurrentPhase = "";
    if (currentPhase) {
      newCurrentPhase = "&SurveyStageId=" + currentPhase.id;
    }
    let isReopenedStandards = "";
    if (
      reOpenedStandardChecked &&
      (permissionsCheck("Pages.UserType.POC") ||
        permissionsCheck("Pages.UserType.CIO") ||
        permissionsCheck("Pages.UserType.Delegate"))
    ) {
      isReopenedStandards = "&IsReopenedStandards=true";
    }
    let IncludeIsReturnedStandardProperty = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
        permissionsCheck("Pages.UserType.CIO") ||
        permissionsCheck("Pages.UserType.Delegate")
    ) {
      IncludeIsReturnedStandardProperty = "&IncludeIsReturnedStandardProperty=true";
    }


    return get(
      environment.getAllStandardList +
        "?SkipCount=" +
        page * 6 +
        "&MaxResultCount=" +
        6 +
        "&surveyId=" +
        surveyId +
        interlocutorQuery +
        perspectiveQuery +
        agencies +
        code +
        delegateId +
        reviewerId +
        pocOfficerId +
        expertId+
        newCurrentPhase+
        isReopenedStandards+ 
        IncludeIsReturnedStandardProperty,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 404) {
          errorToast("حدث خطأ،يرجى التحدث للدعم الفنى");
          setLoading(false);
          return;
        } else if (res.status == 200) {
          setTotalPages(res.data.result.totalCount / 6);
          setTotalCounts(res.data.result.totalCount);
          // setCheckedAnswer(res.data.result.isAnswerAll);
          setStandards(
            res.data.result.items,
            permissionsCheck("Pages.UserType.POC") ||
              permissionsCheck("Pages.UserType.CIO")
              ? getCheckAnswerdStandard(currentPhase)
              : setLoading(false)
          );
        }
      }
    );
  };

  const getCheckAnswerdStandard = () => {
    // setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const urlSearchParams = new URLSearchParams({
      StageId: localStorage.getItem("surveyStageId"),
      SurveyId: surveyId
    })

    // case: when the user is under an agency
    if(localStorage.getItem("agencyId") !== 'null'){
      urlSearchParams.append("AgencyId", localStorage.getItem("agencyId"))
    }

    get(
      environment.getCheckAnswerdStandard + "?" + urlSearchParams.toString(),
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true, setLoading(false))
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true, setLoading(false))
          );
        } else if (res.status == 200) {
          setCheckedAnswer(res.data.result,setLoading(false));
        }
      }
    );
  };

  const getCurrentProgramPhase = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getCurrentSurveyStage +
        "?surveyId=" +
        localStorage.getItem("surveyId"),
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true, setLoading(false))
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true, setLoading(false))
          );
        } else if (res.status == 200) {
          setCurrentPhase(res.data.result,getAllStandards(0,res.data.result, reOpenedStandardChecked));
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllStandards(newOffset, currentPhase, reOpenedStandardChecked);
  };

  const clear = () => {
    setSelectedInterlocator([]);
    setSelectedPerspective([]);
  };

  const handleActionAnswer = (value) => {
    setOpenAnswer(true, getNotAnsweredAgencyStandards(value));
  };

  const getNotAnsweredAgencyStandards = (value) => {
    setLoadingStandards(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    get(
      environment.getNotAnsweredAgencyStandards +
        "?surveyStageId=" +
        currentPhase.id +
        "&agencyId=" +
        localStorage.getItem("agencyId") +
        "&maxResultCount=" +
        2000,
      config,
      (res) => {
        if (res.status == 200) {
          if (res.data.result.items != 0) {
            setCountStandards(
              res.data.result.items,
              setLoadingStandards(false)
            );
          } else {
            setCountStandards(
              res.data.result.items,
              setCheckedAnswer(value, setLoadingStandards(false))
            );
          }
        }
      }
    );
  };

  const addAnswer = () => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      SurveyStageId: currentPhase.id,
      AgencyId: localStorage.getItem("agencyId"),
    };
    post(environment.submitAllAgencyAnswers, data, config, (res) => {
      if (res.status == 200) {
        toast.success(t("تم اٍرسال جميع الاجابات بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCheckedAnswer(checkedAnswer,setOpenAnswer(false,setLoading(false)));
      }
    });
  };

  function handleChangeEntitiesReOpenedStandard(value) {
    setReOpenedStandardChecked(value);
    if (value) {
      getAllStandards(0, currentPhase, value);
    } else {
      getAllStandards(0, currentPhase, value);
    }
  }

  // tables columns
  const standardColumn = [
    {
      name: "كود المعيار",
      accessorKey: "code",
      selector: (row) => row.code,
      sortable: true,
      cell: (info) => <div className="text-sm text-[#292069]">{info.code}</div>,
    },
    {
      name: "أسم المعيار",
      accessorKey: "title",
      selector: (row) => row.title,
      sortable: true,
      cell: (info) => (
        <div className="text-sm text-[#292069]">{info.title}</div>
      ),
    },
    {
      name: "المنظور",
      accessorKey: "prespective",
      selector: (row) => row.perspectiveTitle,
      center: true,
      cell: (row) => (
        <Badge
          className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.perspectiveTitle}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "المحور",
      accessorKey: "interlocuter",
      selector: (row) => row.interlocutorTitle,
      center: true,
      cell: (row) => (
        <Badge
          className="text-white font-medium bg-[#1cc081] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.interlocutorTitle}
        </Badge>
      ),
      sortable: true,
    },

    {
      name: "إجراءات",
      selector: (row) => row.status,
      sortable: true,
      center: true,
      cell: (info) => (
        <div>
        <NewTooltip
          content={
            permissionsCheck("Pages.UserType.POC") ||
            permissionsCheck("Pages.UserType.CIO") ||
            permissionsCheck("Pages.UserType.Delegate")
              ? "عرض المعايير"
              : "عرض الجهات"
          }
        >
          <div
            onClick={() =>
              history.push(
                permissionsCheck("Pages.UserType.POC") ||
                  permissionsCheck("Pages.UserType.CIO") ||
                  permissionsCheck("Pages.UserType.Delegate")
                  ? `/programs/${programId}/program/${localStorage.getItem(
                      "agencyId"
                    )}/entity/${info.id}/standard`
                  : permissionsCheck("Pages.UserType.Admin")
                  ? `programs/${programId}/program/${entityId}/entity/${info.id}/standard`
                  : `/programs/${programId}/standard/${info.id}/entities`
              )
            }
          >
            <img src={viewNew} className="w-[40px] cursor-pointer" />
          </div>
        </NewTooltip>
        {permissionsCheck("Pages.UserType.ExpertManager") ? (
           <NewTooltip content={"اضافة الجهات لمستخدمين"}>
           <div
             onClick={()=>history.push(`/standard/${info.id}/assign-agencies`)}
             className="rounded-full p-1 bg-[#228B221A] cursor-pointer mr-2 ml-2 flex items-center"
           >
             <FontAwesomeIcon icon={faPlus} style={{fontSize:20,color:'#228B22'}}/>
           </div>
         </NewTooltip>
      ):null}
      {permissionsCheck("Pages.UserType.ExpertManager") && (
        <NewTooltip content={"الاٍعدادات"}>
          <div
            onClick={()=>setOpenAnswer(true)}
            className="rounded-full p-1 bg-[#EE5D501A] cursor-pointer"
          >
            <img src={settingDark} alt="setting icon" />
          </div>
        </NewTooltip>
      )}
        </div>
      ),
    },
    {
      name: "معاد فتحه",
      accessorKey: "isReturnedStandard",
      selector: (row) => row.isReturnedStandard,
      center: true,
      cell: (row) => (
        <Badge
          className="text-white font-medium bg-[#1cc081] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.isReturnedStandard || reOpenedStandardChecked ? "نعم" : "لا"}
        </Badge>
      ),
      omit: !(stageNumber === 3)
    },
  ];

  function handleShowMore(standard) {
    history.push(JSON.parse(localStorage.getItem("agencyId")) != null
        ? `/programs/${programId}/program/${localStorage.getItem("agencyId")}/entity/${standard.id}/standard`
        : permissionsCheck("Pages.UserType.Admin")
        ? `/programs/${programId}/program/${entityId}/entity/${standard.id}/standard`
        : `/programs/${programId}/standard/${standard.id}/entities`
    ,{standardTitle:standard?.titleArabic})
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <AppModal
        loading={loading}
        handleSubmit={() => addAnswer()}
        children={
          <div>
            {countStandards.length == 0 ? (
              <div className="text-[#292069] text-[30px] text-center">
                هل انت متاكد من ارسال الاجابات ؟
              </div>
            ) : (
              <div>
                <div className="text-[#292069] text-lg">
                  لم تتم الاجابة علي هذه المعايير قم بالاجابة عليها اولا لارسال
                  الاجابة
                </div>
                {loadingStandards ? (
                  <CSkeleton height={"200px"} width={"100%"} />
                ) : (
                  <div className="grid mt-3 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 xl:grid-cols-4">
                    {countStandards?.map((item, index) => (
                      <div
                        className="cursor-pointer text-sm text-[#108AE0]"
                        onClick={() =>
                          history.push(
                            `/programs/${programId}/program/${localStorage.getItem(
                              "agencyId"
                            )}/entity/${item.standardId}/standard`
                          )
                        }
                      >
                        {item.standardCode}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        }
        open={openAnswer}
        setOpen={() => {
          setOpenAnswer(false);
          setCheckedAnswer(false);
        }}
        headerTitle={"ارسال الاجابات"}
      />
      <div className="px-5">
        <Title
          subTitle={
            permissionsCheck("Pages.UserType.Admin")
              ? program.titleArabic
              : "المعايير"
          }
          iconTitle={programsIcon}
          subTitleSeconed={"المعايير"}
          seconed={permissionsCheck("Pages.UserType.Admin") ? false : true}
          thired={permissionsCheck("Pages.UserType.Admin") ? true : false}
          title={
            permissionsCheck("Pages.UserType.Admin") ? "المؤشرات" : "قياس ٢٠٢٤"
          }
          withoutButton={true}
          handleChangeSwitch={(value) => handleActionAnswer(value)}
          checked={checkedAnswer}
          withSwitch={
            // permissionsCheck("Pages.UserType.POC") ||
            // permissionsCheck("Pages.UserType.CIO")
            //   ? true
            //   : false
            false
          }
          toPageSeconed={() =>
            permissionsCheck("Pages.UserType.POC") ||
            permissionsCheck("Pages.UserType.CIO") ||
            permissionsCheck("Pages.UserType.Delegate") ||
            permissionsCheck("Pages.UserType.Expert") ||
            permissionsCheck("Pages.UserType.ExpertManager") ||
            permissionsCheck("Pages.UserType.Reviewer")
              ? history.push("/programs")
              : history.push(`/programs/${programId}/program`)
          }
          toPage={() => history.push("/programs")}
        />
      </div>
      <AssignStandardToExpertManager showAssignModal={showAssignModal} setShowAssignModal={setShowAssignModal} standardId={standardId}/>
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <CModal
        confirmModal={() => {
          setOpenDelete(false);
          setOpenConfirmModal(true);
        }}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={"هل أنت متأكد من حذف المستخدم 3001 محمد القحطاني!"}
      />
      <FModal
        content={
          <div>
            <InputLabel
              label={"إسم الجهة عربي "}
              placeholder={"أمانة منطقة الرياض"}
            />
            <InputLabel
              label={"اسم الجهة انجليزي"}
              placeholder={"Al Riyadh Municipality"}
            />
            <InputLabel type={"file"} id={"fileUpload"} label={"الصورة"} />
          </div>
        }
        open={open}
        titleButton={"إضافة جهة حكومية"}
        widthButton={false}
        width={160}
        setOpen={() => setOpen(false)}
        headerTitle={"إضافة جهة جكومية "}
      />
      {permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ? (
        <div
          className="m-10 p-5 flex flex-start rounded-md"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <NewSwitch
            reverse={true}
            onsubmit={(value) => handleActionAnswer(value)}
            value={checkedAnswer}
            setValue={setCheckedAnswer}
            label={
              "لن تتمكن من تغيير الإجابات بعد الإرسال  وتعتبر هذه الإجابات نهائية، هل أنت جاهز للإرسال؟"
            }
          />
        </div>
      ) : null}
      <div className="my-5 mb-7 mx-10 flex justify-between items-center gap-4">
        <div className="h-full">
          {(permissionsCheck("Pages.UserType.POC") ||
          permissionsCheck("Pages.UserType.CIO") ||
          permissionsCheck("Pages.UserType.Delegate")) && stageNumber === 3 ? (
            <div className="h-full flex items-center gap-2">
              <AppSwitch
                checked={reOpenedStandardChecked}
                handleChange={handleChangeEntitiesReOpenedStandard}
              />
              <span>
                المعايير المعاد فتحها
              </span>
            </div>
          ) : null}
        </div>
        <div className="">
          <DrawerFilter
            handleSearch={() => getAllStandards(0, currentPhase, reOpenedStandardChecked)}
            handleClear={() => clear()}
          >
            <div className="w-full">
              <AppInput
                type={"text"}
                value={standardCode}
                name={"standardCode"}
                onChange={(e) => setStandardCode(e.target.value)}
                label={"كود المعيار"}
                variant="gray"
                isRequired={false}
              />
            </div>
            <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={false}
                value={selectedPerspective}
                handleChange={handleChangePerspectives}
                placeholder={t("المنظور")}
                label={t("المنظور")}
                options={PerspectivesOptions}
                isMultiple={true}
              />
            </div>

            <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={false}
                value={selectedInterlocator}
                handleChange={handleChangeInterlocators}
                placeholder={t("المحور")}
                label={t("المحور")}
                options={interlocutorOptions}
                isMultiple={true}
              />
            </div>
          </DrawerFilter>
        </div>
      </div>

      <div className="px-5 bg-white rounded-xl">
        <div className="px-5 bg-white rounded-xl">
          {loading ? (
            <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
              <StanderdItemSkeleton />
            </div>
          ) : (
            <div>
              {standards.length == 0 ? (
                <div className="flex justify-center items-center">
                  <span className="text-lg text-[#2B2969]">
                    {t("لا يوجد معايير")}
                  </span>
                </div>
              ) : (
                <>
                  <div className="flex flex-row justify-between items-center">
                    <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3>
                    <TableCardSwitch
                      showTable={showTable}
                      setShowTable={setShowTable}
                    />
                  </div>
                  {!showTable ? (
                    <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                      {standards.map((standard, index) => (
                        <NewECard
                          isReturnedStandard={stageNumber === 3 && (reOpenedStandardChecked || standard.isReturnedStandard)}
                          openModalAssign={()=>setStandardId(standard.id,setShowAssignModal(true))}
                          items={[
                            {
                              title: "المنظور",
                              detail: standard.perspectiveTitle,
                            },
                            {
                              title: "المحور",
                              detail: standard.interlocutorTitle,
                            },
                            {
                              title: "رقم المعيار",
                              detail: standard.code,
                            },
                          ]}
                          from="standards"
                          answered={permissionsCheck("Pages.UserType.Expert") ? standard.standardExpertAnswerStatus : standard.standardReviewerAnswerStatus}
                          showButtonAnswered={true}
                          title={standard.title}
                          handleAssignReviewerAndExpertAndPOCOfficer={()=> history.push(`/standard/${standard.id}/assign-agencies`)}
                          showMore={() => handleShowMore(standard)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="bg-white">
                      <NewTable
                        data={standards}
                        columns={standardColumn}
                        // selectableRows={true}
                        onSelectedRowsChange={handleChangeEntitiesFilter}
                        pagination={false}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <AppPagination
          loading={loading}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export default AllEntities;
