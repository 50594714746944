import {Formik} from "formik";
import {useEffect, useState} from "react";
import {FModal} from "../../../../components";
import Button from "../../../../components/Button";
import CModal from "../../../../components/CModal";
import InputLabel from "../../../../components/InputLabel";
import PCard from "../../../../components/PCard";
import SModal from "../../../../components/SModal";
import Title from "../../../../components/Title";
import useText from "../../../../helpers/customHooks/useText";
import environment from "../../../../services/environment";
import {get, put, remove} from "../../../../services/Request";

import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import ReactPaginate from "react-paginate";
import Skeleton from "react-skeleton-loader";
import {plusLight, programsIcon} from "../../../../assets/icons/Index";
import NewHeader from "../../../../layouts/header/NewHeader";

const Permissions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(0);
  const [values, handleChange] = useText();
  const [role, setRole] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [message, setMessage] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [roles, setRoles] = useState([]);

  const [ids, setIds] = useState([]);

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids.map((i) => i).includes(id);
  };

  useEffect(() => {
    getAllRoles(0);
  }, []);
  const getAllRoles = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllRoles +
        "?SkipCount=" +
        page * 12 +
        "&MaxResultCount=" +
        12,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(res.data.result.totalCount / 12);
          setRoles(res.data.result.items);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllRoles(newOffset);
  };

  const deleteRole = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteRole + "?id=" + role.id, config, (res) => {
      setMessage(
        {
          type: "success",
          message: t(
            "modules.main.users.permissions.messages.deletePermission"
          ),
        },
        setOpenConfirmModal(
          true,
          setOpenDelete(false, setLoading(false, getAllRoles(0)))
        )
      );
    });
  };

  const editRole = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: role.id,
      name: values.name,
      grantedPermissions: ids,
      displayName: values.name,
      normalizedName: values.name,
      description: values.name,
    };
    put(environment.editRole, data, config, (res) => {
      setMessage(
        {
          type: "success",
          message: t("modules.main.users.permissions.messages.editPermission"),
        },
        setOpenConfirmModal(
          true,
          setOpenEditModal(false, setLoading(false, getAllRoles(0)))
        )
      );
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <CModal
        confirmModal={() => deleteRole()}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={
          t("modules.main.users.permissions.messages.confirmMessage") +
          " " +
          role.name
        }
      />
      <Title
        iconTitle={programsIcon}
        title={t("modules.main.users.permissions.title")}
        seconed={true}
        subTitle={t("modules.main.users.permissions.subTitle")}
        withoutButton={false}
        onClick={() => history.push("/permissions/add")}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton={t("modules.main.users.permissions.titleAddButton")}
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969" }}
      />
      <div className="m-5 p-5 bg-white rounded-xl">
        {loading ? (
          <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            {roles.map((role, index) => (
              <Skeleton
                key={index}
                animated={true}
                color={"#2B296969"}
                width={"100%"}
                height={"100px"}
                count={1}
              />
            ))}
          </div>
        ) : (
          <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            {roles.map((role, index) => (
              <PCard
                key={index}
                openDelete={() => setRole(role, setOpenDelete(true))}
                openEdit={() => history.push(`/permissions/${role.id}/edit`)}
                onClick={() => history.push(`/permissions/${role.id}/edit`)}
                isStatic={role.isStatic}
                title={role.name}
              />
            ))}
          </div>
        )}
        <div className="mt-5">
          <ReactPaginate
            nextLabel={loading == true ? null : t("general.next")}
            onPageChange={handlePageClick}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={loading == true ? null : t("general.previous")}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>

      <FModal
        content={
          <div className="mt-5 bg-white rounded p-5 m-2 w-[100%]">
            <Formik
              initialValues={{
                name: role.name,
                grantedPermissions: ids,
                displayName: role.name,
                normalizedName: role.name,
                description: role.name,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = t(
                    "modules.main.users.permissions.messages.title"
                  );
                }
                if (ids.length == 0) {
                  errors.grantedPermissions = t(
                    "modules.main.users.permissions.messages.listEntites"
                  );
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                editRole(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <InputLabel
                    label={t("modules.main.users.permissions.form.title")}
                    placeholder={t(
                      "modules.main.users.permissions.form.placeholderTitle"
                    )}
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    name="name"
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.name && touched.name && errors.name}
                  </div>
                  <InputLabel
                    handleCheckbox={handleCheckbox}
                    isCheckedCheckbox={isCheckedCheckbox}
                    label={t(
                      "modules.main.users.permissions.form.permissionTasks"
                    )}
                    type={"checkbox"}
                    items={[
                      {
                        id: "مدقق",
                        label: t("modules.main.users.permissions.form.auditor"),
                        value: "مدقق",
                      },
                      {
                        id: "خبير",
                        label: t("modules.main.users.permissions.form.expert"),
                        value: "خبير",
                      },
                      {
                        id: "جهة حكومية",
                        label: t("modules.main.users.permissions.form.entity"),
                        value: "جهة حكومية",
                      },
                    ]}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.grantedPermissions &&
                      touched.grantedPermissions &&
                      errors.grantedPermissions}
                  </div>
                  <div className="text-center">
                    <Button
                      type={"submit"}
                      withIcon={true}
                      icon={plusLight}
                      loading={loading}
                      title={t(
                        "modules.main.users.permissions.titleEditButton"
                      )}
                      typeColor={"dark"}
                      style={{ backgroundColor: "#00114E", width: 160 }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        }
        open={openEditModal}
        titleButton={t("modules.main.users.permissions.titleEditButton")}
        widthButton={false}
        width={160}
        confirm={editRole}
        setOpen={() => setOpenEditModal(false)}
        headerTitle={t("modules.main.users.permissions.titleEditButton")}
      />
    </div>
  );
};

export default Permissions;
