import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";
import {
  isAdmin,
  isInquiryManager,
} from "../../../../../../helpers/isPermission";

const ExpertManagerAnswerDegrees = () => {
  const { newExpertManagerAnswer, expertManagerDegree } = useAnswerContext();

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.agencyFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newExpertManagerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.color ||
            "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.color ||
            "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.expertFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newExpertManagerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.checkedExpertManagerDegree?.title ||
            "لا يوجد"
          }
          circleColor={
            newExpertManagerAnswer?.checkedExpertManagerDegree?.color || "#fff"
          }
          title="إجابة رئيس الخبراء لمرحلة التحقق  الأولى"
        />
        {!isAdmin && !isInquiryManager && (
          <AnswerDegreeItem
            degree={
              expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
                ?.title || "لا يوجد"
            }
            circleColor={
              expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
                ?.color || "#fff"
            }
            title="الدرجة المقترحة من النظام"
          />
        )}
      </div>
    </div>
  );
};

export default ExpertManagerAnswerDegrees;
