import React, {useContext} from "react";
import {StandardProgramContext} from "../../../../../../contexts/StandardProgramContext";
import {permissionsCheck} from "../../../../../../helpers/utils";

const StandardAnswerSideTaps = ({menuElement, setMenuElement}) => {
  const standardProgramContext = useContext(StandardProgramContext);
  
  return (
    <div className="bg-transparent">
      <ul className="text-[#7D8BAB] text-[16px] flex flex-col justify-between items-start mt-3">
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 0 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(0)}
          >
            بيانات المعيار
          </button>
        </li>
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 1 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(1)}
          >
            متطلبات التطبيق
          </button>
        </li>
        {standardProgramContext?.program?.currentPhase?.displayOrder > 2 ? (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 2 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(2)}
            >
              المتطلبات السابقة
            </button>
          </li>
        ) : null}
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 4 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(4)}
          >
            مستندات الاثبات
          </button>
        </li>
        {/* {permissionsCheck("Pages.UserType.CIO") || permissionsCheck("Pages.UserType.POC") || permissionsCheck("Pages.UserType.Delegate") ? ( */}
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 3 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(3)}
          >
            {permissionsCheck("Pages.UserType.POC") ||
            permissionsCheck("Pages.UserType.CIO") ||
            permissionsCheck("Pages.UserType.Delegate")
              ? "اجابة الجهة"
              : permissionsCheck("Pages.UserType.Reviewer")
                ? "اجابة المدقق"
                : permissionsCheck("Pages.UserType.Expert") ? "اجابة الخبير" :
                permissionsCheck("Pages.UserType.ExpertManager") 
                  ? "اجابة رئيس الخبراء" :
                  permissionsCheck("Pages.UserType.POCOfficer") ? 
                  "اجابة ضابط الاتصال" 
                  : "اجابة الجهة"}
          </button>
        </li>
        {/* //  ): null}  */}

        {/* <li className={'w-[168px] p-3 m-2 rounded-r-3xl ' + (menuElement === 5 ? 'bg-[#292069] text-white' : 'bg-[#DBE0EF]')}>
    <button className='w-[100%] text-start' onClick={() => setMenuElement(5)}>نماذج حصر الإجراءات</button>
  </li> */}
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 6 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(6)}
          >
            ملاحظات التدقيق
          </button>
        </li>

      </ul>
    </div>
  );
};

export default StandardAnswerSideTaps;
