import {useEffect, useState} from "react";
import {AppInput, SModal, Title,} from "../../../components";
import {get, remove} from "../../../services/Request";
import environment from "../../../services/environment";
import {useTranslation} from "react-i18next";
import {formatDate, formatTime, permissionsCheck,} from "../../../helpers/utils";
import NewHeader from "../../../layouts/header/NewHeader";
import {programsIcon} from "../../../assets/icons/Index";
import NewTable from "../../../components/NewTable";
import DrawerFilter from "../../../components/DrawerFilter";
import {toast} from "react-toastify";

let obj = {
  forms: [
    { id: 1, answers: [{ value: "test" }] },
    { id: 1, answers: [{ value: "test" }] },
  ],
};

const ActivityLog = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [userName, setUserName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [methodName, setMethodName] = useState("");
  // const [selectedUserName, setSelectedUserName] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [statuses, setStatuses] = useState([
    { id: 1, label: "نشط", value: true },
    { id: 2, label: "غير نشط", value: false },
  ]);

  // const handleChangeServiceNames = (value) => {
  //   setSelectedServiceName(value);
  // };
  // const handleChangeUserNames = (value) => {
  //   selectedUserName(value);
  // };

  // ------------------------------------- //
  const [dateInputs, setDateInputs] = useState({
    from: "",
    to: "",
  });

  const handleChangeDate = (e) => {
    setDateInputs({ ...dateInputs, [e.target.name]: e.target.value });
  };
  // ------------------------------------- //

  // const [getServiceNames, serviceNames] = useAxiosGetRequest();

  // useEffect(() => {
  //   getServiceNames(environment.getActivityLogs);
  // }, []);

  // const serviceNamesOptions =
  //   serviceNames?.result?.items &&
  //   serviceNames?.result?.items?.map((item) => {
  //     return { label: item.serviceName, value: item.serviceName };
  //   });

  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState({});

  useEffect(() => {
    getAllLogs(0);
  }, []);

  const getAllLogs = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let serviceNameQuery = "";
    if (serviceName != "") {
      serviceNameQuery = "&ServiceName=" + serviceName;
    }

    let startDate = "";
    if (dateInputs.from !== "") {
      startDate = `&StartDate=${dateInputs.from}`;
    }
    let endDate = "";
    if (dateInputs.to !== "") {
      endDate = `&EndDate=${dateInputs.to}`;
    }
    let userNameQuery = "";
    if (userName !== "") {
      userNameQuery = `&UserName=${userName}`;
    }
    let methodNameQuery = "";
    if (methodName !== "") {
      methodNameQuery = `&MethodName=${methodName}`;
    }

    get(
      `${environment.getActivityLogs}?MaxResultCount=8&SkipCount=${page * 8}${serviceNameQuery}${startDate}${endDate}${userNameQuery}${methodNameQuery}`,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: "لا يوجد لديك صلاحيات" },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: "error",
              message: "هناك مشكلة في الخدمة يجب العودة للدعم الفني",
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(Math.ceil(res.data.result.totalCount / 8));
          setLogs(res.data.result.items, setLoading(false));
        }
      }
    );
  };

  const onPageChange = (page) => setCurrentPage(page, getAllLogs(page));

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllLogs(newOffset);
  };

  const search = () => {
    getAllLogs(0);
  };
  const clear = () => {
    setUserName("");
    setServiceName("");
    setMethodName("");
    setDateInputs({
      from: "",
      to: "",
    });
  };

  const removeLog = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteUser + "?id=" + id, config, (res) => {
      if (res.status == 200) {
        setDeleteLoading(false);
        getAllLogs(1);
        toast.success("تم حذف هذا النشاط بنجاح");
      } else {
        toast.error("حدث خطأ");
      }
    });
  };

  const ActivityList = [
    {
      name: "التاريخ / الوقت",
      selector: (row) => row.executionTime,
      header: "التاريخ / الوقت",
      accessorKey: "executionTime",
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
            {formatDate(new Date(info.executionTime)) +
              " / " +
              formatTime(new Date(info.executionTime))}
          </span>
        </div>
      ),
    },
    // {
    //   name: "الفترة",
    //   selector: (row) => row.executionDuration,
    //   header: "الفترة",
    //   accessorKey: "executionDuration",
    //   footer: (props) => props.column.id,
    //   cell: (info) => (
    //     <div>
    //       <span className="text-sm text-black">
    //         {info.executionDuration + "      " + "ميللي ثانية"}
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "عنوان ال IP",
      selector: (row) => row.clientIpAddress,
      header: "عنوان ال ip",
      accessorKey: "clientIpAddress",
      center: true,
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.clientIpAddress}</span>
        </div>
      ),
    },
    {
      name: "رقم المستخدم",
      selector: (row) => row.userId,
      header: "رقم المستخدم",
      accessorKey: "userId",
      footer: (props) => props.column.id,
      center: true,
      width: "95px",
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.userId}</span>
        </div>
      ),
    },
    {
      name: "الحساب",
      selector: (row) => row.role,
      header: "الحساب",
      accessorKey: "role",
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="py-3">
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#eff6ff] text-qiyas_blue`}
          >
            {info.role}
          </span>
        </div>
      ),
    },

    {
      name: "اسم الجهة",
      selector: (row) => row.agencyName,
      header: "الجهة",
      accessorKey: "agencyName",
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="py-3">
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#eff6ff] text-qiyas_blue`}
          >
            {info.agencyName == "" ? "لا يوجد" : info.agencyName}
          </span>
        </div>
      ),
    },
    {
      name: "المستخدم",
      selector: (row) => row.userName,
      header: "المستخدم",
      accessorKey: "userName",
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="flex gap-3">
          <div class="text-sm">
            <div class="font-medium text-gray-700">{info.userName}</div>
            <div class="text-gray-400">{info.emailAddress}</div>
          </div>
        </div>
      ),
    },
    {
      name: "الهوية",
      selector: (row) => row.iqama,
      header: "الهوية",
      accessorKey: "iqama",
      footer: (props) => props.column.id,
      sortable: true,
      width:"200px",
      cell: (info) => (<div>{info.iqama == "" ? "لا يوجد " : info.iqama}</div>)
    },
    {
      name: "اسم الخدمة",
      selector: (row) => row.serviceName,
      header: "اسم الخدمة",
      accessorKey: "serviceName",
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div>
          <span className="text-sm text-[#1276B2]">{info.serviceName}</span>
        </div>
      ),
    },
    {
      name: "اسم الملف",
      selector: (row) => row.AttachmentFile,
      header: "اسم الملف",
      accessorKey: "AttachmentFile",
      footer: (props) => props.column.id,
      center: true,
      cell: (row) => (
        <div className="text-center">
          {row.methodName == "AttachmentFile"
            ? JSON.parse(row.parameters)?.input?.name != "undefined"
              ? JSON.parse(row.parameters)?.input?.name
              : "-"
            : "-"}
        </div>
      ),
    },
    {
      name: "التفاعل",
      selector: (row) => row.methodName,
      header: "التفاعل",
      accessorKey: "methodName",
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div>
          <span className="text-sm text-[#1276B2]">{info.methodName}</span>
        </div>
      ),
    },
  ];

  //------------------------------//

  // const initialValues = {
  //   from: "",
  //   to: "",
  // };

  // const currentDate = new Date();

  // const validate = (values) => {
  //   const errors = {};
  //   if (dateInputs.from > dateInputs.to || dateInputs.from > currentDate) {
  //     errors.from = "لابد ان تكون القيمة أصغر من تاريخ اليوم وتاريخ (اٍلي)";
  //   }
  //   if (dateInputs.to < dateInputs.from || dateInputs.to > currentDate) {
  //     errors.to =
  //       "لابد ان تكون القيمة أصغر من تاريخ اليوم وأكبر من تاريخ (الفترة من)";
  //   }

  //   return errors;
  // };
  //------------------------------//

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withoutButton={true}
        onClick={() => setOpen(true)}
        title={"سجل النشاطات"}
        single={true}
        subTitle={"المستخدمين"}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton="إضافة مستخدم"
        style={{ backgroundColor: "#2B2969" }}
      />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <div className="px-5 ">
        <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => search()}
            handleClear={() => clear()}
          >
            {/* <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedServiceName}
                options={serviceNamesOptions}
                isMultiple={false}
                name="serviceName"
                handleChange={handleChangeServiceNames}
                label={"اسم الخدمة"}
              />
            </div> */}

            <AppInput
              type={"text"}
              label={"اسم الخدمة"}
              name="serviceName"
              variant="gray"
              value={serviceName}
              isRequired={false}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <AppInput
              type={"text"}
              label={"اسم العملية"}
              name="methodName"
              variant="gray"
              value={methodName}
              isRequired={false}
              onChange={(e) => setMethodName(e.target.value)}
            />

            <AppInput
              type={"text"}
              label={"اسم المستخدم"}
              name="userName"
              variant="gray"
              value={userName}
              isRequired={false}
              onChange={(e) => setUserName(e.target.value)}
            />

            <div className="w-full flex gap-3">
              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  الفترة من:
                </label>

                <input
                  style={{ border: "2px solid #f4f7fe" }}
                  onChange={handleChangeDate}
                  value={dateInputs.from}
                  type="date"
                  name="from"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>

              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  اٍلي
                </label>

                <input
                  style={{ border: "2px solid #f4f7fe" }}
                  onChange={handleChangeDate}
                  value={dateInputs.to}
                  type="date"
                  name="to"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>
            </div>
          </DrawerFilter>
        </div>
        {permissionsCheck("Pages.Administration.AuditLogs") ? (
          <NewTable
            loading={loading}
            columns={ActivityList}
            data={logs}
            pagination={true}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
          />
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {"ليس لديك صلاحية لهذا"}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityLog;
