import {useState} from "react";

const useText=()=>{
  const [state,setState] = useState({})

  const handlechange=(e)=>{
    e.persist()
    setState(state => ({...state, [e.target.name] : e.target.value }))
  }

  return [state,handlechange]
}

export default useText
