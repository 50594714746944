import React from 'react'
import Select from "react-tailwindcss-select";

const Filter = ({
    value,
    options,
    handleChange,
    isSearchable,
    isMultiple,
    placeholder,
    label,
    withoutLabel,
    isClearable,
    selectedStartDate
}) => {

    return (
        <div className='flex flex-col w-[236.5px] container_dropdown_mult_select_white mt-9 h-[50px]'>
            {withoutLabel ? null : <label className='text-[15px] my-[3px] text-slate-400'>{label}</label>}
            <Select 
                value={value}
                onChange={handleChange}
                isMultiple={isMultiple}
                options={options}
                isSearchable={isSearchable}
                isClearable={isClearable}
                classNames={{
                    list:"h-full overflow-y-scroll border-[#A3AED0]",
                    searchBox:"bg-white w-full border-0 rounded-[30px] outline-none",
                    tagItem: ({ item }) => (
                        `break-all flex flex-row items-center p-2 rounded-lg bg-white`
                    ),
                    control: () => "border border-gray-300 rounded-3xl"
                }}
                placeholder={placeholder}
        /> 
        </div>
    );
};


export default Filter