import { createContext, useContext, useState } from "react";

const AdminDashboardContext = createContext();

function AdminDashboardContextProvider({ children }) {
  const [dashboardStats, setDashboardStats] = useState({});
  const [totalStandardsCount, setTotalStandardsCount] = useState(0);
  const [includedAgenciesCount, setIncludedAgenciesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  return (
    <AdminDashboardContext.Provider
      value={{
        dashboardStats,
        setDashboardStats,
        includedAgenciesCount,
        setIncludedAgenciesCount,
        totalStandardsCount,
        setTotalStandardsCount,
        setLoading,
        loading,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
}

const useAdminDashboardContext = () => {
  const context = useContext(AdminDashboardContext);
  if (!context)
    throw new Error(
      "Calling useAdminDashboardContext Outside AdminDashboardContextProvider"
    );
  return context;
};

export { useAdminDashboardContext, AdminDashboardContextProvider };
