import Skeleton from 'react-skeleton-loader';

const CSkeleton = ({width,height,loading}) => (
      <div className="w-[90%] mr-auto ml-auto">
        <Skeleton loading={loading} width={width} height={height} />
      </div>
  );


  export default CSkeleton
