// cardConfigurations.js

import AuditorsCard from "../admin/phase2/cards/AuditorsCard";
import ExpertsCard from "../admin/phase2/cards/ExpertsCard";
import Phase2Standards from "../admin/phase2/cards/Phase2Standards";
import PocOfficerCard from "../admin/phase2/cards/PocOfficerCard";
import AuditorAxis from "./cards/AuditorAxis";
import AuditorFinishedStandards from "./cards/AuditorFinishedStandards";
import AuditorPerspective from "./cards/AuditorPerspective";
import AuditorRemainingStandards from "./cards/AuditorRemainingStandards";
import AuditorStandards from "./cards/AuditorStandards";

const cardConfigurations = {
  expertManager: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <ExpertsCard /> },
    { id: 3, fullWidth: true, component: <PocOfficerCard /> },
    { id: 4, fullWidth: true, component: <AuditorPerspective /> },
    { id: 5, fullWidth: true, component: <AuditorAxis /> },
    { id: 6, fullWidth: true, component: <Phase2Standards /> },
  ],
  expert: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <PocOfficerCard /> },
    { id: 3, fullWidth: true, component: <AuditorPerspective /> },
    { id: 4, fullWidth: true, component: <AuditorAxis /> },
    { id: 5, fullWidth: true, component: <AuditorStandards /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
  pocOfficer: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <ExpertsCard /> },
    { id: 3, fullWidth: true, component: <AuditorPerspective /> },
    { id: 4, fullWidth: true, component: <AuditorAxis /> },
    { id: 5, fullWidth: true, component: <AuditorStandards /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
  auditor: [
    { id: 1, fullWidth: true, component: <AuditorPerspective /> },
    { id: 2, fullWidth: true, component: <AuditorAxis /> },
    { id: 3, fullWidth: true, component: <AuditorStandards /> },
    { id: 4, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 5, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
};

export default cardConfigurations;
