import React, { useContext } from "react";
import { AddStandardContext } from "../addStandard/addStandardContext";
import RequirementItemForEdit from "./requirementItemForEdit";
import AppButton from "../../../../../components/buttons/appButton";
import environment from "../../../../../services/environment";
import {
  axiosPostRequest,
  axiosPutRequest,
} from "../../../../../services/Request";

const EditRequirementClassifications = () => {
  const { editRequirementClassifications } = useContext(AddStandardContext);

  const renderedRequirements =
    editRequirementClassifications?.length > 0 &&
    editRequirementClassifications.map((item, index) => (
      <RequirementItemForEdit
        key={item.title}
        {...item}
        standardRequirementClassificationId={
          item?.standardRequirementClassificationId
        }
        index={index}
      />
    ));

  const handleSaveRequirementClassifications = async () => {
    const reqClassifications = [];
    const subReqClassifications = [];

    editRequirementClassifications?.forEach((req) => {
      reqClassifications.push({
        requirementId: req?.id,
        classificationId: req?.standardRequirementClassificationId,
      });

      if (req?.subStandardRequirements?.length > 0) {
        req?.subStandardRequirements?.forEach((subReq) => {
          subReqClassifications.push({
            subRequirementId: subReq?.id,
            classificationId: subReq?.standardRequirementClassificationId,
          });
        });
      }
    });

    const res = await axiosPutRequest(
      environment.updateStdReqAndSubStdReqClassification,
      {
        requirements: reqClassifications,
        subRequirements: subReqClassifications,
      }
    );

    
  };

  return (
    <div className="mt-8">
      <div className="p-5 rounded-lg bg-indigo-50">
        <h2 className="font-semibold mb-4"> تعديل تصنيف متطلبات التطبيق </h2>
        <div className="row gap-y-2">{renderedRequirements}</div>
        <div className="text-end">
          <AppButton
            className="mt-4"
            onClick={handleSaveRequirementClassifications}
          >
            حفظ التعديل
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default EditRequirementClassifications;
