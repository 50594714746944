import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { call, programsIcon, sms } from "../../../assets/icons/Index";
import { plus } from "../../../assets/icons/questions";
import {
  Button,
  DropdownMultiSelect,
  FModal,
  InputLabel,
} from "../../../components";
import UploadAdapter from "../../../components/UploadAdapter";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import { toast } from "react-toastify";
import { userImage } from "../../../assets/imgs/Index";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import { errorToast, successToast } from "../../../helpers/toast";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import { permissionsCheck } from "../../../helpers/utils";

const AddTeam = () => {
  const surveyId = localStorage.getItem("surveyId");
  const history = useHistory();
  const { t } = useTranslation();
  const [dateStyle, setDateStyle] = useState({ border: "2px solid #f4f7fe" });
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [message, setMessage] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openExternalMember, setOpenExternalMember] = useState(false);

  const [selectedMemberTeam, setSelectedMemberTeam] = useState([]);
  const [selectedMemberTeamExternal, setSelectedMemberTeamExternal] = useState(
    []
  );
  const [selectedVisitTeamAgenceis, setSelectedVisitTeamAgenceis] = useState(
    []
  );
  const [visitAgenda, setVisitAgenda] = useState("");
  const [selectedVisitTeamStandards, setSelectedVisitTeamStandards] = useState(
    []
  );

  const [loading, setLoading] = useState(false);
  const [addTeamLoading, setAddTeamLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const [users, setUsers] = useState([]);

  const [agencies, setAgencies] = useState([]);
  const [standards, setStandards] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllUsers + "?MaxResultCount=" + 50000, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let newUsers = [];
        res.data.result.items.forEach((user, index) => {
          let obj = { label: user.fullName, id: user.id, value: user.id };
          newUsers.push(obj);
        });
        setUsers(newUsers, getAgencies());
      }
    });
  };

  const getAgencies = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let newAgencies = [];
        res.data.result.forEach((agency, index) => {
          let obj = {
            label: agency.nameInArabic,
            id: agency.id,
            value: agency.id,
          };
          newAgencies.push(obj);
        });
        setAgencies(newAgencies, getStandards());
      }
    });
  };

  const getStandards = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllStandard +
        "?MaxResultCount=" +
        1000 +
        "&SurveyId=" +
        surveyId,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t("general.authError"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(t("general.serverError"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          let newStandards = [];
          res.data.result.items.forEach((standard, index) => {
            let obj = {
              label: standard.code,
              id: standard.id,
              value: standard.id,
            };
            newStandards.push(obj);
          });
          setStandards(newStandards, setLoading(false));
        }
      }
    );
  };

  const handleChangeDate = (e) => {
    if (e.target.value) {
      setDateStyle({ border: "2px solid green" });
    } else {
      setDateStyle({ border: "2px solid red" });
    }
    setDate(e.target.value);
  };

  const handleChangeMemberTeam = (value) => {
    setSelectedMemberTeam(value);
  };

  const handleChangeAgencies = (value) => {
    setSelectedVisitTeamAgenceis(value);
  };

  const handleChangeStandards = (value) => {
    setSelectedVisitTeamStandards(value);
  };

  const handleChangeType = (value) => {
    setSelectedType(value);
  };

  const addTeam = (values) => {
    setAddTeamLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      teamName: values.teamName,
      visitTeamMembers: values.visitTeamMembers,
      visitTeamExternalMembers: values.visitTeamExternalMembers,
      visitTeamAgenceis: values.visitTeamAgenceis,
      visitTeamStandards: values.visitTeamStandards,
      visitAgenda: values.visitAgenda,
    };
    post(environment.addVisitTeam, data, config, (res) => {
      setAddTeamLoading(false);
      if (res.status == 500) {
        localStorage.removeItem("usersExternal");
        errorToast(res.data.error.message);
      } else {
        history.push("/visit/settings");
        localStorage.removeItem("usersExternal");
        successToast("تم اٍضافة فريق بنجاح");
      }
    });
  };

  const addExternalMember = (values) => {
    let usersExternal = [];
    let obj = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      nationalityId: values.nationalityId,
    };
    var a = [];
    a = JSON.parse(localStorage.getItem("usersExternal")) || [];
    a.push(obj);
    localStorage.setItem("usersExternal", JSON.stringify(a));
  };

  const formRef = useRef(null);

  const handleSubmitAddExternalMember = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  // ---------------------------------------------------- //
  const [selectedClassification, setSelectedClassification] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedAgency, setSelectedAgency] = useState([]);

  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination, {
      AgencyClassificationId: selectedClassification.value,
      AgencySectorId: selectedSector.value,
    });
  }, [selectedClassification.value, selectedSector.value]);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item?.nameInArabic || "",
        value: item?.id,
        name: item?.name || "",
      };
    });

  const handleAllAgenciesChange = (value) => {
    setSelectedAgency(value || []);
  };

  // ------------------------------------------------------//
  // for allSectors multiSelect
  const [getAllAgencySectors, allAgencySectors] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencySectors(environment.getAllAgencySectors);
  }, []);

  const allSectorsOptions =
    allAgencySectors?.result?.items &&
    allAgencySectors?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || "", value: item?.id };
    });

  const handleSelectedSector = (value) => {
    setSelectedSector(value || "");
  };

  // for multiSelect
  const [getAllAgencyClassification, allAgencyClassification] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyClassification(environment.getAllAgencyClassification);
  }, []);

  const allClassificationsOptions =
    allAgencyClassification?.result?.items &&
    allAgencyClassification?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || "", value: item?.id };
    });

  const handleSelectedClassification = (value) => {
    setSelectedClassification(value || "");
  };

  // ---------------------------------------------------- //

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={[
        {
          icon: programsIcon,
          title: "اٍعدادات الزيارات",
          url: "/visit/settings",
        },
        {
          title: "اٍضافة فريق",
        },
      ]}
    >
      <FModal
        type={"submit"}
        content={
          <div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                mobile: "",
                nationalityId: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = t("مطلوب اسم ");
                }
                if (!values.email) {
                  errors.email = t("مطلوب البريد الالكتروني");
                }
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!regex.test(values.email)) {
                  errors.email = t(
                    "modules.main.users.users.messages.emailFormat"
                  );
                }
                if (!values.mobile) {
                  errors.mobile = t("مطلوب رقم الموبايل");
                }
                if (!values.nationalityId) {
                  errors.nationalityId = t("مطلوب رقم الهوية");
                }
                return errors;
              }}
              innerRef={formRef}
              onSubmit={(values) => {
                addExternalMember(values);
                setOpenExternalMember(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputLabel
                    style={{
                      borderColor: errors.name
                        ? "red"
                        : values.name != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.name}
                    onChange={handleChange}
                    name={"name"}
                    label={"الاسم"}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.name && touched.name && errors.name}
                  </div>

                  <InputLabel
                    style={{
                      borderColor: errors.email
                        ? "red"
                        : values.email != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.email}
                    onChange={handleChange}
                    name={"email"}
                    label={"البريد الالكتروني"}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.email && touched.email && errors.email}
                  </div>

                  <InputLabel
                    style={{
                      borderColor: errors.mobile
                        ? "red"
                        : values.mobile != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.mobile}
                    onChange={handleChange}
                    name={"mobile"}
                    label={"رقم الهاتف"}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.mobile && touched.mobile && errors.mobile}
                  </div>

                  <InputLabel
                    style={{
                      borderColor: errors.nationalityId
                        ? "red"
                        : values.nationalityId != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                    value={values.nationalityId}
                    onChange={handleChange}
                    name={"nationalityId"}
                    label={"رقم الهوية"}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.nationalityId &&
                      touched.nationalityId &&
                      errors.nationalityId}
                  </div>

                  {/* <div className="text-center">
                    <Button
                      type={"submit"}
                      withIcon={true}
                      icon={plusLight}
                      loading={loading}
                      title={"اضافة مستخدم"}
                      typeColor={"dark"}
                      style={{ backgroundColor: "#00114E", width: 160 }}
                    />
                  </div> */}
                </div>
              )}
            </Formik>
          </div>
        }
        action={handleSubmitAddExternalMember}
        open={openExternalMember}
        titleButton={"اضافة مستخدم"}
        widthButton={false}
        width={160}
        setOpen={() => setOpenExternalMember(false)}
        headerTitle={"اضافة عضو خارجي"}
      />
      <Formik
        initialValues={{
          teamName: "",
          visitTeamMembers: [],
          visitTeamExternalMembers: [],
          visitTeamAgenceis: [],
          visitTeamStandards: [],
          visitAgenda: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.teamName) {
            errors.teamName = t("اسم الفريق مطلوب");
          }

          if (!selectedMemberTeam) {
            errors.visitTeamMembers = t("يجب اختيار اعضاء الفريق");
          }
          if (!selectedMemberTeamExternal) {
            errors.visitTeamExternalMembers = t("يجب اختيار اعضاء خارجيين");
          }
          if (!selectedVisitTeamAgenceis) {
            errors.visitTeamAgenceis = t("يجب اختيار الجهات المشمولة");
          }

          if (!selectedVisitTeamStandards) {
            errors.visitTeamStandards = t("يجب اختيار المعايير");
          }

          // if (!visitAgenda) {
          //   errors.visitAgenda = t("الاجندة مطلوبة");
          // }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let newUsers = [];
          selectedMemberTeam.forEach((item, index) => {
            let obj = { teamMemberId: item.id };
            newUsers.push(obj);
          });
          values.visitTeamMembers = newUsers;
          let newAgencies = [];
          selectedVisitTeamAgenceis.forEach((item, index) => {
            let obj = { teamAgencyId: item.id };
            newAgencies.push(obj);
          });
          values.visitTeamAgenceis = newAgencies;
          let newStandards = [];
          selectedVisitTeamStandards.forEach((item, index) => {
            let obj = { teamStandardId: item.id };
            newStandards.push(obj);
          });

          values.visitTeamStandards = newStandards;
          values.visitAgenda = visitAgenda;

          values.visitTeamExternalMembers = JSON.parse(
            localStorage.getItem("usersExternal")
          );

          addTeam(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-3xl p-5 flex flex-col gap-5"
          >
            <section className="w-full flex flex-col">
              <InputLabel
                style={{
                  borderColor: errors.teamName
                    ? "red"
                    : values.teamName != ""
                    ? "#22bb33"
                    : "transparent",
                }}
                label={t("اسم الفريق")}
                type="text"
                value={values.teamName}
                onChange={handleChange}
                name="teamName"
                className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                // placeholder="أكتب هنا"
              />
              <div className="text-sm text-red-600 mt-1">
                {errors.teamName && touched.teamName && errors.teamName}
              </div>
            </section>

            <section className="w-full flex flex-col">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedMemberTeam}
                handleChange={handleChangeMemberTeam}
                placeholder="ابحث باسم العضو"
                label="أعضاء الفريق"
                options={users}
                isMultiple={true}
              />
              <div className="text-sm text-red-600 mt-1">
                {errors.visitTeamMembers &&
                  touched.visitTeamMembers &&
                  errors.visitTeamMembers}
              </div>
            </section>

            <div
              className="cursor-pointer flex gap-2 items-center"
              onClick={() => setOpenExternalMember(true)}
            >
              <img src={plus} alt="plus" />
              <span>اضافة اعضاء خارجيين</span>
            </div>

            <div className="px-5 mb-5 mt-5 rounded-lg w-full shadow-lg py-5">
              <label className="text-[16px] text-[#292069]">
                اعضاء خارجيين
              </label>
              {JSON.parse(localStorage.getItem("usersExternal"))?.map(
                (item, index) => (
                  <div className="grid mt-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 xl:grid-cols-4">
                    <div className="flex flex-row items-center">
                      <img src={userImage} className="w-[44px]" />
                      <span className="text-[13px] text-[#000000] mr-3 ml-3">
                        {item.name}
                      </span>
                    </div>
                    <div className="flex flex-row items-center">
                      <img src={call} />
                      <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                        {item.mobile}
                      </span>
                    </div>
                    <div className="flex flex-row items-center">
                      <img src={sms} />
                      <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                        {item.email}
                      </span>
                    </div>
                    <div className="flex flex-row items-center">
                      <img src={sms} />
                      <span className="text-[13px] text-[#2A206A] mr-3 ml-3">
                        {item.nationalityId}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="flex flex-col gap-5">
              <div className="">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedClassification}
                  handleChange={handleSelectedClassification}
                  label="تصنيف الجهات"
                  options={allClassificationsOptions}
                  isMultiple={false}
                  isRequired={false}
                />
                <div className="text-red-700 mt-1">
                  {errors.agencyClassificationId &&
                    touched.agencyClassificationId &&
                    errors.agencyClassificationId}
                </div>
              </div>

              <div className="">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedSector}
                  handleChange={handleSelectedSector}
                  label="قطاعات الجهات"
                  options={allSectorsOptions}
                  isMultiple={false}
                  isRequired={false}
                />
                <div className="text-red-700 mt-1">
                  {errors.agencySectorId &&
                    touched.agencySectorId &&
                    errors.agencySectorId}
                </div>
              </div>

              <div>
                <div className="w-full flex flex-col">
                  <NewDropdownMultiSelect
                    isClearable={true}
                    withoutLabel={false}
                    isSearchable={true}
                    isMultiple={true}
                    value={selectedAgency}
                    options={allAgenciesOptions || []}
                    handleChange={handleAllAgenciesChange}
                    placeholder="اختر الجهات الحكومية"
                    label="الجهات المشمولة"
                    isRequired={true}
                  />

                  <div className="text-red-700 mt-1"></div>
                </div>
              </div>

              <div>
                <NewDropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedVisitTeamStandards}
                  handleChange={handleChangeStandards}
                  label="المعايير"
                  options={standards}
                  isMultiple={true}
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.visitTeamStandards &&
                    touched.visitTeamStandards &&
                    errors.visitTeamStandards}
                </div>
              </div>

              <div className="w-full">
                <label className="text-[16px] text-[#292069]">
                  أجندة الإجتماع
                </label>
                <div className="mt-3">
                  <CKEditor
                    editor={ClassicEditor}
                    data={visitAgenda}
                    onReady={(editor) => {
                      setVisitAgenda(editor.getData());
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        function (loader) {
                          return new UploadAdapter(loader);
                        };
                    }}
                    config={{
                      language: "ar",

                      simpleUpload: {
                        uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                        withCredentials: true,
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                        },
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setVisitAgenda(data);
                      editor.plugins.get("FileRepository").createUploadAdapter =
                        function (loader) {
                          return new UploadAdapter(loader);
                        };
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </div>
              </div>
            </div>
            {permissionsCheck("Pages.VisitTeam.Create") ? (
              <div className="w-full flex flex-row justify-end">
                <Button
                  loading={addTeamLoading}
                  type={"submit"}
                  title="حفظ"
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    width: "12rem",
                  }}
                />
              </div>
            ) : null}
          </form>
        )}
      </Formik>
    </HeaderWithBreadcrumbLayout>
  );
};

export default AddTeam;
