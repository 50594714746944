import Skeleton from "react-skeleton-loader";

function FinalAnswersSkeleton() {
  return (
    <div className="flex justify-between items-center bg-gray-100 p-5">
      <Skeleton height="20px" width="200px" color={"#fff"} />
      <Skeleton height="20px" width="300px" color={"#fff"} />
    </div>
  );
}

export default FinalAnswersSkeleton;
