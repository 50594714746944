import {ErrorMessage, Formik} from "formik";
import {InputLabel} from "../../../../components";

function AddFQAClassificationModal({
  inputs,
  onChange,
  handleAddFQAClassification,
  formRef,
}) {
  const initialValues = {
    title: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = "هذا الحقل مطلوب";
    }

    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      innerRef={formRef}
      onSubmit={handleAddFQAClassification}
    >
      {({ handleChange, values }) => (
        <div>
          <div className="flex flex-col gap-3">
            <InputLabel
              label={"اسم التصنيف"}
              name={"title"}
              type={"text"}
              value={inputs.title}
              onChange={onChange}
            />
          </div>
          <ErrorMessage className="text-red-700" name="title" component="div" />
        </div>
      )}
    </Formik>
  );
}

export default AddFQAClassificationModal;
