import NextBtn from "../components/buttons/nextBtn";
import StandardProofFilesHeader from "./standardProofFilesHeader";

const StandardAnswerProofFiles = ({ setMenuElement, children, hideNext, label }) => {
  return (
    <>
      <div className="min-h-[440px] p-3 mb-36 w-full">
        <StandardProofFilesHeader label={label} />

        {children}
      </div>
      {!hideNext && <NextBtn onClick={() => setMenuElement(3)} />}
    </>
  );
};

export default StandardAnswerProofFiles;
