import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router";
import { StageContext } from "../../../../../../contexts/stageContext";
import environment from "../../../../../../services/environment";
import { axiosGetRequest } from "../../../../../../services/Request";
import { IExpertEvaluation } from "../../types";
import { useAnswerContext } from "../answerContext";
import AnswerDegreeItem from "./answerDegreeItem";

interface IAgenciesAnswerParams {
  entityId: string;
  standardId: string;
  programId: string;
}

const AgencyAnswerDegrees = () => {
  const { entityId, standardId } =
    useParams() as IAgenciesAnswerParams;
  const { newAgencyAnswer } = useAnswerContext();
  const { currentStage } = useContext(StageContext);

  const isThirdStage = currentStage.stageNumber === 3;
  const phase2StageId =
    currentStage?.stageIds && (currentStage?.stageIds[1] as number | undefined);
  const isConfidentialAgency  = newAgencyAnswer.isConfidentialAgency


  const { data: expertEvaluation } = useQuery<{
    result: IExpertEvaluation | null;
  }>({
    queryKey: [
      environment.getStandardExpertAnswer,
      phase2StageId,
      entityId,
      standardId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardExpertAnswer,
        {
          standardId,
          agencyId: entityId,
          stageId: phase2StageId,
        },
        undefined,
        signal
      ),
    enabled: isThirdStage,
  });


  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        {!isThirdStage && (
          <AnswerDegreeItem
            degree={newAgencyAnswer?.checkedAgencyDegree?.title || "لا يوجد"}
            circleColor={newAgencyAnswer?.checkedAgencyDegree?.color || "#fff"}
            title="إجابة الجهة فى مرحلة فتح النظام"
            range={undefined}
          />
        )}

        {isThirdStage && (
          <AnswerDegreeItem
            degree={
              newAgencyAnswer?.firstStageAnswer?.finalCommitmentLevel?.title ||
              "لا يوجد"
            }
            circleColor={
              newAgencyAnswer?.firstStageAnswer?.finalCommitmentLevel?.color ||
              "#fff"
            }
            title="إجابة الجهة فى مرحلة فتح النظام"
            range={undefined}
          />
        )}

        {/* {isThirdStage && isConfidentialAgency && (
          <AnswerDegreeItem
            degree={
              expertEvaluation?.result?.finalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              expertEvaluation?.result?.finalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الفريق الوطني للقياس فى مرحلة التحقق"
            range={undefined}
          />
        )} */}

        {isThirdStage && !isConfidentialAgency && (
          <AnswerDegreeItem
            degree={
              expertEvaluation?.result?.finalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              expertEvaluation?.result?.finalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الفريق الوطني للقياس فى مرحلة التحقق  الأولى"
            range={undefined}
          />
        )}

        {isThirdStage && !isConfidentialAgency &&
          <AnswerDegreeItem
            degree={newAgencyAnswer?.checkedAgencyDegree?.title || "لا يوجد"}
            circleColor={newAgencyAnswer?.checkedAgencyDegree?.color || "#fff"}
            title="إجابة الجهة لمرحلة إعادة فتح النظام"
            range={undefined}
          />
        }
      </div>
    </div>
  );
};

export default AgencyAnswerDegrees;
