import {t} from "i18next";
import {useState} from "react";
import {AppInput} from "../../../../../components";
import AppButton from "../../../../../components/buttons/appButton";
import AppColorInput from "../../../../../components/form/appColorInput";
import {useParams} from "react-router";
import {axiosPostRequest} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import {toast} from "react-toastify";
import {ErrorMessage, Form, Formik} from "formik";

const defaultDegreesValue = {
  title: "",
  color: "#000000",
  fixedDegree: "",
  rangeFrom: "",
  rangeTo: "",
};

const AddServeyDegree = ({degreeList, setDegreeList}) => {
  const {surveyId} = useParams();
  const [degree, setDegree] = useState(defaultDegreesValue);
  const [loading, setLoading] = useState(false);

  const handleInputsChange = (e) => {
    setDegree({...degree, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPostRequest(environment.addCommitmentLevel, {
      ...degree,
      surveyId,
    });

    if (res.success == true) {
      toast.success("تم إضافة الدرجة ");
      const newDegrees = [...degreeList, res.result];
      setDegreeList(newDegrees);
      setDegree(defaultDegreesValue);
    }

    setLoading(false);
  };

  const initialValues = {
    title: "",
    color: "#000000",
    fixedDegree: "",
    rangeFrom: "",
    rangeTo: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!degree.title) {
      errors.title = "هذا الحقل مطلوب";
    }
    if (
      !degree.fixedDegree ||
      !(
        +degree.fixedDegree >= +degree.rangeFrom &&
        +degree.fixedDegree <= +degree.rangeTo
      )
    ) {
      errors.fixedDegree =
        "هذا الحقل مطلوب ولابد أن تكون القيمة بين النسبة من والنسبة اٍلي";
    }
    if (
      !degree.rangeFrom ||
      +degree.rangeFrom > +degree.rangeTo ||
      +degree.rangeFrom < 0 ||
      +degree.rangeFrom > 100
    ) {
      errors.rangeFrom =
        "لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أصغر من (النسبة اٍلي)";
    }
    if (
      !degree.rangeTo ||
      +degree.rangeTo < +degree.rangeFrom ||
      +degree.rangeTo < 0 ||
      +degree.rangeTo > 100
    ) {
      errors.rangeTo =
        "لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أكبر من (النسبة من)";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({handleChange, values}) => (
        <Form className="">
          <div className="row">
            <div className="col-md-4 mb-3">
              <AppInput
                id={`title-${0}-${degree.title}`}
                label={t("الدرجة")}
                value={degree.title}
                name="title"
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>

            <div className="col-md-4 mb-3">
              <AppColorInput
                label={t("اللون")}
                value={degree.color.toUpperCase()}
                name="color"
                onChange={(e) => handleInputsChange(e)}
              />
            </div>

            <div className="col-md-4 mb-3">
              <AppInput
                id={`title-${0}-${degree.title}`}
                label={t("النسبة الثابتة")}
                type="number"
                value={degree.fixedDegree}
                name="fixedDegree"
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="fixedDegree"
                component="div"
              />
            </div>

            <div className="col-md-4">
              <AppInput
                id={`title-${0}-${degree.title}`}
                label={t("النسبة من")}
                type="number"
                value={degree.rangeFrom}
                name="rangeFrom"
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="rangeFrom"
                component="div"
              />
            </div>

            <div className="col-md-4">
              <AppInput
                id={`title-${0}-${degree.title}`}
                label={t("النسبة إلى")}
                type="number"
                value={degree.rangeTo}
                name="rangeTo"
                onChange={(e) => handleInputsChange(e)}
              />
              <ErrorMessage
                className="text-red-700"
                name="rangeTo"
                component="div"
              />
            </div>

            <div className="col-md-4 self-end">
              <div className="text-end ">
                <AppButton type="submit" loading={loading}>
                  إضافة جديد
                </AppButton>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddServeyDegree;
