import React from "react";
import SubRequirement from "../standardAnswerRequirements/ui/subRequirement";
import MainRequirement from "../standardAnswerRequirements/ui/mainRequirement";

const ReadOnlyRequirements = ({ requirements }) => {
  const renderedAgencyRequirements =
    requirements?.length > 0 &&
    requirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <MainRequirement
          key={requirement?.id}
          title={requirement?.title}
          hasSubRequirements={hasSubRequirements}
          checked={requirement?.decision}
          requirementDonotApplyChecked={requirement?.donotApply}
          readOnly={true}
        >
          {hasSubRequirements &&
            requirement?.subStandardRequirements?.map((subRequirement) => {
              return (
                <SubRequirement
                  key={subRequirement?.id}
                  title={subRequirement?.title}
                  checked={subRequirement?.decision}
                  subRequirementDonotApplyChecked={subRequirement?.donotApply}
                  readOnly={true}
                />
              );
            })}
        </MainRequirement>
      );
    });

  return renderedAgencyRequirements;
};

export default ReadOnlyRequirements;
