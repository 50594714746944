import Input from "../Input";

const AppCheckbox = ({
  column,
  disabled,
  defaultValue,
  pattern,
  title,
  required,
  onKeyPress,
  checked,
  id,
  label,
  onChange,
}) => {
  return (
    <div
      className={
        column
          ? "flex flex-col items-center justify-between"
          : "flex flex-row items-center justify-between"
      }
    >
      <div
        className={
          column
            ? "flex flex-col mt-2 w-full"
            : "flex-row mt-2 grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 ms:grid-cols-2 w-full"
        }
      >
        <div className="flex flex-row mt-1">
          <Input
            disabled={disabled}
            className="ml-3 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] h-[24px] my-1"
            defaultValue={defaultValue}
            pattern={pattern}
            title={title}
            required={required}
            onKeyPress={onKeyPress}
            style={{ marginTop: 0 }}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            id={id}
            name={label}
            value={id}
          />
          <span for="specifyColor" className="text-[16px] mb-2 shrink-0">
            {label}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppCheckbox;
