import { emptyTable } from "../../../assets/imgs/Index";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCheck,
  faTimes,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Button, FModal, Input, InputLabel, SModal } from "../../../components";
import { useEffect, useState } from "react";
import environment from "../../../services/environment";
import { get, post, put, remove } from "../../../services/Request";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import { permissionsCheck } from "../../../helpers/utils";

const MeetingsList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [agencyId, setAgencyId] = useState(0);
  const [date, setDate] = useState("");
  const [type, setType] = useState(2);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lists, setLists] = useState([]);
  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(1);
  const [meetingType, setMeetingType] = useState(1);
  const listAdmin = [
    {
      id: 1,
      name: "onlineMeeting",
      label: t("modules.main.calendar.type.onlineMeeting"),
    },
    {
      id: 3,
      name: "fieldVisit",
      label: t("modules.main.calendar.type.fieldVisit"),
    },
  ];
  const listAgency = [
    { id: 2, name: "Meeting", label: t("modules.main.calendar.type.meeting") },
  ];
  const [fieldVisits, setFieldVisits] = useState([
    {
      id: 0,
      name: "IsConfidential",
      label: t("modules.main.calendar.fieldVisits.confidential"),
    },
    {
      id: 1,
      name: "FieldVisit",
      label: t("modules.main.calendar.fieldVisits.fieldVisit"),
    },
  ]);

  const customStyles = {
    table: {
      style: {},
    },
    // rows: {
    //     style: {
    //         color:'black',
    //         fontSize:14,
    //         '&:not(:last-of-type)': {
    // 			borderBottomStyle: 'solid',
    // 			borderBottomWidth: '0px',
    // 			borderBottomColor: "white",
    // 		},
    //     },
    // },
    headRow: {
      style: {},
    },
    rows: {
      style: {
        color: "black",
        backgroundColor: "#F9F9FF",
        minHeight: "72px",
        fontSize: 14,
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "0px",
          borderBottomColor: "white",
        },
      },
      stripedStyle: {
        color: "black",
        backgroundColor: "#FFFFFF",
        minHeight: "72px",
        fontSize: 14,
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "0px",
          borderBottomColor: "white",
        },
      },
    },
    headCells: {
      style: {
        padding: "8px",
        backgroundColor: "#F9F9FF",
        minHeight: "40px",
        display: "flex",
        justifyContent: "center",
        color: "#292069",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        padding: "8px",
        minHeight: "72px",
        display: "flex",
        justifyContent: "center",
      },
    },
    pagination: {
      style: {
        color: "#656565",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "white",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "white",
        borderRadius: "15px",
        marginTop: "3%",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "px",
        cursor: "pointer",
        transition: "0.4s",
        color: "#656565",
        fill: "#656565",
        backgroundColor: "transparent",
      },
    },
  };
  const columns = [
    {
      id: "meetingTitle",
      name: t("modules.main.calendar.name"),
      selector: (row) => row.meetingTitle,
    },
    {
      id: "notes",
      name: t("modules.main.calendar.description"),
      selector: (row) => row.notes,
    },
    {
      id: "meetingDate",
      name: t("modules.main.calendar.date"),
      selector: (row) => row.dateStr,
    },
    {
      id: "type",
      name: t("modules.main.calendar.type.title"),
      selector: (row) => row.typeText,
    },
    {
      id: "actions",
      name: "الإجراءات",
      selector: (row) => (
        <div>
          {row.meetingStatus == 0 &&
            permissionsCheck("Pages.UserType.Admin") && (
              <div>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-[#09b109] p-2 cursor-pointer"
                  onClick={(e) => acceptMeeting(row.id)}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-[#FF304A] p-2 cursor-pointer"
                  onClick={(e) => rejectMeeting(row.id)}
                />
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-[#FF304A] p-2 cursor-pointer"
                  onClick={(e) => deleteMeeting(row.id, row.type)}
                />
              </div>
            )}
        </div>
      ),
    },
  ];

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    var dateStr = date.split(" ")[0];
    if (e.target.value == 2) setDate(dateStr + " 00:00");
    else setDate(dateStr);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangeCount = (e) => {
    setCount(e.target.value);
  };

  const handleChangeMeetingType = (e) => {
    setMeetingType(e.target.value);
  };

  useEffect(() => {
    setAgencyId(localStorage.getItem("agencyId"));
    getAllList();
    if (permissionsCheck("Pages.UserType.Admin")) {
      setLists(listAdmin);
      setType(1);
    } else if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      setLists(listAgency);
      setType(2);
    }
  }, []);

  const getAllList = () => {
    setEvents([]);
    var query = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      query = "?agencyId=" + localStorage.getItem("agencyId");
    }
    getAllMeetingRequest(query);
  };

  const getAllMeetingRequest = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    var new_query = query + "?meetingStatus=0";
    if (query.length > 0) new_query = query + "&meetingStatus=0";
    get(environment.getAllMeetingRequest + new_query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            array[i].dateStr = Moment(array[i].meetingDate)
              .locale("ar")
              .format("yyyy-MM-DD__HH:mm");
            array[i].type = 2;
            array[i].typeText = t("modules.main.calendar.type.meeting");
          }
          setEvents((old) => [...old, ...array]);
        }
        setTimeout(() => {
          setLoading(false);
          getAllOnlineMeeting(query);
        }, 500);
      }
    });
  };

  const getAllOnlineMeeting = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAvailableMeetingRoom + query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            array[i].dateStr = Moment(array[i].meetingDate)
              .locale("ar")
              .format("yyyy-MM-DD");
            array[i].type = 1;
            array[i].typeText = t("modules.main.calendar.type.onlineMeeting");
            array[i].meetingTitle = t(
              "modules.main.calendar.type.onlineMeeting"
            );
            array[i].notes = array[i].onlineMeetingRoom.length;
          }
          setEvents((old) => [...old, ...array]);
        }
        setTimeout(() => {
          setLoading(false);
          getAllFieldVisit(query);
        }, 500);
      }
    });
  };

  const getAllFieldVisit = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAvailableFieldVisits + query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            array[i].dateStr = Moment(array[i].meetingDate)
              .locale("ar")
              .format("yyyy-MM-DD");
            array[i].type = 3;
            array[i].typeText =
              array[i].meetingType == 0
                ? t("modules.main.calendar.fieldVisits.confidential")
                : array[i].meetingType == 1
                ? t("modules.main.calendar.fieldVisits.fieldVisit")
                : t("modules.main.calendar.fieldVisits.general");
            array[i].meetingTitle = t("modules.main.calendar.type.fieldVisit");
            array[i].notes = array[i].fieldVisitSlot.length;
          }
          setEvents((old) => [...old, ...array]);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const saveEvent = () => {
    if (type == 1) createOnlineMeeting();
    else if (type == 3) createFieldVisit();
    else {
      if (id == 0) createEvent();
      else updateEvent();
    }
  };

  const createEvent = () => {
    setLoadingModel(true);
    var currentAgencyId = localStorage.getItem("agencyId");
    if (!title) {
      setMessage({
        type: "error",
        message: t("modules.main.calendar.nameRequired"),
      });
      setOpenModal(true);
      return;
    }
    if (
      currentAgencyId == null ||
      currentAgencyId == "null" ||
      currentAgencyId == "" ||
      currentAgencyId == "0"
    ) {
      setMessage({
        type: "error",
        message: t("modules.main.calendar.agencyRequired"),
      });
      setOpenModal(true);
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      meetingTitle: title,
      meetingDate: date,
      notes: description,
      agencyId: localStorage.getItem("agencyId"),
    };
    post(environment.createMeetingRequest, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        getAllList();
        setTimeout(() => {
          setOpen(false);
          setOpenModal(true);
        }, 500);
      }
    });
  };

  const updateEvent = () => {
    setLoadingModel(true);
    if (!title) {
      setMessage({
        type: "error",
        message: t("modules.main.calendar.nameRequired"),
      });
      setOpenModal(true);
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: id,
      meetingTitle: title,
      meetingDate: date,
      notes: description,
      agencyId: agencyId,
    };
    put(environment.updateMeetingRequest, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        var array = [...events];
        const index = array.findIndex((e) => e.groupId === res.data.result.id);
        array.splice(index, 1);
        array.push({
          title: res.data.result.meetingTitle,
          start: res.data.result.meetingDate,
          groupId: res.data.result.id,
          editable: true,
          dateStr: res.data.result.meetingDate.split("T")[0],
          notes: res.data.result.notes,
          agencyId: res.data.result.agencyId,
        });
        setEvents(array);
        setTimeout(() => {
          setOpen(false);
          setOpenModal(true);
        }, 500);
      }
    });
  };

  const createOnlineMeeting = () => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    var rooms = [];
    for (var i = 0; i < count; i++) {
      rooms.push({ title: title });
    }
    let data = {
      meetingDate: date,
      onlineMeetingRoom: rooms,
    };
    post(environment.createOnlineMeeting, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        getAllList();
        setTimeout(() => {
          setOpen(false);
          setOpenModal(true);
        }, 500);
      }
    });
  };

  const createFieldVisit = () => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    var rooms = [];
    for (var i = 0; i < count; i++) {
      rooms.push({ title: title });
    }
    let data = {
      meetingDate: date,
      MeetingType: meetingType,
      fieldVisitSlot: rooms,
    };
    post(environment.createFieldVisit, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        getAllList();
        setTimeout(() => {
          setOpen(false);
          setOpenModal(true);
        }, 500);
      }
    });
  };

  const acceptMeeting = (id) => {
    updateMeetingStatus(id, 1);
  };

  const rejectMeeting = (id) => {
    updateMeetingStatus(id, 2);
  };

  const updateMeetingStatus = (id, status) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: id,
      MeetingStatus: status,
    };
    post(environment.updateMeetingRequestStatus, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        setOpenModal(true);
        setTimeout(() => {
          getAllList();
        }, 500);
      }
    });
  };

  const deleteMeeting = (id, type) => {
    if (type == 1) deleteOnlineMeeting(id);
    if (type == 2) deleteMeetingRequest(id);
    if (type == 3) deleteFieldVisit(id);
  };

  const deleteOnlineMeeting = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteOnlineMeeting + "?id=" + id, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        setOpenModal(true);
        setTimeout(() => {
          getAllList();
        }, 500);
      }
    });
  };

  const deleteMeetingRequest = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteMeetingRequest + "?id=" + id, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        setOpenModal(true);
        setTimeout(() => {
          getAllList();
        }, 500);
      }
    });
  };

  const deleteFieldVisit = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteFieldVisit + "?id=" + id, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
        setOpenModal(true);
      } else if (res.status == 200) {
        setMessage({ type: "success", message: t("تم الإرسال بنجاح") });
        setOpenModal(true);
        setTimeout(() => {
          getAllList();
        }, 500);
      }
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <SModal
        type={message.type}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={message.message}
      />
      <FModal
        headerTitle={t("modules.main.calendar.add")}
        content={
          <div>
            <span className="text-base text-[#656565]">
              {t("modules.main.calendar.type.title")}
            </span>
            <div className="flex flex-row mt-3">
              {lists.map(({ name, id, label }) => (
                <div className="flex flex-row items-center">
                  <Input
                    type={"radio"}
                    id={"type" + id}
                    name="event-type"
                    value={id}
                    checked={type == id}
                    onChange={handleChangeType}
                  />
                  <span className="pr-5 pl-5 text-base text-[#656565]">
                    {label}
                  </span>
                </div>
              ))}
            </div>
            {type == 3 && (
              <div>
                <span className="text-base text-[#656565]">
                  {t("modules.main.calendar.fieldVisits.title")}
                </span>
                <div className="flex flex-row mt-3">
                  {fieldVisits.map(({ name, id, label }) => (
                    <div className="flex flex-row items-center">
                      <Input
                        type={"radio"}
                        id={"visit" + id}
                        name="visit-type"
                        value={id}
                        checked={meetingType == id}
                        onChange={handleChangeMeetingType}
                      />
                      <span className="pr-5 pl-5 text-base text-[#656565]">
                        {label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <InputLabel
              label={t("modules.main.calendar.date")}
              type={type == 2 ? "datetime-local" : "date"}
              value={date}
              onChange={handleChangeDate}
            />
            <InputLabel
              label={t("modules.main.calendar.name")}
              type={"text"}
              value={title}
              onChange={handleChangeTitle}
            />
            {type == 2 && (
              <InputLabel
                label={t("modules.main.calendar.description")}
                type={"textarea"}
                value={description}
                onChange={handleChangeDescription}
              />
            )}
            {(type == 1 || type == 3) && (
              <InputLabel
                label={t("modules.main.calendar.count")}
                type={"text"}
                value={count}
                onChange={handleChangeCount}
              />
            )}

            {Moment() < Moment(date) && (
              <Button
                loading={loadingModel}
                title={t("modules.main.calendar.save")}
                typeColor={"dark"}
                className="pt-3 mt-3"
                withIcon={false}
                style={{
                  backgroundColor: "#00114E",
                  width: 160,
                  marginTop: 10,
                }}
                onClick={() => saveEvent()}
              />
            )}
          </div>
        }
        open={open}
        setOpen={() => setOpen(false)}
      />
      <div className="flex">
        <div className="w-11/12 flex"></div>
        <div className="w-1/12">
          <button
            className="btn border border-[#2b2969] p-[12px] relative z-0 inline-flex items-center justify-start overflow-hidden transition-all bg-[#2B2969] rounded-[30px] hover:border-[#21A096] hover:bg-red group"
            onClick={() => history.push("/calendar")}
          >
            <FontAwesomeIcon
              icon={faCalendarCheck}
              className="text-[#09b109] p-2 cursor-pointer"
            />
          </button>
        </div>
      </div>
      <div className="w-full bg-white p-5">
        <NewTable
          columns={columns}
          data={events}
          noDataComponent={
            <div className="flex flex-col items-center content-center p-5">
              <img src={emptyTable} alt="empty" />
              <span className="text-[14px] text-[#292069]">
                {t("general.noFoundTable")}
              </span>
            </div>
          }
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default MeetingsList;
