import React, { useContext } from "react";
import { StandardProgramContext } from "../../../../../../contexts/StandardProgramContext";
import { permissionsCheck } from "../../../../../../helpers/utils";
import { StageContext } from "../../../../../../contexts/stageContext";
import {
  isAdmin,
  isAgency,
  isInquiryManager,
} from "../../../../../../helpers/isPermission";

const StandardAnswerSideTaps = ({ menuElement, setMenuElement, activeTab }) => {
  const standardProgramContext = useContext(StandardProgramContext);
  const { currentStage } = useContext(StageContext);
  const isThirdStage = currentStage?.displayOrder === 3;

  const agencyAnswerTitle = {
    0: "اجابة الجهة",
    1: "اجابة الخبير ",
    2: "اجابة الجهة",
  }

  return (
    <div className="bg-transparent mt-6">
      <ul className="text-[#7D8BAB] text-[16px] flex flex-col justify-between items-start mt-3">
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 0 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(0)}
          >
            بيانات المعيار
          </button>
        </li>
        {

        }
        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 1 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(1)}
          >
            مستندات الاثبات
          </button>
        </li>

        {/* {standardProgramContext?.program?.currentPhase?.displayOrder > 2 ? (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 2 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(2)}
            >
              المتطلبات السابقة
            </button>
          </li>
        ) : null} */}

        <li
          className={
            "w-[168px] p-3 m-2 rounded-r-3xl " +
            (menuElement === 3 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
          }
        >
          <button
            className="w-[100%] text-start"
            onClick={() => setMenuElement(3)}
          >
            متطلبات التطبيق
          </button>
        </li>
        {/* {permissionsCheck("Pages.UserType.CIO") || permissionsCheck("Pages.UserType.POC") || permissionsCheck("Pages.UserType.Delegate") ? ( */}
        {(
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 4 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(4)}
            >
              {permissionsCheck("Pages.UserType.POC") ||
              permissionsCheck("Pages.UserType.CIO") ||
              permissionsCheck("Pages.UserType.Delegate") ||
              permissionsCheck("Pages.UserType.Admin") ||
              permissionsCheck("Pages.UserType.InquiryManager")
                ? agencyAnswerTitle[activeTab]
                : permissionsCheck("Pages.UserType.Reviewer")
                ? "اجابة المدقق"
                : permissionsCheck("Pages.UserType.POCOfficer")
                ? "اجابة ضابط الاتصال"
                : permissionsCheck("Pages.UserType.Expert")
                ? "اجابة الخبير"
                : permissionsCheck("Pages.UserType.ExpertManager")
                ? "اجابة رئيس الخبراء"
                : "اجابة الجهة"}
            </button>
          </li>
        )}
        {permissionsCheck("Pages.UserType.Expert") ||
        permissionsCheck("Pages.UserType.Reviewer") ||
        // permissionsCheck("Pages.UserType.ExpertManager") ||
        permissionsCheck("Pages.UserType.POCOfficer") ? (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 5 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(5)}
            >
              زيارة ميدانية
            </button>
          </li>
        ) : null}
        {/* //  ): null}  */}

        {/* <li className={'w-[168px] p-3 m-2 rounded-r-3xl ' + (menuElement === 5 ? 'bg-[#292069] text-white' : 'bg-[#DBE0EF]')}>
    <button className='w-[100%] text-start' onClick={() => setMenuElement(5)}>نماذج حصر الإجراءات</button>
  </li> */}
        {!isAgency && (isAdmin || permissionsCheck("Pages.UserType.InquiryManager") ) && (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 6 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(7)}
            >
              الملاحظات
            </button>
          </li>
        )}

        {!isAgency && !(isAdmin || permissionsCheck("Pages.UserType.InquiryManager") ) && (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 6 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(6)}
            >
              الملاحظات
            </button>
          </li>
        )}

        {!isAdmin && !isInquiryManager && (
          <li
            className={
              "w-[168px] p-3 m-2 rounded-r-3xl " +
              (menuElement === 7 ? "bg-[#292069] text-white" : "bg-[#DBE0EF]")
            }
          >
            <button
              className="w-[100%] text-start"
              onClick={() => setMenuElement(7)}
            >
              ملاحظات التدقيق
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default StandardAnswerSideTaps;
